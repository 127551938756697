import Backbone from 'backbone';

const SETTINGS = {
	/** Notification id counter. */
	idCounter: 0,

	/** Duration for which a notification is considered new. */
	newDuration: 3000
};

/**
 A notification, that must have either a "title" or "body" (see attributes
 list below).

 Attributes:

 - {string} title
 Notification title.

 - {string} body
 Notification text.

 - {string} [icon]
 Name of icon to display. Defaults to 'info'.

 - {number|Date} [timestamp]
 Milliseconds since 1 January 1970 00:00:00 UTC, or a Date.

 */
const Notification = Backbone.Model.extend({
	settings: SETTINGS,
	defaults: {
		icon: 'info', // default icon
		bubble: true // start as a bubble
	},
	initialize: function(data) {
		const that = this;

		// Add an id
		this.id = SETTINGS.idCounter++;

		// Format timestamp
		const timestamp = typeof data === 'object' ? data.timestamp : undefined;

		if (timestamp instanceof Date) {
			this.set('timestamp', timestamp.getTime());
		} else if (typeof timestamp !== 'number') {
			this.set('timestamp', Date.now());
		}

		// After some time, set bubble=false so that this is removed from
		// the bubble list.
		setTimeout(function() {
			that.set('bubble', false);
		}, SETTINGS.newDuration);
	},
	update: function(data) {
		if (this.has('progress') && !data.hasOwnProperty('progress')) {
			// we are probably done.
			this.set('progress', undefined);
		}

		this.set(data);
	}
});

const Notifications = Backbone.Collection.extend({
	model: Notification,
	comparator: function(a, b) {
		const at = a.get('timestamp'); const bt = b.get('timestamp');
		return at > bt ? -1 : at < bt ? 1 : 0;
	}
});

export default {
	Notification,
	Notifications
};
