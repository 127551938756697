var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="form-group">\n	<label class="form-label '+
((__t=( __editMode ? '' : 'required' ))==null?'':__t)+
' grid-md-3" for="JobType">Job type</label>\n\n	<div class="grid-md-6">\n		';
 if (__editMode) { 
__p+='\n			<span class="form-control-static">'+
((__t=( JobType.get('DisplayName') ))==null?'':__t)+
'</span>\n\n		';
 } else { 
__p+='\n\n			<select class="form-control js-jobtype" name="JobType">\n				<option value="">Select a job type</option>\n				';
 var currJobTypeId = typeof JobType === 'object' ? JobType.id : ''; 
__p+='\n				';
 JobTypes.each(function(jt) { 
__p+='\n					<option value="'+
((__t=( jt.id ))==null?'':__t)+
'" '+
((__t=( jt.id === currJobTypeId ? 'selected' : '' ))==null?'':__t)+
'>'+
((__t=( jt.get('DisplayName') ))==null?'':__t)+
'</option>\n				';
 }); 
__p+='\n			</select>\n\n		';
 } 
__p+='\n	</div>\n</div>\n\n';
 if (typeof JobType !== 'undefined') { 
__p+='\n\n	';
 // Job display name 
__p+='\n	<div class="form-group">\n		<label class="form-label required grid-md-3" for="DisplayName">Name</label>\n		<div class="grid-md-6">\n			<input class="form-control js-fixed-attr" name="DisplayName" type="text" value="'+
((__t=( typeof DisplayName === 'string' ? DisplayName : '' ))==null?'':__t)+
'" />\n		</div>\n	</div>\n\n	';
 // Job description 
__p+='\n	<div class="form-group">\n		<label class="form-label required grid-md-3" for="Description">Description</label>\n		<div class="grid-md-6">\n			<textarea class="form-control js-fixed-attr" name="Description">'+
((__t=( typeof Description === 'string' ? Description : '' ))==null?'':__t)+
'</textarea>\n		</div>\n	</div>\n\n	';
 // Attributes 
__p+='\n	';
 if (typeof Attributes !== 'undefined') { 
__p+='\n		';
 _.each(Attributes, function(item) { 
__p+='\n			<div class="form-group">\n				<label class="form-label grid-md-3" for="'+
((__t=( item.Name ))==null?'':__t)+
'">'+
((__t=( item.DisplayName ))==null?'':__t)+
'</label>\n				<div class="grid-md-6">\n					<input class="form-control js-attr" name="'+
((__t=( item.Name ))==null?'':__t)+
'" type="text" value="'+
((__t=( item.Value ))==null?'':__t)+
'" />\n					';
 if (item.Description && item.Description.length) { 
__p+='\n						<span class="form-control-note">'+
((__t=( item.Description ))==null?'':__t)+
'</span>\n					';
 } 
__p+='\n				</div>\n			</div>\n		';
 }); 
__p+='\n	';
 } 
__p+='\n\n';
 } 
__p+='\n';
}
return __p;
};
