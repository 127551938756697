import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import mixin from 'common/mixins/main';
import template from './template.html';
import 'ui/button';

/**
 Interface:

 - Model:
 - triggers "valid" when model is validated successfully
 - triggers "invalid" when model fails validation
 - triggers "saved" when save request succeeds
 - triggers "failed" when save request fails
 - "failed" event may optionally come with a message; this will be displayed at the bottom of the form
 - has `saveChanges` method, which is called to send save request to server
 - `validate` returns errors in a plain object {}, mapping field name => error message

 - Template:
 - save button: '.js-save'
 - cancel button: '.js-cancel'
 - edit button (switches to edit-mode): '.js-edit'
 - editable fields' names must match their property names in the model, e.g.

 Model({ EmailAddress: '...' })
 <input type="text" name="EmailAddress" />

 - inputs are in .form-group elements, which in turn are in a .form-horizontal
 - mark items to show in view-mode with [data-class="viewOnly"]
 - mark items to show in edit-mode with [data-class="editOnly"]
 */
const View = Marionette.ItemView.extend({

	template: template,

	events: {
		'change input': 'onInputChange',
		'click .js-edit': 'goToEditMode',
		'click .js-cancel': 'onClickCancel',
		'click .js-save': 'onClickSave'
	},

	modelEvents: {
		'valid': 'onValidModel',
		'invalid': 'onInvalidModel',
		'saved': 'onSaveSuccess',
		'failed': 'onSaveFail'
	},

	fieldDefaults: {
		show: true,
		type: 'text',
		isRequired: false,
		isReadOnly: false,
		isSensitive: false
	},

	initialize: function() {
		mixin.cacheData({ model: this.model });

		// Fill in missing settings with defaults for each form field.
		const defaults = this.fieldDefaults;
		_.each(this.options.formFields, function(field) {
			_.defaults(field, defaults);
		});
	},

	templateHelpers: function() {
		const opts = this.options;
		return {
			model: this.model,
			formTitle: opts.formTitle,
			formFields: opts.formFields
		};
	},

	onRender: function() {
		this.$('.js-save').tmbutton();
		this.goToViewMode();
	},

	goToEditMode: function(e) {
		if (e) e.preventDefault();
		this.$('[data-class="viewOnly"]').addClass('hidden');
		this.$('[data-class="editOnly"]').removeClass('hidden');
	},

	goToViewMode: function(e) {
		if (e) e.preventDefault();
		this.$('[data-class="viewOnly"]').removeClass('hidden');
		this.$('[data-class="editOnly"]').addClass('hidden');
	},

	onInputChange: function(e) {
		const $input = $(e.target);
		this.model.set($input.attr('name'), $input.val());
	},

	onClickSave: function(e) {
		e.preventDefault();
		this.$('.js-save').tmbutton('state', 'busy');
		this.model.saveChanges();
	},

	onClickCancel: function(e) {
		e.preventDefault();
		this.model.revert();
		this.render();
	},

	onSaveSuccess: function() {
		this.model.cache();
		this.render();
	},

	onSaveFail: function(msg) {
		msg = msg || 'Could not save changes. Please try again.';
		this.$('.form-horizontal, .form-mini').append(`<div class="form-group js-error"><div class="grid-3 grid-push-2"><span class="msg error">${msg}</span></div></div>`);
		this.$('.js-save').tmbutton('state', 'ready');
		this.$('.js-save').tmbutton('state', 'ready');
	},

	onValidModel: function() {
		this.$('.js-error').remove();
		this.$('.js-save').tmbutton('state', 'ready');
	},

	onInvalidModel: function(model, errors) {
		const $el = this.$el;

		$el.find('.error').remove();

		_.each(errors, function(msg, field) {
			$el.find(`input[name="${field}"]`)
				.closest('.form-group')
				.append(`<div class="grid-2 js-error"><span class="msg error">${msg}</span></div>`);
		});

		$el.find('.js-save').tmbutton('state', 'disabled');
	},

	onDestroy: function() {
		this.$('.js-save').tmbutton('destroy');
	}
});

export default View;
