import _ from 'underscore';
import $ from 'jquery';
import Marionette from 'marionette';
import Pikaday from 'pikaday';
import tools from 'common/tools/main';
import ui from 'ui/main';
import Backbone from 'backbone';
import helpers from '../helpers';
import tplRange from '../templates/columnFilter-range.html';
import tplRecency from '../templates/columnFilter-recency.html';
import tplValue from '../templates/columnFilter-value.html';

const PLUGINNAME = 'ColumnFilter';

/** Functions that initialize a column filter config object

 @param {Backbone.Collection} collection
 @param {object} column
 @returns {object}
 */
const FILTERINIT = {
	range: function(collection, column) {
		return {
			column: column,
			type: 'range',
			from: undefined,
			to: undefined
		};
	},
	recency: function(collection, column) {
		return {
			column: column,
			type: 'recency',
			ranges: [
				'*',
				'past 1h',
				'past 24h',
				'past 1w',
				'custom'
			],

			// selected range
			selectedRange: '*',
			from: undefined,
			to: undefined
		};
	},
	value: function(collection, column) {
		// Get sorted unique list of values in the collection for this attribute.
		const vals = _.uniq(helpers.getColumnValues(collection, column));
		vals.sort(helpers.comparators.asc);

		return {
			column: column,
			type: 'value',
			values: vals,
			selectAll: true,
			exceptions: []
		};
	}
};

/** Functions that apply an update to the specified filter.

 @param {object} col  Definition for column being filtered.
 @param {object} filter  Current filter definition.
 @param {object} update  Filter changes to be merged into "filter".
 */
const FILTERUPDATE = {
	range: function(col, filter, update) {
		const type = col.type;

		if (update.hasOwnProperty('from')) {
			filter.from = helpers.toType(update.from, type);
		}

		if (update.hasOwnProperty('to')) {
			filter.to = helpers.toType(update.to, type);
		}
	},
	recency: function(col, filter, update) {
		const rangeId = update.rangeId;

		// Are we changing range type?
		if (rangeId) {
			filter.selectedRange = rangeId;
		}

		// Update range points.
		if (rangeId === '*') {
			// Any date
			filter.from = undefined;
			filter.to = undefined;
		} else if (typeof rangeId === 'string' && rangeId !== 'custom') {
			// Predefined range, like "past 1y"
			const now = new Date();
			filter.from = ui.date.relative(now, rangeId);
			filter.to = now;
		} else {
			// Custom range
			FILTERUPDATE.range(col, filter, update);
		}
	},
	value: function(col, filter, update) {
		const exceptions = filter.exceptions;

		if (typeof update.selectAll === 'boolean') {
			filter.selectAll = update.selectAll;
			filter.exceptions.length = 0;
		} else {
			update.value = helpers.toType(update.value, col.type);

			if (filter.selectAll === update.checked) {
				const i = _.indexOf(exceptions, update.value);
				if (i > -1) exceptions.splice(i, 1);
			} else {
				exceptions.push(update.value);
			}

			if (exceptions.length === filter.values.length) {
				filter.selectAll = !filter.selectAll;
				exceptions.length = 0;
			}
		}
	}
};

/** Functions that apply model/collection changes to the specified filter.

 @param {object} filter
 @param {Backbone.Collection|Backbone.Model} data
 */
const FILTERCHANGE = {
	value: function(filter, data) {
		if (data instanceof Backbone.Collection) {
			const newValues = helpers.getColumnValues(data, filter.column);
			filter.values = _.uniq(filter.values.concat(newValues));
			filter.values.sort(helpers.comparators.asc);
		} else if (data instanceof Backbone.Model) {
			const val = helpers.getColumnValue(data, filter.column);

			// is this value already in the filter?
			const i = _.indexOf(filter.values, val);
			if (i === -1) {
				filter.values.push(val);
				filter.values.sort(helpers.comparators.asc);
			}
		}
	}
};

/** Functions that return true iff the given value passes the given filter.

 @param {object} filter
 @param {*} val
 @returns {boolean}
 */
const FILTERFX = {
	range: function(filter, val) {
		const a = filter.from;
		const b = filter.to;
		const noA = tools.isNullish(a);
		const noB = tools.isNullish(b);

		return (noA && noB) ||
			(noB && val >= a) ||
			(noA && val <= b) ||
			(val >= a && val <= b);
	},
	recency: function(filter, val) {
		return FILTERFX.range(filter, val);
	},
	value: function(filter, val) {
		const all = filter.selectAll;
		const exceptions = filter.exceptions;

		// all: then everything
		// all & values.length: exclude items in list
		// !all: nothing
		// !all & values.length: include only items in list
		if (_.isArray(exceptions) && exceptions.length) {
			const inList = helpers.contains(exceptions, val);
			return all ? !inList : inList;
		} else {
			return all;
		}
	}
};

/** Functions that return true iff the given filter is ON.

 @param {object} filter
 @returns {boolean}
 */
const FILTERON = {
	range: function(filter) {
		return !tools.isNullish(filter.from) || !tools.isNullish(filter.to);
	},
	recency: function(filter) {
		const selectedRange = filter.selectedRange;
		return selectedRange !== '*' ||
			(selectedRange === 'custom' &&
				(!tools.isNullish(filter.from) ||
					!tools.isNullish(filter.to)));
	},
	value: function(filter) {
		return filter.exceptions.length || !filter.selectAll;
	}
};

/** Base column filter menu view, to be extended with a tpl, events, etc. */
const BASEVIEW = Marionette.ItemView.extend({
	tmRenderOnFilter: true,
	initialize: function(opts) {
		if (this.tmRenderOnFilter) {
			this.listenTo(opts.controller.get('collection'), 'tm:filtered', this.refresh || this.render);
		}
	},
	className: 'context-menu-section tbf-colFilter',
	serializeData: function() {
		return _.extend({ helpers: helpers, tools: tools }, this.options);
	},
	updateViewState: function(filter) {
		this.state = isFilterOn(filter);
		this.trigger('tm:columnmenusection:change:state', this.state);
	}
});

/** Menu views for various filter types. */
const VIEWS = {};
VIEWS.range = BASEVIEW.extend({
	template: tplRange,
	events: {
		'change .js-range': 'onChangeRange',
		'click .js-clear': 'onClickClear'
	},
	onClickClear: function(e) {
		$(e.target).siblings('input').val('').trigger('change');
	},
	onChangeRange: function(e) {
		const $el = $(e.target);
		const obj = {};
		obj[$el.attr('data-range')] = $el.val();
		this.updateRange(obj);
	},
	updateRange: function(data) {
		const opts = this.options;
		const filter = opts.controller.updateFilter(opts.column.id, data);
		this.updateViewState(filter);
	}
});
VIEWS.daterange = BASEVIEW.extend({
	tmRenderOnFilter: false,
	template: tplRange,
	events: {
		'click .js-clear': 'onClickClear'
	},
	onRender: function() {
		this.setupDatePickers();
	},
	onDestroy: function() {
		this.destroyDatePickers();
	},
	setupDatePickers: function() {
		const that = this;

		const pickers = this.pickers || (this.pickers = {});

		const $r1 = this.$('.js-range[data-range="from"]');

		const $r2 = this.$('.js-range[data-range="to"]');

		const format = function(date) {
			return date.format('UiSortable');
		};

		pickers.from = new Pikaday({
			field: $r1[0],
			format: format,
			showTime: true,
			onSelect: function(date) {
				that.updateRange({ from: date });
			}
		});

		pickers.to = new Pikaday({
			field: $r2[0],
			format: format,
			showTime: true,
			onSelect: function(date) {
				that.updateRange({ to: date });
			}
		});
	},
	destroyDatePickers: function() {
		const pickers = this.pickers;

		if (pickers) {
			try {
				pickers.from.destroy();
				pickers.to.destroy();
			} catch (e) {
			}
		}
	},
	onClickClear: function(e) {
		const key = $(e.target).siblings('input').val('').attr('data-range');
		this.pickers[key].clearDate();
		const obj = {};
		obj[key] = '';
		this.updateRange(obj);
	},
	onChangeRange: function(e) {
		const $el = $(e.target);
		const obj = {};
		obj[$el.attr('data-range')] = $el.val();
		this.updateRange(obj);
	},
	updateRange: function(data) {
		const opts = this.options;
		const filter = opts.controller.updateFilter(opts.column.id, data);
		this.updateViewState(filter);
	}
});
VIEWS.recency = tools.extendClass(VIEWS.daterange, {
	template: tplRecency,
	events: {
		'change .js-rangeoption': 'onSelectRange'
	},
	onSelectRange: function(e) {
		const rangeId = $(e.target).val();
		this.updateRange({ rangeId: rangeId });
	}
});
VIEWS.value = tools.extendClass(BASEVIEW, {
	template: tplValue,
	events: {
		'change .js-filterinput-all': 'onChangeSelectAll',
		'change .js-filterinput': 'onChangeSelectValue'
	},
	initialize: function(opts) {
		this.listenTo(opts.controller.get('collection'), 'add reset tm:changedata', this.refresh);
	},
	refresh: function() {
		// Re-render and scroll to previous position. @todo Efficiency?
		const scrollTop = this.$('.tbf-colFilter-1').scrollTop();
		this.render();
		this.$('.tbf-colFilter-1').scrollTop(scrollTop);
	},
	onChangeSelectAll: function(e) {
		const $input = $(e.target);
		const checked = $input.prop('checked');
		const opts = this.options;
		const filter = opts.controller.updateFilter(opts.column.id, { selectAll: checked });
		this.updateViewState(filter);
	},
	onChangeSelectValue: function(e) {
		const $input = $(e.target);
		const checked = $input.prop('checked');
		const val = $input.val();
		const opts = this.options;
		const filter = opts.controller.updateFilter(opts.column.id, { value: val, checked: checked });
		this.updateViewState(filter);
	}
});

/**
 Returns true iff `val` passes the specified filter.

 @param {object} filter
 @param {*} val
 @returns {boolean}
 */
function pass(filter, val) {
	// If no filter fx found, default to passing the value. Else, filter.
	const fx = FILTERFX[filter.type];
	return typeof fx !== 'function' || fx(filter, val);
}

/**
 Returns true iff the specified filter is on.

 @param {object} filter
 @returns {boolean}
 */
function isFilterOn(filter) {
	// If no filter fx found, default to OFF.
	const fx = FILTERON[filter.type];
	return typeof fx === 'function' ? fx(filter) : false;
}

/**
 Returns the view class appropriate for the specified column, or undefined
 if no such class exists.

 @param {object} col
 @returns {Backbone.View}
 */
function getViewClass(col) {
	if (typeof col === 'object') {
		// Attempt to get type-specific filter, e.g. "daterange" first.
		// Fallback to generic filter, e.g. "range".
		const dataType = col.type;
		const filterType = col.filterType;
		return VIEWS[dataType + filterType] || VIEWS[filterType];
	}
}

/**
 Updates a filter with data from the provided new models, by executing
 a FILTERCHANGE function, if it's available for this filter's type.

 @param {object} filter
 @param {array} models
 */
function updateFilter(filter, models) {
	const fx = FILTERCHANGE[filter.type];
	if (typeof fx === 'function') fx(filter, models);
}

export default {

	name: PLUGINNAME,

	columnDefaults: {
		filterType: 'value'
	},

	/*
	- kinds of filters: list of values | date range | number range
	- for value filters, generate filter and save it; regenerate only
		after collection<reset|add|remove> or model<change:attr>
	*/

	Controller: {

		initialize: function() {
			this.set('columnfilter:state', {});
			this.registerFilterMethod('applyFilters');
			this.registerColumnMenuSection({
				name: PLUGINNAME,
				getDataMethod: 'getFilterSectionData',
				getViewClass: getViewClass
			});

			this.listenTo(this.get('collection'), 'add reset tm:changedata', this.onChangeData);
		},

		applyFilters: function(matchedModels) {
			const filters = this.get('columnfilter:state');
			const colIds = _.keys(filters);
			let n, filter, colId, match;

			matchedModels = _.filter(matchedModels, function(model) {
				match = true;
				n = colIds.length;

				// Break out as soon as this model fails a column filter.
				while (match && n--) {
					colId = colIds[n];
					filter = filters[colId];
					match = pass(filter, helpers.getColumnValue(model, filter.column));
				}

				return match;
			});

			return matchedModels;
		},

		updateFilter: function(attrName, update) {
			const col = _.findWhere(this.get('columns'), { id: attrName });
			const filter = this.getFilterSectionData(attrName);
			const fx = FILTERUPDATE[filter.type];

			if (typeof fx === 'function') {
				// Apply update to filter.
				fx(col, filter, update);

				// Re-apply filters on collection.
				this.trigger('refilter');
			}

			return filter;
		},

		getFilterSectionData: function(attrName) {
			const filters = this.get('columnfilter:state');
			let f = filters[attrName];

			// Already have a filter; return it.
			if (f) return f;

			// Exit if no column for this attribute.
			const col = _.findWhere(this.get('columns'), { id: attrName });
			if (!col) return f;

			// Create a new filter iff column has filtering.
			const hasFilter = col.hasColumnFilter;
			if (hasFilter || typeof hasFilter === 'undefined') {
				const type = col.filterType || 'value';
				const fx = FILTERINIT[type];

				if (typeof fx === 'function') {
					f = filters[attrName] = fx(this.get('collection'), col);
				}
			}

			return f;
		},

		/**
		 Triggered when the collection is reset, or when its models are
		 added or changed. Updates existing filters with the new data.

		 @param {Backbone.Collection|Backbone.Model} models  New or newly
		 updated models.
		 @param {*} arg2  Not used. Named only so we can get at the next arg.
		 @param {object|*} arg3  When only single model added/changed, this
		 argument is the operation options. For example:

		 { add: false, remove: false, merge: true }
		 */
		onChangeData: function(models, arg2, arg3) {
			const filters = this.get('columnfilter:state');

			if (models instanceof Backbone.Collection) {
				// This is a reset. Patch created filters.
				_.each(filters, function(f) {
					updateFilter(f, models);
				});
			} else if (models instanceof Backbone.Model) {
				// Only update created filters. Filters that haven't been
				// created yet will naturally get the new values when
				// instantiated.

				// If no `models.changed`, this is a newly added model.
				const attrs = typeof arg3 === 'object' && (arg3.add || arg3.merge)
					? models.attributes : models.changed;

				_.each(attrs, function(val, key) {
					const f = filters[key];
					if (f) updateFilter(f, models);
				});
			}
		}
	}

};
