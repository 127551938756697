import _ from 'underscore';
import Backbone from 'backbone';

// Require at the following path:
// 'appModules/loading_view'

const LoadingView = Backbone.Marionette.ItemView.extend({
	template: _.template('<div><%= message %></div>'),

	serializeData: function(args) {
		return { message: this.options.message };
	}
});

export default LoadingView;
