var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<thead>\n	<tr>\n		<th style="width: 23em;">\n			Run Id\n			'+
((__t=( _generateSortHtml('logId') ))==null?'':__t)+
'\n		</th>\n		<th>\n			Item\n			'+
((__t=( _generateSortHtml('name') ))==null?'':__t)+
'\n		</th>\n		<th style="width: 13em;">\n			Date \n			'+
((__t=( _generateSortHtml('lastModified') ))==null?'':__t)+
'\n		</th>\n	</tr>\n</thead>\n<tbody></tbody>\n';
}
return __p;
};
