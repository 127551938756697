var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<td colspan="4">\n	'+
((__t=( collection._fetching ? 'Loading...' : 'Nothing happened yet' ))==null?'':__t)+
'\n</td>';
}
return __p;
};
