/**
 * @file Helper methods for getting border width, margin, and padding of elements.
 */

import $ from 'jquery';

/**
Returns this element's border width as a float/integer value.

@param {string} direction  Which border width to get. One of 'top',
'bottom', 'left', or 'right'.
*/
$.fn.getBorderWidth = function(direction) {
	let w = this.css(`border-${direction}-width`);

	switch (w) {
		case 'thin':
			w = 1;
			break;
		case 'medium':
			w = 3;
			break;
		case 'thick':
			w = 5;
			break;
		case '':
			w = 0;
			break;
		default:
			w = parseFloat(w);
			break;
	}

	return w;
};

/**
Returns this element's margin as a float/integer value.

@param {string} direction  Which margin to get. One of 'top', 'bottom',
'left', or 'right'.
*/
$.fn.getMargin = function(direction) {
	let margin = this.css(`margin-${direction}`);

	switch (margin) {
		case '':
			margin = 0;
			break;
		default:
			margin = parseFloat(margin);
			break;
	}

	return margin;
};

/**
Returns this element's padding as a float/integer value.

@param {string} direction  Which padding to get. One of 'top', 'bottom',
'left', or 'right'.
*/
$.fn.getPadding = function(direction) {
	let padding = this.css(`padding-${direction}`);

	switch (padding) {
		case '':
			padding = 0;
			break;
		default:
			padding = parseFloat(padding);
			break;
	}

	return padding;
};
