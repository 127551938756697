import View from './shortDisplay_view';

function init(app) {
	return {
		getShortDisplayView: function(clientId, userId) {
			return app.request('app.services.users.getProfile', clientId, userId).then(function(userProfile) {
				return new View({ model: userProfile });
			});
		}
	};
}

export default init;
