import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import app from 'app';
import tools from 'common/tools/main';
import mixin from 'common/mixins/main';
import PagerControlsView from 'common/modules/pagerControls/view';
import tplFile from './templates/tpl_file.html';
import tplFileList from './templates/tpl_fileList.html';
import tplItem from './templates/tpl_fileSpecsItem.html';
import tplItemHeader from './templates/tpl_fileSpecsItemHeader.html';
import tplItemBody from './templates/tpl_fileSpecsItemMain.html';
import tplList from './templates/tpl_fileSpecs.html';
import HistoryController from './widgets/history/controller';

function hasUpload(config) {
	const hasUp = config.HasUpload;
	return typeof hasUp !== 'boolean' || hasUp;
}

// Config for tools displayed beside each file.
const fileToolsConfig = [
	{
		id: 'replace',
		icon: 'upload',
		description: 'Replace with a file from your computer'
	},
	{
		id: 'rename',
		icon: 'edit',
		description: 'Rename this file'
	},
	{
		id: 'delete',
		icon: 'close',
		description: 'Delete this file'
	},
	{
		id: 'history',
		icon: 'history',
		description: 'View this file\'s history'
	}
];

const filespecToolsConfig = [
	{
		id: 'add',
		icon: 'add',
		description: 'Upload new files',
		requirementsMet: hasUpload
	},
	{
		id: 'history',
		icon: 'history',
		description: 'View this filespec\'s history'
	}
];

const FileView = Marionette.ItemView.extend({
	template: tplFile,
	tagName: 'tr',
	className: 'file',
	modelEvents: {
		'change': 'render',
		'err': 'showError'
	},
	events: {
		'click .js-rename': 'onClickRename',
		'click .js-replace': 'onClickReplace',
		'click .js-delete': 'onClickDelete',
		'click .js-cancelDelete': 'onClickCancelDelete',
		'click .js-history': 'onClickHistory',
		'click .js-dismissMsg': 'onClickDismissMsg',
		'change .input-replace': 'onSelectReplacementFile',
		'keyup .input-rename': 'onKeyupRename',
		'blur .input-rename': 'cancelRename'
	},
	templateHelpers: function() {
		return { modelId: this.model.cid, toolsConfig: fileToolsConfig };
	},
	onRender: function() {
		const $el = this.$el;
		const inTrash = this.model.get('InTrash') === true;
		const isUpdated = this.model.get('IsUpdated') === true;

		$el.toggleClass('changed', inTrash || isUpdated);

		if (isUpdated) {
			setTimeout(function() {
				$el.removeClass('changed', 1000);
			}, 3000);
		}
	},
	onClickRename: function() {
		// Cancel all other rename inputs.
		this.$el.closest('.filespec').find('.input-rename').each(function() {
			const e = $.Event('keyup', { which: 27 });
			$(this).trigger(e);
		});

		// Enable rename input for this file.
		this.$('.input-rename').removeClass('hidden').focus();
	},
	onKeyupRename: function(e) {
		const key = e.which;
		let $input, filename;

		if (key === 13) { // enter
			$input = $(e.target);
			filename = $.trim($input.val());
			this.model.renameFile(filename);
			$input.addClass('hidden');
		} else if (key === 27) { // esc
			this.cancelRename();
		}
	},
	cancelRename: function() {
		const $input = this.$('.input-rename');
		$input.val(this.model.get('filename'));
		$input.addClass('hidden');
	},
	onClickReplace: function() {
		this.$('.input-replace').click();
	},
	onSelectReplacementFile: function() {
		const files = this.$('.input-replace')[0].files;
		if (files.length) this.model.replaceFile(files[0]);
	},
	onClickDelete: function() {
		this.model.deleteFile();
	},
	onClickCancelDelete: function() {
		this.model.cancelDelete();
	},
	onClickHistory: function(e) {
		e.stopPropagation();
		this.trigger('display:history', { filter: this.model.get('name') });
	},
	showError: function(msg) {
		this.$('.file-msg').removeClass('hidden').children('.js-file-msg').text(msg);
	},
	onClickDismissMsg: function() {
		this.$('.file-msg').addClass('hidden').children('.js-file-msg').text('');
	},
	onBeforeDestroy: function() {
		// Don't remove until after $el is faded out.
		this.$el.fadeOut('slow', _.bind(this.remove, this));
		this.remove = _.noop;
	}
});

const NoFileView = Marionette.ItemView.extend({
	template: _.template('<td colspan="3">No files yet</td>'),
	tagName: 'tr',
	className: 'row-empty'
});

const FileCollectionView = Marionette.CompositeView.extend({
	template: tplFileList,
	tagName: 'table',
	className: 'table table-minimal file-list',
	childViewContainer: 'tbody',
	childView: FileView,
	emptyView: NoFileView,
	childEvents: {
		'display:history': 'onChildDisplayHistory'
	},
	initialize: function() {
		mixin.sortableCollectionView({ that: this });
		mixin.pagingCollectionView({ that: this });
		mixin.resizableTable({ that: this });
	},
	onChildDisplayHistory: function(childView, opts) {
		app.vents.filespecs.trigger(`display:history:${this.model.get('name')}`, opts);
	}
});

const FilespecNotifView = Marionette.ItemView.extend({
	template: _.template('<%- message %>'),
	className: function() {
		return `msg block ${this.options.classes || ''}`;
	},
	templateHelpers: function() {
		return { message: this.options.message };
	}
});

const FilespecHeaderView = Marionette.LayoutView.extend({
	template: tplItemHeader,
	className: 'card-header clickable filespec-header',
	attributes: function() {
		return {
			'title': this.model.get('InputConfig').Description,
			'aria-expanded': false,
			'tabindex': 0
		};
	},
	modelEvents: {
		'change:name': 'render',
		'change:Status': 'render',
		'change:ProgressPct': 'render'
	},
	events: {
		'click .js-add': 'addFiles',
		'click .js-history': 'toggleHistory',
		'change .input-add': 'onSelectFiles'
	},
	templateHelpers: function() {
		const model = this.model;
		const isOk = model.get('Status') === 'OK';

		return {
			toolsConfig: filespecToolsConfig,
			statusClass: typeof model.get('ProgressPct') === 'number' ? 'waiting' : isOk ? 'success' : 'error',
			statusIcon: isOk ? 'success' : 'warning'
		};
	},
	onSelectFiles: function() {
		const files = this.$('.input-add')[0].files;
		if (files.length) this.model.addFiles(files);
	},
	addFiles: function() {
		this.$('.input-add').click();
	},
	toggleHistory: function() {
		app.vents.filespecs.trigger(`display:history:${this.model.get('name')}`);
	}
});

const FilespecBodyView = Marionette.LayoutView.extend({
	template: tplItemBody,
	regions: {
		regionNotif: '.region-notification',
		regionFiles: '.region-files',
		regionPager: '.region-pager'
	},
	initialize: function() {
		const model = this.model;
		const files = model.get('Files');
		this.listenTo(files, 'add remove reset', this.togglePager);
		this.listenTo(files, 'pageLengthChange', this.toggleFileListScroll);
		this.listenTo(model, 'change:Status', this.refreshNotification);

		this.statusMessages = this.createStatusMessageMap();
	},
	createStatusMessageMap: function() {
		const config = this.model.get('InputConfig');
		const minFiles = config.MinimumFiles;
		const maxFiles = config.MaximumFiles;

		let rangeMsg = '';
		if (minFiles === maxFiles) {
			rangeMsg = `Must have ${minFiles} file(s).`;
		} else if (minFiles > 0 && maxFiles < Infinity) {
			rangeMsg = `Must have ${minFiles}-${maxFiles} files.`;
		} else if (maxFiles < Infinity) {
			rangeMsg = `Maximum ${maxFiles} file(s).`;
		} else if (minFiles > 0) {
			rangeMsg = `Minimum ${minFiles} file(s).`;
		}

		return {
			Error: 'Something went wrong. Please try again or contact support.',
			TooFewFiles: `Not enough files! ${rangeMsg}`,
			TooManyFiles: `Too many files! ${rangeMsg}`
		};
	},
	onRender: function() {
		const files = this.model.get('Files');
		this.regionFiles.show(new FileCollectionView({ collection: files, model: this.model }));
		this.togglePager();
		this.toggleFileListScroll();
		this.refreshNotification();
	},
	togglePager: function() {
		// Only show pager if >1 page or there is available server data.
		const files = this.model.get('Files');
		if (files.getDefaultNumPages() > 1 || files.hasServerPages()) {
			this.regionPager.show(new PagerControlsView({ collection: files }));
		} else {
			this.regionPager.empty();
		}
	},
	toggleFileListScroll: function() {
		// If showing all items at once, enable scroll.
		const files = this.model.get('Files');
		this.regionFiles.$el.toggleClass('scroll-off', files.pageLength > 0);
	},
	refreshNotification: function() {
		const model = this.model;
		const status = model.get('Status');
		let msg;

		if (status === 'Error' && model.get('Error')) {
			msg = model.get('Error').toString();
		} else {
			msg = this.statusMessages[status] || '';
		}

		if (msg) {
			this.regionNotif.show(new FilespecNotifView({ classes: 'error', message: msg }));
		} else {
			this.regionNotif.empty();
		}
	}
});

const FilespecView = Marionette.LayoutView.extend({
	template: tplItem,
	className: 'card filespec',

	regions: {
		regionHeader: '.region-header',
		regionBody: '.region-body',
		regionDrawer: '.region-drawer'
	},

	initialize: function(opts) {
		mixin.cardView({ view: this });
		this.listenTo(app.vents.filespecs, `display:history:${this.model.get('name')}`, this.displayHistory);
	},

	onRender: function() {
		this.regionHeader.show(new FilespecHeaderView({ model: this.model }));

		const config = this.model.get('InputConfig');
		this.setupDragDropUpload(!hasUpload(config));
	},

	onExpandBody: function() {
		this.regionBody.show(new FilespecBodyView({ model: this.model }));
	},

	setupDragDropUpload: function(isDisabled) {
		// Exit if browser doesn't support drag/drop uploading.
		if (!tools.features.advancedUpload) return;

		const that = this;
		const $el = that.$el;

		// Displays bg that says whether drop is allowed.
		const dragoverCls = isDisabled ? 'is-dragover-blocked' : 'is-dragover';

		// Disables pointer events on descendant els.
		const dropzoneCls = 'dropzone';

		// Last time a dragstart/dragenter/dragover event occurred.
		// Used to calculate whether to remove classes on dragleave/dragend,
		// b/c dragleave fires all the time when you're hovering over
		// descendant elements.
		let lastDragInEvent = false;

		// Min number of seconds to wait after a dragstart/dragenter/
		// dragover event before a dragleave/dragend event should be
		// processed.
		const dragOutThreshold = 20;

		// setTimeout callback for dragleave/dragend events.
		function onDragOut() {
			const diff = new Date().getTime() - lastDragInEvent.getTime();

			if (!lastDragInEvent || diff > dragOutThreshold) {
				$el.removeClass(dropzoneCls); // Restore pointer events on descendants
				$el.removeClass(dragoverCls); // Remove info bg
			}
		}

		$el.on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
			// Stops default behaviour (e.g. browser opening dropped files).
			e.preventDefault();
			e.stopPropagation();
		}).on('dragstart dragenter dragover', function(e) {
			lastDragInEvent = new Date(); // Note when this event occurred
			$el.addClass(dropzoneCls); // Cancel pointer events on descendants
			$el.addClass(dragoverCls); // Show info bg
		}).on('dragleave dragend', function(e) {
			setTimeout(onDragOut, dragOutThreshold);
		}).on('drop', function(e) {
			$el.removeClass(dropzoneCls); // Restore pointer events on descendants
			$el.removeClass(dragoverCls); // Remove info bg

			// Upload dropped files.
			if (!isDisabled) {
				const files = e.originalEvent.dataTransfer.files;
				if (files.length) that.model.addFiles(files);
			}
		});
	},

	displayHistory: function(opts) {
		opts = opts || {};
		const currView = this.regionDrawer.currentView;
		const filter = opts.filter;

		// If already displaying desired history, assume we're toggling.
		if (currView instanceof HistoryController.View &&
			currView.options.collection.options.filter === filter) {
			this.drawerEmpty();
		} else {
			const history = new HistoryController({
				filespec: this.model,
				path: this.model.get('key'),
				filter: filter
			});

			this.drawerShow(history.view);
		}
	}

});

const NoFilespecView = Marionette.ItemView.extend({
	template: _.template('No filespecs')
});

const FilespecCollectionView = Marionette.CompositeView.extend({
	template: tplList,
	className: 'column-inner',
	childViewContainer: '.js-filespecs',
	childView: FilespecView,
	emptyView: NoFilespecView
});

export default FilespecCollectionView;
