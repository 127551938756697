/**
 * Adds grips to table headings to make columns resizable.
 *
 * It looks for `<th>` elements within the view, and appends a grip to it.
 * It also appends a resize guide to the view $el, which appears when a column is
 * being resized.
 *
 * @module common/mixins/resizableTable
 *
 * @param {Backbone.View} view  The view to add this mixin to. Alias: `that`
 * @param {number} [minWidth=16]  Minimum width for each column.
 *
 * @todo Save custom col widths and apply them after rerender
 * @todo Option to specify custom col widths on initialization
 * @todo Disable resize on certain columns
 */

import $ from 'jquery';
import _ from 'underscore';

let idCounter = 0;

const resizableTable = function(opts) {
	const view = opts.that || opts.view;
	const settings = {
		id: idCounter++,
		ns: `${idCounter}.mixinResizableTable`,
		$currentColumn: null,
		$guide: null,
		guidePos: null,
		leftLimit: null,
		mouseDownTimeout: null,
		mouseDownLeftPos: null,
		minWidth: typeof opts.minWidth === 'number' ? opts.minWidth : 16
	};

	// Extend collection with methods, letting existing ones take precedence.
	_.defaults(view, {

		/**
			 * Adds resize grips to column headers and a resize guide to the
			 * view $el.
			 */
		_setupColumnResizing: function() {
			this.$('th').append('<span class="tbf-resize-grip"></span>');
			settings.$guide = $('<div class="tbf-col-resize-guide"></div>').appendTo(this.$el);
		},

		/**
			 * Resizes column headed by $th to the specified size.
			 *
			 * @param {$} $th  Column to resize.
			 * @param {number} size  Desired width of column.
			 */
		_resizeColumn: function($th, size) {
			const oldSize = $th.outerWidth();

			// Set table width.
			view.$el.width(view.$el.width() + (size - oldSize));

			// Set column width.
			$th.width(size);
		},

		/**
			 * Called when user clicks a resize grip (i.e. on mousedown).
			 * Figures out which column is being resized, shows a resize guide,
			 * and binds a one-off handler on document to wait for mouseup.
			 *
			 * @param {$.Event} e  Mousedown event on resize grip.
			 */
		_onMousedownColumnResizeGrip: function(e) {
			e.preventDefault();

			// Column to resize.
			const $th = $(e.target).closest('th');
			settings.$currentColumn = $th;

			// Only activate column resizing if user has not released the
			// grip for at least 50ms.
			settings.mouseDownTimeout = setTimeout(this._columnResizeGripOn, 50);

			// The mouse's left position within the table wrapper (0 is
			// the left edge of the table wrapper).
			settings.mouseDownLeftPos = e.pageX - this.$el.offset().left;

			// Left-most position resize guide can go to. This is the min col width.
			settings.leftLimit = $th.position().left + settings.minWidth;

			// Set resize guide's left position for immediate visual feedback.
			settings.$guide.css('left', settings.mouseDownLeftPos);

			// Single-use mouseup binding. Use document so that if mouseup
			// happens outside of view $el, it's still caught.
			$(document).one('mouseup', function() {
				clearTimeout(settings.mouseDownTimeout);
				view._columnResizeGripOff();
			});
		},

		/**
			 * setTimeout callback, called once the user has clicked and held
			 * onto the column resize grip for some amount of time.
			 *
			 * Allows the user to drag the grip and see a guide to preview the
			 * new column width. Enforces a min-width on columns.
			 */
		_columnResizeGripOn: function() {
			document.body.style.cursor = 'col-resize';
			view.$el.css({ 'user-select': 'none' });

			$(document).on(`mousemove.${settings.ns}`, function(e) {
				// e's left is relative to the window, but resize guide's
				// left is relative to the table wrapper.
				const left = e.pageX - view.$el.offset().left;

				// Only update guide's position if it defines a col width
				// that is at least larger than the minimum allowed width.
				if (left > settings.leftLimit) {
					settings.guidePos = left;
					settings.$guide.css('left', left);
				}
			});
		},

		/**
			 * Called when user releases a resize grip (i.e. on mouseup).
			 *
			 * Resets cursor, hides resize guide, unbinds mousemove, resizes column.
			*/
		_columnResizeGripOff: function() {
			document.body.style.cursor = '';

			const $el = view.$el;
			$el.css({ 'user-select': 'inherit' });

			// Resize the column iff the user actually moved the cursor.
			if (settings.guidePos !== null && settings.guidePos - settings.mouseDownLeftPos !== 0) {
				const $th = settings.$currentColumn;
				const width = Math.floor(settings.guidePos - $th.position().left);
				this._resizeColumn($th, width);
			}

			settings.$guide.css('left', -99999);
			settings.guidePos = null;
			settings.$currentColumn = null;
			settings.leftLimit = null;
			settings.mouseDownLeftPos = null;
			settings.mouseDownTimeout = null;

			$(document).off(`mousemove.${settings.ns}`);
		}

	});

	// Add additional view events.
	const newEvents = { 'mousedown .tbf-resize-grip': '_onMousedownColumnResizeGrip' };
	view.events = view.events ? _.extend(view.events, newEvents) : newEvents;

	view.on('render', view._setupColumnResizing);
};

export default resizableTable;
