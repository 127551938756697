var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+=''+
((__t=( typeof column.displayName === 'function' ? 
		column.displayName(column) : 
		column.displayName || column.id 
))==null?'':__t)+
'\n\n<span class="tbf-thBtns"></span>\n\n';
 if (options.hasColumnResize) { 
__p+='\n	<span class="tbf-resize-grip"></span>\n';
 } 
__p+='\n';
}
return __p;
};
