var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var selectAll = data.selectAll;
var exceptions = data.exceptions;
var col = data.column;

__p+='\n<div class="context-menu-section-title  tbf-colFilter">Filter by</div>\n<div class="context-menu-section-content  tbf-colFilter  tbf-colFilter-0">\n	<span class="context-menu-option checkbox context-menu-option-all">\n		<label>\n			<input class="js-filterinput-all" type="checkbox" name="filter-'+
((__t=( attrName ))==null?'':__t)+
'" '+
((__t=( selectAll ? 'checked' : '' ))==null?'':__t)+
'>\n			Select all\n		</label>\n	</span>\n</div>\n<div class="context-menu-section-content tbf-colFilter tbf-colFilter-1">\n	';

	_.each(data.values, function(val) { 
		var inEx = helpers.contains(exceptions, val);
		var checked = selectAll ? !inEx : inEx;
	
__p+='\n	<span class="context-menu-option checkbox">\n		<label>\n			<input class="js-filterinput" type="checkbox" name="filter-'+
((__t=( attrName ))==null?'':__t)+
'" value="'+
((__t=( val ))==null?'':__t)+
'" '+
((__t=( checked ? 'checked' :'' ))==null?'':__t)+
' />\n			\n			';
 if (typeof col.renderValue === 'function') { 
__p+='\n				'+
((__t=( col.renderValue(val) ))==null?'':__t)+
'\n				\n			';
 } else { 
__p+=' \n				'+
((__t=( val === undefined || val === null || val === "" ? '(empty)' : val ))==null?'':__t)+
'\n				\n			';
 } 
__p+='\n			\n		</label>\n	</span>\n	';
 }); 
__p+='\n</div>\n';
}
return __p;
};
