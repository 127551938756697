var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (typeof Clients === 'undefined') return; 
__p+='\n\n';
 if (Clients.length > 1) { 
__p+='\n	<label class="hidden" for="clientDropdown">Choose a client</label>\n	<select class="client-dropdown" id="clientDropdown" name="clientDropdown">\n		<option value="" '+
((__t=( ClientId === '' ? 'selected' : '' ))==null?'':__t)+
'>- Select a client -</option>\n		';

		Clients.each(function(model) {
		var id = model.get('Id');
		var selected = id === ClientId ? 'selected' : '';
		 
__p+='\n		<option value="'+
((__t=( id ))==null?'':__t)+
'" data-name="'+
((__t=( model.get('Name') ))==null?'':__t)+
'" '+
((__t=( selected ))==null?'':__t)+
'>'+
((__t=( model.get('DisplayName') ))==null?'':__t)+
'</option>\n		';
 }); 
__p+='\n	</select>	\n';
 } 
__p+='\n';
}
return __p;
};
