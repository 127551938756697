import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import app from 'app';
import WorkspaceView from './view';
// import 'jquery-whenAll';
// import toolConfig from 'path';

const WorkspaceController = Marionette.Object.extend({
	// Min duration to show loading overlay when changing tools.
	// This is so that when loading is fast, the overlay doesn't flash.
	minLoadingTime: 200,

	initialize: function(opts) {
		const that = this;

		// save ref to model
		that.model = opts.model;

		// console.log('path to toolconfig', opts.toolConfigPath);

		// fetch tool config
		that.model.set('__toolFetch', that.fetchToolConfig(opts.toolConfigPath));

		// create view
		that.view = new WorkspaceView({ model: that.model });

		// listen to view
		that.listenTo(that.view, {
			openTool: that.switchToTool,
			closeSubtool: that.hideSubtool,
			render: that.onRender,
			destroy: that.destroy
		});
	},

	fetchToolConfig: function(path) {
		const d = $.Deferred();
		const model = this.model;

		if (path === 'appModules/job/workspaceToolsConfig') {
			import(/* webpackMode: "eager" */ 'appModules/job/workspaceToolsConfig').then((toolConfig) => {
				model.set('ToolsConfig', toolConfig.default);
				// console.log(model);
				d.resolve(toolConfig.default);
			});
		} else if (path === 'appModules/jobType/workspaceToolsConfig') {
			import(/* webpackMode: "eager" */ 'appModules/jobType/workspaceToolsConfig').then((toolConfig) => {
				model.set('ToolsConfig', toolConfig.default);
				d.resolve(toolConfig.default);
			});
		} else {
			console.warn(`workspace fetchToolConfig: could not fetch ${path}`);
		}

		return d.promise();
	},

	switchToTool: function(toolName, subtoolName) {
		// No names provided, do nothing.
		if (!toolName && !subtoolName) return;

		const that = this;
		const model = this.model;

		return new Promise((resolve, reject) => {
			// Using equality comparison on purpose, so that undefined == null, etc.
			// eslint-disable-next-line eqeqeq
			if (model.get('CurrentToolName') != toolName) {
				model.trigger('loading:tool');

				this.getTool(toolName).then(tool => {
					// Destroy previous main and sub tools.
					that.destroyTools();

					model.set({
						CurrentTool: tool,
						CurrentToolName: toolName
					});

					resolve();
				});
			} else {
				// Don't have to do anything. Just continue.
				resolve();
			}
		}).then(() => {
			// Using equality comparison on purpose, so that undefined == null, etc.
			// eslint-disable-next-line eqeqeq
			if (model.get('CurrentSubtoolName') != subtoolName) {
				// console.log(`switchToTool: getting sub tool ${subtoolName}`);
				if (model.get('CurrentToolName') === toolName) {
					model.trigger('loading:subtool');
				}

				return this.getTool(toolName, subtoolName).then(subtool => {
					model.set({
						CurrentSubtool: subtool,
						CurrentSubtoolName: subtoolName
					});
				});
			}
		}).catch(err => {
			console.warn('error getting tools', err);
		});
	},

	getTool (toolName, subtoolName) {
		// No tool specified. Use default.
		if (!toolName && !subtoolName) {
			toolName = this.startTool;
		}

		const that = this;
		const tools = this.model.get('ToolsConfig').tools;
		let viewInFlyout = false;
		let config = _.findWhere(tools, { name: toolName });

		if (subtoolName && config.subtools) {
			config = _.findWhere(config.subtools, { name: subtoolName });
			viewInFlyout = true;
		}

		// console.log('tool config', config);

		// import(/* webpackMode: "eager" */ 'appModules/' + name + '/' + name + '_ctrl').then(
		return import(/* webpackMode: "eager" */ `appModules/job/${config.importName}/${config.importName}_ctrl`).then(Tool => {
			// @todo a hack to get publish flyout working. want to just pass in controller tbh
			const opts = typeof config.makeCustomOpts === 'function' ? config.makeCustomOpts(that) : that;
			const toolController = Tool.create(opts);
			toolController.viewInFlyout = viewInFlyout;
			return toolController;
		});
	},

	destroyTools: function() {
		const model = this.model;

		if (!model) return;

		const baseKeys = ['CurrentTool', 'CurrentSubtool'];
		const toSet = {};
		let toolKey, nameKey, tool;

		let i = 0;
		const n = baseKeys.length;
		for (; i < n; i++) {
			toolKey = baseKeys[i];
			nameKey = `${toolKey}Name`;
			tool = model.get(toolKey);
			if (tool) {
				toSet[toolKey] = undefined;
				toSet[nameKey] = undefined;
				tool.destroy();
			}
		}

		model.set(toSet);
		_.extend(model._previousAttributes, toSet);
	},

	hideSubtool: function() {
		const model = this.model;
		model.set({
			CurrentSubtool: undefined,
			CurrentSubtoolName: undefined
		});

		// navigate to current tool
		app.navigate(model.getCurrentRoute());
	},

	onRender: function() {
		// Only show tool view once data all loaded.
		const that = this;
		const model = that.model;
		const opts = that.options;

		model.trigger('loading:tool');
		$.whenAll(model.get('__dataFetch'), model.get('__toolFetch')).done(function() {
			const config = model.get('ToolsConfig');
			const startTool = (opts && opts.toolName) || config.startTool;
			const startSubtool = (opts && opts.subtoolName) || config.startSubtool;
			that.switchToTool(startTool, startSubtool);
		});
	},

	onDestroy: function() {
		this.destroyTools();

		if (this.view) {
			this.view.destroy();
			delete this.view;
		}

		if (this.model) {
			delete this.model;
		}
	}
});

/**
 Returns true iff ctrl is a Workspace Controller and has the same path
 as defined by the specified Workspace Model type and identifiers.

 @param {*} ctrl
 @param {Backbone.Model} Model
 @param {...string} ids
 @returns {bool}
 */
const isSpecifiedPage = function(ctrl, Model) {
	// console.log('is specified page model', Model);
	const idParts = Array.prototype.slice.call(arguments, 2);
	return ctrl instanceof WorkspaceController && ctrl.model.id === Model.createId.apply(Model, idParts);
};

const create = function(opts) {
	return new WorkspaceController(opts);
};

export {
	isSpecifiedPage,
	create
};
