import app from 'app';
import _ from 'underscore';
import Papa from 'papaparse';
import tools from 'common/tools/main';
import Backbone from 'backbone';

const DEFAULT_NUM_OBSERVATIONS = 17;

const SrriModel = Backbone.Model.extend({
	idAttribute: 'MasterKey',
	initialize: function(data) {
		const attrs = _.omit(data, 'CalculatedData', 'PublishedData', 'SrriValues');

		// We're going to be merging `calcAttrs` into the main attributes.
		// Remove "Id" so we don't overwrite the one on CalculatedData.
		// Remove "SrriValues" because we'll serialize that in the next step.
		const calcAttrs = _.omit(data.CalculatedData, 'Id', 'SrriValues');

		// Add in calc/pub data objects.
		_.extend(attrs, data.PublishedData, calcAttrs);
		attrs.Guid = data.Id; // Want to keep the fund GUID
		attrs.DocId = data.CalculatedData.Id;

		// Store each SRRI observation as a property.
		const srriVals = data.CalculatedData.SrriValues;
		let i = 0;
		const n = this.collection.state.numObservations;

		for (; i < n; i++) {
			attrs[`Week${i + 1}`] = srriVals[i];
		}

		if (data.CalculatedData.SrriValues.length > 0) { attrs.QaPdfUrl = app.request('app.services.publishing.getPdfPreviewUrl', attrs.ClientId, attrs.JobTypeId, attrs.JobId, attrs.DocId); } else { attrs.QaPdfUrl = null; }

		if (!_.has(data, 'PublishedData')) { attrs.NumWeeksOutside = null; }

		this.attributes = attrs;

		this.on('remove', this.onRemove);
	},
	onRemove: function() {
		// @todo Display error message if this fails
		app.request('app.services.srri.deleteFund', app.session.get('clientId'), this.get('Isin'), this.get('EffectiveDate'));
	},
	toCsvReadyJson: function() {
		// Exclude tm:* attributes.
		const attrs = this.attributes;
		const json = _.omit(attrs, tools.regex.isTmAttrIteratee);

		// Serialize SRRI observations.
		let i = 1;
		const n = this.collection.state.numObservations;
		let id, o;

		for (; i <= n; i++) {
			id = `Week${i}`;
			o = attrs[id];

			if (o && o.Date) {
				json[`${id}: ${o.Date}`] = o.Srri || '';
			} else {
				json[`${id}: N/A`] = '';
			}

			delete json[id];
		}

		return json;
	}
});

const SrriCollection = Backbone.Collection.extend({
	model: SrriModel,
	initialize: function() {
		this.state = {
			asOfDate: undefined,
			numObservations: DEFAULT_NUM_OBSERVATIONS
		};
	},
	fetchData: function(date) {
		const that = this;
		that.trigger('fetching');

		app.request('app.services.srri.get', app.session.get('clientId'), date).done(function(data) {
			const state = that.state;
			state.asOfDate = date;
			state.numObservations = data.NumObservations || state.numObservations;
			that.reset(data.Documents);
		});
	},
	toCsv: function() {
		const data = this.map(function(model) {
			return model.toCsvReadyJson();
		});
		return Papa.unparse(data);
	}
});

export default SrriCollection;
