var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<td colspan="'+
((__t=( options.columns.length ))==null?'':__t)+
'">\n	';
 if (options.collection.length) { 
__p+='\n		No data matching filter criteria\n	';
 } else { 
__p+='\n		No data available\n	';
 } 
__p+='\n</td>\n';
}
return __p;
};
