/**
 * Mix into a CollectionView that has the pagingCollection mixin, to display
 * only the current page's items at any given time.
 *
 * ##### Requirements
 *
 * - Collection has method `isOnCurrentPage`, which accepts the index {int} of
 * 	an item in the collection and returns `true` if that item is on the
 * 	current page.
 *
 * ##### Added methods
 *
 * NB: You can override these methods in your view definition.
 *
 * - `onPageChange`: Called when collection is paged. By default, re-renders the view.
 *
 * ##### Warnings
 *
 * - Overrides Marionette.CollectionView#filter.
 * - Overrides Marionette.CollectionView#_onCollectionAdd.
 *
 * @module common/mixins/pagingCollectionView
 *
 * @param {Backbone.View} view  The view to add this mixin to. Alias: `that`.
 *
 */

import _ from 'underscore';

const pagingCollectionView = function(opts) {
	const view = opts.that || opts.view;

	// Extend view with methods, letting existing ones take precedence.
	_.defaults(view, {

		onPageChange: function() {
			this.render();
		}

	});

	// Extend view with methods, overriding existing ones.
	_.extend(view, {

		/**
		 * Overrides Marionette#filter.
		 *
		 * Returns true if "child" should be shown on the current page.
		 *
		 * @override
		 * @param child {Backbone.Model}
		 * @param index {int}
		 * @param collection {Backbone.Collection}
		 * @returns {bool}
		 */
		filter: function(child, index, collection) {
			return collection.isOnCurrentPage(index);
		},

		/**
		 * Overrides Marionette.CollectionView#_onCollectionAdd.
		 *
		 * Why do we need to do this? If we let Marionette does its usual
		 * ItemView create+append on model add, we end up with duplicate
		 * ItemViews sometimes because we do a re-render on adds to update
		 * the pagination.
		 *
		 * @override
		 */
		_onCollectionAdd: _.noop

	});

	view.listenTo(view.collection, 'page', view.onPageChange);
};

export default pagingCollectionView;
