import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import helpers from '../helpers';
import tplSort from '../templates/columnSort.html';

const PLUGINNAME = 'ColumnSort';

const ColumnSortMenuView = Marionette.ItemView.extend({
	template: tplSort,
	className: 'context-menu-section tbf-colSort',
	events: {
		'change input': 'onChange'
	},
	initialize: function(opts) {
		this.listenTo(opts.controller.get('collection'), 'tm:sorted', this.render);
	},
	serializeData: function() {
		return this.options;
	},
	onChange: function(e) {
		const direction = $(e.target).val();
		const opts = this.options;
		opts.controller.sortByColumn(opts.column, direction);
	}
});

const comparators = {
	asc: function(m1, m2) {
		const col = this.sortColumn;
		const a = helpers.getColumnValue(m1, col);
		const b = helpers.getColumnValue(m2, col);
		if (a > b) return 1;
		if (a < b) return -1;
		return 0;
	},
	dsc: function(m1, m2) {
		const col = this.sortColumn;
		const a = helpers.getColumnValue(m1, col);
		const b = helpers.getColumnValue(m2, col);
		if (a > b) return -1;
		if (a < b) return 1;
		return 0;
	}
};

export default {

	name: PLUGINNAME,

	Controller: {

		initialize: function() {
			this.set('columnsort:state', {});
			this.registerColumnMenuSection({
				name: PLUGINNAME,
				getDataMethod: 'getSortSectionData',
				viewClass: ColumnSortMenuView
			});
		},

		sortByColumn: function(column, direction) {
			column = typeof column === 'string'
				? _.findWhere(this.get('columns'), { id: column })
				: column;

			// Save sort column and direction.
			const data = this.getSortSectionData(column.id);
			data.order = direction;

			// Set sort column and comparator on collection.
			const collection = this.get('collection');
			collection.sortColumn = column;
			collection.comparator = comparators[direction];

			// Force sort.
			collection.sort();
		},

		getSortSectionData: function(columnId) {
			const data = this.get('columnsort:state');
			let o = data[columnId];

			if (!o) {
				// initialize sort data object
				o = data[columnId] = {
					order: undefined
				};
			}

			return o;
		}
	}
};
