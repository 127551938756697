import _ from 'underscore';
import Marionette from 'marionette';

import mixin from 'common/mixins/main';
import PagerControlsView from 'common/modules/pagerControls/view';
import tplCompositeView from './templates/tpl_compositeView.html';
import tplItemView from './templates/tpl_itemView.html';

const ItemView = Marionette.ItemView.extend({
	template: tplItemView,
	tagName: 'tr'
});

const EmptyView = Marionette.ItemView.extend({
	template: _.template('<td>Nothing happened yet</td>'),
	tagName: 'tr',
	className: 'row-empty'
});

const CollectionView = Marionette.CompositeView.extend({
	template: tplCompositeView,
	childViewContainer: 'tbody',
	childView: ItemView,
	emptyView: EmptyView,
	events: {
		'click .js-clear': 'onClickClear'
	},
	initialize: function() {
		mixin.pagingCollectionView({ that: this });
		mixin.sortableCollectionView({ that: this });
	},
	onClickClear: function() {
		this.collection.reset();
	}
});

const LayoutView = Marionette.LayoutView.extend({
	template: _.template('<div class="region-log"></div><div class="region-pager"></div>'),
	regions: {
		regionLog: '.region-log',
		regionPager: '.region-pager'
	},
	onRender: function() {
		const logs = this.options.collection;
		this.regionLog.show(new CollectionView({ collection: logs }));
		this.regionPager.show(new PagerControlsView({ collection: logs }));
	}
});

export default LayoutView;
