import Backbone from 'backbone';
import View from './view';

function init(app) {
	return {
		getEditIpRestrictionsView: function(clientId) {
			return app.request('app.services.users.getClientLoginSettings', clientId).then(function(data) {
				const viewModel = new Backbone.Model({
					ClientId: data.Id,
					IsEnabled: data.IsIPRestrictionEnabled,
					IpRanges: data.AllowedIpRanges
				});

				const view = new View({ model: viewModel });

				view.on('enableIpRestriction', function(range) {
					app.request('app.services.users.enableIpRestriction', clientId, range)
						.done(function() {
							view.trigger('enabled', 'success');
						})
						.fail(function(err) {
							view.trigger('enabled', 'fail', err);
						});
				});

				view.on('disableIpRestriction', function() {
					app.request('app.services.users.disableIpRestriction', clientId)
						.done(function() {
							view.trigger('disabled', 'success');
						})
						.fail(function(err) {
							view.trigger('disabled', 'fail', err);
						});
				});

				return view;
			});
		}
	};
}

export default init;
