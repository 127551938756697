var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>Password</h2>\n\n<div data-class="viewOnly">\n	<button class="js-edit btn btn-link-tight">Change your password</button>\n</div>\n\n<div data-class="editOnly">\n\n	<div class="grid-row">\n	<form class="form-horizontal grid-md-12 grid-lg-8">\n		\n		';
 
		var fields = [
			{
				name: 'OldPassword',
				label: 'Current password'
			},
			{
				name: 'NewPassword',
				label: 'New password'
			},
			{
				name: 'ConfirmPassword',
				label: 'Confirm new password'
			}
		];
		
		_.each(fields, function(field) {
			var name = field.name;
		
__p+='\n\n		<div class="form-group">\n			<label class="form-label required grid-md-3" for="'+
((__t=( name ))==null?'':__t)+
'">'+
((__t=( field.label ))==null?'':__t)+
'</label>\n			<div class="grid-md-6">\n				<input type="password" class="form-control" name="'+
((__t=( name ))==null?'':__t)+
'" autocomplete="off" />\n			</div>\n		</div>\n		\n		';
 }); 
__p+='\n		\n		<div class="form-group">\n			<div class="grid-md-6 grid-md-push-3">\n				<button class="js-save btn">Save</button>\n				<button class="js-cancel btn btn-link btn-sm">Cancel</button>\n			</div>\n		</div>\n	</form>\n	</div>\n	\n</div>\n';
}
return __p;
};
