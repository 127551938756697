/**
 * Mix into a `LayoutView` with a `.card` markup for basic card functionality.
 *
 * ##### Requirements
 *
 * - Header has `.card-header`.
 * - Things in the header that you don't want to toggle card expand/collapse
 *   when clicked on are `input` or `.js-tool`.
 * - View has a `regionBody` region.
 * - View has a `regionDrawer` region.
 * - If the view has a method called `onExpandBody`, it will be called when
 *   the card is expanded.
 *
 * @module common/mixins/cardView
 * @param {Backbone.View} view  The view to add this mixin to. Alias: `that`
 */

import $ from 'jquery';
import _ from 'underscore';

export default function (opts) {
	const view = opts.that || opts.view;

	_.extend(view, {

		onToggleHeader: function(e) {
			// Don't toggle header if keyup was not "enter", or if
			// click/keyup was on a tool button or an input.
			if ((e.type === 'click' || e.which === 13) &&
					!$(e.target).closest('.js-tool, input').length) {
				this.toggleCardBody();
			}
		},

		toggleCardBody: function() {
			const that = this;
			const $el = that.$el;

			if (that.regionBody.hasView()) {
				const isOpen = $el.hasClass('open-body');
				const $header = $el.find('.card-header');
				$el.toggleClass('open-body');
				$header.attr('aria-expanded', !isOpen);
				// If collapsing body, also collapse drawer.
				if (isOpen) that.drawerEmpty();
			} else {
				if (typeof that.onExpandBody === 'function') that.onExpandBody();
				$el.addClass('open-body');
			}
		},

		drawerShow: function(childview) {
			this.regionDrawer.show(childview);
			this.$el.addClass('open-drawer');
		},

		drawerEmpty: function() {
			this.$el.removeClass('open-drawer');
			this.regionDrawer.empty();
		}

	});

	// Add additional view events.
	const newEvents = {
		'click .card-header': 'onToggleHeader',
		'keyup .card-header': 'onToggleHeader'
	};
	view.events = view.events ? _.extend(view.events, newEvents) : newEvents;
};
