import app from 'app';
import $ from 'jquery';
import Marionette from 'marionette';
import tplLayout from './templates/layout.html';
import tplNode from './templates/treeNode.html';
import tplEmptyNode from './templates/emptyNode.html';

// Explorer layout.
const Layout = Marionette.LayoutView.extend({
	template: tplLayout,
	tagName: 'div',
	className: 'sidebar-inner',
	regions: {
		regionTree: '#region-job-tree'
	},
	events: {
		'click #btn-addJob': 'onClickAddJob',
		'keyup #sidebar-jobSearch': 'onKeyupSearch',
		'click .js-close': 'onClickClose'
	},
	initialize: function(opts) {
		this.state = opts.state;
	},
	templateHelpers: function() {
		return { state: this.state };
	},
	onClickAddJob: function() {
		this.trigger('addJob hide');
	},
	onClickClose: function() {
		this.trigger('hide');
	},
	onKeyupSearch: function(e) {
		if (e.which === 13) {
			const str = $.trim($(e.target).val());
			this.trigger('search', str);
		}
	}
});

// The recursive tree view.
const TreeView = Marionette.CompositeView.extend({
	template: tplNode,
	tagName: 'li',
	className: 'tree-node',
	childViewContainer: 'ul',

	events: {
		'keyup .tree-node-contents': 'onLabelKeyup',
		'click .tree-node-contents': 'onLabelClick',
		'click .js-info': 'toggleDetails'
	},

	initialize: function() {
		// We have a collection too if this is a job type.
		this.collection = this.model.nodes;
		if (this.collection) {
			this.listenTo(this.collection, 'reset', this.render);
		}

		// Re-render if the node name has changed or the job it represents
		// was opened/closed.
		this.listenTo(this.model, 'change:nodeName change:selected', this.render);

		// Re-apply filter view when the model changes, and after
		// all of our collection's children have been rendered.
		this.listenTo(this.model, 'change', this.applyFilterView);
		this.on('composite:collection:rendered', this.applyFilterView);
	},

	templateHelpers: function() {
		const data = {};
		const model = this.model;
		const m = model.get('model');

		if (model.get('nodeType') === 'job') {
			data.route = app.router.makeJobRoute(m.get('ClientId'), m.get('JobTypeId'), m.get('Id'));
		} else {
			// implied job type
			data.route = app.router.makeJobTypeRoute(m.get('ClientId'), m.get('Id'));
		}

		return data;
	},

	attachHtml: function(collectionView, childView, index) {
		collectionView.$el.children('ul:first').prepend(childView.el);
	},

	showEmptyNode: function(text) {
		// Remove existing tree nodes that are empty messages.
		this.removeEmptyNodes();

		// Create a new empty tree node, render it, and add it to DOM.
		const emptyView = new EmptyTreeView({ message: text });
		this.$el.children('.tree-nodes').prepend(emptyView.render().$el);
	},

	removeEmptyNodes: function() {
		this.$el
			.children('.tree-nodes')
			.children('.tree-node-empty')
			.remove();
	},

	applyFilterView: function() {
		const model = this.model;

		const isJobType = model.get('nodeType') === 'jobType';

		if (isJobType && model.get('_hiddenAllChildren')) {
			// All children are hidden. Show a message that says that.
			this.showEmptyNode('No matching items');
		} else if (model.nodes && !model.nodes.length) {
			// There are no children. Show a message that says that.
			this.showEmptyNode('No items');
		} else {
			// There are children and they are shown. Remove all no/hidden
			// children messages.
			this.removeEmptyNodes();
		}

		// Show/hide this node, depending on its _hiddenNode attribute.
		// A node doesn't necessarily have _hiddenNode; if it doesn't,
		// show the node.
		this.$el.toggle(!model.get('_hiddenNode'));
	},

	onRender: function() {
		const isJobType = this.model.get('nodeType') === 'jobType';

		// @TODO: for now, only job types have children.
		// will need a better way to figure out if this node has children
		// in the future when jobs can have children.
		if (isJobType) {
			this.$el.addClass('has-children');
		} else {
			const regionDetails = new Marionette.Region({ el: this.$('.region-details') });
			const job = this.model.get('model');
			app.request('app.services.jobs.getMetadataView', job, { type: 'sidebar' }).done(function(metadataView) {
				regionDetails.show(metadataView);
			});
		}

		this.applyFilterView();
	},

	onShow: function() {
		const isJobType = this.model.get('nodeType') === 'jobType';

		// If this is the only job type, expand it immediately.
		if (isJobType && this.model.collection.length === 1) {
			this.toggleNode(true);
		}
	},

	onLabelClick: function(e) {
		const nodeType = this.model.get('nodeType');

		if (nodeType === 'jobType') {
			if ($(e.target).closest('.js-link').length) {
				// Job type link. Browser handles nav to it. We handle
				// sidebar hiding.
				this._parentLayoutView().trigger('hide');
			} else {
				this.toggleNode();
			}
		} else if (nodeType === 'job') {
			// Job node. Label is a link, so browser will handle navigation
			// to job. Trigger 'hide' on parent view, so sidebar closes.
			this._parentLayoutView().trigger('hide');
		}
	},

	onLabelKeyup: function(e) {
		// If "Enter" pressed, simulate a click on the item.
		if (e.which === 13) this.onLabelClick(e);
	},

	toggleNode: function(open) {
		const $el = this.$el;
		const toOpen = typeof open !== 'undefined' ? !!open : !$el.hasClass('open');

		if (toOpen) {
			$el.siblings('.tree-nodes').hide();

			// @todo: once we have the jobUpdated event, this should no longer refetch jobs from the server again;
			// instead, we should just keep updating the same collection of tree nodes.
			const jobTypeId = this.model.get('nodeId');
			this._parentLayoutView().trigger('refreshJobs', jobTypeId);

			$el.children('.tree-nodes').slideDown();
			$el.addClass('open');

			// @todo want to set focus on either this node or first
			// child, but because the node re-renders and the children
			// are not available yet (fetching from server), can't use
			// jquery to focus here...
		} else {
			$el.removeClass('open');
			$el.children('.tree-nodes').slideUp();
		}
	},

	toggleDetails: function(e) {
		e.stopPropagation();
		e.preventDefault();
		this.$el.children('.tree-node-details').toggleClass('hidden');
	}
});

// Tree leaf that shows empty message.
const EmptyTreeView = Marionette.ItemView.extend({
	template: tplEmptyNode,
	tagName: 'li',
	className: 'tree-node tree-node-empty',
	serializeData: function() {
		return { message: this.options.message };
	}
});

// The tree's root.
const TreeRoot = Marionette.CollectionView.extend({
	tagName: 'ul',
	className: 'tree-nodes',
	childView: TreeView,
	emptyView: EmptyTreeView,
	emptyViewOptions: {
		message: 'No job types available'
	}
});

export default {
	Layout,
	TreeView,
	EmptyTreeView,
	TreeRoot
};
