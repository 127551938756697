var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<span class="tb-controls-left">\n	<span class="btn-group tb-pager-btns tb-pager-component">\n		<button class="btn btn-page btn-page-prev" '+
((__t=( prevDisabled ))==null?'':__t)+
'><span class="fa fa-caret-left"></span></button>\n		<button class="btn btn-page btn-page-next" '+
((__t=( nextDisabled ))==null?'':__t)+
'><span class="fa fa-caret-right"></span></button>\n	</span>\n	<span class="tb-pager-info tb-pager-component">'+
((__t=( pagerInfo ))==null?'':__t)+
'</span>\n</span>\n\n<span class="tb-controls-right">\n	<span class="tb-pager-lengthSelect tb-pager-component">\n		View \n		<select id="'+
((__t=( id ))==null?'':__t)+
'-pageLength" class="js-pageLength">\n			';
 _.each(pageLengths, function(n) { 
__p+='\n				<option value="'+
((__t=( n ))==null?'':__t)+
'" '+
((__t=( n == pageLength ? 'selected' : '' ))==null?'':__t)+
'>\n					'+
((__t=( n == Infinity ? 'All' : n ))==null?'':__t)+
'\n				</option>\n			';
 }); 
__p+='\n		</select>\n		<label for="'+
((__t=( id ))==null?'':__t)+
'-pageLength" class="screenreader">Number of items to show</label>\n	</span>\n</span>';
}
return __p;
};
