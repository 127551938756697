import app from 'app';
import _ from 'underscore';
import Backbone from 'backbone';

const debug = false;
const MATCH_KEY = 'MasterKey'; // the name of the property used to match two documents
const FALLBACK_MATCH_KEY = 'MasterEntityKey';

/**
 Used by BlacklineModel to keep track of the current old docsets in use.
 Each model is a docset, whose format is determined by the server.
 */
const OldDocSetCollection = Backbone.Collection.extend({
	_colorCounter: -1,

	initialize: function() {
		this.on('add', this.onModelAdd);
	},

	markers: [
		{ color: '1', shape: 'diamond' },
		{ color: '2', shape: 'square-o' },
		{ color: '3', shape: 'triangle' },
		{ color: '4', shape: 'hexagon-o' },
		{ color: '5', shape: 'circle' },
		{ color: '1', shape: 'diamond-o' },
		{ color: '2', shape: 'square' },
		{ color: '3', shape: 'triangle-o' },
		{ color: '4', shape: 'hexagon' },
		{ color: '5', shape: 'circle-o' }
	],

	onModelAdd: function(docSet) {
		docSet.set('marker', this.getNextValidColor());

		docSet.formatDocuments().done(() => {
			const documents = docSet.get('Documents');
			this.listenTo(documents, 'change:Selected', function(oldDoc, isSelected) {
				docSet.trigger('docSelectionChanged', oldDoc, isSelected);
				this.trigger('change:Selected', oldDoc, isSelected);
			});
		});
	},

	_getNextColor: function() {
		this._colorCounter++;
		return this._colorCounter % this.markers.length;
	},

	getNextValidColor: function() {
		// Use numModels-2 because numModels-1 is the newly added model we
		// want to find a color for right now.
		const numModels = this.length;
		const recentColor = numModels > 1 ? this.at(numModels - 2).get('marker').color : false;
		let nextColor = this._getNextColor();

		if (nextColor === recentColor) {
			// don't want the same color side by side
			nextColor = this._getNextColor();
		}

		return this.markers[nextColor];
	}
});

const BlacklineRow = Backbone.Model.extend({
	defaults: {
		isVisible: true
	},

	initialize: function() {
		this.oldDocs = this.get('oldDocs');
		this.listenTo(this.oldDocs, 'change:Selected', this.onSelectedChanged);
	},

	onSelectedChanged: function(oldDoc, isSelected) {
		const marker = isSelected ? this.getDocSetMarker(oldDoc) : '';
		this.set('marker', marker);
		this.trigger('matchChanged');
	},

	/**
	 Toggles whether document with "docSetId" is selected, according to
	 "selection".

	 @param docSetId {string} GUID of document set
	 @param selection {bool} True to select document
	 */
	selectDoc: function(docSetId, selection) {
		if (selection) {
			// unselect all old documents in the collection first
			this.clearSelection();
		}

		// now select the targeted document
		const oldDocs = this.get('oldDocs');
		const targetedDoc = oldDocs.findWhere({ DocSetId: docSetId });
		if (targetedDoc) {
			targetedDoc.set('Selected', selection);
		}
	},

	/** Clears all matches in this row. */
	clearSelection: function() {
		this.get('oldDocs').invoke('set', { Selected: false });
	},

	getBlacklinePair: function() {
		/** Returns the blackline pair of the current blackline row if it exists.  */
		const oldDocs = this.get('oldDocs');
		const newDoc = this.get('newDoc');
		const selectedDoc = oldDocs.findWhere({ Selected: true });
		const newDocGuid = newDoc.get('id');
		const oldDocGuid = selectedDoc ? selectedDoc.get('id') : '';

		const newDocsetGuid = newDoc.get('DocSetId');
		const oldDocsetGuid = oldDocGuid ? selectedDoc.get('DocSetId') : '';

		const blacklinePair = newDocGuid && oldDocGuid
			? {
				'New': { 'DocumentSetId': newDocsetGuid, 'DocumentId': newDocGuid },
				'Old': { 'DocumentSetId': oldDocsetGuid, 'DocumentId': oldDocGuid }
			}
			: null;

		return blacklinePair;
	},

	getDocSetMarker: function(doc) {
		const docSetId = doc.get('DocSetId');
		const docSet = this.get('blacklineModel')
			.get('oldDocSetColl')
			.get(docSetId);
		return docSet.get('marker');
	},

	/** Removes all documents belonging to the older doc set with
	 "docSetId" from the row, and resets summary column as needed. */
	removeDocFromOldDocSet: function(docSet) {
		const docs = this.get('oldDocs');
		const doc = docs.findWhere({ DocSetId: docSet.id });
		if (doc) {
			doc.set('Selected', false);
			docs.remove(doc);
		}
	}
});

const BlacklineRows = Backbone.Collection.extend({
	model: BlacklineRow,
	sortDir: 1,
	sortAttr: 'displayName',
	initialize: function() {
		this.on('sort', this.onSort);
	},

	comparator: function(a, b) {
		a = a.get(this.sortAttr);
		b = b.get(this.sortAttr);

		if (this.sortDir >= 0) {
			return a.localeCompare(b);
		} else {
			return -a.localeCompare(b);
		}
	},

	sortRows: function(attr, dir) {
		this.sortAttr = attr;
		this.sortDir = dir;
		this.sort();
	},

	onSort: function() {
		this.trigger('reset');
		this.each(function(blmRow) {
			blmRow.get('oldDocs').trigger('reset');
		});
	},

	search: function(searchString) {
		// Exit if no rows to search.
		if (this.length === 0) return;

		if (searchString) {
			const re = app.tools.regex.createSearchRegex(searchString, false, true, true);
			this.each(function(row) {
				const string = row.get('DisplayName') || row.get('displayName');
				const matches = string.match(re);
				row.set('isVisible', !!matches);
			});
		} else {
			// Empty search; show all rows.
			this.each(function(row) {
				row.set('isVisible', true);
			});
		}
	},

	selectDocSet: function(docSetId, selection) {
		this.each(function(row) {
			row.selectDoc(docSetId, selection);
		});
	},

	clearAllSelection: function() {
		this.each(function(row) {
			row.clearSelection();
		});
	},

	getBlacklinePairs: function() {
		/** Returns an array of blackline pairs from all rows. */
		const blacklinePairs = [];

		this.each(function(blacklineRow) {
			if (blacklineRow) {
				const blacklinePair = blacklineRow.getBlacklinePair();
				if (blacklinePair) {
					blacklinePairs.push(blacklinePair);
				}
			}
		});

		return blacklinePairs;
	},

	addOldDocSet: function(docSet) {
		const rows = this;
		docSet.get('Documents').each(function(doc) {
			let row = rows.get(doc.get(MATCH_KEY) || doc.get(FALLBACK_MATCH_KEY));
			if (typeof row !== 'undefined' && row !== null) {
				row.get('oldDocs').push(doc);
			} else {
				row = _.find(rows.models, function(r) {
					return r.get(FALLBACK_MATCH_KEY) === doc.get(FALLBACK_MATCH_KEY);
				});

				if (typeof row !== 'undefined' && row !== null) {
					row.get('oldDocs').push(doc);
				}
			}
		}, this);
	},

	removeOldDocSet: function(docSet) {
		this.each(function(row) {
			row.removeDocFromOldDocSet(docSet);
		});
	}
});

/**
 Composite blackline model that the views use. This is the Model that this
 module returns.
 */
const BlacklineModel = Backbone.Model.extend({
	defaults: {
		newDocSet: null,
		numMatches: 0
	},

	initialize: function(options) {
		this.set({
			oldDocSetColl: new OldDocSetCollection(),
			blacklineRows: new BlacklineRows()
		});

		this.workspaceModel = options.workspaceModel;
		this.listenTo(this.get('blacklineRows'), 'matchChanged', this.onChangeMatches);

		// Set up event listeners
		this.on('change:newDocSet', this.onChangeNewDocSet);

		const oldDocSetCollection = this.get('oldDocSetColl');
		this.listenTo(oldDocSetCollection, 'add', this.onAddOldDocSet);
		this.listenTo(oldDocSetCollection, 'remove', this.onRemoveOldDocSet);
	},

	// == This model's event callbacks
	onChangeMatches: function() {
		if (debug) console.log('@ BLACKLINE MODEL onChangeMatches');
		this.set('numMatches', this.getMatchedPairs().length);
	},

	onChangeNewDocSet: function(blacklineModel, newDocSet) {
		if (debug) console.log('@ BLACKLINE MODEL EVENT onChangeNewDocSet', arguments);
		const that = this;

		if (that._previousAttributes.newDocSet) {
			// There was a new docset before; clear all matches and reset
			// everything before we continue.
			that.clearMatches();
			that.get('blacklineRows').reset();
			that.get('oldDocSetColl').reset();
		}

		if (newDocSet) that.initBlacklineRows(newDocSet);
	},

	onAddOldDocSet: function(oldDocSet) {
		if (debug) console.log('@BLACKLINE MODEL EVENT onAddOldDocSet', oldDocSet);
		this.get('blacklineRows').addOldDocSet(oldDocSet);
	},

	onRemoveOldDocSet: function(oldDocSet) {
		if (debug) console.log('@BLACKLINE MODEL EVENT onRemoveOldDocSet', oldDocSet);
		this.get('blacklineRows').removeOldDocSet(oldDocSet);
	},

	// == Private methods

	initBlacklineRows: function(newDocSet) {
		/**
		 Using the new document (the first added set) create a collection of blackline row.
		 The collection will model all blacklining operations.
		 */
		const that = this;
		const blacklineRows = this.get('blacklineRows');

		// For each document, build a row model and add it to the blackline
		// row collection.
		newDocSet.formatDocuments().done(() => {
			newDocSet.get('Documents').each(function(doc) {
				// Use push instead of add! add is very slow.
				blacklineRows.push({
					id: doc.get(MATCH_KEY) || doc.get(FALLBACK_MATCH_KEY),
					displayName: doc.get('DisplayName') || doc.get('displayName'),
					newDoc: doc,
					oldDocs: new Backbone.Collection(),
					blacklineModel: that // make model available in each row
				});
			});
		});
	},

	getDocSet: function(docSetId) {
		const collection = this.get('availableDocSets');
		if (collection) {
			const docSet = collection._byId[docSetId];
			if (docSet) docSet.formatDocuments();
			return docSet;
		}
	},

	// == Public methods
	changeNewDocSet: function(docSetId) {
		this.set('newDocSet', this.getDocSet(docSetId));
	},

	addOldDocSet: function(docSetId) {
		this.getDocSet(docSetId).formatDocuments().done(() => {
			this.get('oldDocSetColl').push(this.getDocSet(docSetId));
		});
	},

	removeOldDocSet: function(docSetId) {
		this.get('oldDocSetColl').remove(docSetId);
	},

	clearMatches: function() {
		this.get('blacklineRows').clearAllSelection();
	},

	selectDoc: function(rowId, docSetId, selection) {
		const blacklineRows = this.get('blacklineRows');
		const targetedRow = blacklineRows.get(rowId);

		if (targetedRow) {
			targetedRow.selectDoc(docSetId, selection);
		}
	},

	selectDocSet: function(docSetId, selection) {
		this.get('blacklineRows').selectDocSet(docSetId, selection);
	},

	sortRows: function(attribute, direction) {
		this.get('blacklineRows').sortRows(attribute, direction);
	},

	search: function(searchString) {
		this.get('blacklineRows').search(searchString);
	},

	getMatchedPairs: function() {
		return this.get('blacklineRows').getBlacklinePairs();
	}
});

export default BlacklineModel;
