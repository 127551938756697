/**
@file Adds to the `Date` prototype a `format` method.
Based on http://blog.stevenlevithan.com/archives/date-time-format
*/

// Regexes and supporting functions are cached through closure
const dateFormat = (function() {
	const token = /d{1,4}|M{1,4}|yy(?:yy)?|([HhmsTt])\1?|[LloSZ]|"[^"]*"|'[^']*'/g;
	const timezone = /\b(?:[PMCEA][SDP]T|(?:Pacific|Mountain|Central|Eastern|Atlantic) (?:Standard|Daylight|Prevailing) Time|(?:GMT|UTC)(?:[-+]\d{4})?)\b/g;
	const timezoneClip = /[^-+\dA-Z]/g;
	const pad = function(val, len) {
		val = String(val);
		len = len || 2;
		while (val.length < len) val = `0${val}`;
		return val;
	};

	return function(date, mask, utc) {
		const dF = dateFormat;

		// You can't provide utc if you skip other args (use the "UTC:" mask prefix)
		if (arguments.length === 1 && Object.prototype.toString.call(date) === '[object String]' && !/\d/.test(date)) {
			mask = date;
			date = undefined;
		}

		// Passing date through Date applies Date.parse, if necessary
		date = date ? new Date(date) : new Date();
		if (isNaN(date)) return '';

		mask = String(dF.masks[mask] || mask || dF.masks['default']);

		// Allow setting the utc argument via the mask
		if (mask.slice(0, 4) === 'UTC:') {
			mask = mask.slice(4);
			utc = true;
		}

		const _ = utc ? 'getUTC' : 'get';
		const d = date[`${_}Date`]();
		const D = date[`${_}Day`]();
		const M = date[`${_}Month`]();
		const y = date[`${_}FullYear`]();
		const H = date[`${_}Hours`]();
		const m = date[`${_}Minutes`]();
		const s = date[`${_}Seconds`]();
		const L = date[`${_}Milliseconds`]();
		const o = utc ? 0 : date.getTimezoneOffset();
		const flags = {
			d: d,
			dd: pad(d),
			ddd: dF.i18n.AbbreviatedDayNames[D],
			dddd: dF.i18n.DayNames[D],
			M: M + 1,
			MM: pad(M + 1),
			MMM: dF.i18n.AbbreviatedMonthNames[M],
			MMMM: dF.i18n.MonthNames[M],
			yy: String(y).slice(2),
			yyyy: y,
			h: H % 12 || 12,
			hh: pad(H % 12 || 12),
			H: H,
			HH: pad(H),
			m: m,
			mm: pad(m),
			s: s,
			ss: pad(s),
			l: pad(L, 3),
			L: pad(L > 99 ? Math.round(L / 10) : L),
			t: H < 12 ? dF.i18n.AMDesignator.substring(0, 1).toLowerCase() : dF.i18n.PMDesignator.substring(0, 1).toLowerCase(),
			tt: H < 12 ? dF.i18n.AMDesignator.toLowerCase() : dF.i18n.PMDesignator.toLowerCase(),
			T: H < 12 ? dF.i18n.AMDesignator.substring(0, 1).toUpperCase() : dF.i18n.PMDesignator.substring(0, 1).toUpperCase(),
			TT: H < 12 ? dF.i18n.AMDesignator : dF.i18n.PMDesignator,
			Z: utc ? 'UTC' : (String(date).match(timezone) || ['']).pop().replace(timezoneClip, ''),
			o: (o > 0 ? '-' : '+') + pad(Math.floor(Math.abs(o) / 60) * 100 + Math.abs(o) % 60, 4),
			S: ['th', 'st', 'nd', 'rd'][d % 10 > 3 ? 0 : (d % 100 - d % 10 !== 10) * d % 10]
		};

		return mask.replace(token, function($0) {
			return $0 in flags ? flags[$0] : $0.slice(1, $0.length - 1);
		});
	};
}());

// Internationalization strings
dateFormat.i18n = {
	'AMDesignator': 'AM',
	'PMDesignator': 'PM',
	'DateSeparator': '/',
	'TimeSeparator': ':',
	'AbbreviatedDayNames': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
	'ShortestDayNames': ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
	'DayNames': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
	'AbbreviatedMonthNames': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', ''],
	'MonthNames': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', ''],
	'AbbreviatedMonthGenitiveNames': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', ''],
	'MonthGenitiveNames': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', ''],

	// standard format patterns
	'FullDateTimePattern': 'dddd, MMMM dd, yyyy h:mm:ss tt',
	'LongDatePattern': 'dddd, MMMM dd, yyyy',
	'LongTimePattern': 'h:mm:ss tt',
	'MonthDayPattern': 'MMMM dd',
	'RFC1123Pattern': "ddd, dd MMM yyyy HH':'mm':'ss 'GMT'",
	'ShortDatePattern': 'M/d/yyyy',
	'ShortTimePattern': 'h:mm tt',
	'SortableDateTimePattern': "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
	'UniversalSortableDateTimePattern': "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
	'YearMonthPattern': 'MMMM, yyyy'
};

// Some common format strings
dateFormat.masks = {
	'default': dateFormat.i18n.FullDateTimePattern,
	FullDateTime: dateFormat.i18n.FullDateTimePattern,
	LongDate: dateFormat.i18n.LongDatePattern,
	LongTime: dateFormat.i18n.LongTimePattern,
	MonthDay: dateFormat.i18n.MonthDayPattern,
	RFC1123: dateFormat.i18n.RFC1123Pattern,
	ShortDate: dateFormat.i18n.ShortDatePattern,
	ShortTime: dateFormat.i18n.ShortTimePattern,
	SortableDateTime: dateFormat.i18n.SortableDateTimePattern,
	UniversalSortableDateTime: dateFormat.i18n.UniversalSortableDateTimePattern,
	YearMonth: dateFormat.i18n.YearMonthPattern,
	IsoDotNet: "yyyy'-'MM'-'dd'T'HH':'mm':'ss'.'l'0000Z'",
	UiSortable: 'yyyy/MM/dd hh:mm tt', // default format in ui
	UiSortableDate: 'yyyy/MM/dd'
};

/**
 * Formats this Date.
 *
 * @param {string} mask
 * @param {bool} [utc]
 * @param {string} Formatted date.
 */
// eslint-disable-next-line no-extend-native
Date.prototype.format = function(mask, utc) {
	return dateFormat(this, mask, utc);
};
