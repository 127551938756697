import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import Cache from 'common/lib/dataCache';
import appSettings from 'config';
import selectClient from './modules/selectClient/module';
const uriRoot = `${appSettings.UserManagementUri}clients`;

const subModuleApis = [selectClient];

const Client = Backbone.Model.extend({ idAttribute: 'Id' });
const Clients = Backbone.Collection.extend({
	model: Client,
	comparator: function(model) {
		return model.get('DisplayName');
	}
});

function init(app) {
	const getClientsFromServer = function() {
		return $.ajax({
			url: uriRoot,
			type: 'GET',
			contentType: 'application/json',
			dataType: 'json'
		}).then(function(arr) {
			return new Clients(arr);
		});
	};

	const getClientFromServer = function(clientId) {
		return $.ajax({
			url: `${uriRoot}/${clientId}`,
			type: 'GET',
			contentType: 'application/json',
			dataType: 'json'
		}).then(function(obj) {
			return new Client(obj);
		});
	};

	const getAccessibleClients = function() {
		let clientId;

		return app
			.request('app.services.users.getCurrentUser')
			.then(function(user) {
				clientId = user.get('ClientId');
				return app.request('app.services.users.isSystemAdmin', clientId, user.get('Id'));
			})
			.then(function(isSysAdmin) {
				if (isSysAdmin) {
					return getClientsFromServer();
				} else {
					return getClientFromServer(clientId).then(function(client) {
						return new Clients([client.attributes]);
					});
				}
			});
	};

	const clientsCache = new Cache({
		name: 'clie',
		fetchCollection: getAccessibleClients
	});

	const api = {
		list: function() {
			return clientsCache.getCollection({ fetchArgs: arguments });
		},
		get: function(clientId) {
			return clientsCache.getItem({
				key: clientId,
				fetchArgs: arguments,
				fetchViaCollection: true
			});
		}
	};

	// Extend with submodule apis.
	_.each(subModuleApis, (subModuleApi) => {
		_.extend(api, typeof subModuleApi === 'function' ? subModuleApi(app) : subModuleApi);
	});

	return api;
}

export default init;
