import $ from 'jquery';
import Marionette from 'marionette';
import template from './template.html';
import 'ui/button';

const ToggleLockView = Marionette.ItemView.extend({
	template: template,

	modelEvents: {
		'success': 'render',
		'error': 'showError'
	},

	events: {
		'click .js-toggleLock': 'onToggleLock'
	},

	showError: function() {
		this.render();
		this.$('.js-messages')
			.removeClass('hidden')
			.html('<span class="msg error">Could not lock your account. Please try again.</span>');
	},

	onToggleLock: function(e) {
		e.preventDefault();
		this.model.toggleLock();
		$(e.target).tmbutton({ initialState: 'busy' });
	},

	onDestroy: function() {
		this.$('.js-toggleLock').tmbutton('destroy');
	}
});

export default ToggleLockView;
