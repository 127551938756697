import tools from 'common/tools/main';
import mixin from 'common/mixins/main';
import Backbone from 'backbone';

const EventLogModel = Backbone.Model.extend({
	initialize: function(data) {
		if (data.Timestamp) {
			this.set('Timestamp', tools.parseIsoDate(data.Timestamp));
		}
	}
});

const EventLogCollection = Backbone.Collection.extend({
	model: EventLogModel,
	initialize: function() {
		mixin.pagingCollection({ that: this, hasMoreServerData: false });
		mixin.sortableCollection({ that: this, sortDirection: -1, sortProperty: 'Timestamp' });
	}
});

export default EventLogCollection;
