var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 var idPrefix = 'file-' + modelId; 
__p+='\n\n';
 if (typeof InTrash === 'boolean' && InTrash === true) { 
__p+='\n\n	<td colspan="2">\n		Deleted '+
((__t=( filename ))==null?'':__t)+
'\n	</td>\n	<td class="file-undo">\n		<button class="js-cancelDelete btn-reset action-link">undo</button>\n	</td>\n\n';
 } else { 
__p+='\n\n	<td>\n		';
 if (DownloadUri) { 
__p+='\n			<a href="'+
((__t=( DownloadUri ))==null?'':__t)+
'" target="_blank">'+
((__t=( filename ))==null?'':_.escape(__t))+
'</a>\n		';
 } else { 
__p+='\n			'+
((__t=( filename ))==null?'':_.escape(__t))+
'\n		';
 } 
__p+='\n		<label for="'+
((__t=( idPrefix ))==null?'':_.escape(__t))+
'-renameFile" class="screenreader">Rename file</label>\n		<input type="text" id="'+
((__t=( idPrefix ))==null?'':_.escape(__t))+
'-renameFile" name="renameFile" class="input-rename hidden" value="'+
((__t=( filename ))==null?'':_.escape(__t))+
'" />\n		<span class="msg error file-msg hidden">\n			<span class="ldi ldi-close hoverable-light clickable js-dismissMsg" title="Dismiss this message"></span>\n			<span class="js-file-msg"></span>\n		</span>\n	</td>\n	<td class="file-meta col-metadata-date">\n		'+
((__t=( lastModified.format('UiSortable') ))==null?'':__t)+
'\n	</td>\n	<td class="file-tools">\n		<input type="file" name="inputFile" class="input-replace hidden" aria-labelledby="'+
((__t=( idPrefix ))==null?'':_.escape(__t))+
'-replace" />\n		';
 _.each(toolsConfig, function(o) { 
__p+='\n			<button\n				id="'+
((__t=( idPrefix ))==null?'':_.escape(__t))+
'-'+
((__t=( o.id ))==null?'':__t)+
'"\n				class="js-tool js-'+
((__t=( o.id ))==null?'':__t)+
' btn-reset ldi hoverable clickable ldi-'+
((__t=( o.icon ))==null?'':__t)+
'"\n				title="'+
((__t=( o.description ))==null?'':__t)+
'"\n				aria-label="'+
((__t=( o.description ))==null?'':__t)+
'"\n				aria-role="button"\n			></button>\n		';
 }); 
__p+='\n	</td>\n\n';
 } 
__p+='\n';
}
return __p;
};
