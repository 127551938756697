var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>Group memberships</h2>\n\n<div class="grid-row">\n	<div class="form-horizontal grid-md-12 grid-lg-8">\n		<div class="region-error"></div>\n\n		';
 if (typeof error !== 'string' || error === '') { 
__p+='\n		<div class="form-group">\n			<label class="form-label grid-md-3" for="userGroups">Non-system groups</label>\n			<div class="grid-md-6">\n				';
 _.each(Groups, function(group) { 
__p+='\n					';
 if (!group.IsSystemDefined) { 
__p+='\n					<div class="checkbox">\n						<label>\n							<input class="js-groups" type="checkbox" name="userGroups" value="'+
((__t=( group.GroupId ))==null?'':__t)+
'" ';
 if (group.Selected) { 
__p+=' checked ';
 } 
__p+=' />\n							'+
((__t=( group.GroupName ))==null?'':__t)+
'\n						</label>\n					</div>\n					';
 } 
__p+='\n				';
 });
__p+='\n			</div>\n		</div>\n\n		<div class="form-group">\n			<label class="form-label grid-md-3" for="userSysgroups">System groups</label>\n			<div class="grid-md-6">\n				';
 _.each(Groups, function(group) { 
__p+='\n					';
 if (group.IsSystemDefined) { 
__p+='\n					<div class="checkbox">\n						<label>\n							<input class="js-groups" type="checkbox" name="userSysgroups" value="'+
((__t=( group.GroupId ))==null?'':__t)+
'" ';
 if (group.Selected) { 
__p+=' checked ';
 } 
__p+=' />\n							'+
((__t=( group.GroupName ))==null?'':__t)+
'\n						</label>\n					</div>\n					';
 } 
__p+='\n				';
 });
__p+='\n			</div>\n		</div>\n\n		<div class="form-group">\n			<div class="grid-md-6 grid-md-push-3">\n				<button id="submit_groups" data-class="editOnly" name="save" class="btn js-submit">Save</button>\n			</div>\n		</div>\n\n		';
 } 
__p+='\n\n	</div>\n</div>\n';
}
return __p;
};
