import app from 'app';
import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import Grid from 'common/modules/table/main';
import tplView from './templates/view.html';
import tplTdActions from './templates/tdActions.html';
import tplTdProgress from './templates/tdProgress.html';
import tplTdFinal from './templates/tdFinal.html';
// import debug from 'appModules/monitor/debug' // @todo

const debug = false;

const Layout = Marionette.LayoutView.extend({
	template: tplView,
	className: 'workspace-main',
	regions: {
		regionHeader: '#region-header',
		regionBody: '#region-body'
	},
	events: {
		'click .js-finalize': 'onClickFinalize'
	},
	initialize: function (opts) {
		this.collection = opts.collection;
		this.controller = opts.controller;
		this.grid = this.createGrid();
		this.grid.get('controller').sortByColumn('Date', 'dsc');
	},
	createGrid: function () {
		const collection = this.collection;
		const grid = new Grid({
			pageLength: 25,
			collection: collection,
			columns: [
				// Columns backed by real data
				{ id: 'JobTypeName', displayName: 'Job Type', width: 200 },
				{ id: 'JobName', displayName: 'Job', width: 150 },
				{ id: 'DisplayName', displayName: 'Publication Name', width: 200 },
				{ id: 'Id', displayName: 'Publication Id', width: 150, hasColumnFilter: false },
				{ id: 'UserName', displayName: 'User', width: 150 },
				{ id: 'OutputType', displayName: 'Type', width: 120 },
				{ id: 'DocsCount', displayName: '# Items', width: 100 },
				{ id: 'Date', displayName: 'Submitted', width: 180, type: 'date', filterType: 'range' },
				{ id: 'Status', displayName: 'Status', width: 100 },
				{ id: '_isFinal', displayName: 'Final?', width: 80, tdViewMixin: { template: tplTdFinal } },

				// Custom column with progress bar
				{
					id: 'Progress',
					displayName: 'Progress',
					width: 100,
					hasColumnFilter: false,
					hasColumnSort: false,
					tdViewMixin: {
						template: tplTdProgress,
						onInitialize: function () {
							this.destroyProgressTracking();
							this.setupProgressTracking();
						},
						setupProgressTracking: function () {
							const model = this.model;

							if (model.get('Status') !== 'Started') return;

							this.listenTo(app.vent, 'server:events', function (e) {
								const id = model.get('CorrelationId');

								if (debug) console.log(`monitor: heard a progressEvent (correlationId ${id})`);

								// My event AND it has data
								if (e.CorrelationId === id && typeof e === 'object' && typeof e.Message === 'object') {
									if (debug) console.log('monitor: heard a matching progress event');

									const pct = e.Message.pct || e.Message.Percentage || 0;
									model.set('ProgressPct', pct);

									// Are we done?
									if (e.Message.Type === 'OutputCreationCompleted' || e.Message.Type === 'OutputCreationFailed') {
										model.set('Status', e.Message.Status);
										this.destroyProgressTracking();
									}
								}
							});
						},
						destroyProgressTracking: function () {
							this.stopListening(app.vent, 'server:events');
						}
					}
				},

				// Custom column with buttons
				{
					id: 'Downloads',
					displayName: 'Downloads',
					width: 300,
					hasColumnFilter: false,
					hasColumnSort: false,
					tdViewMixin: {
						template: tplTdActions,
						events: {
							'click .js-cancel': 'onClickCancel'
						},
						onClickCancel: function () {
							const model = this.model;
							app.request('app.services.publishing.cancel', model.get('ClientId'), model.get('Id'));
						}
					}
				}
			]
		});

		this.listenTo(grid.get('controller'), 'page', this.onPage);
		this.listenTo(collection, 'tm:selectionchange', this.onSelectionChange);

		return grid;
	},
	onShow: function () {
		this.regionBody.show(this.grid.view);
	},
	onClickFinalize: function (e) {
		const isFinal = $(e.target).attr('data-final') === 'true';
		const selected = this.collection.where({ 'tm:selected': true });
		this.controller.markAsFinal(selected, isFinal).done(function () {
			_.invoke(selected, 'set', 'tm:selected', false);
		});
	},
	onPage: function () {
		_.invoke(this.grid.get('controller').get('pageModels'), 'load');
	},
	onSelectionChange: function () {
		const selected = this.collection.where({ 'tm:selected': true });
		this.$('.js-bulkaction').prop('disabled', !selected.length);
	},
	onDestroy: function () {
		if (this.grid) this.grid.destroy();
	}
});

export default Layout;
