import Marionette from 'marionette';
import template from './templates/layout_dataUploader.html';

const View = Marionette.LayoutView.extend({
	template: template,
	regions: {
		regionHeader: '#region-dataUploader-header',
		regionFileSpecs: '#region-dataUploader-fileSpecs',
		regionOperations: '#region-dataUploader-operations'
	}
});

export default View;
