import _ from 'underscore';
import Marionette from 'marionette';
import tools from 'common/tools/main';
import helpers from '../helpers';

const TdView = Marionette.ItemView.extend({
	tm_classId: 'TdView',
	tagName: 'td',
	template: _.template('<%= renderedValue %>'),
	className: function() {
		return this.options.column.tdClasses;
	},
	attributes: function() {
		return { 'data-tbf-attr': this.options.column.id };
	},
	_initialize: function(opts) {
		this.options = opts;

		const col = opts.column;
		const model = this.model = opts.model;

		this.listenTo(model, `change:${col.id}`, this.render);
		Marionette.bindEntityEvents(this, model, this.modelEvents);

		if (typeof this.onInitialize === 'function') this.onInitialize(opts);

		return this;
	},
	initialize: function(opts) {
		this.listenTo(opts.controller, 'tm:redrawn', this.delegateEvents);
		Marionette.bindEntityEvents(this, opts.collection, this.collectionEvents);
		return this._initialize(opts);
	},
	reinitialize: function(opts) {
		this.stopListening(this.model);
		Marionette.unbindEntityEvents(this, this.model, this.modelEvents);
		return this._initialize(opts);
	},
	serializeData: function() {
		const opts = this.options;
		const model = opts.model;
		const col = opts.column;
		const val = helpers.getColumnValue(model, col);

		return {
			column: col,
			model: model,
			value: val,
			renderedValue: this._renderValue(col, val),
			options: opts
		};
	},
	render: function(renderOpts) {
		// Trigger onBeforeRender callbacks
		this.triggerMethod('before:render', this);

		const $el = this.$el;

		// If this is a bulk redraw, detach $el before mutating it. Someone
		// else will be responsible for re-attaching.
		if (typeof renderOpts === 'object' && renderOpts.redraw) $el.detach();

		$el.empty().html(this.template(this.serializeData()));

		// Trigger onRender callbacks
		this.triggerMethod('render', this);

		return this;
	},
	_renderValue: function(col, val) {
		let tmp;

		if (typeof col.renderValue === 'function') {
			return col.renderValue(val);
		} else if (col.type === 'date') {
			if (!_.isDate(val)) {
				// Date column but `val` not Date object. Attempt to parse
				// it into a valid date.
				try {
					tmp = new Date(val);
					if (tools.isValidDate(tmp)) val = tmp;
				} catch (e) {
				}
			}

			// If we have a valid date, format it.
			if (tools.isValidDate(val)) {
				return val.format(col.dateFormat || 'UiSortable');
			}
		}

		// If we got here, just returning `val` w/o special formatting.
		return val;
	}
});

export default TdView;
