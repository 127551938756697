import Marionette from 'marionette';
import tpl from '../templates/base-th.html';

const ThView = Marionette.ItemView.extend({
	tm_classId: 'ThView',
	tagName: 'th',
	template: tpl,
	className: function() {
		return this.options.column.thClasses;
	},
	attributes: function() {
		const col = this.options.column;
		return {
			'data-tbf-attr': col.id,
			'style': `width: ${col.width}px;`
		};
	},
	serializeData: function() {
		const opts = this.options;
		return { column: opts.column, options: opts };
	}
});

export default ThView;
