var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<td>'+
((__t=( Name ))==null?'':__t)+
'</td>\n<td>\n    ';
 if (IsSystemDefined) { 
__p+='\n        <button class="js-edit btn" disabled>Edit</button>\n        <button class="js-delete btn" disabled>Delete</button>\n    ';
 } else { 
__p+='\n        <a href="#admin/groups/'+
((__t=( Id ))==null?'':__t)+
'" class="btn">Edit</a>\n        <button class="js-delete btn">Delete</button>\n    ';
 } 
__p+='\n</td>\n';
}
return __p;
};
