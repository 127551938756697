var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="card-progress amt" role="progressbar" aria-valuemin="0" aria-valuemax="100" \n	';
 if (hasProgressPct) { 
__p+='\n		aria-labelledby="operation-'+
((__t=( name ))==null?'':__t)+
'-name"\n		aria-valuenow="'+
((__t=( progressPct * 100 ))==null?'':__t)+
'"\n		style="width: '+
((__t=( progressPct * 100 ))==null?'':__t)+
'%"\n	';
 } else { 
__p+='\n		aria-hidden="true"\n	';
 } 
__p+='\n></div>\n<div class="card-controls-left">\n	<span class="card-toggle fa fa-caret-right"></span>\n</div>\n<div class="card-name" id="operation-'+
((__t=( name ))==null?'':__t)+
'-name">'+
((__t=( DisplayName ))==null?'':__t)+
'</div>\n<div class="card-controls-right">\n	<div class="card-tools">\n		';
 _.each(toolsConfig, function(o) { 
__p+='\n			<button\n				class="js-tool js-'+
((__t=( o.id ))==null?'':__t)+
' btn-reset ldi hoverable clickable ldi-'+
((__t=( o.icon ))==null?'':__t)+
'"\n				title="'+
((__t=( o.description ))==null?'':__t)+
'"\n				aria-label="'+
((__t=( o.description ))==null?'':__t)+
'"\n			></button>\n		';
 }); 
__p+='\n	</div>	\n	<div class="card-status '+
((__t=( statusClass ))==null?'':__t)+
'">\n		';
 if (hasProgressPct) { 
__p+='\n			<span class="card-progress-pct">'+
((__t=( Math.ceil(progressPct * 100) ))==null?'':__t)+
'</span>\n		';
 } else { 
__p+='\n			<span class="ldi ldi-'+
((__t=( statusIcon ))==null?'':__t)+
'"></span>\n		';
 } 
__p+='\n	</div>\n</div>';
}
return __p;
};
