import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import tools from 'common/tools/main';
import tplLayout from './templates/tpl_layout.html';
import tplForm from './templates/tpl_form.html';

const isPrivateKey = tools.regex.isPrivateKey;

const FormView = Marionette.ItemView.extend({
	template: tplForm,

	modelEvents: {
		change: 'render'
	},

	events: {
		'change .js-jobtype': 'onChangeJobType',
		'change .js-fixed-attr': 'onChangeFixedAttr',
		'change .js-attr': 'onChangeAttr'
	},

	templateHelpers: function() {
		let attributes = _.clone(this.model.attributes.Attributes);

		// Remove all private attributes. Shouldn't be editing those!
		attributes = _.filter(attributes, function(item) {
			return !isPrivateKey(item.Name);
		});

		// For remaining keys, if they have no display name, default to its name.
		_.each(attributes, function(item) {
			if (typeof item.DisplayName === 'undefined' || item.DisplayName === '') {
				item.DisplayName = item.Name;
			}
		});

		// Will override original Attributes in model.
		return { Attributes: attributes };
	},

	onChangeJobType: function(e) {
		const jobTypeId = $(e.target).val();
		this.model.set('JobTypeId', jobTypeId);
	},

	onChangeFixedAttr: function(e) {
		const $input = $(e.target);
		const inputName = $input.attr('name');
		const inputValue = $input.val();
		this.model.set(inputName, inputValue);
	},

	onChangeAttr: function(e) {
		const $input = $(e.target);
		const inputName = $input.attr('name');
		const inputValue = $input.val();

		// Find the relevant attribute in the view model.
		const attributes = this.model.get('Attributes');
		const attr = _.find(attributes, function(a) {
			return a.Name === inputName;
		});

		// If the attribute exists, save the new value in the view model.
		if (typeof attr !== 'undefined') {
			attr.Value = inputValue;
		}
	}
});

const LayoutView = Marionette.LayoutView.extend({
	template: tplLayout,

	regions: {
		regionForm: '.region-form'
	},

	initialize: function() {
		const model = this.model;
		model.set('__editMode', model.has('Job'));
	},

	modelEvents: {
		created: 'onCreated',
		updated: 'onUpdated',
		error: 'onError'
	},

	events: {
		'click .js-save': 'onClickSave',
		'change .form-control': 'updateViewState',
		'keyup .form-control': 'updateViewState'
	},

	onRender: function() {
		// show form view
		this.regionForm.show(new FormView({ model: this.model }));

		// set state
		this.$saveBtn = this.$el.find('.js-save');
		this.$saveBtn.tmbutton();
		this.updateViewState();
	},

	areRequiredFieldsFilled: function() {
		const $requireds = this.$('.required');
		return _.every(
			$requireds.map(function() {
				return (
					$(this)
						.siblings()
						.find('.form-control')
						.val() !== ''
				);
			}),
			function(item) {
				return item === true;
			}
		);
	},

	updateViewState: function() {
		this.setViewState(this.getViewState());
	},

	getViewState: function() {
		return this.model.get('__editMode') || this.areRequiredFieldsFilled() ? 'dirty' : 'clean';
	},

	/**
	 Updates form state -- shows/hides messages, enables/disables save button.

	 Takes account page mode (add|edit) when making these changes, but does
	 not change page mode.

	 @param state {string} Name of state.
	 */
	setViewState: function(state) {
		const $saveBtn = this.$saveBtn;

		switch (state) {
			case 'clean':
				this.clearMessages();
				$saveBtn.tmbutton('state', 'disabled');
				break;

			case 'dirty':
				this.clearMessages();
				$saveBtn.tmbutton('state', 'ready');
				break;

			case 'saving':
				this.clearMessages();
				$saveBtn.tmbutton('state', 'busy');
				break;

			case 'created':
				this.showMessage('Job created!', 'success');
				$saveBtn.tmbutton('state', 'ready');
				break;

			case 'updated':
				this.showMessage('Changes saved!', 'success');
				$saveBtn.tmbutton('state', 'ready');
				break;

			case 'error':
				this.showMessage("Your changes couldn't be saved. Please try again.", 'error');
				$saveBtn.tmbutton('state', 'ready');
				break;
		}
	},

	showMessage: function(msg, type) {
		const map = {
			success: { icon: 'success', msgColor: 'success' },
			error: { icon: 'warning', msgColor: 'error' }
		};
		const s = map[type];

		this.$('.js-messages').append(
			`<div class="form-group js-saveMsg">
			    <div class="grid-md-6 grid-md-push-3">
			        <span class="msg ${s.msgColor}">
			            <span class="ldi ldi-${s.icon}"></span> ${msg}
			        </span>
			    </div>
			</div>`
		);
	},

	clearMessages: function() {
		this.$('.js-messages').empty();
	},

	onClickSave: function(e) {
		e.preventDefault();
		const that = this;
		that.trigger('save', this.model);
		that.setViewState('saving');
	},

	onCreated: function() {
		this.model.set('__editMode', true);
		this.render(); // re-render to update save button label
		this.setViewState('created');
	},

	onUpdated: function() {
		this.setViewState('updated');
	},

	onError: function() {
		this.setViewState('error');
	},

	onDestroy: function() {
		if (this.$saveBtn) this.$saveBtn.tmbutton('destroy');
	}
});

export default LayoutView;
