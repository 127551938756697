var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="form-mini">\n    <div class="form-row">\n        <label for="GroupName" class="form-label">Group name</label>\n        <input id="GroupName" type="text" name="GroupName" value="'+
((__t=( Name ))==null?'':__t)+
'"/>\n    </div>\n</div>\n\n<div class="policy-editor">\n\n    <table class="table table-striped table-bordered">\n        <thead>\n        <tr>\n            <th class="pe-col-chbx"><!-- <input type="checkbox" name="pe-all" value="*" /> --></th>\n            <th class="pe-col-name">Job type</th>\n            <th class="pe-col-activity"><span class="pe-col-title">Create Jobs</span>\n                <!--<input type="checkbox" name="CreateJob" value="*" />       --> </th>\n            <th class="pe-col-activity"><span class="pe-col-title">Import data</span>\n                <!--<input type="checkbox" name="ImportData" value="*" />      --> </th>\n            <th class="pe-col-activity"><span class="pe-col-title">Create documents</span>\n                <!--<input type="checkbox" name="CreateDocuments" value="*" /> --> </th>\n            <th class="pe-col-activity"><span class="pe-col-title">View documents</span>\n                <!--<input type="checkbox" name="ViewDocuments" value="*" />   --> </th>\n            <th class="pe-col-activity"><span class="pe-col-title">Publish documents</span>\n                <!--<input type="checkbox" name="PublishDocuments" value="*" />--> </th>\n            <th class="pe-col-activity"><span class="pe-col-title">Publish blacklines</span>\n                <!--<input type="checkbox" name="PublishBlackline" value="*" />--> </th>\n        </tr>\n        </thead>\n        <tbody>\n        </tbody>\n    </table>\n\n</div>\n\n<p>\n    <button class="btn js-save">Save</button>\n</p>\n\n<div class="form-row">\n    <div class="js-messages"></div>\n</div>';
}
return __p;
};
