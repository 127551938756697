var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (model.get('_isFinal')) { 
__p+='\n	<span class="fa fa-star"></span> Yes\n';
 } else { 
__p+='\n	<span class="fa fa-star-o"></span> No\n';
 } 
__p+='\n';
}
return __p;
};
