import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import View from './view';

function init(app) {
	return {
		getEditUserGroupsView: function(clientId, subjectId) {
			const d1 = app.request('app.services.users.getSecuritySubject', clientId, subjectId);
			const d2 = app.request('app.services.users.getSecurityGroups', clientId);
			const final = $.Deferred();

			$.when(d1, d2)
				.then(function(subject, allGroups) {
					const viewModel = new Backbone.Model({
						SubjectId: subject.get('Id'),
						ClientId: clientId,
						Groups: allGroups.map(function(g) {
							return {
								GroupId: g.get('Id'),
								GroupName: g.get('Name'),
								IsSystemDefined: g.get('IsSystemDefined'),
								Selected: _.contains(subject.get('Groups'), g.get('Id'))
							};
						})
					});

					const view = new View({ model: viewModel });

					view.on('save', function(model) {
						const selectedGroupsIds = _.map(
							_.filter(model.get('Groups'), function(g) {
								return g.Selected;
							}),
							function(g) {
								return g.GroupId;
							}
						);
						app.request(
							'app.services.users.setSubjectSecurityGroups',
							model.get('ClientId'),
							model.get('SubjectId'),
							selectedGroupsIds
						);
						this.trigger('saved');
					});

					final.resolve(view);
					return view;
				})
				.fail(function() {
					const model = new Backbone.Model({});
					const view = new View({ model: model });

					if (d1.state() === 'rejected') {
						model.set('error', 'Could not fetch user information.');
					} else if (d2.state() === 'rejected') {
						model.set('error', 'Could not fetch groups.');
					} else {
						model.set('error', 'An error occurred.');
					}

					final.resolve(view);
					return view;
				});

			return final.promise();
		}
	};
}

export default init;
