import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import tplNotif from './templates/notification.html';
import tplEmpty from './templates/empty.html';
import tplPanel from './templates/panel.html';
import 'jquery-timeago';

/** Blank template, given to Views that don't actually need a template. */
const tplBlank = _.template('');

/** Displayed in the panel when there are no notifications. */
const EmptyView = Marionette.ItemView.extend({
	template: tplEmpty
});

/** A notification displayed in the panel. */
const NotificationView = Marionette.ItemView.extend({
	template: tplNotif,
	className: 'notification',
	modelEvents: {
		'change:title change:body change:progress change:icon change:timestamp': 'render'
	},
	events: {
		'click .notification-close': 'onClickDismiss'
	},
	serializeData: function() {
		return { model: this.model, $ };
	},

	/**
	 Updates this notification's displayed time, if it is currently being
	 displayed.
	 */
	refreshDate: function() {
		this.$('.notification-date').html($.timeago(this.model.get('timestamp')));
	},

	onClickDismiss: function() {
		this.model.destroy();
	}
});

/** The notification panel. */
const NotificationPanelView = Marionette.CompositeView.extend({
	settings: {
		autoRefreshInterval: 60000
	},
	el: '#app-notification-panel',
	template: tplPanel,
	childViewContainer: '.notifications-list',
	childView: NotificationView,
	emptyView: EmptyView,
	events: {
		'click .btn-clearNotifications': 'onClickClear'
	},
	collectionEvents: {
		'change:bubble': 'render'
	},
	initialize: function() {
		// Cache elements.
		this.$btn = $('#notifications-toggle'); // @todo messy!

		// Used in `autoRefreshDates`.
		this.fnRefreshDatesBound = _.bind(this.refreshDates, this);
	},

	/**
	 Returns true if the specified model should be shown in this View.

	 @overrides Marionette.CollectionView#filter
	 @param {Backbone.Model} child
	 @returns {boolean}
	 */
	filter: function(child) {
		return !child.get('bubble');
	},

	/**
	 Returns true if the backing collection is considered empty for this
	 View's purposes.

	 @overrides Marionette.CollectionView#isEmpty
	 @param {Backbone.Collection} collection
	 @returns {boolean}
	 */
	isEmpty: function(collection) {
		return collection.where({ bubble: false }).length <= 0;
	},

	/**
	 Toggles visibility of the notification panel. If the panel is currently
	 visible, hides it; if the panel is currently hidden, shows it. When the
	 panel is shown, date auto-refresh is enabled.

	 @param {boolean} [makeOpen]  Forces panel to be open if `true`, closed
	 if `false`, regardless of current visibility of panel.
	 */
	toggle: function(makeOpen) {
		if (typeof makeOpen !== 'boolean') {
			makeOpen = this.$el.hasClass('hidden');
		}

		if (makeOpen) {
			this.$btn.addClass('active');
			this.refreshDates();
			this.autoRefreshDates(true);
			this.$el.removeClass('hidden');
		} else {
			this.$btn.removeClass('active');
			this.$el.addClass('hidden');
			this.autoRefreshDates(false);
		}
	},

	/**
	 Refresh dates on all notifications within the panel.
	 */
	refreshDates: function() {
		_.invoke(this.children._views, 'refreshDate');
	},

	/**
	 Toggles date auto-refresh on the notifications within the panel. When on,
	 dates auto-refresh every minute.

	 @param {bool} on  True to turn on auto-refresh, false to turn it off.
	 */
	autoRefreshDates: function(on) {
		if (on) {
			this.autoRefreshDateTimer = setInterval(this.fnRefreshDatesBound, this.settings.autoRefreshInterval);
		} else {
			clearInterval(this.autoRefreshDateTimer);
		}
	},

	onClickClear: function() {
		const collection = this.collection;
		const panelModels = collection.where({ bubble: false });

		if (panelModels.length === collection.length) {
			// All notifications are currently displayed in the panel, so
			// clear means resetting entire collection.
			collection.reset();
		} else {
			collection.remove(panelModels);
		}
	}

});

/** A notification bubble. */
const NotificationBubbleView = Marionette.ItemView.extend({
	settings: {
		fadeSpeed: 400
	},
	template: tplNotif,
	className: 'notification notification-bubble',
	serializeData: function() {
		return { model: this.model, $ };
	},
	remove: function(view) {
		const $el = this.$el;
		$el.fadeOut(this.settings.fadeSpeed, function() {
			$el.remove();
		});
	}
});

/** Container holding notification bubbles. */
const NotificationBubbleListView = Marionette.CollectionView.extend({
	el: '#app-notification-bubbles',
	template: tplBlank,
	childView: NotificationBubbleView,
	collectionEvents: {
		'change:bubble': 'onChangeBubbleState'
	},
	filter: function(child) {
		return child.get('bubble');
	},
	onChangeBubbleState: function(child, bubble, collection) {
		if (bubble) {
			// Show notification in this list
			this.addChild(child, this.childView);
		} else {
			// Remove notification from this list
			const childView = this.children.findByModel(child);
			this.removeChildView(childView);
		}
	}
});

/** The notification count on the nav bar icon. */
const NotificationCountView = Marionette.ItemView.extend({
	settings: {
		lang: {
			// Format array: Must start from lowest amount!
			count: [
				{ amount: 1000, format: '%n%k' },
				{ amount: 1000000, format: '<span style="font-size:14px; line-height:0.8;">&infin;</span>' }
			]
		}
	},
	el: '#notifications-toggle .notification-count',
	template: tplBlank,
	initialize: function(opts) {
		const collection = this.collection = opts.collection;
		this.on('render', this.refresh);
		this.listenTo(collection, 'add remove reset', this.refresh);
		this.listenTo(collection, 'change:progress', this.updatePulseState);
	},

	/**
	 Refreshes the counter and pulse state to reflect collection status.
	 Called on render and collection add/remove/reset.
	 */
	refresh: function() {
		this.updateCount();
		this.updatePulseState();
	},

	/**
	 Updates the notification counter in the UI to reflect the current total
	 number of panel and bubble notifications.
	 */
	updateCount: function() {
		const n = this.collection.length;
		this.$el.toggleClass('hidden', n <= 0).html(this.formatCount(n));
	},

	/**
	 Updates the pulse state in the UI, reflecting whether there are any
	 ongoing progress notifications.
	 */
	updatePulseState: function() {
		const pulseOn = this.collection.some(function(model) {
			return typeof model.get('progress') === 'number';
		});
		this.$el.toggleClass('pulse', pulseOn);
	},

	/**
	 Formats notification count for display.

	 @param num {int} Number to format.
	 @returns {str|int} num as formatted string, or num itself if no formatting
	 was necessary.
	 */
	formatCount: function(num) {
		const points = this.settings.lang.count;
		let i = points.length; let pt; let x;

		// Look for correct count format settings...
		while (i--) {
			pt = points[i];
			x = num / pt.amount;
			// Correct formatter. Floor "x", and put that in the format string.
			if (x >= 1) return pt.format.replace(/%n%/g, 0 | x);
		}

		// None of the formatters were appropriate. Return "num" as-is.
		return num;
	}
});

export default {
	NotificationCount: NotificationCountView,
	NotificationPanel: NotificationPanelView,
	NotificationBubbleList: NotificationBubbleListView,
	Notification: NotificationView,
	NotificationBubble: NotificationBubbleView
};
