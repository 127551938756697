// in order of display in toolbar
// main tools always display in main area, sub tools in flyout
export default {
	startTool: 'edit',
	startSubtool: undefined,

	// in order of display in toolbar
	// main tools always display in main area, sub tools in flyout
	tools: [
		{
			name: 'documents',
			icon: 'documents',
			displayName: 'Documents',
			requireUri: 'appModules/job/documents/documents_ctrl',
			importName: 'documents',
			subtools: [
				{
					name: 'publish',
					icon: 'publish',
					displayName: 'Publish',
					requireUri: 'appModules/job/publisher/publisher_ctrl',
					importName: 'publisher',
					makeCustomOpts: function(workspaceController) { // @todo hack
						return {
							type: 'documents',
							job: workspaceController.model.get('Job')
						};
					}
				}
			]
		},
		{
			name: 'edit',
			icon: 'edit',
			displayName: 'Edit',
			requireUri: 'appModules/job/addJob/addJob_ctrl',
			importName: 'addJob',
			alwaysEnabled: true
		},
		{
			name: 'loadData',
			icon: 'loadData',
			displayName: 'Load data',
			requireUri: 'appModules/job/dataUploader/dataUploader_ctrl',
			importName: 'dataUploader'
		},
		{
			name: 'blackline',
			icon: 'blackline',
			displayName: 'Blackline',
			requireUri: 'appModules/job/blackline/blackline_ctrl',
			importName: 'blackline',
			subtools: [
				{
					name: 'publish',
					icon: 'publish',
					displayName: 'Publish',
					requireUri: 'appModules/job/publisher/publisher_ctrl',
					importName: 'publisher',
					makeCustomOpts: function(workspaceController) { // @todo hack
						const model = workspaceController.model;
						return {
							type: 'blackline',
							job: model.get('Job'),
							blm: model.get('CurrentTool').blacklineModel
						};
					}
				}
			]
		},
		{
			name: 'logs',
			icon: 'history',
			displayName: 'Logs',
			requireUri: 'appModules/job/logs/logs_ctrl',
			importName: 'logs'
		}
	]
};
