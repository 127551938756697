import $ from 'jquery';
import Marionette from 'marionette';
import tpl from './templates/tpl_layout.html';

const View = Marionette.LayoutView.extend({
	template: tpl,
	className: 'workspace-main',

	modelEvents: {
		change: 'onModelChange'
	},

	templateHelpers: function() {
		return {
			userDisplayName: this.buildUserDisplayName()
		};
	},

	buildUserDisplayName: function() {
		const model = this.model;
		let name = '';
		const account = model.get('userAccount');

		if (account) {
			if (account.get('FirstName')) {
				name = $.trim(account.get('FirstName'));
			} else if (account.get('Email')) {
				name = $.trim(account.get('Email'));
			}
		}

		return name;
	},

	onModelChange: function() {
		const model = this.model;

		// Listen to user account model for changes too.
		if (model.changed.userAccount) {
			this.listenTo(model.changed.userAccount, 'change', this.render);
			const prevAccount = model.previous('userAccount');
			if (prevAccount) this.stopListening(prevAccount);
		}

		// Re-render view.
		this.render();
	}
});

export default View;
