import $ from 'jquery';
import _ from 'underscore';

import FAKE_DATA from 'services/srri/debug';

import appSettings from 'config';
const uriRoot = `${appSettings.ApiUri}srri/`;
// app.session.set('debug:srri', true);

function init(app) {
	return {
		get: function(clientId, dateStr) {
			if (!clientId) throw new Error('clientId required');

			let promise;

			if (app.session.get('debug:srri')) {
				promise = FAKE_DATA.get(dateStr);
			} else {
				let url = `${uriRoot + clientId}/getall`;

				if (dateStr) {
					if (_.isDate(dateStr)) {
						throw new Error('date strings (yyyy-mm-dd) required; Date objects not accepted');
					} else {
						url += `/${dateStr}`;
					}
				}

				promise = $.ajax({ method: 'get', url: url });
			}

			return promise;
		},

		deleteFund: function(clientId, isin, effectiveDate) {
			if (!clientId) throw new Error('clientId required');
			if (!isin) throw new Error('isin required');
			if (!effectiveDate || _.isDate(effectiveDate)) throw new Error('date string (yyyy-mm-dd) required');

			return $.ajax({
				method: 'delete',
				url: `${uriRoot + clientId}/${isin}/${effectiveDate}`
			});
		},

		getRawValues: function(clientId, fundGuid, dateStr) {
			if (!clientId) throw new Error('clientId required');
			if (!fundGuid) throw new Error('fundGuid required');
			if (!dateStr || _.isDate(dateStr)) throw new Error('date string (yyyy-mm-dd) required');

			let promise;

			if (app.session.get('debug:srri')) {
				promise = FAKE_DATA.getRawValues(dateStr);
			} else {
				const url = `${uriRoot + clientId}/getnavdata/${fundGuid}/${dateStr}`;
				promise = $.ajax({
					method: 'get',
					url: url,
					contentType: 'application/json',
					dataType: 'json'
				});
			}

			return promise;
		}
	};
}

export default init;
