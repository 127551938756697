import FormView from 'common/modules/formView/view';
import tpl from './template.html';

export default FormView.extend({
	template: tpl,
	goToEditMode: function() {
		FormView.prototype.goToEditMode.apply(this, arguments);
		this.$('[data-class="afterCreate"]').addClass('hidden');
	},
	onSaveSuccess: function() {
		FormView.prototype.onSaveSuccess.apply(this, arguments);
		this.$('[data-class="afterCreate"]').removeClass('hidden');
	}
});
