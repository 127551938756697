import $ from 'jquery';
import View from './view';

function init (app) {
	return {
		getSecuritySettingsView: function(clientId) {
			const view = new View();

			view.on('show', function() {
				app.request('app.services.users.getEditIpRestrictionsView', clientId).done(function(ipRestrictionsView) {
					view.region_ipRestrictions.show(ipRestrictionsView);
				});
			});

			return $.Deferred().resolve(view).promise();
		}
	};
}

export default init;
