import _ from 'underscore';
import Backbone from 'backbone';

const ViewModel = Backbone.Model.extend({
	initialize: function() {
		this.on('change', function() {
			this.isValid();
		});
	},

	// This passes any kind of data. Override to specify validation rules!
	validateData: _.noop,

	validate: function(attrs, options) {
		const errors = this.validateData(attrs, options);

		if (_.isEmpty(errors)) {
			// So that views know this model has been validated.
			this.trigger('valid');
		} else {
			// Must return value only if there are errors.
			return errors;
		}
	}
});

export default ViewModel;
