import $ from 'jquery';
import View from './view';

function init(app) {
	return {
		getUsersPageView: function(clientId) {
			const view = new View({ app, clientId: clientId });
			return $.Deferred()
				.resolve(view)
				.promise();
		}
	};
}

export default init;
