/**
 * Mix into a Marionette CollectionView to enable sorting of its item views.
 *
 * ##### Requirements
 *
 * - Backing collection has a `sortData` method with the following signature:
 *
 * 		sortData(property, direction)
 *			property {string}  Name of attribute to sort by
 *			direction {int}  1 for ascending, -1 for descending
 *
 * - Sort toggle in DOM has class .fa-sort and an attribute "data-property"
 *   whose value is the name of the collection attribute to sort by.
 *
 * ##### Added methods
 *
 * NB: You can override these methods in your Collection definition.
 *
 * - `onSort`: Called when the collection is sorted. By default, rerenders the view.
 * - `onClickToggleSort`: Called when the toggle (`.fa-sort`) is clicked.
 *   Calculates `sortProperty` and `sortDirection`, so be careful if you
 *   override this.
 *
 * @module common/mixins/sortableCollectionView
 *
 * @param {Backbone.View} view  The view to add this mixin to. Alias: `that`
 * @param {string} sortProperty  Initial property to sort by. This is an attribute
 * 	name in this Collection's models.
 * @param {number} [sortDirection=1] Initial sort direction. 1 for ascending,
 * 	-1 for descending.
 *
 * @todo Option to autosort on add/reset/change.
 */
import _ from 'underscore';
import $ from 'jquery';

const sortableCollectionView = function(opts) {
	const view = opts.that || opts.view;

	// Save this mixin's settings onto the view.
	view.mixin_tableSort = _.extend({}, opts);

	// Return some additional data to the template about sort state using
	// serializeData. Remember to run the original serializeData if it
	// exists too!
	const originalSerializeData = view.serializeData;
	view.serializeData = function() {
		const data = _.isFunction(originalSerializeData) ? originalSerializeData.apply(this, arguments) : {};

		return _.extend(data, {
			sortDirection: this.collection.sortDirection,
			sortProperty: this.collection.sortProperty,
			_generateSortHtml: function(name) {
				const cls = name === this.sortProperty ? this.sortDirection >= 0 ? 'fa-sort-up' : 'fa-sort-down' : '';
				return `<span data-property="${name}" class="iconf-tb-th fa fa-sort ${cls}"></span>`;
			}
		});
	};

	// Extend view with methods, letting existing ones take precedence.
	_.defaults(view, {

		/**
			 * Called when the collection is sorted. Renders the view.
			 */
		onSort: function() {
			this.render();
		},

		/**
			 * Called when a sort toggle is clicked. Sorts the collection
			 * according to new sort parameters.
			 *
			 * @param {$.Event} e  Click event.
			 */
		onClickToggleSort: function(e) {
			const $toggle = $(e.target);
			const newProp = $toggle.attr('data-property');

			let newDir;

			const currProp = this.collection.sortProperty;
			const currDir = this.collection.sortDirection;

			if (newProp === currProp) {
				// User is toggling direction on current sort property.
				newDir = currDir >= 0 ? -1 : 1;
			} else {
				newDir = 1;
			}

			// Sort data.
			this.collection.sortData(newProp, newDir);
		}

	});

	// Add additional view events.
	const newEvents = { 'click .fa-sort': 'onClickToggleSort'	};
	view.events = view.events ? _.extend(view.events, newEvents) : newEvents;

	// Finally, set up our listeners. Need to do this at the end because
	// we only have all our callbacks defined on view at this point.
	view.listenTo(view.collection, 'sort', view.onSort);
};

export default sortableCollectionView;
