/**
livedoc.repository.js
=====================

A wrapper to simplify common file actions with the repository's REST API.

- Can force all uploads to use lowercase filenames.  @todo

Requirements
------------

- jQuery (for Deferred objects)
- Underscore
- File, FormData API

Example set up
--------------

    var Repository = require('livedoc-repository');
    var r = new Repository({ host: 'http://localhost:8081/api/repository/resources/' });

    // Then... to rename a file, for example:
    r.rename('dirA/apples.txt', 'apples2.txt').done(function() {
        // success! item now available at 'dirA/apples2.txt'
    }).fail(function() {
        // fail. :(
    });

@module common/lib/livedoc.repository
*/

import _ from 'underscore';
import $ from 'jquery';
import tools from '../tools/regex';

const PCT_ENCODING_MAP = {
	'!': '%21',
	'#': '%23',
	'$': '%24',
	'&': '%26',
	'\'': '%27',
	'(': '%28',
	')': '%29',
	'*': '%2A',
	'+': '%2B',
	',': '%2C',
	':': '%3A',
	';': '%3B',
	'=': '%3D',
	'?': '%3F',
	'@': '%40',
	'[': '%5B',
	']': '%5D'
};

// Regular expressions.
const RE_PATH_PROTOCOL = /^([a-z]+:\/\/)(.*)/i;
const RE_HEADER_CONTENTDISP_FILENAME = /filename="(.+?)"/;
const INVALID_FILENAME_CHARS = '\\\\/<>:?*|"';
const RE_INVALID_FILENAME_CHARS = new RegExp(`[${INVALID_FILENAME_CHARS}]`);
const INVALID_URL_CHARS = tools.escapeRegex(Object.keys(PCT_ENCODING_MAP).join(''));

// Default settings for `Repository` instances.
const DEFAULTS = { scope: '' };

// Map for converting repository options object to query objects to be
// sent with a request to the server.
const REQUEST_OPTS_CONFIG = {
	/**
     * Version number for a particular resource.
     */
	versionNumber: { fieldName: 'version' },

	/**
     * Number of items to return per page in a `list` request.
     */
	take: {
		valueValidate: function (val) {
			return typeof val === 'number' && val >= 0;
		}
	},

	/**
     * Number of items to skip in a `list` request.
     */
	skip: {
		valueValidate: function (val) {
			return typeof val === 'number' && val >= 0;
		}
	},

	/**
     * Sort property for a `list` request.
     */
	sortBy: {
		valueValidate: function (val) {
			return typeof val === 'string';
		}
	},

	/**
     * Sort direction for results of a `list` request. "asc" or "desc".
     */
	sortDirection: {
		valueFormat: function (val) {
			return typeof val === 'string' ? $.trim(val).toLowerCase() : val;
		},
		valueValidate: function (val) {
			return typeof val === 'string' && /^asc|desc$/.test(val);
		}
	},

	/**
	 * Set custom filename for resource download
	 */
	responseFilename: {
		fieldName: 'response-filename',
		valueValidate: function (val) {
			return typeof val === 'string';
		}
	}
};

/**
 * Returns "key" percent-encoded so it can be used in URLs.
 *
 * @param {string} key - String to percent-encode.
 * @returns {string} Percent-encoded version of "key".
 */
function pctEncodeKey(key) {
	return key.replace(new RegExp(`[${INVALID_URL_CHARS}]`), function (c) {
		return PCT_ENCODING_MAP[c];
	});
}

/**
 * Executes provided callback function(s) based on the HTTP status code
 * found in the jqXhr object in `args`.
 *
 * @param {object} callbacks - Set of callbacks.
 *   - Keys are either actual status codes, e.g. '201' or '404', or status
 *     classes, e.g. '2xx', which represents any 200-level status code.
 *   - Can specify a callback at key 'else', which is called if the response
 *     status did not match any other specified callbacks.
 *   - Values are callback functions; they are bound to the jqXhr object
 *     (context) and called with `args`.
 *   - If '200' and '2xx' are both given, and response was 200, then both
 *     callbacks are called.
 * @param {arguments} args - Arguments from $.ajax `done` or `fail`.
 */
function handleCallbacks(callbacks, args) {
	// Find jqXhr request, so we can get status code.
	// Success callback args: [data, statusText, jqXhr]
	// Failure callback args: [jqXhr, statusText, error]
	const r = typeof args[2] === 'object' && args[2].status ? args[2] : args[0];

	// Determine status class. This is faster than `Math.floor(status/100)`.
	const status = r.status;
	const statusCls = `${(`${status}`).substring(0, 1)}xx`;

	// Run callbacks, passing $ AJAX args to it.
	let doElseCallback = true;
	const statusClsCallback = callbacks[statusCls];
	const statusCallback = callbacks[status];

	if (typeof statusClsCallback === 'function') {
		statusClsCallback.apply(r, args);
		doElseCallback = false;
	}

	if (typeof statusCallback === 'function') {
		statusCallback.apply(r, args);
		doElseCallback = false;
	}

	if (doElseCallback && typeof callbacks['else'] === 'function') {
		callbacks['else'].apply(r, args);
	}
}

/**
 * Returns query parameters for a GET request, based on `opts`. Only valid
 * options from `opts` (i.e. only the keys in `REQUEST_OPTS_CONFIG`) are
 * included in the returned query.
 *
 * @param {object} opts - Options to parse into $.get query.
 * @param {object<string, function>} [formatters] - Functions to modify or
 * format `opts`. Keys are the option names.
 * @returns {object} Query for $.get.
 */
function composeRequestOptions(opts, formatters) {
	formatters = formatters || {};
	const data = {};
	const keys = opts && typeof opts === 'object' ? Object.keys(opts) : [];

	for (let i = 0, n = keys.length; i < n; i++) {
		const key = keys[i];
		let val = opts[key];
		const config = REQUEST_OPTS_CONFIG[key];

		// Key not found in config, so this is not a valid query string
		// parameter. Skip it.
		if (!config) continue;

		const fieldName = typeof config.fieldName === 'function'
			? config.fieldName(key, val)
			: config.fieldName || key;

		// If a format fn was provided, use it to customize the value.
		const formatFn = formatters[key] || config.valueFormat;
		if (typeof formatFn === 'function') val = formatFn(val);

		// If a validate fn was provided, use it to check the value. Throw
		// an error if value not valid.
		const validateFn = config.valueValidate;
		if (typeof validateFn === 'function' && !validateFn(val)) {
			throw new Error(`Invalid value for request option "${fieldName}: "${val}`);
		}

		data[fieldName] = val;
	}

	return data;
}

/**
 * Returns a path composed of the provided path segments.
 *
 * Notes:
 * - Does not resolve relative paths (., ..)
 * - Keeps protocol (e.g. http://) if provided
 * - Keeps starting slash, if no protocol and first segment has starting
 *   slash OR is empty string
 * - Keeps trailing slash, if last segment has trailing slash
 *
 * @param {...string} - Path parts to put together.
 * @return {string} Composed path.
 */
function normalize() {
	// First, put all the parts together
	let s = Array.prototype.join.call(arguments, '/');

	// If the url includes a protocol, temporarily remove it.
	const matches = RE_PATH_PROTOCOL.exec(s);
	let protocol = '';

	if (matches) {
		protocol = matches[1];
		s = matches[2];
	}

	// Split the remaining string on '/'.
	const parts = s.split('/');
	const newParts = [];
	let i = 0; const n = parts.length; let p;

	// Keep only non-empty url parts.
	for (; i < n; i++) {
		p = parts[i];
		if (p) newParts.push(p);
	}

	// Re-assemble url parts.
	let path = newParts.join('/');

	// Preserve ending slash, if there was one.
	if (s.endsWith('/')) path += '/';

	// If no protocol, preserve starting slash, if there was one.
	if (!protocol && s.startsWith('/')) protocol = '/';

	// Final assembly.
	return protocol + path;
}

/**
 * Returns "s" with trailing forward slashes removed.
 *
 * @param {string} s - String to trim trailing forward slashes from.
 * @returns {string} "s" with trailing forward slashes removed.
 */
function trimTrailingSlash(s) {
	return s.replace(/\/+$/g, '');
}

/**
 * Returns the filename in the Content-Disposition header.
 *
 * @param {object} data - Node metadata.
 * @returns {string|undefined} Filename, or `undefined` if header/filename
 * not found.
 */
function getFilenameFromHeader(data) {
	if (typeof data !== 'object') return;

	const val = data.headers['Content-Disposition'];
	// var header = _.where(data.ContentHeaders, { Name: 'Content-Disposition' });

	if (val) {
		// if (header && _.isArray(header.values) && header.values.length) {
		const matches = RE_HEADER_CONTENTDISP_FILENAME.exec(val[0]);
		// var matches = RE_HEADER_CONTENTDISP_FILENAME.exec(header.values[0]);
		if (matches) return matches[1];
	}
}

function cleanLinkHeaderSegment(segment) {
	segment = $.trim(segment);

	if (segment.startsWith('<') && segment.endsWith('>') && segment.length > 2) {
		// This is: <.?skip=5&take=5>
		return { url: segment.substring(1, segment.length - 1) };
	} else {
		const matches = /([^ />"'=]+)="([^"]*)"/.exec(segment);
		if (matches) {
			// This is: rel="next"
			const result = {};
			result[matches[1]] = matches[2];
			return result;
		}
	}
}

/**
 * Parses a link header into an object keyed by link rels..
 *
 * Example: Given this header:
 *  "<../blurbs>; rel="parent", <.?skip=5&take=5>; rel="next"; title="next page""
 *
 * ...returns this:
 *
 *  {
 *      parent: {
 *          rel: 'parent',
 *          url: '../blurbs'
 *      },
 *      next: {
 *          rel: 'next',
 *          url: '.?skip=5&take=5',
 *          title: 'next page'
 *      }
 * }
 *
 * @param {string} s
 * @returns {object<string, object>}
 */
function parseLinkHeader(s) {
	if (typeof s !== 'string') return {};

	const rawLinks = s.split(',');

	return _.reduce(rawLinks, function (o, link) {
		const parsedSegments = {};
		const segments = link.split(';');
		const nSeg = segments.length;

		if (nSeg > 1) {
			for (let i = 0; i < nSeg; i++) {
				_.extend(parsedSegments, cleanLinkHeaderSegment(segments[i]));
			}
		}

		const rel = parsedSegments.rel;
		o[rel] = parsedSegments;

		return o;
	}, {});
}

/**
 * Creates a new repository interface.
 *
 * @constructs Repository
 * @param {object} opts - Config.
 *  - {string} host - Url endpoint for repository.
 *  - {string} scope - Path to node where all operations should be scoped to.
 *  - {bool} lowercaseFileNames - If true, lowercases all filenames on upload.
 */
const Repository = function (opts) {
	this.settings = _.extend({}, DEFAULTS, opts);

	const scope = this.settings.scope;
	if (scope) {
		this.settings.scope = normalize(pctEncodeKey(scope), '/');
	}
};

Repository.prototype = {

	constructor: Repository,

	/**
     * Duplicates the item at `key` under the key `newKey`.
     *
     * E.g. After `copy('a/b/c', 'd')`, node "d" will have the contents of
     * "a/b/c". If "d" did not exist before, it is created. Otherwise, its
     * previous contents are overwritten.
     *
     * @param {string} key - Node to copy.
     * @param {string} newKey - Key to copy node to.
     * @param {boolean} [recursive] Whether to copy recursively. Defaults to
     * `false`, which copies just this node and none of its descendants.
     * @returns {$.Deferred} Resolves if successfully copied.
     */
	copy: function (key, newKey, recursive) {
		const settings = this.settings;

		return $.ajax({
			method: 'post',
			url: normalize(settings.host, 'copy'),
			data: JSON.stringify({
				Recursive: !!recursive,
				SourcePath: normalize('/', settings.scope, pctEncodeKey(key)),
				DestinationPath: normalize('/', settings.scope, newKey)
			})
		});
	},

	/**
     * Fetches the node located at `key`.
     *
     * @param {string} key - Node to fetch.
     * @param {object} [opts]
     *  - {int} versionNumber
     * @returns {$.Deferred} Resolves with node's data.
     */
	get: function (key, opts) {
		const settings = this.settings;
		const url = normalize(settings.host, 'resources', settings.scope, pctEncodeKey(key));
		const queryData = composeRequestOptions(opts);
		return $.ajax({ method: 'get', url: url, data: queryData });
	},

	/**
     * Fetches the contents of node at `key` as JSON.
     *
     * @todo Not needed if the resource's mimetype specifies "application/json"
     *
     * @param {string} key - Node to get as JSON
     * @param {object} [opts] - See `get` for details.
     * @returns {$.Deferred} Resolves with node contents as JSON.
     */
	getJson: function (key, opts) {
		return this.get(key, opts).then(function (data) {
			const d = $.Deferred();

			try {
				d.resolve(typeof data === 'object' ? data : JSON.parse(data));
			} catch (err) {
				d.reject(err);
			}

			return d.promise();
		});
	},

	/**
     * Returns the metadata for the node at `key`.
     *
     * @param {string} key - Node to get metadata for.
     * @param {object} [opts] - See `get` for details.
     * @returns {$.Deferred} Resolves with metadata.
     */
	getMetadata: function (key, opts) {
		const that = this;
		const settings = this.settings;
		const url = normalize(settings.host, 'meta', settings.scope, pctEncodeKey(key));
		const queryData = composeRequestOptions(opts);

		return $.ajax({
			method: 'get',
			url: url,
			data: queryData
		}).then(function (data, statusText, jqXhr) {
			return $.Deferred().resolve(that._enhanceMetadata(data), statusText, jqXhr);
		});
	},

	/**
     * List all children of node located at `key`.
     *
     * @param {string} key
     * @param {object} [opts]
     *  - {int} [take] - Pagination: Number of items to return.
     *  - {int} [skip] - Pagination: Number of items to skip.
     * @returns {$.Deferred} If successful, resolves with directory listing.
     */
	list: function (key, opts) {
		opts = opts || {};

		const that = this;
		const settings = this.settings;
		const scope = settings.scope;
		const url = normalize(settings.host, 'resources', scope, pctEncodeKey(key), '/');
		const queryData = composeRequestOptions(opts);

		return $.ajax({
			method: 'get',
			url: url,
			data: queryData
		}).then(function (data, statusText, jqXhr) {
			// Massage data
			_.each(data, _.bind(that._enhanceMetadata, that));
			return $.Deferred().resolve(data, jqXhr);
		});
	},

	/**
     * Gets list of files, non-recursively.
     *
     * @param {string} path
     * @param {object} [opts]
     * @returns {$.Deferred} Resolves with array of objects.
     */
	listFiles: function (path, opts) {
		return this.list(path, opts).then(function (list, jqXhr) {
			let n = list.length;
			let item;

			while (n--) {
				item = list[n];
				if (item.type === 'collection') list.splice(n, 1);
			}

			return $.Deferred().resolve(list, jqXhr);
		});
	},

	/**
     * Gets list of subdirectories under path, non-recursively.
     *
     * @param {string} path
     * @param {object} [opts]
     * @returns {$.Deferred} Resolves with array of objects.
     */
	listDirs: function (path, opts) {
		return this.list(path, opts).then(function (list, jqXhr) {
			let n = list.length;
			let item;

			while (n--) {
				item = list[n];
				if (item.type !== 'collection') list.splice(n, 1);
			}

			return $.Deferred().resolve(list, jqXhr);
		});
	},

	/**
     * Returns list of versions for node at `key`. Most recent version first.
     *
     * @param {string} key - Node to get versions for.
     * @param {object} [opts]
     *  - {int} [take] - Pagination: Number of items to return.
     *  - {int} [skip] - Pagination: Number of items to skip.
     * @param {string} _endpoint - PRIVATE argument, do not use. Allows
     * reuse of this method for listing children versions.
     * @returns {$.Deferred} Resolves with an array of objects, each
     * containing data for one version.
     */
	listVersions: function (key, opts, _endpoint) {
		_endpoint = _endpoint || 'versions';
		const that = this;
		const settings = that.settings;
		const url = trimTrailingSlash(normalize(settings.host, _endpoint, settings.scope, pctEncodeKey(key)));
		const queryData = composeRequestOptions(opts);

		return $.ajax({
			method: 'get',
			url: url,
			data: queryData
		}).then(function (data, statusText, jqXhr) {
			let n = data.length; let item; let id;

			// Some formatting
			while (n--) {
				item = data[n];
				id = item.resourceId;
				item.key = that._parseKey(id);
				item.name = tools.parseFilePath(id)[1];
				item.lastModified = new Date(item.lastModified);
			}

			return $.Deferred().resolve(data, jqXhr);
		});
	},

	/**
     * Returns list of versions for all children of the node at `key`.
     * Most recent version first.
     *
     * @param {string} key - Parent node.
     * @param {object} [opts]
     *  - {int} [take] - Pagination: Number of items to return.
     *  - {int} [skip] - Pagination: Number of items to skip.
     * @returns {$.Deferred} Resolves with an array of objects, each
     * containing data for one version.
     */
	listChildrenVersions: function (key, opts) {
		return this.listVersions(key, opts, 'collection-versions');
	},

	/**
     * Puts data at the given path, creating the node (and its ancestors) if
     * it doesn't exist yet and overwriting existing contents if it
     * already exists.
     *
     * E.g. If `key` is 'a/b/c', then data is stored as contents of node `c`.
     *
     * @param {string} key - Location of node.
     * @param {*} data - Data to persist.
     * @param {string} [contentType]  - Defaults to "text/plain". If `data` is
     * a File, then this argument is ignored in favour of `data`'s own type.
     * @param {object} [opts] - Additional options:
     *  - {string} [filename] - Custom filename. For file uploads where
     *    the file's own name is undesired.
     * @returns {$.Deferred} Resolves if data is stored successfully.
     */
	put: function (key, data, contentType, opts) {
		opts = typeof opts === 'object' ? opts : {};

		const settings = this.settings;
		const lowercase = !!settings.lowercaseFileNames;
		const ajaxSettings = {
			method: 'put',
			url: normalize(settings.host, 'resources', settings.scope, pctEncodeKey(key)),
			data: data,
			processData: false
		};

		if (data instanceof File) {
			// Stop jQuery from trying to guess at a content type.
			ajaxSettings.contentType = false;

			// File needs to be sent in a FormData.
			const formData = new FormData();
			let filename = typeof opts.filename === 'string' && opts.filename ? opts.filename : data.name;

			if (lowercase) filename = filename.toLowerCase();

			formData.append('put', data, filename);
			ajaxSettings.data = formData;
		} else {
			// Send with user-specified content type.
			ajaxSettings.headers = {
				'Content-Type': contentType || 'text/plain; charset=UTF-8'
			};
		}

		return $.ajax(ajaxSettings);
	},

	/**
     * Sets a node's headers to the ones provided. REPLACES EXISTING HEADERS.
     *
     * @param {string} key - Node to update.
     * @param {object} headers - Headers to set. Values must be arrays, e.g.:
     *     {
     *         "Content-Type": [ "text/plain" ],
     *         "X-Custom-Header": [ 1, 2, 3 ]
     *     }
     * @returns {$.Deferred)  Resolves if headers updated successfully.
     */
	putHeaders: function (key, headers) {
		const settings = this.settings;
		const url = normalize(settings.host, 'meta', settings.scope, pctEncodeKey(key));

		return $.ajax({
			method: 'put',
			url: url,
			data: JSON.stringify({ headers: headers }),
			contentType: 'application/json'
		});
	},

	/**
     * Uploads files to given path.
     *
     * If uploading multiple files and one fails, then all following files
     * are not uploaded.
     *
     * @param {string} path - Path to directory to upload files to.
     * @param {File[]} files - Files to upload. Uses Web API File object.
     * @returns {$.Deferred} Resolves iff all files uploaded.
     */
	uploadFiles: function (path, files, callback) {
		const that = this;
		const settings = this.settings;
		const lowercase = !!settings.lowercaseFileNames;
		const hasCallback = typeof callback === 'function';

		const promises = _.map(files, function (file) {
			const d = $.Deferred();
			const slug = lowercase ? file.name.toLowerCase() : file.name;
			const key = normalize(path, slug);

			const callbacks = {
				'2xx': function successCallback(data, statusText, jqXhr) {
					const v = data.VersionNumber;

					// Get metadata for newly uploaded file to force request
					// for this version.
					that.getMetadata(key, { versionNumber: v }).done(function (data) {
						if (hasCallback) callback(data, statusText, jqXhr);
						d.notify({ key: key, status: 'Uploaded' });
						d.resolve(data);
					});
				},
				'else': d.reject
			};

			that.put(key, file).always(function () {
				handleCallbacks(callbacks, arguments);
			});

			return d;
		});

		return $.when.apply($, promises).promise();
	},

	/**
     * Renames node located at `key` to new name.
     *
     * E.g. `rename('a/b/c', 'd')` renames node "c" to "d".
     *
     * IMPORTANT: This is actually implemented as a COPY and DELETE, so nodes
     * lose their version history after a rename.
     *
     * @param {string} key - Location of node to rename.
     * @param {string} newName - New node name.
     * @returns {$.Deferred} Resolves if successful.
     */
	rename: function (key, newName) {
		const that = this;
		const matches = tools.parseFilePath(key);
		const newKey = (matches ? matches[0] : '') + newName;

		// A rename is really copy file to new location + delete old file.
		return that.copy(key, newKey).then(function () {
			return that.remove(key);
		}).then(function (data, statusText, jqXhr) {
			return $.Deferred().resolve({
				key: normalize('/', newKey),
				name: newName
			}, statusText, jqXhr);
		});
	},

	/**
     * Sets the filename for the contents stored at the given node.
     *
     * This is done by updating (or adding) the Content-Disposition header.
     *
     * @param {string} key
     * @param {string} filename
     * @returns {$.Deferred} Resolves if successful.
     */
	renameFile: function (key, filename) {
		const that = this;

		// Validate filename first.
		if (RE_INVALID_FILENAME_CHARS.test(filename)) {
			const err = new Error(`Filename contains invalid characters: ${INVALID_FILENAME_CHARS}`);
			return $.Deferred().reject(err);
		}

		return this.getMetadata(key).then(function (data) {
			const headers = data.headers;
			const headerName = 'Content-Disposition';
			const directive = `filename="${filename}"`;
			const val = headers[headerName];
			let s1; let s2 = '';

			if (val) {
				// Have existing content disposition header. Get the
				// header value (string) from array.
				s1 = val[0].trim();

				// Attempt to replace existing filename.
				s2 = s1.replace(RE_HEADER_CONTENTDISP_FILENAME, directive);

				if (s1 === s2) {
					// Could not do regex replace. This would be b/c the
					// header didn't have a filename originally.
					if (s2.length) s2 += '; ';
					s2 += directive;
				}
			} else {
				// No Content-Disposition header. Set new one.
				s2 = `attachment; ${directive}`;
			}

			headers[headerName] = [s2];

			return that.putHeaders(key, headers);
		});
	},

	/**
     * Restores a node to the specified version.
     *
     * Cannot restore a node to a delete marker.
     * Restoring a node to its current version is a no-op.
     *
     * @param {string} key - Node to restore.
     * @param {string} versionNum - Version to restore to.
     * @returns {$.Deferred} Resolves if node was successfully restored.
     */
	restore: function (key, versionNum) {
		const settings = this.settings;

		return $.ajax({
			method: 'post',
			url: normalize(settings.host, 'revert'),
			contentType: 'application/json; charset=utf-8',
			data: JSON.stringify({
				Path: normalize('/', settings.scope, pctEncodeKey(key)),
				Version: versionNum
			})
		});
	},

	/**
     * Deletes file located at `key`.
     *
     * @param {string} key - Node to delete.
     * @returns {$.Deferred} Resolves if successfully deleted.
     */
	remove: function (key) {
		const settings = this.settings;
		const url = normalize(settings.host, 'resources', settings.scope, pctEncodeKey(key));
		return $.ajax({ method: 'delete', url: url });
	},

	/**
     * Returns the tags for the node at `key`.
     *
     * @param {string} key - Node to get tags for.
     * @param {object} [opts] - See `get` for details.
     * @returns {$.Deferred} Resolves with array of tags.
     */
	getTags: function (key, opts) {
		const settings = this.settings;
		const url = normalize(settings.host, 'tags', settings.scope, pctEncodeKey(key));
		const queryData = composeRequestOptions(opts);
		return $.ajax({
			method: 'get',
			url: url,
			data: queryData
		}).then(function (o) {
			// Response format changed! Now it's: { Tags: [ ... ] }
			// To save client work, just return the array inside.
			return o.Tags;
		});
	},

	/**
     * Updates the tags for the node located at `key`.
     *
     * @param {string} key - Node to delete.
     * @param {array<string>} tagsToAdd - Tags to add.
     * @param {array<string>} tagsToRemove - Tags to remove.
     * @returns {$.Deferred} Resolves if tags successfully modified..
     */
	updateTags: function (key, tagsToAdd, tagsToRemove) {
		tagsToAdd = _.isArray(tagsToAdd) ? tagsToAdd : [];
		tagsToRemove = _.isArray(tagsToRemove) ? tagsToRemove : [];

		const settings = this.settings;
		const url = normalize(settings.host, 'tags', settings.scope, pctEncodeKey(key));

		return $.ajax({
			method: 'patch',
			url: url,
			contentType: 'application/json; charset=utf-8',
			data: JSON.stringify({ Add: tagsToAdd, Remove: tagsToRemove })
		});
	},

	/**
     * Gets download URL for file located at `key`. Only resolves with an
     * URL if the file exists.
     *
     * @todo permissions
     * @param {string} key - Location of file.
     * @param {object} [opts] - See `get` for details.
     * @returns {$.Deferred} If successful, resolves with download URL
     * {string}.
     */
	getDownloadUrl: function (key, opts) {
		const that = this;
		const settings = this.settings;
		const queryData = composeRequestOptions(opts);

		return $.ajax({
			method: 'head',
			url: normalize(settings.host, 'resources', settings.scope, pctEncodeKey(key)),
			data: queryData
		}).then(function () {
			return that.getSignedUrl(key, opts);
		});
	},

	/**
     * Gets download URL for file located at `key`. Will resolve whether
     * or not file exists.
     *
     * @todo permissions
     * @todo currently generating url locally; should come from server
     * @param {string} key - Location of file.
     * @param {object} [opts] - See `get` for details.
     * @returns {$.Deferred} If successful, resolves with download URL
     * {string}.
     */
	getSignedUrl: function (key, opts) {
		opts = opts || {};
		const settings = this.settings;
		let url = normalize(settings.host, 'resources', settings.scope, pctEncodeKey(key));
		const queryData = composeRequestOptions(opts);
		const queryStr = $.param(queryData);

		if (queryStr) url += `?${queryStr}`;

		return $.Deferred().resolve(url).promise();
	},

	/**
     * Parses id (as returned from server) into a key (i.e. removes the scope).
     *
     * @param {string} id - Node id, e.g. "/scope/a/b/c"
     * @returns {string} Key, e.g. "/a/b/c"
     */
	_parseKey: function (id) {
		return typeof id === 'string' ? id.substring(this.settings.scope.length) : id;
	},

	/**
     * Makes helpful changes to the given node metadata object, e.g.
     * parsing date strings into `Date` objects.
     *
     * @param {object} data - Node metadata, as received from server.
     * @returns {object} Same object as `data`.
     */
	_enhanceMetadata: function (data) {
		data.key = this._parseKey(data.id);
		data.lastModified = new Date(data.lastModified);

		// Get filename from Content-Disposition header. If that doesn't
		// exist, default to node name.
		const filename = getFilenameFromHeader(data);
		data.filename = typeof filename === 'undefined' ? data.name : filename;

		return data;
	},

	parseLinkHeader: parseLinkHeader
};

export default Repository;
