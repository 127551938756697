import $ from 'jquery';

const ui = {};
let scrollbarWidth;

/**
	Returns the dimensions of the document, as an object with the following
	format: { 'w': 1280, 'h': 800 }
	*/
ui.getDocSize = function() {
	const $d = $(document);
	return {
		w: $d.width(),
		h: $d.height()
	};
};

/**
	Returns the dimensions of the window, as an object with the following
	format: { 'w': 1280, 'h': 800 }
	*/
ui.getWindowSize = function() {
	const $w = $(window);
	return {
		w: $w.width(),
		h: $w.height()
	};
};

/**
	Returns the width of a scrollbar. First call requires calculation; later
	calls fetch a cached value.

	@returns {float} Width of scrollbar.
	*/
ui.getScrollbarWidth = function() {
	if (typeof scrollbarWidth === 'undefined') {
		const outer = document.createElement('div');
		outer.style.visibility = 'hidden';
		outer.style.width = '100px';
		outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps

		document.body.appendChild(outer);

		const widthNoScroll = outer.offsetWidth;
		outer.style.overflow = 'scroll';

		// add innerdiv
		const inner = document.createElement('div');
		inner.style.width = '100%';
		outer.appendChild(inner);

		const widthWithScroll = inner.offsetWidth;

		// remove divs
		outer.parentNode.removeChild(outer);

		scrollbarWidth = widthNoScroll - widthWithScroll;
	}

	return scrollbarWidth;
};

export default ui;
