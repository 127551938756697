var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var zipUri = model.get('DownloadUri') ||  '';
var statsUri = model.get('StatsUri') ||  '';

__p+='\n<a class="btn js-download-zip" '+
((__t=( zipUri ? '' : 'disabled' ))==null?'':__t)+
'" href="'+
((__t=( zipUri || 'javascript:void(0)' ))==null?'':__t)+
'" target="_blank">Docs (.ZIP)</a>\n<a class="btn js-download-stats" '+
((__t=( statsUri ? '' : 'disabled' ))==null?'':__t)+
'" href="'+
((__t=( statsUri || 'javascript:void(0)' ))==null?'':__t)+
'" target="_blank">Stats (.TXT)</a>\n<button class="btn js-cancel" '+
((__t=( model.get('Status') == 'Started' ? '' : 'disabled' ))==null?'':__t)+
'>Cancel</button>\n';
}
return __p;
};
