import Marionette from 'marionette';
import itemTemplate from './itemTemplate.ejs';
import template from './template.html';

const GridRow = Marionette.ItemView.extend({
	template: itemTemplate,
	tagName: 'tr',
	triggers: {
		'click .js-delete': 'delete'
	}
});

const GridView = Marionette.CompositeView.extend({
	tagName: 'table',
	className: 'table table-striped',
	template: template,
	childView: GridRow,
	childViewContainer: 'tbody'
});

export default GridView;
