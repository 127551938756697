var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var r1 = data.from;
var r2 = data.to;

if (tools.isNullish(r1)) {
	r1 = '';
}
if (tools.isNullish(r2)) {
	r2 = '';
}


__p+='\n<div>\n	<div class="context-menu-section-title  tbf-colFilter">Filter by range</div>\n	<div class="context-menu-section-content padded tbf-colFilter tbf-colFilter-0">\n		<div class="grid-row">\n			<label class="form-label grid-md-3" for="tbf-columnfilter-range-from">From</label>\n		</div>\n		<div class="grid-row">\n			<div class="grid-md-12 position-container">\n				<input class="js-range form-control" data-range="from" type="text" id="tbf-columnfilter-range-from" value="'+
((__t=( r1 ))==null?'':__t)+
'" />\n				<button class="js-clear btn-reset ldi ldi-close hoverable form-field-icon"></button>\n			</div>\n		</div>\n		<div class="grid-row">\n			<label class="form-label grid-md-3" for="tbf-columnfilter-range-to">To</label>\n		</div>\n		<div class="grid-row">\n			<div class="grid-md-12 position-container">\n				<input class="js-range form-control" data-range="to" type="text" id="tbf-columnfilter-range-to" value="'+
((__t=( r2 ))==null?'':__t)+
'" />\n				<button class="js-clear btn-reset ldi ldi-close hoverable form-field-icon"></button>\n			</div>\n		</div>\n	</div>\n</div>\n';
}
return __p;
};
