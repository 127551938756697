import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import app from 'app';
import Collection from './model';
import View from './view';

const Controller = Marionette.Object.extend({
	initialize: function(workspaceModel) {
		// Re-casting the Inputs collection into custom collection.
		const job = workspaceModel.get('Job');
		const filespecs = new Collection(null);

		const promise = this.fetchInputs(job);
		promise.done(function(data) {
			filespecs.reset(data);
		});

		app.vents.filespecs = new Marionette.Object();

		this.view = new View({ collection: filespecs });
	},

	fetchInputs: function(job) {
		const that = this;
		const defer = $.Deferred();
		const path = `clients/${job.get('ClientId')}/jobtypes/${job.get('JobTypeId')}/jobs/${job.get('Id')}/inputs/`;

		app.repository.listDirs(path).done(function(data) {
			app.repository.getJson(`${path}settings.json`).always(function(settings) {
				const sorted = that.sortInputs(data, settings);
				defer.resolve(sorted);
			});
		});

		return defer.promise();
	},

	sortInputs: function(list, settings) {
		const sorted = [];
		const config = settings.Inputs;

		// Get the inputs listed in config, push them to the sorted array
		// and remove those inputs from the original list. Also attaches
		// the config for that input under "InputConfig".
		_.each(config, function(obj, i) {
			const id = obj.Id;
			const j = _.findIndex(list, function(o) {
				return id === o.name;
			});
			if (j > -1) {
				const dir = list[j];
				dir.InputConfig = obj;
				sorted.push(dir);
				list.splice(j, 1);
			}
		});

		// Add any remaining inputs that were not defined in the config but
		// exist s3 to the end of the sorted list, and return it.
		return sorted.concat(list);
	},

	onDestroy: function() {
		this.view.destroy();
		delete app.vents.filespecs;
	}
});

const create = function(workspaceModel) {
	return new Controller(workspaceModel);
};

export default { create };
