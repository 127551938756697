/**
 A directory of existing top-level modules that can be listed in the main
 nav bar.

 Valid keys for each module's listing:
 - id {string}  Used to refer to module, in file system and in hrefs.
 - displayName {string}  Used in nav bar label.
 - region {string}  Name of region to display module when it's opened.
 'main' or 'sidebar'.
 - [isHomePage] {boolean}  True iff this is the homepage. Gets special
 nav button. :)
 - [checkRequirements] {function}  Returns a promise that resolves to `true`
 iff this module should be listed in the nav bar. Takes the app as an
 argument.
 */

import $ from 'jquery';

export default [
	{
		id: 'dashboard',
		displayName: 'LiveDoc',
		region: 'main',
		isHomePage: true
	},
	{
		id: 'jobExplorer',
		displayName: 'Jobs',
		region: 'sidebar'
	},
	{
		id: 'monitor',
		displayName: 'Monitor',
		region: 'main'
	},
	{
		id: 'srri',
		displayName: 'SRRI',
		region: 'main',
		checkRequirements (app) {
			const d = $.Deferred();
			const clientId = app.session.get('clientId');
			const isSysAdmin = app.session.get('isSysAdmin');

			const reKiid = /\bkiid\b/i;
			function isKiidJobType (jobType) {
				return jobType.get('Type') === 'kiid' || reKiid.test(jobType.get('DisplayName'));
			}

			// Show SRRI module for admins and for any users who have
			// access to KIID job types. Duck typing job types for now.
			if (isSysAdmin) {
				d.resolve(true);
			} else {
				app.request('app.services.jobTypes.get', clientId).then(jobTypes => {
					d.resolve(jobTypes ? jobTypes.some(isKiidJobType) : false);
				}).catch(d.reject);
			}

			return d.promise();
		}
	}
];
