var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="btn btn-sm js-clear btn-clear">Clear</button>\n<span class="card-heading">Event log</span>\n\n<table class="table table-minimal table-wordbreak">\n	<thead>\n		<th>Item / Date '+
((__t=( _generateSortHtml('Timestamp') ))==null?'':__t)+
'</th>\n	</thead>\n	<tbody></tbody>\n</table>\n';
}
return __p;
};
