import $ from 'jquery';
import _ from 'underscore';
const PLUGINNAME = 'ColumnResize';

export default {
	name: PLUGINNAME,

	Controller: {
		initialize: function() {
			this.set('columnResizeSettings', {
				minWidth: 20,
				maxWidth: 400,
				$guide: $('<div class="tbf-col-resize-guide"></div>')
			});
		},
		resizeColumn: function(attr, width) {
			const col = _.findWhere(this.get('columns'), { id: attr });
			col.width = width;
			this.get('collection').trigger('tm:columnresize', attr, width);
		},

		$resizeColumn: function($table, $cell, colWidth) {
			const oldColWidth = $cell.outerWidth();
			const tableWidth = $table.width() + (colWidth - oldColWidth);
			$table.width(tableWidth);
			$cell.outerWidth(colWidth);
			this.set('tableWidth', tableWidth);
		}
	},

	// things to set up on header view
	// this.$('th').append('<span class="tbf-resize-grip"></span>');
	TheadView: {
		collectionEvents: {
			'tm:columnresize': 'resizeColumn'
		},
		events: {
			'mousedown .tbf-resize-grip': 'onMousedownResizeGrip'
		},

		resizeColumn: function(attr, size) {
			const $cell = this.$(`th[data-tbf-attr="${attr}"]`);
			const $table = this.$('table');
			this.options.controller.$resizeColumn($table, $cell, size);
		},

		/**
		 Called when user clicks a resize grip (i.e. on mousedown).
		 Figures out which column is being resized, shows a resize guide,
		 and binds a one-off handler on document to wait for mouseup.

		 @param {$.Event} e  Mousedown event on resize grip.
		 */
		onMousedownResizeGrip: function(e) {
			e.preventDefault();
			const that = this;
			const ctrl = that.options.controller;
			const settings = ctrl.get('columnResizeSettings');

			// Column to resize.
			const $th = $(e.target).closest('th');
			settings.$currentColumn = $th;

			// Only activate column resizing if user has not released the
			// grip for at least 50ms.
			settings.mouseDownTimeout = setTimeout(_.bind(that.columnResizeGripOn, that), 50);

			// The mouse's left position within the table wrapper (0 is
			// the left edge of the table wrapper).
			settings.mouseDownLeftPos = e.pageX - that.$el.offset().left;

			// Left-most position resize guide can go to. This is the min col width.
			settings.leftLimit = $th.position().left + settings.minWidth;

			// Set resize guide's left position for immediate visual feedback.
			if (!settings.$guide.parent().length) { // @todo should this be here?
				this.$el.closest('.tbf-compositeTable').append(settings.$guide);
			}
			settings.$guide.css('left', settings.mouseDownLeftPos);

			// Single-use mouseup binding. Use document so that if mouseup
			// happens outside of view $el, it's still caught.
			$(document).one('mouseup', function() {
				clearTimeout(settings.mouseDownTimeout);
				that.columnResizeGripOff();
			});
		},

		/**
		 setTimeout callback, called once the user has clicked and held
		 onto the column resize grip for some amount of time.

		 Allows the user to drag the grip and see a guide to preview the
		 new column width. Enforces a min-width on columns.
		 */
		columnResizeGripOn: function() {
			const that = this;
			const ctrl = that.options.controller;
			const settings = ctrl.get('columnResizeSettings');

			document.body.style.cursor = 'col-resize';
			that.$el.css({ 'user-select': 'none' });

			$(document).on(`mousemove.${ctrl.cid}`, function(e) {
				// e's left is relative to the window, but resize guide's
				// left is relative to the table wrapper.
				const $el = that.$el;
				const left = e.pageX - $el.offset().left - $el.parent().scrollLeft();
				settings.$guide.css('left', left);

				// Only update guide's position if it defines a col width
				// that is at least larger than the minimum allowed width.
				if (left > settings.leftLimit) {
					settings.guidePos = left;
					settings.$guide.css('left', left);
				}
			});
		},

		/**
		 Called when user releases a resize grip (i.e. on mouseup).

		 Resets cursor, hides resize guide, unbinds mousemove, resizes column.
		 */
		columnResizeGripOff: function() {
			const that = this;
			const ctrl = that.options.controller;
			const settings = ctrl.get('columnResizeSettings');

			document.body.style.cursor = '';
			that.$el.css({ 'user-select': 'inherit' });

			// Resize the column iff the user actually moved the cursor.
			if (settings.guidePos !== null && settings.guidePos - settings.mouseDownLeftPos !== 0) {
				const $th = settings.$currentColumn;
				const width = Math.floor(settings.guidePos - $th.position().left);
				ctrl.resizeColumn($th.attr('data-tbf-attr'), width);
			}

			settings.$guide.css('left', -99999);
			settings.guidePos = null;
			settings.$currentColumn = null;
			settings.leftLimit = null;
			settings.mouseDownLeftPos = null;
			settings.mouseDownTimeout = null;

			$(document).off(`mousemove.${ctrl.cid}`);
		}
	},

	TbodyView: {
		collectionEvents: {
			'tm:columnresize': 'resizeColumn'
		},
		resizeColumn: function(attr, size) {
			const $cell = this.$(`.tbf-colWidthCell[data-tbf-attr="${attr}"]`);
			const $table = this.$el;
			this.options.controller.$resizeColumn($table, $cell, size);
		}
	}

};
