var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<td>'+
((__t=( isDeleteMarker ? 'D' : 'A' ))==null?'':__t)+
'</td>\n<td>\n	';
 if (DownloadUri) { 
__p+='\n		<a href="'+
((__t=( DownloadUri ))==null?'':__t)+
'">'+
((__t=( name ))==null?'':__t)+
'</a>\n	';
 } else { 
__p+='\n		'+
((__t=( name ))==null?'':__t)+
'\n	';
 } 
__p+='\n</td>\n<td>'+
((__t=( lastModified.format('UiSortable') ))==null?'':__t)+
'</td>\n<td>'+
((__t=( typeof authorName !== 'undefined' ? authorName : author ))==null?'':__t)+
'</td>\n<td>\n	';
 if (!isDeleteMarker) { 
__p+='\n		<a href="javascript:void(0);" \n			class="js-tool js-restore ldi hoverable clickable ldi-replace"\n			title="Restore this version"\n			aria-label="Restore this version"\n			aria-role="button"\n		></a>\n	';
 } 
__p+='\n</td>';
}
return __p;
};
