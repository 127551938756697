var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+=''+
((__t=( displayName ))==null?'':__t)+
'&nbsp;\n<span class="ldi-container">\n	';
 if (pdfUri) { 
__p+='\n		<a href="'+
((__t=( pdfUri ))==null?'':__t)+
'" class="ldi ldi-pdf x-large" target="_blank"></a>\n	';
 } 
__p+='\n</span>';
}
return __p;
};
