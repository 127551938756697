export default {
	startTool: 'srri',
	startSubtool: undefined,

	// in order of display in toolbar
	// main tools always display in main area, sub tools in flyout
	tools: [
		{
			name: 'documentSets',
			icon: 'documents',
			displayName: 'Document sets',
			requireUri: 'appModules/jobType/documentSets/controller',
			alwaysEnabled: true
		},
		{
			name: 'srri',
			icon: 'hexagon-o',
			displayName: 'SRRI Monitor',
			requireUri: 'appModules/jobType/srri/controller',
			alwaysEnabled: true,
			requirements: {
				type: 'kiid'
			}
		}
	]
};
