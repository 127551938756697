var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (Groups.length) { 
__p+='\n\n	<ul>\n	';
 _.each(Groups, function(group) { 
__p+='\n		<li>'+
((__t=( group.GroupName ))==null?'':__t)+
'</li>\n	';
 }); 
__p+='\n	</ul>\n\n';
 } else { 
__p+='\n\n	<em>None</em>\n\n';
 } 
__p+='\n\n';
}
return __p;
};
