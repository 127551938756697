import Marionette from 'marionette';

const FadeInRegion = Marionette.Region.extend({
	attachHtml: function(view) {
		this.$el.hide();
		this.$el.empty().append(view.el).fadeIn();
	}
});

const FlyoutRegion = Marionette.Region.extend({
	onShow: function(view) {
		this.listenTo(view, 'destroy close', this.customOnClose);
		this.listenTo(view, 'request:close', this.customOnCloseRequest);
	},
	attachHtml: function(view) {
		this.$el.empty().append(view.el).show();
	},
	customOnCloseRequest: function(view) {
		this.trigger('requested:close');
		this.customOnClose(view);
	},
	customOnClose: function(view) {
		this.stopListening(view);
		this.empty();
		this.$el.hide();
	}
});

export default {
	FadeInRegion,
	FlyoutRegion
};
