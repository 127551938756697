import $ from 'jquery';
import FormModel from 'common/modules/formView/model';
import tools from 'common/tools/main';
import View from './view';

const ALREADY_EXISTS = 409;

const isValidEmailAddress = tools.regex.isValidEmailAddress;

function init (app) {
	const ViewModel = FormModel.extend({
		defaults: {
			Email: ''
		},

		saveChanges: function() {
			const that = this;

			if (!that.isValid()) return;

			app.request('app.services.users.addAccount', that.get('ClientId'), that.get('Email'))
				.done(function() {
					that.set('Email', '', { silent: true }); // reset form
					that.trigger('saved');
				})
				.fail(function(err) {
					let msg;

					if (err.status === ALREADY_EXISTS) {
						msg = 'User already exists.';
					}

					if (err.responseJSON && err.responseJSON.Message) {
						msg = err.responseJSON.Message;
					}

					that.trigger('failed', msg);
				});
		},

		validateData: function(attrs, options) {
			const errors = {};

			if (!attrs.Email || !isValidEmailAddress(attrs.Email)) {
				errors.Email = 'Invalid email address.';
			}

			return errors;
		}
	});

	return {
		getAddUserView: function(clientId) {
			const viewModel = new ViewModel({ ClientId: clientId });
			return $.Deferred()
				.resolve(new View({ model: viewModel }))
				.promise();
		}
	};
}

export default init;
