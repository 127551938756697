import _ from 'underscore';
import Marionette from 'marionette';
import app from 'app';
import mixin from 'common/mixins/main';
import tplItem from './templates/tpl_operation.html';
import tplItemHeader from './templates/tpl_operationHeader.html';
import tplList from './templates/tpl_operations.html';
import HistoryController from './widgets/history/controller';
import RunWithOptionsController from './widgets/runWithOptions/controller';
import EventLogView from './widgets/eventLog/view';

const operationToolsConfig = [
	{
		id: 'run',
		icon: 'right',
		description: 'Run this operation'
	},
	{
		id: 'run-opts',
		icon: 'play-plus',
		description: 'Run this operation with options...'
	},
	{
		id: 'history',
		icon: 'history',
		description: 'View history'
	}
];

const OperationHeaderView = Marionette.ItemView.extend({
	template: tplItemHeader,
	className: 'card-header clickable operation-header',
	attributes: function() {
		return {
			'title': this.model.get('Description'),
			'aria-expanded': false,
			'tabindex': 0
		};
	},
	modelEvents: {
		'change:DisplayName': 'render',
		'change:Status': 'render',
		'change:ProgressPct': 'render'
	},
	events: {
		'click .js-run': 'runOperation',
		'click .js-run-opts': 'toggleRunOpts',
		'click .js-history': 'toggleHistory'
	},
	templateHelpers: function() {
		const model = this.model;
		const status = model.get('Status');

		// Do not ever display 100%. 100% implies completion, but the op
		// isn't really done until its status is Successful/Failed.
		let progressPct = model.get('ProgressPct');
		progressPct = progressPct >= 1 ? 0.99 : progressPct;

		// Determines whether to show % or status icon.
		const hasProgressPct = typeof progressPct === 'number' && status !== 'Successful' && status !== 'Failed';

		return {
			hasProgressPct: hasProgressPct,
			progressPct: progressPct,
			statusClass: hasProgressPct || status === 'Queued' || status === 'Started' || status === 'Running' ? 'waiting'
				: status === 'Failed' ? 'error'
					: 'success',
			statusIcon: status === 'Queued' || status === 'Started' || status === 'Running' ? 'replace ldi-spin-slow'
				: status === 'Failed' ? 'warning'
					: 'success',
			toolsConfig: operationToolsConfig
		};
	},
	toggleHistory: function() {
		app.vents.operations.trigger(`display:history:${this.model.get('key')}`);
	},
	toggleRunOpts: function() {
		app.vents.operations.trigger(`display:runopts:${this.model.get('key')}`);
	},
	runOperation: function() {
		this.model.runOperation();
	}
});

const OperationView = Marionette.LayoutView.extend({
	template: tplItem,
	className: 'card operation',

	regions: {
		regionHeader: '.region-header',
		regionBody: '.region-body',
		regionDrawer: '.region-drawer'
	},

	initialize: function(opts) {
		mixin.cardView({ view: this });

		const key = this.model.get('key');
		const vent = app.vents.operations;
		this.listenTo(vent, `display:history:${key}`, this.displayHistory);
		this.listenTo(vent, `display:runopts:${key}`, this.displayRunWithOptions);
	},

	onRender: function() {
		this.regionHeader.show(new OperationHeaderView({ model: this.model }));
	},

	onExpandBody: function() {
		this.regionBody.show(new EventLogView({ collection: this.model.get('Events') }));
	},

	displayHistory: function() {
		const model = this.model;
		const logsPath = `clients/${model.get('clientId')}/jobtypes/${model.get('jobTypeId')}/jobs/${model.get('jobId')}/logs/`;
		const scriptName = model.get('scriptName');

		const history = new HistoryController({
			operation: model,
			prefix: logsPath,
			filter: function(item) {
				return item.scriptName === scriptName;
			},
			takeAll: true // Need to get everything so we can filter list down by filename
		});

		this.drawerShow(history.view);
	},

	displayRunWithOptions: function() {
		const runWithOptions = new RunWithOptionsController({
			operation: this.model
		});

		this.drawerShow(runWithOptions.view);
	}
});

const NoOperationView = Marionette.ItemView.extend({
	template: _.template('No operations')
});

const OperationCollectionView = Marionette.CompositeView.extend({
	template: tplList,
	className: 'column-inner',
	childViewContainer: '.js-operations',
	childView: OperationView,
	emptyView: NoOperationView
});

export default OperationCollectionView;
