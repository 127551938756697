import $ from 'jquery';
import _ from 'underscore';
import appSettings from 'config';
import 'url-search-params-polyfill';

const deferredLoggedIn = $.Deferred();

const loginTemplate =
	'<div id="loginDiv" class="login">' +
	'<form id="loginForm">' +
	'<div class="login-logo"></div>' +
	'<input type="submit" class="btn login-submit" id="btnLogin" value="Login"/>' +
	'</form>' +
	'</div>';

const api = {};

/**
 * read and return the value of a cookie.
 * @param {*} name - name of the cookie
 */
function getCookieNotChunked(name) {
	const re = new RegExp(`(?:(?:^|.*;\\s*)${name}\\s*\\=\\s*([^;]*).*$)|^.*$`);
	const val = document.cookie.replace(re, '$1');
	return val;
}

/**
 * read the value of a cookie. If the cookie was chunked due to size, then concat all chunks and return the value.
 * @param {*} name - name of the cookie to read
 */
function getCookie(name) {
	const val = getCookieNotChunked(name);
	const chunkSize = Number(val);
	let ret = '';
	if (!isNaN(chunkSize)) {
		for (let i = 0; i < chunkSize; i++) {
			ret += getCookieNotChunked(`${name}.${i}`);
		}
	} else {
		ret = val;
	}
	return ret;
}

/**
 * split a string into chunks
 * @param {string} str - the string to split
 * @param {number} size - max length of each chunk
 */
function chunkSubstr(str, size) {
	const numChunks = Math.ceil(str.length / size);
	const chunks = new Array(numChunks);

	for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
		chunks[i] = str.substr(o, size);
	}

	return chunks;
}

/**
 * Set a cookie. If the value is too large, splits it into chunks.
 * @param {string} name - the name of the cookie
 * @param {string} value - the value of the cookie
 * @param {number} expires - number of seconds until cookie expires.
 */
function setCookie(name, value, expires) {
	// If "expires" is not a date, is number of seconds to expiry. Convert
	// that to a date.
	expires = _.isDate(expires) ? expires : new Date(new Date().getTime() + (expires - 1) * 1000);
	const domain = appSettings.AuthCookieDomain;
	const domainStr = domain ? `domain=${domain};path=/` : '';

	const chunkSize = 100;
	if (value.length <= chunkSize) {
		const cookieValue = `${name}=${value};expires=${expires.toGMTString()};${domainStr}`;
		document.cookie = cookieValue;
	} else {
		// chunk time
		// let cookieValue = `${name}=${JSON.stringify()};expires=${expires.toGMTString()};${domainStr}`;
		const chunks = chunkSubstr(value, 1024);
		document.cookie = `${name}=${chunks.length};expires=${expires.toGMTString()};${domainStr}`;
		for (let i = 0; i < chunks.length; i++) {
			document.cookie = `${name}.${i}=${chunks[i]};expires=${expires.toGMTString()};${domainStr}`;
		}
	}

	// ;path=path (e.g., '/', '/mydir') If not specified, defaults to the current path of the current document location.
	// ;domain=domain (e.g., 'example.com', '.example.com' (includes all subdomains), 'subdomain.example.com') If not specified, defaults to the host portion of the current document location.
	// ;max-age=max-age-in-seconds (e.g., 60*60*24*365 for a year)
	// ;expires=date-in-GMTString-format If not specified it will expire at the end of session.
}

/**
 * deletes cookies. If a cookie is chunked, deletes all chunks.
 * @param {*} names - a name or array of names
 */
function deleteCookies(names) {
	if (typeof names === 'string') {
		deleteCookies([names]);
	} else {
		for (let i = 0; i < names.length; i++) {
			const val = getCookie(names[i]);
			const chunkSize = Number(val);
			if (!isNaN(chunkSize)) {
				for (let j = 0; j < chunkSize; j++) {
					setCookie(`${names[i]}.${j}`, '', -1);
				}
			} else {
				setCookie(names[i], '', -1);
			}
		}
	}
}

function setupAjax(accessToken) {
	$.ajaxSetup({
		xhrFields: {
			withCredentials: true
		},
		beforeSend: function(xhr) {
			xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`); // Bearer, JWT, ACS
		}
	});
}

function isLoggedIn() {
	const authData = getCookie('_lduiauth');
	const isUserLoggedIn = typeof authData !== 'undefined' && authData !== null && authData !== '';
	return isUserLoggedIn;
}

// == HTML/UI wrangling functions == //

function randomString(length) {
	// this feature is considered experimental in IE11. As such, it is prefixed with ms, and is accessible via window.msCrypto
	const crypto = window.crypto || window.msCrypto;

	const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
	let str = '';

	while (length > 0) {
		const bytes = new Uint8Array(16);
		const random = crypto.getRandomValues(bytes);

		random.forEach(function(c) {
			if (length == 0) {
				return;
			}

			if (c < chars.length) {
				str += chars[c];
				length--;
			}
		});
	}

	return str;
}

export function showLoginForm() {
	// just redirect to the oids endpoint

	// To mitigate replay attacks, we include a  cryptographic nonce with the request which we will receive again after a successful login. when using the Implicit Grant, a cryptographic nonce must be sent on authentication requests as required by the OpenID Connect specification.
	const nonce = randomString(16);

	// save the generated nonce in local storage so we can use it to validate the received data
	// this prevents replay attacks.
	window.localStorage.setItem('nonce', nonce);

	// save the current url to the state, this is used to redirect the user to original location after login
	const state = encodeURIComponent(JSON.stringify({ location: window.location.href }));

	const $loginDiv = $(loginTemplate).appendTo('body');
	const oidcClientSettings = appSettings.oidcClientSettings;

	$loginDiv.find('#loginForm .login-submit').on('click', function(e) {
		e.stopPropagation();

		window.location = `${oidcClientSettings.authorization_endpoint}?` +
		`response_type=${encodeURIComponent('id_token token')}` +
		`&client_id=${encodeURIComponent(oidcClientSettings.client_id)}` +
		`&redirect_uri=${encodeURIComponent(`${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/${oidcClientSettings.redirect_prefix || ''}signin-oidc.html`)}` +
		`&scope=${encodeURIComponent(oidcClientSettings.scope)}` +
		`&state=${state}` +
		`&nonce=${nonce}`;

		return false;
	});
}

function removeLoginForm() {
	$('#loginDiv').remove();
	$('body').removeClass('login-page');
}

// == API == //

// this is the setup function as well
api.whenLoggedIn = function(callback) {
	if (!appSettings.UseJwt) {
		// authentication is taken care of with server app cookies. nothing to do for UI
		deferredLoggedIn.resolve();
	} else if (isLoggedIn()) {
		// use is already logged in, and we have a cookie
		const cookieValue = getCookie('_lduiauth');
		setupAjax(cookieValue);
		deferredLoggedIn.resolve();
	} else if (window.location.href.indexOf('signin-oidc.html') > 0) {
		// user has logged int oidc provider, and was redirected here
		// now we can collect whatever data we need
		let search = window.location.href.substring(window.location.href.indexOf('signin-oidc.html') + 'signin-oidc.html'.length);
		search = search.trim().replace(/^#/, '?');
		const urlParams = new URLSearchParams(search);
		const access_token = urlParams.get('access_token');
		const expires_in = urlParams.get('expires_in');
		const state = urlParams.get('state');
		const id_token = urlParams.get('id_token');

		// notice we are not validating the token here, so technically any well formed token will be accepted.
		// this is only ok in the UI application, the jwt is validated on each API on each request. so a user
		// logged in with a bad token cannot retrieve any data.
		const decodedIdToken = JSON.parse(window.atob(id_token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')));
		if (decodedIdToken.nonce === window.localStorage.getItem('nonce')) {
			const stateObj = state ? JSON.parse(decodeURIComponent(state)) : {};
			setCookie('_lduiauth', access_token, Number(expires_in) - 300);

			let counter = 0;
			const i = setInterval(() => {
				counter++;
				const t = getCookie('_lduiauth');
				if ((t && t === access_token) || counter === 10) {
					clearInterval(i);
					setupAjax(getCookie('_lduiauth'));
					if (state && stateObj.location) {
						window.location = stateObj.location;
					}
					deferredLoggedIn.resolve();
				}
			}, 100);
		} else {
			// Nonce is not a match! a token replay attack might be underway
		}
	} else {
		showLoginForm();
	}

	deferredLoggedIn.done(function() {
		removeLoginForm();
		callback();
	});
};

api.signOut = function() {
	const apiUri = appSettings.ApiRootUri;
	const signOffUri = `${apiUri}app/Account/SignOff`;

	if (appSettings.UseJwt) {
		deleteCookies('_lduiauth');
	}

	// Redirect to api sign out page, to fully sign out.
	window.location.replace(signOffUri);
};

export default api;
