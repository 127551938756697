var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var status = model.get('Status');
var pct = model.get('ProgressPct') || (status === 'Started' ? 0 : 1);
var style = status === 'Started' ? 'cyan' :
	status === 'Successful' ? 'green' :
	status === 'Failed' ? 'red' :
	status === 'Cancelled' ? 'hidden' : '';

__p+='\n\n<div class="progress-bar inline '+
((__t=( style ))==null?'':__t)+
'">\n	<span class="amt" style="width: '+
((__t=( pct * 100 ))==null?'':__t)+
'%;"></span>\n	<span class="label"></span>\n</div>\n';
}
return __p;
};
