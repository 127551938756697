import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import app from 'app';
import Collection from './model';
import View from './operations_view';
const debug = false;

/**
 Returns true if `o` is an object but is not an Error or a rejected
 $.Deferred.

 @param {*} o
 @returns {boolean}
 */
function excludeNonData(o) {
	return typeof o === 'object' && !(o instanceof Error || (typeof o.state === 'function' && o.state() === 'rejected'));
}

const Controller = Marionette.Controller.extend({
	initialize: function(workspaceModel) {
		this.workspaceModel = workspaceModel;
		const collection = this.collection = new Collection(null, { job: workspaceModel.get('Job') });

		app.vents.operations = new Marionette.Object();

		this.view = new View({
			collection: collection,
			workspaceModel: workspaceModel
		});

		this.refreshOperations();
	},

	refreshOperations: function() {
		const that = this;
		const job = this.workspaceModel.get('Job');
		const clientId = job.get('ClientId');
		const jobTypeId = job.get('JobTypeId');
		const jobId = job.get('Id');
		const path = `clients/${clientId}/jobtypes/${jobTypeId}/jobs/${jobId}/configuration/operations/`;

		app.repository.listDirs(path).then(function(list) {
			// For each operation, fetch its settings.json.
			const promises = _.map(list, function(dir) {
				const opName = dir.name;
				const opDirPath = `${path + opName}/`;

				return app.repository.getJson(`${opDirPath}settings.json`).then(function(op) {
					return _.defaults(op, {
						key: opDirPath,
						clientId: clientId,
						jobTypeId: jobTypeId,
						jobId: jobId,
						name: opName
					});
				});
			});

			return $.whenAll.apply($, promises);
		}).then(function() {
			// When all requests for `settings.json`s are complete, exclude
			// any rejected ops and reset collection with data.
			that.collection.reset(_.filter(arguments, excludeNonData));
		}).fail(function(err) {
			if (debug) console.error('could not load operations', err);
		});
	},

	onDestroy: function() {
		this.view.destroy();
		delete app.vents.operations;
	}
});

const create = function(workspaceModel) {
	return new Controller(workspaceModel);
};

export default { create };
