import $ from 'jquery';
import _ from 'underscore';
import tools from 'common/tools/main';
import Cache from 'common/lib/dataCache';
import appSettings from 'config';
import Models from './model';
import metadata from './modules/metadata/module';

const subModuleApis = [metadata];
const uriRoot = `${appSettings.JobTypesUri}clients/`;

const getSummariesFromServer = function(clientId, jobTypeId) {
	return $.ajax({
		url: `${uriRoot + clientId}/jobtypes/${jobTypeId}/jobs`,
		type: 'GET',
		contentType: 'application/json',
		dataType: 'json'
	});
};

const summariesCache = new Cache({
	name: 'jobs',
	fetchCollection: getSummariesFromServer
});

function init(app) {
	// Reset cache on client change.
	app.session.on('change:clientId', function() {
		summariesCache.reset();
	});

	const api = {
		get: function(clientId, jobtypeId, jobId) {
			if (tools.isNullish(clientId)) throw new Error('clientId is required');
			if (tools.isNullish(jobtypeId)) throw new Error('jobtypeId is required');

			let obj;

			if (_.isString(jobId) && jobId) {
				obj = new Models.JobModel({ ClientId: clientId, JobTypeId: jobtypeId, Id: jobId }, { app });
				return obj
					.fetch()
					.then(function() {
						return obj.fetchSettingsJson();
					})
					.then(function() {
						return obj;
					});
			} else {
				obj = new Models.JobCollection();
				obj.url = `${uriRoot + clientId}/jobtypes/${jobtypeId}/jobs/`;
				return obj.fetch().then(function() {
					return obj;
				});
			}
		},

		create: function(obj) {
			if (tools.isNullish(obj)) throw new Error('empty object');

			const job = new Models.JobModel(obj.attributes, { app });

			const clientId = job.get('ClientId');
			if (tools.isNullish(clientId)) throw new Error('ClientId must be set');

			const jobTypeId = job.get('JobTypeId');
			if (tools.isNullish(jobTypeId)) throw new Error('JobTypeId must be set');

			const displayName = job.get('DisplayName');
			if (tools.isNullish(displayName)) throw new Error('DisplayName must be set');

			job.url = `${uriRoot + clientId}/jobtypes/${jobTypeId}/jobs`;

			// unset the id, so that BB knows that its a new object
			job.unset('Id', { silent: true });

			return job.save().then(function(ret) {
				job.set(ret);
				app.trigger('app:job:created', job);
				job.fetchSettingsJson();

				return job;
			});
		},

		update: function(job) {
			if (!job) throw new Error('empty object');

			const clientId = job.get('ClientId');
			if (!clientId) throw new Error('ClientId must be set');

			const jobTypeId = job.get('JobTypeId');
			if (!jobTypeId) throw new Error('JobTypeId must be set');

			job.url = `${uriRoot + clientId}/jobtypes/${jobTypeId}/jobs/${job.id}`;

			return job.save().then(function() {
				app.trigger('app:job:updated', job);
				return job;
			});
		},

		getSummaries: function(clientId, jobTypeId) {
			return summariesCache.getCollection({
				key: jobTypeId,
				fetchArgs: arguments
			});
		},

		getSummary: function(clientId, jobTypeId, jobId) {
			return summariesCache.getItem({
				key: jobId,
				collectionKey: jobTypeId,
				fetchArgs: arguments,
				fetchViaCollection: true
			});
		},

		getDocuments: function(clientId, jobtypeId, jobId) {
			if (typeof clientId === 'undefined' || clientId === null || clientId === '') { throw new Error('clientId is required'); }

			if (typeof jobtypeId === 'undefined' || jobtypeId === null || jobtypeId === '') { throw new Error('jobtypeId is required'); }

			if (typeof jobId === 'undefined' || jobId === null || jobId === '') throw new Error('jobId is required');

			const key =
				`clients/${clientId}/jobtypes/${jobtypeId}/jobs/${jobId}/documents/manifest.json`;
			return app.repository.getJson(key);
		},

		runOperation: function(operation, job, params) {
			let uriPrefix, uriPath, url;
			if (operation.get('Version') === '2') {
				// for v2 jobs, we call jobmanagement to exute
				uriPath =
					`clients/${
						job.get('ClientId')
					}/jobtypes/${
						job.get('JobTypeId')
					}/jobs/${
						job.get('Id')
					}/operations/${
						operation.get('Id')
					}/execute`;
				url = app.settings.JobTypesUri + uriPath;
			} else {
				uriPrefix = operation.get('RunAsCommand') ? 'scriptsvc/execute/' : 'scriptsvc/';
				uriPath =
					`clients/${
						job.get('ClientId')
					}/jobtypes/${
						job.get('JobTypeId')
					}/jobs/${
						job.get('Id')
					}/${
						operation.get('ScriptLocation')}`;
				if (/\.js$/.test(uriPath)) uriPath = uriPath.slice(0, -3);
				url = app.settings.ApiUri + uriPrefix + uriPath;
			}

			if (params) {
				// POST to include parameters
				return $.ajax({
					method: 'post',
					url: url,
					contentType: 'application/json; charset=utf-8',
					dataType: 'json',
					data: params
				});
			} else {
				// No parameters; a GET is sufficient
				return $.ajax(url);
			}
		}
	};

	// Extend with submodule apis.
	_.each(subModuleApis, (subModuleApi) => {
		_.extend(api, typeof subModuleApi === 'function' ? subModuleApi(app) : subModuleApi);
	});

	return api;
}

export default init;
