import $ from 'jquery';
import _ from 'underscore';
import tools from 'common/tools/main';
// import helpers from './helpers';
import BASE_CLASSES from './classes/main';
import rowSelect from './plugins/rowSelect';
import columnMenu from './plugins/columnMenu';
import columnSort from './plugins/columnSort';
import columnFilter from './plugins/columnFilter';
import columnResize from './plugins/columnResize';
import columnVis from './plugins/columnVis';
import search from './plugins/search';
import settings from './plugins/tableSettings';
// './plugins/rowExpand',

const plugins = [
	rowSelect,
	columnMenu,
	columnSort,
	columnFilter,
	columnResize,
	columnVis,
	search,
	settings
];

let IDBANK = 0;
const INSTANCES = {};
const CLASS_NAMES = _.keys(BASE_CLASSES);
const CLASS_MIXIN_NAMES = _.map(CLASS_NAMES, function(name) {
	return tools.string.lowerFirst(`${name}Mixin`);
});

// if menus are open, and we click, then determine whether we need to close the menu.
$(document).on('click', function(e) {
	const $menu = $(e.target).closest('.tbf-filter, .region-dropmenu, .js-tablesettings, .pika-single');

	if (!$menu.length) {
		// Clicked outside menu. Close menus from all instances.
		_.each(INSTANCES, function(obj) {
			const region = obj.view.regionDropmenu;
			if (region) region.empty();
		});
	}
});

const DEFAULTS = {
	// how to identify models. 'id' or 'cid'
	modelIdAttribute: 'id',

	// paging
	hasPaging: true,
	hasPrevPage: false,
	hasNextPage: false,
	pageCurrent: 0,
	pageLength: 100,

	tableHeight: 'fill'
};

const COLUMNDEFAULTS = { type: 'string' };

function Grid(opts) {
	// Fill in missing options with defaults.
	this.options = _.defaults(opts, DEFAULTS);

	// Fill in missing column options with defaults.
	_.each(opts.columns, function(col) {
		if (!col.id) {
			throw new Error('Every column must have an "id" attribute.');
		}

		if (!col.displayName) {
			col.displayName = col.id;
		}

		_.defaults(col, COLUMNDEFAULTS);
	});

	// Generate id for this grid.
	const id = opts.id = IDBANK++;

	// Generate custom class definitions by applying enabled plugins and
	// mixins specified in `opts` to base classes.
	const classes = opts.classes = this.createClasses(opts);

	// Create controller.
	opts.controller = new classes.Controller(opts);

	// Create view.
	this.view = new classes.SubstrateView(opts);

	// Save reference to this instance in this module.
	INSTANCES[id] = this;

	return this;
}

Grid.plugins = {};

Grid.prototype = {
	constructor: Grid,
	createClasses: function(opts) {
		const exts = _.reduce(CLASS_NAMES, function(memo, name) {
			memo[name] = [];
			return memo;
		}, {});

		// Unpack plugins
		_.each(Grid.plugins, function(plugin) {
			if (opts[`has${plugin.name}`]) {
				_.each(CLASS_NAMES, function(name) {
					const p = plugin[name];
					if (typeof p === 'object') exts[name].push(p);
				});
			}
		});

		// Add custom view mixins from options
		_.each(CLASS_NAMES, function(name, i) {
			const p = opts[CLASS_MIXIN_NAMES[i]];
			if (typeof p === 'object') exts[name].push(p);
		});

		// Generate customized view classes
		return _.reduce(CLASS_NAMES, function(memo, name) {
			memo[name] = tools.extendClass(BASE_CLASSES[name], exts[name]);
			return memo;
		}, {});
	},
	get: function(name) {
		switch (name) {
			case 'controller':
			case 'collection':
				return this.options[name];
		}
	},
	destroy: function() {
		if (this.view) this.view.destroy();
		delete INSTANCES[this.options.id];
	}
};

// Registering plugins
// @todo registration order here determines order of menu sections; that's not good
_.each(plugins, function(p) {
	Grid.plugins[p.name] = p;

	// Update Grid defaults with plugin defaults.
	DEFAULTS[`has${p.name}`] = true;
	_.extend(DEFAULTS, p.defaults);
	_.extend(COLUMNDEFAULTS, p.columnDefaults);
});

export default Grid;
