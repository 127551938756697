import Marionette from 'marionette';
import template from './template.html';

const GroupsView = Marionette.LayoutView.extend({
	template: template,

	regions: {
		region_addGroup: '.region-addGroup',
		region_groupsGrid: '.region-groupsGrid'
	},

	initialize: function(opts) {
		const that = this;
		that.app = opts.app;
		that.clientId = opts.ClientId;
	},

	onRender: function() {
		const that = this;
		const app = that.app;
		const clientId = that.clientId;

		app.request('app.services.users.getAddGroupView', clientId).done(function(addGroupView) {
			that.region_addGroup.show(addGroupView);
		});

		app.request('app.services.users.getGroupListView', clientId).done(function(groupGridView) {
			that.region_groupsGrid.show(groupGridView);
		});
	}

});

export default GroupsView;
