import $ from 'jquery';
import Marionette from 'marionette';
import Regions from 'common/modules/regions';
import ui from 'ui/main';

import tpl from './app_tpl.ejs';

/** Custom region that hides itself when it hears 'hide' events from its
 children views. When hiding, does not detach or destroy child view, which
 means child view cannot be shown again. In such a situation, use
 showRegion instead. */
const SidebarRegion = Marionette.Region.extend({
	initialize: function() {
		this.isHidden = true;
	},
	onShow: function(view) {
		this.showRegion();

		this.listenTo(view, 'destroy close', function() {
			this.stopListening(view);
			this.empty();
			this.hideRegion();
		});

		this.listenTo(view, 'hide', this.hideRegion);
	},
	attachHtml: function(view) {
		this.$el
			.empty()
			.append(view.el)
			.show();
	},
	showRegion: function() {
		this.isHidden = false;
		this.$el.removeClass('hidden');
	},
	hideRegion: function() {
		this.isHidden = true;
		this.$el.addClass('hidden');
		this.trigger('hide');
	},
	toggleVisibility: function() {
		const makeActive = this.isHidden;
		makeActive ? this.showRegion() : this.hideRegion();
		return makeActive;
	}
});

const AppLayout = Marionette.LayoutView.extend({
	el: $('#app'),

	template: tpl,

	regions: {
		regionSidebar: {
			el: '#region-sidebar',
			regionClass: SidebarRegion
		},
		regionMain: {
			el: '#region-main',
			regionClass: Regions.FadeInRegion
		},
		regionClientSelector: '#region-client-selector'
	},

	events: {
		// Settings menu is handled by ui/contextMenu
		'click [data-region="sidebar"]': 'onClickSidebarToggle',
		'click #notifications-toggle': 'toggleNotificationsPanel'
	},

	initialize: function(opts) {
		this.app = opts.app;
		this.listenTo(this.regionSidebar, 'hide', this.onSidebarHide);
	},

	templateHelpers: function() {
		const app = this.app;
		return {
			isAdmin: app.session.get('isAdmin'),
			modules: app.modules
		};
	},

	onRender: function() {
		const that = this;
		const app = that.app;

		ui.skeleton.init(app);

		const clientId = app.session.get('clientId');

		app.request('app.services.clients.getSelectClientView', clientId).done(function(view) {
			that.regionClientSelector.show(view);
		});
	},

	onClickSidebarToggle: function(e) {
		const name = $(e.target).attr('data-link');
		this.app.trigger('app:show:sidebar', name);
	},

	toggleNotificationsPanel: function(e) {
		e.stopPropagation();
		$(e.target).trigger('blur');
		this.app.notifications.togglePanel();
	},

	showPage: function(name, view) {
		// Exit if invalid view.
		if (!view) return;

		// Since these regions only show this one view, we don't have to
		// show the view again if it's already shown.
		this.regionMain.show(view);

		// Highlight nav/menu link for this page.
		this.setActiveLink(name, 'pagelink');

		ui.skeleton.refresh();
	},

	toggleSidebar: function(name, view) {
		const region = this.regionSidebar;
		const currentView = region.currentView;
		let makeActive;

		if (currentView && currentView === view) {
			// Correct view already shown. Toggling sidebar.
			makeActive = region.toggleVisibility();
		} else {
			region.show(view);
			makeActive = true;
		}

		this.toggleActiveLink(name, makeActive);
		ui.skeleton.refresh();
	},

	setActiveLink: function(name, type) {
		// Unfocus & unhighlight all nav btns and menu page links.
		const $navBtns = this.$('#app-nav')
			.find('.js-link')
			.trigger('blur')
			.removeClass('active');
		const $menuLinks = this.$('#app-settings-menu')
			.find('[data-link]')
			.trigger('blur')
			.removeClass('active');

		// Don't want to highlight logo btn used for dashboard page.
		if (name === 'dashboard') return;

		const selector = `[data-link="${name}"]`;

		// Attempt to find btn in nav menu.
		let $link = $navBtns.filter(selector);

		// Didn't find it, so this may be a settings menu item.
		if (!$link.length) {
			$link = $menuLinks.filter(selector);

			// It's a menu item! Highlight settings btn.
			if ($link.length) $navBtns.filter('#settings-btn').addClass('active');
		}

		// Highlight the appropriate link.
		$link.addClass('active');
	},

	toggleActiveLink: function(name, makeActive) {
		this.$(`[data-link="${name}"]`).toggleClass('active', makeActive);
	},

	onSidebarHide: function() {
		this.$('a[data-region="sidebar"]').removeClass('active');
	}
});

export default AppLayout;
