var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';


var inputType, title, doneBtn, isNewerCatalogue = viewType === 'newer';

if (isNewerCatalogue) {
	inputType = 'radio';
	title = 'Change your newer set';
	doneBtn = 'Cancel';
	
} else {
	inputType = 'checkbox';
	title = 'Choose your older sets';
	doneBtn = 'Done';
}


__p+='\n\n<div class="bl-setList-available bl-setList-available-'+
((__t=( viewType ))==null?'':__t)+
'">\n	<div class="bl-setList-available-header">\n		<h2 class="column-title bl-setList-available-title">'+
((__t=( title ))==null?'':__t)+
'</h2>\n		<span class="bl-setList-header-btns">\n			<button class="btn btn-sm bl-setList-done">'+
((__t=( doneBtn ))==null?'':__t)+
'</button>\n		</span>\n		';

		//<div class="bl-setList-available-filters">
		//	<input type="text" class="bl-setList-search" placeholder="Find a document set" />
		//	<div class="bl-setList-sort">
		//		<span class="bl-setList-sortFacet"><span class="fa fa-clock-o"></span> Publication date</span>
		//		<span class="bl-setList-sortDirection">&nbsp;&nbsp;<span class="fa fa-sort-alpha-asc"></span> Sort ascending</span>
		//	</div>
		//</div>
		
__p+='\n	</div>\n	<div class="bl-setList-treeContainer">\n\n		<!-- job level -->\n		<ul class="tree-nodes">\n		';
 
		_.each(collection, function(docsets, jobId) {
			var jobName = docsets[0].get('JobName');
		
__p+='	\n			<li class="tree-node has-children">\n				<span class="tree-node-contents bl-setList-jobNodeContents">\n					<span class="tree-toggle fa fa-caret-right"></span>\n					<span class="tree-node-label">'+
((__t=( jobName ))==null?'':__t)+
' ('+
((__t=( docsets.length ))==null?'':__t)+
')</span>\n					<span class="tree-node-controls"></span>\n				</span>\n				<!-- doc set level -->\n				<ul class="tree-nodes">\n					';
 _.each(docsets, function(docset) { 
__p+='\n						<li class="tree-node">\n							<span class="tree-node-contents">\n								<label class="tree-node-selectable-wrapper">\n									';

									var isNewDocSet = docset.get('isNewDocSet');
									var isOldDocSet = docset.get('isOldDocSet');
									var checked = (isNewerCatalogue && isNewDocSet) || (!isNewerCatalogue && isOldDocSet) ? 'checked' : '';
									var disabled = !isNewerCatalogue && isNewDocSet ? 'disabled' : '';
									
__p+='\n									<input type="'+
((__t=( inputType ))==null?'':__t)+
'" name="bl-sets-'+
((__t=( viewType ))==null?'':__t)+
'" value="'+
((__t=( docset.get('Id') ))==null?'':__t)+
'" '+
((__t=( checked ))==null?'':__t)+
' '+
((__t=( disabled ))==null?'':__t)+
' />\n									<div class="tree-node-label bl-set-data">\n										<span class="bl-set-name">'+
((__t=( docset.get('DisplayName') || 'Unnamed' ))==null?'':__t)+
'</span>\n										<div class="bl-set-details">\n											<span class="bl-set-detail">'+
((__t=( docset.get('Id') ))==null?'':__t)+
'</span>\n											<span class="bl-set-detail">'+
((__t=( docset.get('Date').format('UiSortable') ))==null?'':__t)+
'</span>\n											<span class="bl-set-detail">'+
((__t=( docset.get('UserName') ))==null?'':__t)+
'</span>\n											<span class="bl-set-detail">\n												';
 if (docset.get('DownloadUri')) { 
__p+='\n													<a href="'+
((__t=( docset.get('DownloadUri') ))==null?'':__t)+
'" target="_blank">'+
((__t=( docset.get('NumberOfDocuments') ))==null?'':__t)+
' documents <span class="ldi ldi-download"></span></a>\n												';
 } else { 
__p+='	\n													'+
((__t=( docset.get('NumberOfDocuments') ))==null?'':__t)+
' documents\n												';
 } 
__p+='	\n											</span>\n										</div>\n									</div>\n								</label>\n							</span>\n						</li>\n					';
 }); 
__p+='\n				</ul>\n			</li>\n		';
 }); 
__p+='\n		</ul>\n\n	</div>\n</div>';
}
return __p;
};
