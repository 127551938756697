import * as SignalR from '@aspnet/signalr';
import appSettings from 'config';

const debug = false;

const connection = new SignalR.HubConnectionBuilder()
	.withUrl(`${appSettings.SignalR.Hub}`)
	.build();

function init (app) {
	connection.onclose(function(e) {
		if (debug) {
			if (e) {
				console.log(`Connection closed with error: ${e}`);
			} else {
				console.log('Disconnected');
			}
		}
	});

	const agg = {};
	connection.on('broadcastMessage', function(obj) {
		const sourceId = obj.SourceId;
		const version = obj.Version;

		obj.Message = JSON.parse(obj.Message);

		if (sourceId && version) {
			if (agg[sourceId] >= version) {
				if (debug) {
					console.warn('Out of order message ignored', obj);
				}
			} else {
				agg[sourceId] = version;
				if (debug) {
					console.info('Server says: ', obj);
				}

				// Dispatching to app vent
				app.vent.trigger('server:events', obj);
			}
		}
	});

	connection.start().catch(function() {
		if (debug) console.error('Failed to open SignalR connection');
	});
}

export default init;
