import $ from 'jquery';
import Marionette from 'marionette';
import Regions from 'common/modules/regions';
import ui from 'ui/main';
import tplWorkspace from './templates/tpl_workspace.html';
import tplToolbar from './templates/tpl_toolbar.html';
import tplLoading from './templates/tpl_loading.html';

const LoadingView = Marionette.ItemView.extend({
	template: tplLoading
});

const WorkspaceToolbarView = Marionette.ItemView.extend({
	template: tplToolbar,

	modelEvents: {
		// Re-render when..
		// (1) Job set: existing job is loaded.
		// (2) Job types set: data for new job form is loaded.
		// (3) Tool names set: switched tools.
		// (4) Tool config changes.
		'change:JobTypes change:Job change:CurrentToolName change:CurrentSubtoolName change:ToolsConfig': 'render'
	},

	serializeData: function() {
		// Hand over entire model so template can access its route creation
		// methods.
		return { model: this.model };
	}
});

const WorkspaceView = Marionette.LayoutView.extend({
	template: tplWorkspace,
	className: 'workspace-instance',

	modelEvents: {
		'change:CurrentTool change:CurrentSubtool': 'switchTool',
		'loading:tool': 'showLoadingMain',
		'loading:subtool': 'showLoadingFlyout'
	},

	events: {
		'click .js-toolbarToggle': 'toggleToolbar'
	},

	regions: {
		regionTools: '.region-toolbar',
		regionMain: '.region-main',
		regionFlyout: {
			el: '.region-flyout',
			regionClass: Regions.FlyoutRegion
		}
	},

	initialize: function() {
		const model = this.model;
		this.toolbarView = new WorkspaceToolbarView({ model: model });
		this.listenTo(this.regionFlyout, 'requested:close', this.onFlyoutRegionRequestedClose);
	},

	onRender: function() {
		this.regionTools.show(this.toolbarView);
	},

	switchTool: function(workspaceModel, toolController) {
		// If there is no tool controller, it means a tool was closed.
		// It will have closed its view already, so no need to do anything
		// besides updating tool buttons.
		if (toolController) {
			if (toolController.viewInFlyout) {
				// Opening sub tool in flyout region.
				this.regionFlyout.show(toolController.view);
			} else {
				// Opening tool in main region.
				this.regionMain.show(toolController.view);
				this.regionFlyout.empty(); // close flyout region
			}

			ui.skeleton.refresh();
		}
	},

	showLoadingOverlay: function(region) {
		const currView = region.currentView;

		if (!currView) {
			// no view shown yet; show loading view
			const view = new LoadingView();
			region.show(view);
		} else if (!(currView instanceof LoadingView)) {
			// current view is not loading view; put loading overlay on
			region.$el.append($(tplLoading()).hide().fadeIn(50));
		}

		ui.skeleton.refresh();
	},

	showLoadingMain: function() {
		this.showLoadingOverlay(this.regionMain);
	},

	showLoadingFlyout: function() {
		this.showLoadingOverlay(this.regionFlyout);
	},

	toggleToolbar: function() {
		// Show/hide toolbar; used in mobile where toolbar is not always shown
		this.$('.workspace-toolbar').toggleClass('show');
	},

	onFlyoutRegionRequestedClose: function() {
		this.trigger('closeSubtool');
	}

});

export default WorkspaceView;
