import $ from 'jquery';
import _ from 'underscore';
import tools from 'common/tools/main';

const NUM_DOCS = 20;
const NUM_SRRIS = 17;
const NUM_RETURNS = 260;
const DATE_START = new Date('2013/01/01');

function formatDate(d) {
	return d.toISOString().substring(0, 10);
}

function masterKey(i) {
	return `[${i}-CAD].[en].[web]`;
}

function randomDate(start, end) {
	return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

function randomReturn() {
	return Math.random() / 10;
}

function randomSrri(seed) {
	let srri;

	if (seed) {
		// Coin flip: Vary seed by +/- 1?
		const variance = Math.round(Math.random());
		// Coin flip: If varying seed, add or subtract?
		const direction = Math.round(Math.random()) > 0 ? 1 : -1;
		// Generate new SRRI number.
		srri = seed + variance * direction;
	} else {
		srri = Math.floor(Math.random() * 8);
	}

	return tools.clamp(srri, 1, 7);
}

function getDateAtWeeksAgo(d, weeksAgo) {
	if (!_.isDate(d)) d = new Date(d);
	const offset = weeksAgo * 7;
	return new Date(d.getFullYear(), d.getMonth(), d.getDate() - offset);
}

function generateSummaryModel(id, asOfDate, opts) {
	opts = _.extend(
		{
			calcSrri: 4,
			pubSrri: undefined,
			calcDate: new Date('2016-05-06'),
			pubDate: undefined,
			effDate: new Date('2016-05-15'),
			wksOut: 0,
			shareClass: 'n/a'
		},
		opts
	);

	return _.extend(
		{
			Id: id,
			MasterKey: masterKey(id),
			ClientId: 'cl0001',
			JobTypeId: 'jt0001',
			JobId: 'jb001',
			Isin: `XXX${id}`,
			FundName: `Fund ${id}`,
			ShareClassName: opts.shareClass,
			PublishedPdfUrl: `http://official.transmissionmedia.ca/downloads/${id}.pdf`,
			CalculatedData: {
				Id: `doc-${id}`,
				EffectiveDate: formatDate(opts.effDate),
				CalculatedSrri: opts.calcSrri,
				CalculatedSrriDate: formatDate(opts.calcDate),
				NumWeeksOutside: opts.wksOut,
				SrriValues: generateSrriValues(opts.calcSrri, asOfDate)
			},
			PublishedData: {
				EffectiveDate: formatDate(opts.effDate),
				PublishedSrri: opts.pubSrri, // optional
				PublishedSrriDate: opts.pubDate ? formatDate(opts.pubDate) : opts.pubDate // optional
			}
		},
		opts.otherProps
	);
}

function generateSummary(asOfDate) {
	asOfDate = asOfDate ? new Date(asOfDate) : new Date();
	const d = $.Deferred();
	const SET_SRRI = _.range(1, 8);
	const SET_WEEKSOUT = _.range(0, 21);
	const SET_CLASS = ['A', 'B', 'C', 'D', 'E'];

	const list = _.map(_.range(1, NUM_DOCS + 1), function(i) {
		const calcSrri = _.sample(SET_SRRI);
		const calcDate = randomDate(DATE_START, asOfDate);
		const hasPubSrri = Math.random() > 0.4;

		return generateSummaryModel(i, asOfDate, {
			effDate: randomDate(DATE_START, asOfDate),
			calcSrri: calcSrri,
			calcDate: calcDate,
			pubSrri: hasPubSrri ? _.sample(SET_SRRI) : undefined,
			pubDate: hasPubSrri ? randomDate(DATE_START, calcDate) : undefined,
			wksOut: hasPubSrri ? _.sample(SET_WEEKSOUT) : undefined,
			shareClass: _.sample(SET_CLASS)
		});
	});

	// fake server delay
	setTimeout(function() {
		d.resolve({
			Documents: list
			// NumObservations: 4
		});
	}, 1000);

	return d.promise();
}

function generateSrriValues(srriSeed, asOfDate) {
	// +2 because inclusive range
	return _.map(_.range(1, NUM_SRRIS + 2), function(i) {
		return {
			Date: formatDate(getDateAtWeeksAgo(asOfDate, i)),
			Srri: randomSrri(srriSeed)
		};
	});
}

function generateRaw(asOfDate) {
	// This is the raw data for a particular fund, as of a particular date.
	asOfDate = new Date(asOfDate);
	const d = $.Deferred();
	const data = [];

	for (let i = 0; i < NUM_RETURNS; i++) {
		const start = formatDate(getDateAtWeeksAgo(asOfDate, i));
		const end = formatDate(getDateAtWeeksAgo(asOfDate, i - 1));

		data.push({
			Date: end,
			PeriodStart: start,
			PeriodEnd: end,
			PeriodStartDay: 'Monday',
			PeriodEndDay: 'Friday',
			Return: randomReturn()
		});
	}

	// fake server delay
	setTimeout(function() {
		d.resolve(data);
	}, 1000);

	return d.promise();
}

export default {
	get: generateSummary,
	getRawValues: generateRaw,
	getModel: generateSummaryModel
};
