import $ from 'jquery';
import View from './view';

function init (app) {
	return {
		getEditUserView: function(clientId, userId) {
			const view = new View();

			view.on('render', function() {
				app.request('app.services.users.getEditProfileView', clientId, userId, { showEmail: true }).done(function(v) {
					view.region_profile.show(v);
				});

				app.request('app.services.users.getEditUserGroupsView', clientId, userId).done(function(v) {
					view.region_memberships.show(v);
				});

				app.request('app.services.users.getToggleUserLockView', clientId, userId).done(function(v) {
					view.region_lock.show(v);
				});

				app.request('app.services.users.getToggleMfaView', clientId, userId).done(function(v) {
					view.region_mfa.show(v);
				});
			});

			return $.Deferred()
				.resolve(view)
				.promise();
		}
	};
}

export default init;
