var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

var hasFirstName = typeof FirstName != 'undefined' && FirstName !== '';
var hasLastName = typeof LastName != 'undefined' && LastName !== '';
Id = typeof Id === 'undefined' ? '' : Id;

__p+='\n\n';
 if (hasFirstName || hasLastName) { 
__p+='\n	';
 if (hasFirstName) { 
__p+=' '+
((__t=( FirstName ))==null?'':__t)+
' ';
 } 
__p+='\n	';
 if (hasLastName) { 
__p+=' '+
((__t=( LastName ))==null?'':__t)+
' ';
 } 
__p+='\n	<br>\n';
 } 
__p+='\n';
}
return __p;
};
