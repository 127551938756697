import _ from 'underscore';

const module = {};

/**
 Returns true iff "d" is a valid date object.

 @param {Date} d
 @returns {boolean}
 @source http://stackoverflow.com/q/1353684/1804948
 */
module.isValidDate = function isValidDate(d) {
	if (Object.prototype.toString.call(d) !== '[object Date]') { return false; }
	return !isNaN(d.getTime());
};

module.areSameDays = function areSameDays(d1, d2) {
	return _.isDate(d1) && _.isDate(d2) &&
		d1.getFullYear() === d2.getFullYear() &&
		d1.getMonth() === d2.getMonth() &&
		d1.getDate() === d2.getDate();
};

/**
 Returns true iff "d" is today.

 @param {Date} d
 @returns {boolean}
 */
module.isToday = function isToday(d) {
	return module.areSameDays(d, new Date());
};

/**
 Forces ISO date to have 000 ms. ISO 8601 : 2004

 @param {string} val  Date string to parse.
 @return {string}
 */
module.parseIsoDate = function parseIsoDate(val) {
	return typeof val === 'string'
		? new Date(val.replace(/^(\d{4}-[0-1]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.)(\d+)Z/, '$1000Z')) : val;
};

module.weeksAgo = function weeksAgo(d, n) {
	const offset = n * 7;
	return new Date(d.getFullYear(), d.getMonth(), d.getDate() - offset);
};

export default module;
