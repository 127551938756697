/**
 * Allows you to show/hide a child view within a view/region...

 * - without destroying the previously displayed child view
 * - and on the newly shown child view, triggers a `customShow` event and sets
 *   the `_isCustomShown` property on it to `true`.
 * - and on the newly hidden child view, triggers a `customHide` event and sets
 *   the `_isCustomShown` property on it to `false`.
 *
 * ##### Added properties
 *
 * This mixin tacks on to your `container`:
 *
 * - `currentChildView`: Reference to the currently shown view.
 * - `mixin_customShow`: Mixin settings.
 *
 * @module common/mixins/customShow
 *
 * @param {object} that  What `this` should refer to in your callbacks.
 * @param {Backbone.View|Backbone.Region} container  The view or region that you
 * 	want to show child views in. Can be the same as what you specify for `that`.
 * @param {function} [beforeCustomShow]  Callback before your child view has
 * 	been shown. Accepts one argument, which is the child view to be shown.
 * @param {function} [afterCustomShow]  Callback after your child view is shown.
 * 	Accepts one argument, which is the shown child view.
 *
 * @example <caption>Basic usage</caption>
 *	var SampleView = Marionette.LayoutView.extend({
 * 		regions: {
 * 			region_fruit: '#fruits',
 * 			region_dairy: '#dairy',
 * 		},
 *
 * 		initialize: function() {
 * 			// Include mixin for region_fruit.
 * 			mixin.customshow({
 * 				'that': this,
 * 				'container': this.region_fruit,
 * 				'afterCustomShow': function(childView) {
 * 					console.log('showed fruity view ', childView);
 * 				},
 * 			});
 *
 * 			// Include mixin for region_dairy.
 * 			// (I can have more than one regions that do customShow!)
 * 			mixin.customshow({
 * 				'that': this,
 * 				'container': this.region_dairy,
 * 				'afterCustomShow': function(childView) {
 * 					console.log('showed milky view ', childView);
 * 				},
 * 			});
 * 		},
 *
 * 		showThing: function(childView) {
 * 			if (childView.isFruit()) {
 * 				this.region_fruit.customShow(childView);
 *
 * 			} else if (childView.isDairy()) {
 * 				this.region_dairy.customShow(childView);
 * 			}
 * 		},
 * 	});
 *
 * @example <caption>Changing a callback after initialization:</caption>
 *
 * 	this.region_fruit.mixin_customShow.afterCustomShow = function(toolView) {
 * 		console.log('using a different callback after displaying a fruit');
 * 	};
 */
import _ from 'underscore';

const customShow = function(opts) {
	// Settings for this mixin are saved on the view.
	const container = opts.container;

	// Saving all of this mixin's settings onto the container, at
	// mixin_customShow.
	container.mixin_customShow = _.extend({}, opts);

	// Extend container with some methods.
	_.extend(container, {
		/**
			 * Shows the provided view in this container view.
			 *
			 * @param {Backbone.View} childView  View to show.
			 */
		customShow: function(childView) {
			const settings = this.mixin_customShow;

			const that = settings.that;

			const container = settings.container;

			// Run beforeCustomShow callback if provided.
			if (settings.beforeCustomShow) {
				settings.beforeCustomShow.call(that, childView);
			}

			const previousChildView = container.currentChildView;

			// Only regions have ensureEl; if this isn't a region, it
			// will already have $el ready, so we don't have to check.
			if (container._ensureElement) {
				container._ensureElement();
			}

			const $container = container.$el;
			const $childView = childView.$el;

			// Append childView to container if it isn't already there,
			// and render that childView if it hasn't been rendered yet.
			if ($childView.closest($container).length < 1) {
				$container.append($childView);
				if (!childView._isRendered) childView.render();
			}

			// Show this childView and hide the other ones.
			$childView.show().siblings().hide();

			// Maintain a reference to the currently shown child view...
			container.currentChildView = childView;

			// ...but remember to clear this reference when someone
			// closes this view.
			container.listenTo(childView, 'close', function() {
				if (container.currentChildView === childView) {
					container.currentChildView = undefined;
				}
				container.stopListening(childView);
			});

			// Run afterCustomShow callback if provided.
			if (settings.afterCustomShow) {
				settings.afterCustomShow.call(that, childView);
			}

			// Trigger customHide and set _isCustomShown=false on previous child view.
			if (previousChildView) {
				previousChildView.trigger('customHide');
				previousChildView._isCustomShown = false;
			}

			// Trigger customShow and set _isCustomShown=true on current child view.
			childView.trigger('customShow');
			childView._isCustomShown = true;
		},

		/**
			 * Hides the provided view if it's shown in the container. If no
			 * view is provided, hides the current view shown.
			 *
			 * @param {Backbone.View} [childView]  View to hide.
			 */
		customHide: function(childView) {
			const settings = this.mixin_customShow;

			const that = settings.that;

			const container = settings.container;

			// Run beforeCustomHide callback if provided.
			if (settings.beforeCustomHide) {
				settings.beforeCustomHide.call(that, childView);
			}

			// If not given a childView, use the current one.
			childView = childView || container.currentChildView;

			// If we still don't have a childView, it means nothing was shown
			// in this region in the first place!
			if (typeof childView === 'undefined') return;

			// If we were told to hide a childView that's currently shown,
			// no longer mark it as the currently shown child view.
			if (container.currentChildView === childView) {
				container.currentChildView = undefined;
			}

			childView.$el.hide();

			// Run beforeCustomHide callback if provided.
			if (settings.afterCustomHide) {
				settings.afterCustomHide.call(that, childView);
			}

			childView.trigger('customHide');
			childView._isCustomShown = false;
		}
	});
};

export default customShow;
