import app from 'app';
import Backbone from 'backbone';
import Marionette from 'marionette';
import View from './dashboard_view';

const Controller = Marionette.Object.extend({
	initialize: function(opts) {
		const model = new Backbone.Model();
		this.view = new View({ model: model });

		app.request('app.services.users.getCurrentUser').done(function(account) {
			model.set('userAccount', account);
		});
	},

	onDestroy: function() {
		this.view.destroy();
		this.view.remove();
	}
});

const create = function(opts) {
	return new Controller(opts);
};

export { create };
