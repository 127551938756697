var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 var hasIpRanges = IsEnabled && typeof IpRanges !== 'undefined' && IpRanges.length; 
__p+='\n\n<h2>IP restriction</h2>\n\n<p>Permit users to use LiveDoc only when they\'re on certain IP addresses.</p>\n\n';
 if (hasIpRanges) { 
__p+='\n    <div class="js-ipRanges" data-mode="readOnly" data-class="enabledOnly">\n        <p class="list-title">Currently restricting access to the following IP ranges:</p>\n        <ul class="monospace">\n            ';
 _.each(IpRanges, function(range) { 
__p+='\n                <li>'+
((__t=( range ))==null?'':__t)+
'</li>\n            ';
 }); 
__p+='\n        </ul>\n    </div>\n';
 } 
__p+='\n\n<p class="js-messages"></p>\n\n<p class="js-controls" data-mode="readOnly">\n    <button class="btn js-enable" data-class="disabledOnly">Enable</button>\n    <button class="btn js-edit" data-class="enabledOnly">Edit</button>\n    <button class="btn js-disable" data-class="enabledOnly">Disable</button>\n</p>\n\n<form class="js-form hidden" data-mode="editOnly">\n    <p>Enter IP ranges, one per line.</p>\n    <p>\n        <textarea class="js-ipRanges-input monospace" cols="40" rows="6" placeholder="IP ranges, one per line">\n            ';
 if (hasIpRanges) {
                _.each(IpRanges, function(range) {
                    print(range + '\n');
                });
            } 
__p+='\n        </textarea>\n    </p>\n    <p class="js-messages"></p>\n    <p>\n        <button class="js-submit btn">\n            ';
 if (IsEnabled) { 
__p+='\n                Save\n            ';
 } else { 
__p+='\n                Enable\n            ';
 } 
__p+='\n        </button>\n        <button class="js-cancel btn btn-link btn-sm">Cancel</button>\n    </p>\n</form>\n';
}
return __p;
};
