import $ from 'jquery';

const ie9 = (function() {
	return $('html').hasClass('ie9');
})();

const ie10 = (function() {
	return 'onpropertychange' in document && !!window.matchMedia;
})();

/** @return {bool} True if pointer-events are supported. */
const cssPointerEvents = (function() {
	const style = document.createElement('a').style;
	style.cssText = 'pointer-events:auto';
	return style.pointerEvents === 'auto' && !ie9 && !ie10;
})();

/** @return {bool} True if drag/drop upload supported. */
const advancedUpload = (function() {
	const div = document.createElement('div');
	return (
		('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
		cssPointerEvents &&
		'FormData' in window &&
		'FileReader' in window
	);
})();

export default {
	ie9: ie9,
	ie10: ie10,
	cssPointerEvents: cssPointerEvents,
	advancedUpload: advancedUpload
};
