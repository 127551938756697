var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';

// @todo move this out of template

var re = /^\s*(past|next)\s*(\d+)\s*(ms|s|min|h|d|w|m|y)\s*$/i;
var resources = {
	'*': 'Any',
	'custom': 'Custom',
	'ms': 'millisecond',
	's': 'second',
	'min': 'minute',
	'h': 'hour',
	'd': 'day',
	'w': 'week',
	'm': 'month',
	'y': 'year'
};
function userFriendly(s) {
	switch (s) {
		case '*':
			return 'Any';
		case 'custom':
			return 'Custom';
		default: 
			var matches = re.exec(s);
			if (matches) {
				var s1 = matches[1];
				s1 = s1.charAt(0).toUpperCase() + s1.slice(1).toLowerCase();
				s2 = +matches[2];
				s3 = resources[matches[3]];
				if (s2 !== 1) s3 += 's';
				return s1 + ' ' + s2 + ' ' + s3;
			} else {
				return s;
			}
	}
}
var selectedRange = data.selectedRange;

__p+='\n\n<div class="context-menu-section-title  tbf-colFilter">Filter by range</div>\n<div class="context-menu-section-content tbf-colFilter tbf-colFilter-0">\n	';
 _.each(data.ranges, function(id) { 
__p+='\n	<span class="context-menu-option checkbox">\n		<label>\n			';

			if (id === 'custom') {
				var r1 = data.from;
				var r2 = data.to;

				if (tools.isNullish(r1)) {
					r1 = '';
				}
				if (tools.isNullish(r2)) {
					r2 = '';
				}
			}
			
__p+='\n	\n			<input type="radio" class="js-rangeoption" name="filter-'+
((__t=( attrName ))==null?'':__t)+
'" value="'+
((__t=( id ))==null?'':__t)+
'" '+
((__t=( id === selectedRange ? 'checked' :'' ))==null?'':__t)+
' />\n			'+
((__t=( userFriendly(id) ))==null?'':__t)+
'\n	\n			';
 if (id === 'custom') { 
__p+='\n	\n				<div class="form-optional">\n					<div class="grid-row">\n						<label class="form-label grid-md-3" for="tbf-columnfilter-range-from">From</label>\n					</div>\n					<div class="grid-row">\n						<div class="grid-md-12 position-container">\n							<input class="js-range form-control" data-range="from" type="text" id="tbf-columnfilter-range-from" value="'+
((__t=( r1 ))==null?'':__t)+
'" />\n							<button class="js-clear btn-reset ldi ldi-close hoverable form-field-icon"></button>\n						</div>\n					</div>\n					<div class="grid-row">\n						<label class="form-label grid-md-3" for="tbf-columnfilter-range-to">To</label>\n					</div>\n					<div class="grid-row">\n						<div class="grid-md-12 position-container">\n							<input class="js-range form-control" data-range="to" type="text" id="tbf-columnfilter-range-to" value="'+
((__t=( r2 ))==null?'':__t)+
'" />\n							<button class="js-clear btn-reset ldi ldi-close hoverable form-field-icon"></button>\n						</div>\n					</div>\n				</div>\n			\n			';
 } 
__p+='\n	\n		</label>\n	</span>\n	';
 }); 
__p+='\n</div>\n';
}
return __p;
};
