var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (typeof formTitle == 'string' && formTitle) { 
__p+='\n	<h2>'+
((__t=( formTitle ))==null?'':__t)+
'</h2>\n';
 } 
__p+='\n\n<div class="grid-row">\n<form class="form-horizontal grid-md-12 grid-lg-8">\n\n	';
 _.each(formFields, function(field) {
		// Skip if not showing this field.
		if (!field.show) return;
		
		var name = field.name;
		var val = model.get(name);
	
__p+='\n\n		<div class="form-group">\n			<label class="form-label grid-md-3 '+
((__t=( field.isRequired ? 'required' : '' ))==null?'':__t)+
'" for="'+
((__t=( name ))==null?'':__t)+
'">'+
((__t=( field.label ))==null?'':__t)+
'</label>\n			<div class="grid-md-6">\n				';
 if (field.isReadOnly) { 
__p+='\n					<span class="form-control-static" data-name="'+
((__t=( name ))==null?'':__t)+
'">'+
((__t=( val ))==null?'':__t)+
'</span>\n\n				';
 } else { 
__p+='\n					<span class="form-control-static" data-class="viewOnly" data-name="'+
((__t=( name ))==null?'':__t)+
'">'+
((__t=( field.isSensitive ? '*****' : val ))==null?'':__t)+
'</span>\n					<input class="form-control" data-class="editOnly" id="'+
((__t=( name ))==null?'':__t)+
'" name="'+
((__t=( name ))==null?'':__t)+
'" type="text" value="'+
((__t=( val ))==null?'':__t)+
'" />\n				';
 } 
__p+='\n			</div>\n		</div>\n\n	';
 }); 
__p+='\n\n	<div class="form-group" data-class="viewOnly">\n		<div class="grid-md-0">\n			<button class="js-edit btn btn-link-tight" data-class="viewOnly">Edit</button>\n		</div>\n	</div>\n	\n	<div class="form-group" data-class="editOnly">\n		<div class="grid-md-6 grid-md-push-3">\n			<button class="js-save btn" data-class="editOnly">Save</button>\n			<button class="js-cancel btn btn-link btn-sm" data-class="editOnly">Cancel</button>\n		</div>\n	</div>\n\n</form>\n</div>';
}
return __p;
};
