import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import tools from 'common/tools/main';
import helpers from '../helpers';
import tplSearch from '../templates/search.html';

const PLUGINNAME = 'Search';

const createSearchRegex = tools.regex.createSearchRegex;

const SearchView = Marionette.ItemView.extend({
	template: tplSearch,
	events: {
		'change .js-search': 'search',
		'keyup .js-search': 'onKeyup'
	},
	serializeData: function() {
		return {};
	},
	search: function(e) {
		this.options.controller.search($(e.target).val());
	},
	onKeyup: function(e) {
		if (e.which === 13) this.search(e);
	}
});

export default {

	name: PLUGINNAME,

	Controller: {

		initialize: function(opts) {
			this.set('search:val', '');
			this.registerFilterMethod('applySearch');
			this.listenTo(opts.collection, 'tm:changedata', this.onModelChangeData);
		},

		onModelChangeData: function(model) {
			this.getModelSearchableValues(model, true);
		},

		getModelSearchableValues: function(model, reset) {
			let vals;

			if (!reset && model.has('tm:search:cache')) {
				vals = model.get('tm:search:cache');
			} else {
				// Gets all values across all displayed columns, joins them
				// into a string. This string is cached, so that future
				// searches can consult this string.
				vals = _.map(this.get('columns'), function(col) {
					return helpers.getColumnValue(model, col);
				}).join(' ');

				model.set('tm:search:cache', vals, { silent: true });
			}

			return vals;
		},

		applySearch: function(matchedModels) {
			const that = this;
			const str = that.get('search:val');

			// If search value, look through all models. Else, return
			// `matchedModels` as is; they all pass an empty search.
			if (str) {
				const re = createSearchRegex(str, false, true, true);

				matchedModels = _.filter(matchedModels, function(model) {
					return re.test(that.getModelSearchableValues(model));
				});
			}

			return matchedModels;
		},

		search: function(str) {
			this.set('search:val', str);
			this.trigger('refilter');
		}
	},

	SubstrateView: {
		onRender: function() {
			this.regionHeaderRight.show(new SearchView(this.options));
		}
	}

};
