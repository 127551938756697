/**
 * Mix into a Model to cache attributes, so you can revert following changes.
 *
 * ##### Added methods
 *
 * - `cache`: Stores current attribute data.
 * - `revert`: Reverts attribute data to what was cached.
 *
 * @module common/mixins/cacheData
 *
 * @param {Backbone.Model} model  The model to add this mixin to. Alias: `that`
 * @param {string[]} [keysToCache]  Names of attributes to cache. If not
 *	provided, caches all attributes.
 */
import _ from 'underscore';
const cacheData = function(opts) {
	// Model taking on the cache functionality.
	const model = opts.model;
	const keys = opts.keysToCache;

	// Extend container with some methods.
	_.extend(model, {

		/**
			 * Saves a copy of the current attribute values.
			 */
		cache: function() {
			model.savedValues = keys ? _.pick(model.attributes, keys) : _.clone(model.attributes);
		},

		/**
			 * If there are values cached, reverts model attributes to those
			 * values.
			 */
		revert: function() {
			if (model.savedValues) model.set(model.savedValues);
		}

	});

	// Do initial cache.
	model.cache();
};

export default cacheData;
