import $ from 'jquery';
import Marionette from 'marionette';
import tplHeader from './templates/tpl_header.html';
import tplSidebar from './templates/tpl_sidebar.html';

const saveToObject = function(obj, key, value, defaultValue) {
	value = typeof value === 'undefined' || value === null ? defaultValue : value;

	if (typeof value !== 'undefined') {
		obj[key] = value;
	}
};

const MetadataView = Marionette.ItemView.extend({
	className: 'w',

	getTemplate: function() {
		return this.options.type === 'sidebar' ? tplSidebar : tplHeader;
	},

	modelEvents: {
		change: 'render'
	},

	setModel: function(newModel) {
		// Stop listening to previous model.
		this.stopListening(this.model);

		// Use new model and listen to it.
		this.model = newModel;
		this.listenTo(this.model, 'change', this.render);

		// Re-render.
		this.render();
	},

	serializeData: function() {
		const data = {};
		const job = this.model;
		const jobType = this.options.jobType;

		saveToObject(data, 'guid', job.get('Id'));
		saveToObject(data, 'displayName', job.get('DisplayName'));
		saveToObject(data, 'description', job.get('Description'));
		saveToObject(data, 'username', job.get('UserName'));
		saveToObject(data, 'dateCreated', new Date(job.get('DateCreated')));
		saveToObject(data, 'dateModified', new Date(job.get('DateModified')));
		saveToObject(data, 'jobTypeDisplayName', jobType && jobType.get('DisplayName'));

		// Template accesses info like `data.description`. This is
		// necessary b/c some keys are used as ids by the browser, e.g.
		// `description` refers to the description <meta> el in IE.
		return { data: data };
	}
});

// @todo Ideally, should pass clientId/jobTypeId/jobId to get the
// metadata view, but since all the modules that need this view already
// have the job model, it's faster to just use the job model.
const api = {
	getMetadataView: function(job, opts) {
		// Add job/templateType to opts.
		opts = opts || {};
		opts.model = job;

		// Return promise for the metadata view.
		return $.Deferred()
			.resolve(new MetadataView(opts))
			.promise();
	}
};

export default api;
