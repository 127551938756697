var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="workspace-content single-column">\n\n	<h1 class="title">'+
((__t=( __editMode ? 'Edit job' : 'Add job' ))==null?'':__t)+
'</h1>\n\n	<div class="grid-row">\n	<form class="form-horizontal grid-md-12 grid-lg-6">\n\n		<div class="region-form"></div>\n		\n		<div class="js-messages"></div>\n		\n		<div class="form-group">\n			<div class="grid-md-6 grid-md-push-3">\n				<button class="js-save btn">'+
((__t=( __editMode ? 'Save job' : 'Create job' ))==null?'':__t)+
'</button>\n			</div>\n		</div>\n\n	</form>\n	</div>\n\n</div>\n';
}
return __p;
};
