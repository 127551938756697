import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import template from './templates/tpl_pagerView.html';
// define(['./templates/tpl_pagerView.html'], function(template) {

/*

[pager controls view]

description:
	paging controls for a paged collection.

requirements:
	* backing collection is a paging collection, which means it
		- triggers "page" events on page change
		- triggers "sizeChange" events when items are added/removed from it
		- has methods:
			* {bool} isViewingFirstPage()
			* {bool} isViewingLastPage()
			* {array} getVisibleItemRange()
			* {bool} hasServerPages()
			* page(string)

*/

// Default page lengths.
const PAGELENGTHS = [10, 25, 50, 100, Infinity];

// For sorting page length menu.
const NONDESC_COMPARATOR = function(a, b) {
	return a > b;
};

const PagerView = Marionette.ItemView.extend({
	template: template,
	tagName: 'div',
	className: 'tb-controls',
	infoTemplate: _.template('<%= firstItem %> to <%= lastItem %> of <%= totalItems %>'),

	events: {
		'click .btn-page-prev': 'pagePrev',
		'click .btn-page-next': 'pageNext',
		'change .js-pageLength': 'onChangePageLength'
	},

	initialize: function(opts) {
		// Set defaults.
		_.extend(opts, { pageLengths: PAGELENGTHS.slice(0) });

		const collection = this.collection = opts.collection;
		const pageLengths = opts.pageLengths;

		// If custom page length used, add it to page length menu.
		if (_.indexOf(pageLengths, collection.pageLength, true) < 0) {
			pageLengths.push(collection.pageLength);
			pageLengths.sort(NONDESC_COMPARATOR);
		}

		this.listenTo(collection, 'page sizeChange', this.render);
	},

	serializeData: function() {
		const collection = this.collection;

		return {
			id: this.cid,
			pagerInfo: this.makePagingInfoString(),
			prevDisabled: collection.isViewingFirstPage() ? 'disabled' : '',
			nextDisabled: collection.isViewingLastPage() && !collection.hasServerPages() ? 'disabled' : '',
			pageLengths: this.options.pageLengths,
			pageLength: collection.pageLength
		};
	},

	makePagingInfoString: function() {
		const collection = this.collection;
		const itemRange = collection.getVisibleItemRange();
		const totalItems = collection.getTotalLength();
		const moreAvailable = collection.hasServerPages();

		return totalItems === 0
			? '0 of 0'
			// +1 for first and last because the range is 0-based.
			: this.infoTemplate({
				firstItem: itemRange.first + 1,
				lastItem: itemRange.last + 1,
				totalItems: totalItems + (moreAvailable ? '+' : '')
			});
	},

	onChangePageLength: function(e) {
		const newPageLen = Number($(e.target).val());
		this.collection.setPageLength(newPageLen);
	},

	pagePrev: function() {
		this.collection.page('prev');
	},

	pageNext: function() {
		this.collection.page('next');
	}
});

export default PagerView;

// });
