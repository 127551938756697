import $ from 'jquery';
import Backbone from 'backbone';
import View from './view';

function init (app) {
	const ViewModel = Backbone.Model.extend({
		enableMfa: function(token) {
			const that = this;

			// If already enabled, means this request was to reset MFA.
			that.set('IsMFAReset', that.get('IsMFAEnabled'));

			app.request('app.services.users.enableMFA',
				that.get('ClientId'),
				that.get('Id'),
				that.get('Secret').secretKey,
				token
			).done(function(data) {
				that.set('IsMFAEnabled', true);
				that.trigger('mfaEnabled', 'success');
			}).fail(function(err) {
				that.trigger('mfaEnabled', 'failed', err);
			});
		},
		disableMfa: function() {
			const that = this;
			app.request('app.services.users.disableMFA',
				that.get('ClientId'),
				that.get('Id')
			).done(function(data) {
				that.set('IsMFAEnabled', false);
				that.trigger('mfaDisabled', 'success');
			}).fail(function(err) {
				that.trigger('mfaDisabled', 'failed', err);
			});
		}
	});

	return {
		getToggleMfaView: function(clientId, userId) {
			return $.when(
				app.request('app.services.users.getAccount', clientId, userId),
				app.request('app.services.users.generateMFASecret')
			)
				.then(function(user, secret) {
					const model = new ViewModel(user.attributes);
					model.set('Secret', secret);
					return new View({ model: model });
				});
		}
	};
}

export default init;
