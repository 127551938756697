import _ from 'underscore';
import Backbone from 'backbone';
import helpers from '../helpers';

/*
columnfilter:state = { attr1: {}, attr2: {}, ...}  //per column
columnsort:state = { attr1: {}, attr2: {}, ...} //per column
pager:hasPrev
pager:hasNext
pager:current
pager:firstItem
pager:lastItem
pager:showing/pageModels
*/

const Controller = Backbone.Model.extend({
	tm_classId: 'Controller',
	initialize: function(opts) {
		this.set({ 'id': this.cid, 'filter:methods': [] }, { silent: true });

		this.doPaging();
		this.on('change:pageCurrent', this.doPaging);
		this.on('change:pageLength', this.resetPaging);
		this.on('refilter', this.refilter);

		const collection = opts.collection;
		this.listenTo(collection, 'sort tm:filtered', this.resetPaging);
		this.listenTo(collection, 'add remove reset', this.refilter);
		this.listenTo(collection, 'change', this.onModelChange);

		this.refilter(); // initial filter
	},
	resetPaging: function() {
		this.set('pageCurrent', 0, { silent: true });
		this.doPaging();
	},
	pagePrev: function() {
		if (this.get('hasPrevPage')) {
			let p = this.get('pageCurrent');
			this.set('pageCurrent', --p);
		}
	},
	pageNext: function() {
		if (this.get('hasNextPage')) {
			let p = this.get('pageCurrent');
			this.set('pageCurrent', ++p);
		}
	},
	doPaging: function() {
		const p = this.get('pageCurrent');
		const filtered = this.get('collection').where({ 'tm:match': true });
		const pageLength = this.get('pageLength');
		const pageLast = Math.ceil(filtered.length / pageLength - 1);
		const itemFirst = p * pageLength;
		const itemLast = Math.min(itemFirst + pageLength, filtered.length);

		this.set({
			hasPrevPage: p > 0,
			hasNextPage: p < pageLast,
			pageFirstItem: itemFirst,
			pageLastItem: itemLast,
			pageModels: filtered.slice(itemFirst, itemLast),
			filteredTotal: filtered.length
		});

		this.trigger('page');
	},
	refilter: function() {
		const that = this;
		const collection = this.get('collection');
		let matchedModels = collection.models;
		const methodNames = this.get('filter:methods');

		// Call each filter method, thereby narrowing down matchedModels
		// to just the set of models passing all filters.
		let i = 0;
		const n = methodNames.length;
		for (; i < n; i++) {
			matchedModels = that[methodNames[i]](matchedModels);
		}

		// Store ids of matched models in hash. For each model, will
		// determine whether it passed filters based on whether its id is
		// in this hash. Hash lookup is faster than array lookup!
		const matchedIds = helpers.pluckToObject(matchedModels, 'cid');

		// Update each model re: whether it passed all the filters.
		const silent = { silent: true };
		collection.each(function(model) {
			const match = matchedIds[model.cid];
			model.set('tm:match', !!match, silent);
		});

		// Let everyone know collection is ready to be re-rendered.
		collection.trigger('tm:filtered');
	},
	registerFilterMethod: function(methodName) {
		this.get('filter:methods').push(methodName);
	},
	onModelChange: function(model, opts) {
		// If someone said { 'tm:refilter': false }, then don't refresh.
		if (helpers.hasModelAttrsChanged(model) && opts['tm:refilter'] !== false) {
			this.refilter();
			model.collection.trigger('tm:changedata', model, opts);
		}
	},

	/**
	 Inserts one or more columns into the table.

	 @param {array<object>|object} cfgs  An array of column config objects,
	 or a single column config object.
	 @param {object} [where]  Specify where to insert these columns using
	 only one of the properties listed below. If this param is not provided,
	 new columns are added at the end (right) side of the table.
	 * at {number}     Position to insert at.
	 * before {string} Id of existing column to insert in front of.
	 * after {string}  Id of existing column to insert in after.
	 @fires "tm:redraw"
	 */
	addColumns: function(cfgs, where) {
		const columns = this.get('columns');
		let i;

		if (typeof where === 'object') {
			if (typeof where.at === 'number') {
				// Insert at a particular position.
				i = where.at;
			} else if (where.before || where.after) {
				// Insert before/after a specific column.
				const colId = where.before || where.after;

				i = _.findIndex(columns, function(c) {
					return c.id === colId;
				});

				if (where.after && i > 0) i++;
			}
		}

		// Ensure that these columns don't already exist in this grid.
		cfgs = _.reject(_.isArray(cfgs) ? cfgs : [cfgs], function(c) {
			return _.where(columns, { id: c.id }).length;
		});

		if (cfgs.length) {
			if (typeof i === 'undefined' || i < 0) {
				// If no specified column to insert in front of, or specified
				// column not found, append new columns.
				Array.prototype.push.apply(columns, cfgs);
			} else {
				// Insert columns in specified position.
				Array.prototype.splice.apply(columns, [i, 0].concat(cfgs));
			}
		}

		// Let everyone the number of columns have changed.
		this.trigger('tm:redraw');
	},

	/**
	 Removes one or more columns from the table.

	 @param {array<string>|string} ids  Set of column ids, or single id.
	 @fires "tm:redraw"  If columns were actually removed.
	 */
	removeColumns: function(ids) {
		function _removeColumn(cols, id) {
			const i = _.findIndex(cols, function(col) {
				return col.id === id;
			});

			if (i > -1) {
				cols.splice(i, 1);
				return true;
			}
		}

		const columns = this.get('columns');
		let changed;

		if (_.isArray(ids)) {
			// Set of ids
			let n = ids.length;
			while (n--) {
				changed = _removeColumn(columns, ids[n]) || changed;
			}
		} else {
			// Single id
			changed = _removeColumn(columns, ids);
		}

		// Let everyone the number of columns have changed.
		if (changed) this.trigger('tm:redraw');
	}
});

export default Controller;
