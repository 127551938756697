import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import app from 'app';
import Grid from 'common/modules/table/main';
import LoadingView from 'appModules/loading_view';
import View from './documents_view';

const tplPdf = _.template('<a data-tool="downloadPdf" class="ldi ldi-pdf x-large" href="<%= model.get(\'_PreviewUri\') %>" target="_blank"></a>');

function createGridConfig(documents, meta) {
	// Only display columns that are supposed to be visible.
	let columns = _.pick(meta, function(col) {
		return typeof col.isVisible === 'boolean' ? col.isVisible : col.IsVisible;
	});
	columns = _.sortBy(columns, function(col) {
		return col.displayOrder || col.DisplayOrder || 999;
	});

	const regexIds = /(key|id|\bisin|\bwkn)$/i;
	const regexShortNames = /(key|id|wkn|locale|language|currency)\W*$/i;
	const regexLongNames = /(portfolio|fund|display)\s*name/i;

	// Patch columns with requisite options.
	_.each(columns, function(col) {
		const id = $.trim(col.name || col.Name);

		col.id = id;
		col.displayName = col.displayName || col.DisplayName || id;
		col.type = col.type || col.Type || null;

		// Heuristically set column options.
		let width = 200;

		if (regexIds.test(id)) {
			col.hasColumnFilter = col.hasColumnFilter || false;
		}

		if (regexShortNames.test(id)) {
			width = 100;
		} else if (regexLongNames.test(id)) {
			width = 350;
		} else if (id.endsWith('AsAtDate')) {
			col.type = col.type || 'date';
			col.filterType = 'range';
			width = 120;
		}

		col.width = typeof col.width === 'number' ? col.width : width;
	});

	// Add in PDF column.
	columns.unshift({
		id: '_PreviewUrl',
		displayName: 'PDF',
		width: 50,
		hasColumnFilter: false,
		hasColumnSort: false,
		tdViewMixin: {
			template: tplPdf
		}
	});

	return { columns: columns, collection: documents };
}

const Controller = Marionette.Object.extend({

	initialize: function(workspaceController) {
		const that = this;
		const workspaceModel = workspaceController.model;
		const job = this.job = workspaceModel.get('Job');
		const view = this.view = new View();

		// Start fetching documents.
		this.docsPromise = job.getDocuments();

		this.listenTo(view, 'show', function() {
			app.request('app.services.jobs.getMetadataView', job, { jobType: workspaceModel.get('JobType') }).done(function(metadataView) {
				view.regionHeader.show(metadataView);
			});

			view.regionBody.show(new LoadingView({ message: 'Loading your documents...' }));

			that.makeGrid().done(function(grid) {
				view.regionBody.show(grid.view);
			});
		});
	},

	makeGrid: function() {
		const that = this;

		return that.docsPromise.then(function(documents) {
			const opts = createGridConfig(documents, that.job.get('DocumentAttributesMeta'));
			const grid = new Grid(opts);
			const silent = { 'tm:refilter': false };

			// Monitor UI selection and mirror it on the 'selected' attribute.
			function updateSelection(model) {
				model.set('selected', model.get('tm:selected'), silent);
			}

			that.listenTo(documents, 'tm:selectionchange', function(changed) {
				if (_.isArray(changed)) {
					_.each(changed, updateSelection);
				} else {
					updateSelection(changed);
				}
			});

			return grid;
		});
	},

	onDestroy: function() {
		if (this.view) this.view.destroy();
	}

});

const create = function(workspaceController) {
	return new Controller(workspaceController);
};

export { create };
