import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import template from './templates/tpl_publisher.html';

// Template strings would be great right now.

const tplCheckboxOrRadio = _.template('<div class="<%= data.type %>">' +
	'<label>' +
	'<input type="<%= data.type %>" ' +
	'name="<%= data.name %>" ' +
	'value="<%= _.isUndefined(data.value) ? "": data.value %>" ' +
	'<%= data.selected ? "checked" : "" %>' +
	'<%= _.isUndefined(data.uncheckedValue) ? "" : "data-unchecked-value=\'" + data.uncheckedValue + "\'" %>' +
	'/>' +
	'<%= data.label %>' +

	'<% if (typeof data.note === "string") { %>' +
	'<span class="form-control-note"><%= data.note %></span>' +
	'<% } %>' +

	'<% if (typeof data.optionalField === "object") { %>' +

	'<div class="form-optional">' +
	'<% var tpl = templates[data.optionalField.type];' +
	'tpl ? print(tpl({ data: data.optionalField, templates: templates })) : print("no template found for " + item.type); %>' +
	'</div>' +

	'<% } %>' +

	'</label>' +
	'</div>');

const TEMPLATES = {
	_looper: function(data) {
		const section = data.section;
		let html;

		if (section.body && section.body.length) {
			html = _.map(section.body, function(item) {
				const tpl = data.templates[item.type];
				if (tpl) {
					return tpl({ data: item, templates: data.templates });
				} else {
					return `no template found for ${item.type}`;
				}
			}).join('');
		} else if (typeof section.emptyMessage === 'string') {
			html = `<p class="no-data">${section.emptyMessage}</p>`;
		}

		return html;
	},
	subsection: _.template('<div class="flyout-content-section js-subsection-<%= data.name %>">' +
		'<h3><%= data.heading %></h3>' +
		'<div class="flyout-content-section-body"><%= templates._looper({ section: data, templates: templates }) %></div>' +
		'</div>'),
	checkbox: tplCheckboxOrRadio,
	radio: tplCheckboxOrRadio,
	sortablecheckbox: _.template('<div class="checkbox">' +
		'<label>' +
		'<input type="checkbox" name="<%= data.checkboxName %>" value="<%= _.isUndefined(data.value) ? "" : data.value %>" <%= data.selected ? "checked" : "" %>/>' +
		'<%= data.label %>' +
		'</label>' +
		'<span class="switch-wrapper">' +
		'<span class="switch-label-left">A-Z</span>' +
		'<span class="switch">' +
		'<input class="switch-checkbox" ' +
		'id="<%= data.sortName %>_<%= data.value %>"' +
		'type="checkbox" ' +
		'name="<%= data.sortName %>" ' +
		'value="<%= _.isUndefined(data.value) ? "" : data.value %>" ' +
		'<%= data.sortChecked ? "checked" : "" %>' +
		'/>' +
		'<label class="switch-toggle" for="<%= data.sortName %>_<%= data.value %>"></label>' +
		'</span>' +
		'<span class="switch-label-right">Z-A</span>' +
		'</span>' +
		'</div>'),
	password: _.template('<input type="password" name="<%= data.name %>" placeholder="<%= _.isUndefined(data.placeholder) ? "" : data.placeholder %>" autocomplete="off" />'),
	text: _.template('<input type="text" name="<%= data.name %>" value="<%= _.isUndefined(data.value) ? "" : data.value %>" placeholder="<%= _.isUndefined(data.placeholder) ? "" : data.placeholder %>" />'),
	select: _.template('<select name="<%= data.name %>">' +
		'<% _.each(data.options, function(o) { %>' +
		'<option value="<%= o.value %>"><%= o.label %></option>' +
		'<% }); %>' +
		'</select>')
};

const View = Marionette.ItemView.extend({
	template: template,

	initialize: function() {
		const model = this.model;
		this.listenTo(model.get('GroupByAttributes'), 'reset', this.render);
		this.listenTo(model.get('SortByAttributes'), 'reset', this.render);
	},

	onRender: function() {
		this.$el.find('.js-publish').tmbutton();
		this.updateItemsToPublish();
	},

	templateHelpers: function() {
		const model = this.model;
		const selectedOutputType = model.get('OutputType');
		const pkgNameDefault = model.get('DefaultPackageDisplayName');
		const pkgName = model.get('PackageDisplayName');
		const filenameDefault = model.get('PredefinedDocumentFileName');
		const filename = model.get('NamingConvention');

		const data = {
			templates: TEMPLATES,
			sections: [
				{
					name: 'outputType',
					heading: 'Output type',
					open: true,
					body: _.map(model.get('OutputTypes'), function(item) {
						return {
							type: 'radio',
							name: 'OutputType',
							value: item,
							label: item,
							// TODO: eqeqeq?
							// eslint-disable-next-line eqeqeq
							selected: selectedOutputType == item
						};
					})
				},
				{
					name: 'packageName',
					heading: 'Document set name',
					open: true,
					body: [
						{
							type: 'radio',
							name: 'PackageDisplayName',
							value: pkgNameDefault,
							label: 'Use default',
							selected: true
						},
						{
							type: 'radio',
							name: 'PackageDisplayName',
							// TODO: eqeqeq?
							// eslint-disable-next-line eqeqeq
							value: pkgName == pkgNameDefault ? '' : pkgName,
							label: 'Use a custom name',
							optionalField: {
								type: 'text',
								name: 'PackageDisplayName',
								// TODO: eqeqeq?
								// eslint-disable-next-line eqeqeq
								value: pkgName == pkgNameDefault ? '' : pkgName,
								placeholder: 'Custom name'
							}
						}
					]
				},
				{
					name: 'group',
					heading: 'Group documents by',
					body: model.get('GroupByAttributes').map(function(item) {
						return {
							type: 'checkbox',
							name: 'GroupByAttributes',
							value: item.get('Name'),
							label: item.get('Value'),
							selected: item.get('Selected')
						};
					}),
					emptyMessage: 'There are no attributes to group by.'
				},
				{
					name: 'sort',
					heading: 'Sort documents by',
					hidden: true,
					body: model.get('SortByAttributes').map(function(item) {
						return {
							type: 'sortablecheckbox',
							checkboxName: 'SortByAttributes',
							value: item.get('Name'),
							label: item.get('Value'),
							selected: item.get('Selected'),
							sortName: 'SortDirection',
							sortChecked: item.get('Direction') === 'Descending'
						};
					}),
					emptyMessage: 'There are no attributes to sort by.'
				},
				{
					name: 'filename',
					heading: 'Document filename format',
					body: [
						{
							type: 'radio',
							name: 'NamingConvention',
							value: filenameDefault.Name,
							label: `Use default: %${filenameDefault.Name}%`,
							selected: true,
							note: `e.g. ${filenameDefault.Example}`
						},
						{
							type: 'radio',
							name: 'NamingConvention',
							value: filename,
							label: 'Use a custom name',
							optionalField: {
								type: 'text',
								name: 'NamingConvention',
								value: filename,
								placeholder: 'Custom name'
							}
						}
					]
				},
				{
					name: 'pdfOpt',
					heading: 'File optimization',
					body: [
						{
							type: 'checkbox',
							name: 'PdfOptimizationLevel',
							value: 1,
							uncheckedValue: 0,
							label: 'Optimize files',
							optionalField: {
								type: 'select',
								name: 'PdfOptimizationLevel',
								options: [
									{ label: 'Low', value: 1 },
									{ label: 'Medium', value: 2 },
									{ label: 'High', value: 3 }
								]
							}
						}
					]
				},
				{
					name: 'security',
					heading: 'Security',
					body: [
						{
							type: 'subsection',
							name: 'encryption',
							heading: 'Encryption',
							body: [
								{
									type: 'select',
									name: 'PdfSecurity_EncryptionAlgorithm',
									options: [
										{ label: 'Low', value: 'Low' },
										{ label: 'AESV2', value: '128-bit AES' },
										{ label: 'AESV3', value: '256-bit AES' }
									]
								}

							]
						},
						{
							type: 'subsection',
							name: 'password',
							heading: 'Password protection',
							body: _.map([
								{ id: 'PdfSecurity_Password', label: 'PDF user password' },
								{ id: 'PdfSecurity_OwnerPassword', label: 'PDF owner password' },
								{ id: 'ZipSecurity_Password', label: 'ZIP password' }
							], function(item) {
								item.type = 'checkbox';
								item.name = item.id;
								item.value = model.get(item.id);
								item.optionalField = {
									type: 'password',
									name: item.id,
									placeholder: item.label
								};
								return item;
							})
						},
						{
							type: 'subsection',
							name: 'restrictions',
							heading: 'Document restrictions',
							body: _.map([
								{ id: 'DisallowPrinting', label: 'Printing' },
								{ id: 'DisallowChaningDocument', label: 'Changing the document' },
								{ id: 'DisallowDocumentAssembly', label: 'Document assembly' },
								{ id: 'DisallowContentCopying', label: 'Content copying' },
								{
									id: 'DisallowContentCopyingForAccessibility',
									label: 'Content copying for accessibility'
								},
								{ id: 'DisallowPageExtraction', label: 'Page extraction' },
								{ id: 'DisallowCommenting', label: 'Commenting' },
								{ id: 'DisallowFillingOfFormFields', label: 'Filling of form fields' },
								{ id: 'DisallowSigning', label: 'Signing' },
								{ id: 'DisallowCreationOfTemplatePages', label: 'Creation of template pages' }
							], function(item) {
								item.type = 'checkbox';
								item.name = `PdfSecurity_${item.id}`;
								item.value = 1;
								return item;
							})
						}
					]

				}
			]
		};

		if (model.get('HasSelectAllOption')) {
			data.sections.unshift({
				name: 'itemsToPublish',
				heading: 'Documents to publish',
				open: true,
				body: [
					{
						type: 'radio',
						name: 'SelectAll',
						label: 'Selected documents',
						selected: !model.get('SelectAll')
					},
					{
						type: 'radio',
						name: 'SelectAll',
						value: 'true',
						label: 'All documents',
						selected: !!model.get('SelectAll')
					}
				]
			});
		}

		return data;
	},

	modelEvents: {
		'published': 'onModelPublishComplete',
		'change': 'clearMessages',
		'change:SelectAll change:NumberOfSelected': 'updateItemsToPublish'
	},

	events: {
		'click .js-publish': 'onClickPublish',
		'click .js-close': 'onClickClose',
		'click .flyout-content-section-toggle-area': 'onClickSectionToggle',
		'change input[name="GroupByAttributes"], input[name="SortByAttributes"]': 'onChangeGroupSortAttrs',
		'change input[name="SortDirection"]': 'onChangeSortDirection',
		'change input[name="OutputType"]': 'onChangeOutputTypeToggleSortSection',
		'change input[type="checkbox"]': 'onChangeCheckbox',
		'change input[type="radio"], select': 'onChangeSingleValue',
		'keyup input[type="text"], input[type="password"]': 'onChangeSingleValue'
	},

	/**
	 Returns true if this is not an optional field or if it's an enabled
	 optional field.

	 @param {$} $el
	 @returns {bool}
	 */
	isActiveField: function($el) {
		const $opt = $el.closest('.form-optional');
		return !$opt.length || $opt.siblings('input').prop('checked');
	},

	onChangeSingleValue: function(e) {
		const $el = $(e.target);

		if (this.isActiveField($el)) {
			const name = $el.attr('name');
			const val = $el.val();
			this.model.set(name, val);
		}
	},

	onChangeCheckbox: function(e) {
		const $el = $(e.target);

		if (this.isActiveField($el)) {
			const name = $el.attr('name');
			const checked = $el.prop('checked');
			const val = checked ? $el.val() : $el.attr('data-unchecked-value');
			this.model.set(name, val);
		}
	},

	onChangeGroupSortAttrs: function(e) {
		// Don't fall through to other listeners
		e.stopPropagation();
		e.stopImmediatePropagation();

		const $el = $(e.target);
		const section = $el.attr('name');
		const attr = $el.val();
		const checked = $el.prop('checked');
		const item = this.model.get(section).findWhere({ Name: attr });
		if (item) item.set('Selected', checked);
	},

	onChangeSortDirection: function(e) {
		// Don't fall through to other listeners
		e.stopPropagation();
		e.stopImmediatePropagation();

		const $el = $(e.target);
		const attr = $el.val();
		const checked = $el.prop('checked');
		const item = this.model.get('SortByAttributes').findWhere({ Name: attr });
		const direction = checked ? 'Descending' : 'Ascending';
		item.set('Direction', direction);
	},

	onModelPublishComplete: function(state, msg) {
		const $el = this.$el;
		const msgHtml = state === 'success'
			? '<span class="msg success">Publication submitted!<br>To check on the progress of your publications, visit the job monitor.</span>'
			: `<span class="msg error">${msg}</span>`;

		$el.find('.js-publish').tmbutton('state', 'ready');
		$el.find('.publish-header').append(`<p class="js-message">${msgHtml}</p>`);
	},

	clearMessages: function() {
		this.$('.js-message').remove();
	},

	updateItemsToPublish: function() {
		const model = this.model;
		const isSelectAll = model.get('SelectAll');
		const numSelected = model.get('NumberOfSelected');
		const msg = isSelectAll ? 'All documents'
			: numSelected === 1 ? '1 document'
				: `${numSelected} documents`;
		this.$el.find('.js-itemsToPublish').text(msg);
	},

	onClickSectionToggle: function(e) {
		$(e.target).closest('.flyout-content-section').toggleClass('open');
	},

	onChangeOutputTypeToggleSortSection: function(e) {
		const $el = $(e.target);
		// TODO: eqeqeq?
		// eslint-disable-next-line eqeqeq
		const showSortSection = $el.val() == 'Consolidated';
		const $sortSection = $el.closest('form').find('.js-section-sort');
		$sortSection.toggleClass('hidden', !showSortSection);
	},

	onClickClose: function(e) {
		e.preventDefault();
		this.trigger('request:close');
	},

	onClickPublish: function(e) {
		e.preventDefault();
		this.$el.find('.js-publish').tmbutton('state', 'busy');
		this.model.publish();
	},

	onDestroy: function() {
		this.$('.js-publish').tmbutton('destroy');
	}

});

export default View;
