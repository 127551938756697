import $ from 'jquery';
import Backbone from 'backbone';
import View from './view';

function init(app) {
	return {
		getGroupListView: function(clientId) {
			const collection = new Backbone.Collection();
			const view = new View({
				model: new Backbone.Model({ NewSecurityGroupName: '' }),
				collection: collection
			});

			view.on('childview:delete', function(obj1, obj2) {
				const model = obj2.model;
				app.request('app.services.users.removeSecurityGroup', clientId, model.get('Id'));
			});

			view.on('childview:edit', function(obj1, obj2) {
				view.trigger('editGroup', obj2.model);
			});

			function fetchGroups() {
				app.request('app.services.users.getSecurityGroups', clientId).done(function(groups) {
					collection.reset(groups.models);
				});
			}

			fetchGroups();

			// When groups are added/removed, refresh list of groups.
			app.vent.on('services:users:group:added', fetchGroups);
			app.vent.on('services:users:group:removed', fetchGroups);

			return $.Deferred()
				.resolve(view)
				.promise();
		}
	};
}

export default init;
