var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<td>\n	'+
((__t=( Message.Text ))==null?'':__t)+
'\n	<br><span class="cell-caption">'+
((__t=( _.isDate(Timestamp) ? Timestamp.format('UiSortable') : '' ))==null?'':__t)+
'</span>\n</td>';
}
return __p;
};
