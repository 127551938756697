import Marionette from 'marionette';
import Models from './models';
import Views from './views';

// @todo hardcoded...
const notificationStatuses = {
	Successful: 'success',
	Failed: 'warning'
};

const NotificationManager = Marionette.Object.extend({
	initialize: function(opts) {
		opts = typeof opts === 'object' ? opts : {};
		this.options = opts;
		this.app = opts.app;

		// All notifications (panel + bubbles)
		this.notifications = new Models.Notifications();
		// this.autoRefreshDateTimer;
		/** setInterval id {int}. */

		// Create views.
		this.view = new Views.NotificationPanel({ collection: this.notifications });
		this.bView = new Views.NotificationBubbleList({ collection: this.notifications });
		this.countView = new Views.NotificationCount({ collection: this.notifications });
	},

	render: function() {
		this.view.render();
		this.bView.render();
		this.countView.render();
	},

	publish: function(data) {
		if (!data) {
			throw new Error('Not enough data to publish notification');
		} else if (typeof data === 'string') {
			data = { title: data };
		} else if (typeof data !== 'object') {
			throw new Error('Invalid notification data');
		}

		// Is this notification linked to an existing one?
		const correlId = data.correlationId;
		const notif = this.notifications.find(function(model) {
			return model.get('correlationId') === correlId;
		});

		if (notif) {
			// If so, update the existing notification
			notif.update(data);
		} else {
			// Else, add a new notification
			this.notifications.add(data);
		}
	},

	togglePanel: function() {
		this.view.toggle();
	},

	/**
	 Listens to particular events on a vent and uses those to generate
	 notifications.
	 */
	subscribe: function(vent, eventName) {
		this.listenTo(vent, eventName, this._onHeardEvent);
	},

	unsubscribe: function(vent, eventName) {
		this.stopListening(vent, eventName);
	},

	_onHeardEvent: function(e) {
		// Not my client
		if (e.ClientId !== this.app.session.get('clientId')) return;

		const eventBody = e.Message;

		// No content to display, or is a log and therefore irrelevant
		if (typeof eventBody !== 'object' || eventBody.Type === 'Log') return;

		if (eventBody.Type === 'Progress') {
			// A progress event! Is it related to a notification we have?
			// If so, update that notification.
			this.publish({
				original: e,
				correlationId: e.CorrelationId,
				bubble: false,
				body: eventBody.Text || eventBody.Type,
				progress: eventBody.pct || eventBody.Percentage || 0
			});
		} else {
			// A regular notification
			this.publish({
				original: e,
				correlationId: e.CorrelationId,
				body: eventBody.Text || eventBody.Type,
				icon: notificationStatuses[eventBody.Status]
			});
		}
	},

	onDestroy: function() {
		this.view.destroy();
		this.bView.destroy();
		this.countView.destroy();

		// Unsubscribe from all vents.
		this.unsubscribe();
	}
});

export default NotificationManager;
