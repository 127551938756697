import Marionette from 'marionette';
import tpl from '../templates/base-emptyRow.html';

const EmptyRowView = Marionette.ItemView.extend({
	tm_classId: 'EmptyRowView',
	tagName: 'tr',
	className: 'tbf-row-empty',
	template: tpl,
	serializeData: function() {
		return { options: this.options };
	}
});

export default EmptyRowView;
