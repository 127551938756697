import app from 'app';
import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';

export const Model = Backbone.Model.extend({
	initialize: function() {
		const that = this;
		const clientId = that.get('ClientId');
		const jobTypeId = that.get('JobTypeId');

		that.set('id', Model.createId(clientId, jobTypeId));
		that.set('IsNew', !jobTypeId);
		that.set('__dataFetch', that.loadData());
	},

	loadData: function() {
		const d = $.Deferred();
		const that = this;
		const clientId = that.get('ClientId');
		const jobTypeId = that.get('JobTypeId');

		if (jobTypeId) {
			app.request('app.services.jobTypes.get', clientId, jobTypeId).done(function(jobType) {
				that.set('JobType', jobType);
				d.resolve(that);
			});
		} else {
			d.resolve(that);
		}

		return d.promise();
	},

	getCurrentRoute: function() {
		return this.makeRoute();
	},

	makeRoute: function(toolName, subtoolName) {
		const that = this;
		toolName = toolName || that.get('CurrentToolName');
		subtoolName = subtoolName || that.get('CurrentSubtoolName');

		return app.router.makeJobTypeRoute(that.get('ClientId'), that.get('JobTypeId'), toolName, subtoolName);
	}
});

Model.createId = function(clientId, jobTypeId) {
	if (!(_.isString(clientId) && clientId !== '')) {
		throw new Error('clientId is invalid');
	}

	if (_.isString(jobTypeId) && jobTypeId !== '') {
		return `${clientId}/${jobTypeId}`;
	} else {
		return `${clientId}/new`;
	}
};
