import $ from 'jquery';
import FormModel from 'common/modules/formView/model';
import View from './view';

function init (app) {
	const ViewModel = FormModel.extend({
		defaults: {
			GroupName: ''
		},

		saveChanges: function() {
			const that = this;

			if (!that.isValid()) return;

			app.request('app.services.users.createSecurityGroup',
				that.get('ClientId'),
				that.get('GroupName')
			).done(function() {
				that.set('GroupName', '', { silent: true }); // reset form
				that.trigger('saved');
			}).fail(function() {
				that.trigger('failed', 'Could not add group.');
			});
		},

		validateData: function(attrs, options) {
			const errors = {};

			if (attrs.GroupName === '') {
				errors.GroupName = 'You must specify a group name.';
			}

			return errors;
		}
	});

	return {
		getAddGroupView: function(clientId) {
			const viewModel = new ViewModel({ ClientId: clientId });
			return $.Deferred().resolve(new View({ model: viewModel })).promise();
		}
	};
}

export default init;
