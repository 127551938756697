import _ from 'underscore';

import controller from './controller';
import thView from './thView';
import tdView from './tdView';
import rowView from './rowView';
import emptyRowView from './emptyRowView';
import theadView from './theadView';
import tbodyView from './tbodyView';
import substrateView from './substrateView';

const imports = [
	controller,
	thView,
	tdView,
	rowView,
	emptyRowView,
	theadView,
	tbodyView,
	substrateView
];

export default _.reduce(imports, function(memo, Cls) {
	memo[Cls.prototype.tm_classId] = Cls;
	return memo;
}, {});
