import $ from 'jquery';
import Backbone from 'backbone';
import Marionette from 'marionette';

import tplLayout from './templates/tpl_layout.html';
import tplTable from './templates/tpl_table.html';
import tplTableRow from './templates/tpl_tableRow.html';
import tplEmptyRow from './templates/tpl_emptyRow.html';

const TableRowView = Marionette.LayoutView.extend({
	template: tplTableRow,
	tagName: 'tr',
	regions: {
		region_userinfo: '.js-userinfo',
		region_groups: '.js-groups'
	},
	events: {
		'click .js-deleteUser': 'onClickDelete'
	},
	initialize: function(opts) {
		this.app = opts.app;
	},
	onClickDelete: function(e) {
		const model = this.model;
		const collection = model.collection;
		$(e.target).closest('.js-deleteUser').tmbutton('state', 'busy');

		this.app.request('app.services.users.removeAccount', model.get('ClientId'), model.get('Id')).done(function() {
			collection.remove(model);
		});
	},
	onRender: function() {
		const that = this;
		const model = that.model;
		const clientId = model.get('ClientId');
		const userId = model.get('Id');

		this.$el.find('.js-deleteUser').tmbutton();

		this.app.request('app.services.users.getShortDisplayView', clientId, userId).done(function(v) {
			if (v && that.region_userinfo) that.region_userinfo.show(v);
		});

		this.app.request('app.services.users.getUserGroupListView', clientId, userId).done(function(v) {
			if (v && that.region_groups) that.region_groups.show(v);
		});
	},
	onDestroy: function() {
		this.$('.js-deleteUser').tmbutton('destroy');
	}
});

const EmptyRowView = Marionette.ItemView.extend({
	template: tplEmptyRow,
	tagName: 'tr'
});

const TableCompositeView = Marionette.CompositeView.extend({
	template: tplTable,
	tagName: 'table',
	className: 'table table-striped inventory-table',
	childViewContainer: 'tbody',
	childView: TableRowView,
	emptyView: EmptyRowView,
	childViewOptions () {
		return { app: this.options.app };
	},
	collectionEvents: {
		'update': 'render',
		'reset': 'render'
	}
});

const Layout = Marionette.LayoutView.extend({
	template: tplLayout,

	regions: {
		region_createuser: '#region-createuser',
		region_table: '#region-userList-table'
	},

	initialize: function(opts) {
		this.app = opts.app;
		const collection = this.collection = new Backbone.Collection();
		this.refreshList();
		this.tableView = new TableCompositeView({ app: this.app, collection: collection });
		this.listenTo(this.app.vent, 'services:users:account:added', this.refreshList);
	},

	refreshList: function() {
		const collection = this.collection;
		const clientId = this.options.clientId;

		this.app.request('app.services.users.getProfiles', clientId).done(function(users) {
			collection.reset(users.models);

			// Update each model so it links to this collection instead of
			// its original one.
			collection.each(function(model) {
				model.collection = collection;
			});
		});
	},

	onRender: function() {
		const that = this;
		const clientId = that.options.clientId;

		that.region_table.show(that.tableView);

		this.app.request('app.services.users.getAddUserView', clientId).done(function(createUserView) {
			that.region_createuser.show(createUserView);
		});
	}

});

export default Layout;
// });
