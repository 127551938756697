var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<button class="btn-close ldi ldi-close hoverable-light"></button>\n<span class="ldi ldi-'+
((__t=( marker.shape ))==null?'':__t)+
' bl-set-marker bl-set-color-'+
((__t=( marker.color ))==null?'':__t)+
'"></span>\n<div class="bl-set-data">\n	<span class="bl-set-name">'+
((__t=( DisplayName || 'Unnamed' ))==null?'':__t)+
'</span>\n	<div class="bl-set-details">\n		<span class="bl-set-detail">'+
((__t=( Id ))==null?'':__t)+
'</span>\n		\n		';
 if (typeof Date !== 'undefined') { 
__p+='\n		<span class="bl-set-detail">'+
((__t=( Date.format('UiSortable') ))==null?'':__t)+
'</span>\n		';
 } 
__p+='	\n		\n		';
 if (typeof UserName !== 'undefined') { 
__p+='\n		<span class="bl-set-detail">'+
((__t=( UserName ))==null?'':__t)+
'</span>\n		';
 } 
__p+='	\n		\n		<span class="bl-set-detail">\n			';
 if (DownloadUri) { 
__p+='\n				<a href="'+
((__t=( DownloadUri ))==null?'':__t)+
'" target="_blank">'+
((__t=( NumberOfDocuments ))==null?'':__t)+
' documents <span class="ldi ldi-download"></span></a>\n			';
 } else { 
__p+='	\n				'+
((__t=( NumberOfDocuments ))==null?'':__t)+
' documents\n			';
 } 
__p+='	\n		</span>\n		\n	</div>\n	';
 //<div class="bl-set-currMatches">Matched to 0 documents</div> 
__p+='\n</div>\n';
}
return __p;
};
