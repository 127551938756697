var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<thead>\n	<tr>\n		<th>File '+
((__t=( _generateSortHtml('name') ))==null?'':__t)+
'</th>\n		<th class="file-meta col-metadata-date">Date '+
((__t=( _generateSortHtml('lastModified') ))==null?'':__t)+
'</th>\n		<th class="file-tools">&nbsp;</th>\n	</tr>\n</thead>\n<tbody></tbody>';
}
return __p;
};
