/* v1.2 */
// eslint-disable-next-line no-unused-expressions
!(function(root, factory) {
	if (typeof define === 'function' && define.amd) {
		define(['jquery', 'underscore'], factory);
	} else {
		factory(root.jQuery, root.underscore);
	}
}(this, function($, _) {
	'use strict';

	// Set to true to turn on test code.
	// const __DEV__ = false;

	const namespace = 'tmbutton';
	let idCounter = 0;

	// Plugin defaults.
	const defaults = {

		// Starting state of button.
		initialState: 'ready',

		// Set of possible states and their configurations.
		// Can define more custom states here.
		states: {
			ready: {
				btnDisabled: false
			},
			busy: {
				btnDisabled: true,
				icon: 'spinner'
			},
			success: {
				btnDisabled: false,
				icon: 'success'
			},
			error: {
				btnDisabled: false,
				icon: 'warning'
			},
			disabled: {
				btnDisabled: true
			}
		},

		// Minimum amount of time to spend in each state.
		// This prevents state changes from being so fast that users
		// can't perceive them. Set to 0 to permit immediate state changes.
		minStateDuration: 400
	};

	// Plugin constructor. Instance will be stored in $el's data.
	const Button = function($el, options) {
		this.id = namespace + idCounter++;
		this.$el = $el;
		this.settings = options;

		// Save data in settings for convenience.
		options.id = this.id;
		options.$el = $el;

		// Keeps track of setTimeouts.
		options.waits = [];

		// Set initial state.
		options.minStateDurationReached = true;
		this.state(options.initialState);
	};

	Button.prototype = {
		// Reset constructor - http://goo.gl/EcWdiy
		constructor: Button,

		/**
		 Sets the button to the specified state.

		 @param name {str} Name of state.
		 */
		state: function(name) {
			const that = this;

			const $el = this.$el;

			const settings = this.settings;
			const stateConfig = settings.states[name];

			// Exit if this state doesn't exist.
			if (!stateConfig) return;

			if (!settings.minStateDurationReached) {
				// Current state hasn't existed for min duration yet, so wait
				// until it has, then do state change.
				const eventName = this._fullEventName('update', 'minStateDurationReached');
				this.$el.on(eventName, function(e) {
					if (e.value) {
						that.state(name);
						that.$el.off(eventName);
					}
				});
			} else {
				// Ready to change state. Update DOM.
				$el.prop('disabled', stateConfig.btnDisabled);
				this._toggleIcon(stateConfig);

				// Update settings & trigger events.
				this._update('currentState', name);
				this._update('minStateDurationReached', false);

				// Enforce min state duration for this new state.
				const waitId = setTimeout(function() {
					that._update('minStateDurationReached', true);

					// Remove this setTimeout id from waits array.
					const i = _.indexOf(settings.waits, waitId);
					if (i > -1) settings.waits.splice(i, 1);
				}, settings.minStateDuration);
				settings.waits.push(waitId);
			}
		},

		/**
		 Returns the full namespaced name for an event of a particular type
		 and (optional) subtype.

		 Event name format: "<subtype>.<type>.<instanceId>.tmbutton"

		 @param type {str}
		 @param subtype {str} [optional]
		 @returns {str} Namespaced event name.
		 */
		_fullEventName: function(type, subtype) {
			let name = `${type}.${this.id}.tmbutton`;

			if (typeof subtype !== 'undefined' && subtype !== '') {
				name = `${subtype}.${name}`;
			}

			return name;
		},

		/**
		 Updates "key" in instance settings with "value", and triggers an
		 "update.<key>" event with the old and new value.

		 @param key {str}
		 @param value {?}
		 */
		_update: function(key, value) {
			const settings = this.settings;
			const oldValue = settings[key];

			settings[key] = value;

			this.$el.trigger({
				type: this._fullEventName('update', key),
				oldValue: oldValue,
				value: value
			});
		},

		_toggleIcon: function(stateConfig) {
			if (stateConfig.icon) {
				this.$el.find('.tmbutton-icon').remove();
				this.$el.append(' ').append(`<span class="tmbutton-icon ldi ldi-${stateConfig.icon}"></span>`);
			} else {
				this.$el.find('.tmbutton-icon').remove();
			}
		},

		/**
		 Destroys this plugin instance.
		 */
		destroy: function() {
			// Clear timeouts.
			_.each(this.settings.waits, clearTimeout);

			// Remove data.
			this.$el.find('.tmbutton-icon').remove();
			this.$el.removeData('tmbutton');
			this.$el = undefined;
		}

	};

	// Plugin api methods.
	// Use like this: $('...').tmbutton('methodName', arg1, arg2, ...);
	const methods = {
		init: function(options) {
			const $el = $(this);
			options = $.extend({}, defaults, options);
			$el.data('tmbutton', new Button($el, options));
		},

		state: function(name) {
			$(this).data('tmbutton').state(name);
		},

		destroy: function() {
			$(this).data('tmbutton').destroy();
		}
	};

	// Create the jQuery plugin.
	$.fn.tmbutton = function(method) {
		const args = arguments;

		// Call the desired method on each element separately.
		return this.each(function() {
			const $el = $(this);

			if (methods[method]) {
				// Don't execute the method if this doesn't have the plugin
				// enabled on it.
				if (typeof $el.data('tmbutton') !== 'undefined') {
					return methods[method].apply(this, Array.prototype.slice.call(args, 1));
				}
			} else if (typeof method === 'object' || !method) {
				// Don't apply plugin again if it's already enabled.
				if (typeof $el.data('tmbutton') === 'undefined') {
					return methods.init.apply(this, args);
				}
			} else {
				$.error(`Method ${method} does not exist on jQuery.tmbutton.`);
			}
		});
	};

	// Expose defaults.
	$.fn.tmbutton.defaults = defaults;
}));
