import $ from 'jquery';
import View from './view';

function init(app) {
	return {
		getGroupsPageView: function(clientId) {
			const view = new View({ app, ClientId: clientId });
			return $.Deferred().resolve(view).promise();
		}
	};
}

export default init;
