import _ from 'underscore';
import config from './config';
import date from './utils/date';
import dom from './utils/dom';
import skeleton from './skeleton';
import 'ui/contextMenu';
import 'ui/forms';
import 'ui/tooltip';

const ui = {};

ui.config = config;

config.getModule = function(name) {
	const parts = name.split('.');
	const len = parts.length;
	let currentModule = ui;
	let childName;

	for (let i = 0; i < len; i++) {
		childName = parts[i];
		currentModule = currentModule[childName] || (currentModule[childName] = {});
	}

	return currentModule;
};

_.extend(ui, dom);
ui.date = date;
ui.skeleton = _.extend(ui.skeleton || {}, skeleton(ui.config.styleguide));

export default ui;
