import $ from 'jquery';

$(document).on('click', function(e) {
	const $el = $(e.target);

	if ($el.closest('[data-tbf2-id]').length) {
		return; // ignore
	}

	const $toggle = $el.closest('[data-contextmenu]');

	if ($toggle.length) {
		// Clicked on menu toggle.
		const selector = $toggle.attr('data-contextmenu');
		$(selector).toggle();
		$toggle.toggleClass('active');
	} else if (
		!$el.closest('.context-menu').length ||
		$el.find('.context-menu-option-link').length === 1 ||
		$el.closest('.context-menu-option-link').length === 1
	) {
		// Clicked outside menus or on menu link. Close menus.
		$('.context-menu:not(.region-dropmenu)').hide();
		$('[data-contextmenu]').removeClass('active');
	}
});

// $(document).on(click, '[data-context-menu-toggle]', function(e) {
//	var $el = $(e.target);
//	var selector = $el.attr('data-context-menu-toggle');
//
//	$(selector).toggle();
//
// });
