import $ from 'jquery';

// Module to return.
const module = {};

// Regexes.
const rePrivateKey = /^_+.*$/i; // matches underscore-prefixed strings
const reGuid = /^[a-f0-9-]+$/i;
const reZeroGuid = /^[0-]+$/;
const reEscapeRegex = new RegExp(`(\\${['/', '.', '*', '+', '?', '|', '(', ')', '[', ']', '{', '}', '\\', '$', '^', '-'].join('|\\')})`, 'g');
const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const reJobRoute = /job\/(\w+)\/(\w+)\/(\w+)(\/.*)?/;
const reTmAttr = /^tm:.+$/i;
const reFilepath = /(.*\/)(.*)/;
const reDirPath = /(.+\/)(.*)\//;

// -- Private helper functions -- //

/**
 Escapes a string such that it can be used in a regular expression.

 @param str {str} String to escape.
 @returns   {str} Escaped string.
 @source jQuery DataTables v1.10.0's _fnEscapeRegex.
 */
module.escapeRegex = function(str) {
	return str.replace(reEscapeRegex, '\\$1');
};

// -- Exposed functions -- //

/**
 Returns true iff guid is a non-zero GUID (i.e. does not look like one of
 these: '00000000-0000-0000-0000-000000000000').
 */
module.isNonZeroGuid = function(guid) {
	return reGuid.test(guid) && !reZeroGuid.test(guid);
};

/**
 Returns array with client, job type, and job name if str is a job route.
 */
module.isJobRoute = function(str) {
	const results = reJobRoute.exec(str);
	return results ? results.slice(1, 4) : false;
};

/**
 Returns true if `str` is a private key, i.e. starts with at least one
 underscore "_".

 @param {string} str
 @returns {boolean}
 */
module.isPrivateKey = function(str) {
	return rePrivateKey.test(str);
};

/**
 Returns true if `key` is a tm attribute, i.e. starts with "tm:".
 Used as an Underscore collection iteratee.

 @param {*} val
 @param {string} key
 @returns {boolean}
 */
module.isTmAttrIteratee = function isTmAttr(val, key) {
	return reTmAttr.test(key);
};

/**
 Returns false if the date has a year of 0. It may be a valid year, but
 no one has done anything LiveDoc-related in Year 0.

 @source Simplified ver of http://stackoverflow.com/a/7914311/1804948
 */
module.isValidDate = function(d) {
	return typeof d !== 'undefined' && d.getFullYear() !== 0;
};

module.isValidEmailAddress = function(email) {
	// http://stackoverflow.com/a/46181/11236
	return reEmail.test(email);
};

/**
 Returns an array with path and filename if str is a filepath.
 */
module.parseFilePath = function(str) {
	const matches = reFilepath.exec(str);
	return matches ? matches.slice(1) : undefined;
};

/**
 Returns an array with path and directory name if str is a path to a
 directory (i.e. ends with forward slash).
 */
module.parseDirPath = function(str) {
	if (str === '/') return ['/', ''];
	const matches = reDirPath.exec(str);
	return matches ? matches.slice(1) : undefined;
};

/**
 Builds a regular expression object suitable for searching a table.

 @param search          {string} String to search for.
 @param regex           {bool}   Treat as a regular expression or not.
 @param smart           {bool}   Perform smart filtering or not.
 @param caseInsensitive {bool}   Do case insensitive matching or not.
 @returns               {RegExp} Constructed object.
 @source jQuery DataTables v1.10.0's _fnFilterCreateSearch.
 */
module.createSearchRegex = function(search, regex, smart, caseInsensitive) {
	search = regex ? search : module.escapeRegex(search);
	if (smart) {
		// For smart filtering, allow the search to work regardless of
		// word order. Also double-quoted text must be preserved, so
		// word order is important - a la google.
		// So this is what we want to generate:
		// ^(?=.*?\bone\b)(?=.*?\btwo three\b)(?=.*?\bfour\b).*$
		const a = $.map(search.match(/"[^"]+"|[^ ]+/g) || '', function(word) {
			return word.charAt(0) === '"'
				? word.match(/^"(.*)"$/)[1]
				: word;
		});
		search = `^(?=.*?${a.join(')(?=.*?')}).*$`;
	}
	return new RegExp(search, caseInsensitive ? 'i' : '');
};

export default module;
