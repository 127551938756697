import app from 'app';
import _ from 'underscore';
import Backbone from 'backbone';

// @todo validation, e.g. package name is not empty string

const PublishModel = Backbone.Model.extend({

	initialize: function(data, opts) {
		this.options = opts;
		this.selectedItems = new Backbone.Collection();
		this.setupDefaults();
		this.setupListeners();

		// general set up
		const job = opts.job;
		this.set({
			SetType: opts.type || 'documents',
			ClientId: job.get('ClientId'),
			JobTypeId: job.get('JobTypeId'),
			JobId: job.get('Id'),
			JobVersion: job.get('Version') || '1'
		});

		// override defaults w/ job's publish settings
		this.set(job.get('PublishSettings'));

		// type-specific set up
		this.get('SetType') === 'blackline'
			? this.setupForBlackline()
			: this.setupForDocuments();
	},

	setupDefaults: function() {
		// Values in Model#defaults that are non-primitives (arrays/objects)
		// are merged with model instances' attributes *by reference*,
		// meaning that all instances will share the same arrays/objects.
		// So, set up those default arrays/objects here instead. Also
		// setting up default values that are primitive here so that
		// everything is in one place.

		// Our defaults.
		const defaults = {
			NumberOfSelected: 0,

			// Is select-all/selected-documents option enabled?
			HasSelectAllOption: true,
			SelectAll: false,

			// Package display name
			PackageDisplayName: '',

			// PDF file name convention
			PredefinedDocumentFileName: {
				Name: 'FundName',
				Example: 'Fund ABC.pdf',
				Value: 'FundName'
			},
			NamingConvention: 'FundName', // e.g. 'FundName-Series-LANG'

			// Available output types
			OutputTypes: [
				'Individual',
				'Zipped',
				'Consolidated'
			],

			// Selected output type
			OutputType: 'Individual', // e.g. 'consolidated'

			// Available group-by attributes
			// Example attribute models:
			//	{ Name: 'Family', Value: 'Family', Selected: false },
			//	{ Name: 'Fund Code', Value: 'FundCode', Selected: true },
			//	{ Name: 'Series Code', Value: 'SeriesCode', Selected: false },
			GroupByAttributes: new Backbone.Collection([]),

			// Selected group-by attributes, e.g. ['Family', 'FundCode']
			GroupBy: [],

			// Available sort-by attributes
			// Example attribute models:
			//	{ Name: 'Family', Value: 'Family', Selected: false, Direction:'asc' },
			//	{ Name: 'Fund Code', Value: 'FundCode', Selected: true, Direction:'dec' },
			//	{ Name: 'Series Code', Value: 'SeriesCode', Selected: false, Direction:'asc' },
			SortByAttributes: new Backbone.Collection([]),

			// Selected sort-by attributes, e.g. ['Family', 'FundCode']
			SortBy: [],

			// PDF optimization
			PdfOptimizationLevel: 0,

			// Passwords
			PdfSecurity_Password: '',
			PdfSecurity_OwnerPassword: '',
			ZipSecurityPassword: '',

			// PDF security options
			PdfSecurity_DisallowPrinting: false,
			PdfSecurity_DisallowChaningDocument: false,
			PdfSecurity_DisallowDocumentAssembly: false,
			PdfSecurity_DisallowContentCopying: false,
			PdfSecurity_DisallowContentCopyingForAccessibility: false,
			PdfSecurity_DisallowPageExtraction: false,
			PdfSecurity_DisallowCommenting: false,
			PdfSecurity_DisallowFillingOfFormFields: false,
			PdfSecurity_DisallowSigning: false,
			PdfSecurity_DisallowCreationOfTemplatePages: false,

			PdfSecurity_EncryptionAlgorithm: 'Low'
		};

		// Merge in our default values with the model attributes,
		// making sure not to override data that already exists.
		_.defaults(this.attributes, defaults);
	},

	setupListeners: function() {
		const that = this;

		that.on('change', that.onChange);

		that.listenTo(that.selectedItems, 'add remove reset', function() {
			that.set('NumberOfSelected', that.selectedItems.length);
		});

		that.listenTo(that.get('GroupByAttributes'), 'change:Selected', function() {
			that.set('GroupBy',
				that.get('GroupByAttributes')
					.where({ Selected: true })
					.map(function(ot) {
						return ot.get('Name');
					}));
		});

		that.listenTo(that.get('SortByAttributes'), 'change:Selected change:Direction', function() {
			that.set('SortBy',
				that.get('SortByAttributes')
					.where({ Selected: true })
					.map(function(ot) {
						return {
							FieldName: ot.get('Name'),
							Direction: ot.get('Direction') || 'Ascending'
						};
					}));
		});
	},

	setupForDocuments: function() {
		// additional info needed from job
		const job = this.options.job;
		this.set({
			DocumentListGuid: job.get('DocumentListId') || '',
			JobDisplayName: job.get('DisplayName'),
			DefaultPackageDisplayName: `Document set for ${job.get('DisplayName')}`,
			PackageDisplayName: `Document set for ${job.get('DisplayName')}`
		});

		// handling document attributes
		this.setGroupSortAttributesFromDocAttrsMeta(job.get('DocumentAttributesMeta'));
		this.listenTo(job, 'change:DocumentAttributesMeta', function() {
			this.setGroupSortAttributesFromDocAttrsMeta(job.get('DocumentAttributesMeta'));
		});

		// handling document resets
		const docs = job.get('Documents');
		const selectedItems = this.selectedItems;
		selectedItems.reset(docs.where({ selected: true }));

		this.listenTo(docs, 'change:selected', function(doc, selected) {
			selected ? selectedItems.add(doc) : selectedItems.remove(doc);
		});

		this.listenTo(docs, 'reset', function() {
			this.selectedItems.reset();
		});
	},

	setupForBlackline: function() {
		this.set('HasSelectAllOption', false);
		const blm = this.options.blm;
		this._blacklineOnChangeNumMatches();
		this._blacklineOnChangeNewDocSet();
		this.listenTo(blm, 'change:numMatches', this._blacklineOnChangeNumMatches);
		this.listenTo(blm, 'change:newDocSet', this._blacklineOnChangeNewDocSet);
	},
	_blacklineOnChangeNumMatches: function() {
		this.set('NumberOfSelected', this.options.blm.get('numMatches'));
	},
	_blacklineOnChangeNewDocSet: function() {
		const docSet = this.options.blm.get('newDocSet');

		if (!docSet) return;

		this.set({
			ClientId: docSet.get('ClientId'),
			JobTypeId: docSet.get('JobTypeId'),
			JobId: docSet.get('JobId')
		});

		this.setGroupSortAttributesFromDocSet(docSet.get('Documents'));
	},

	onChange: function() {
		// enforce types
		const model = this;
		const changed = model.changed;
		const silent = { silent: true };
		const types = {
			SelectAll: 'boolean',
			OutputType: 'string',
			PdfOptimizationLevel: 'number',
			PdfSecurity_Password: 'string',
			PdfSecurity_OwnerPassword: 'string',
			ZipSecurityPassword: 'string',
			PdfSecurity_DisallowPrinting: 'boolean',
			PdfSecurity_DisallowChaningDocument: 'boolean',
			PdfSecurity_DisallowDocumentAssembly: 'boolean',
			PdfSecurity_DisallowContentCopying: 'boolean',
			PdfSecurity_DisallowContentCopyingForAccessibility: 'boolean',
			PdfSecurity_DisallowPageExtraction: 'boolean',
			PdfSecurity_DisallowCommenting: 'boolean',
			PdfSecurity_DisallowFillingOfFormFields: 'boolean',
			PdfSecurity_DisallowSigning: 'boolean',
			PdfSecurity_DisallowCreationOfTemplatePages: 'boolean',
			PdfSecurity_EncryptionAlgorithm: 'string'
		};

		_.each(changed, function(val, key) {
			const type = types[key];

			if (!type) {
				return;
			} else if (type === 'string') {
				val = typeof val === 'undefined' || val === null ? '' : `${val}`;
			} else if (type === 'boolean') {
				val = !!val;
			} else if (type === 'number') {
				val = +val;
			}

			model.set(key, val, silent);
		});
	},

	setGroupSortAttributesFromDocAttrsMeta: function(attrs) {
		const publicAttrs = _.where(attrs, { IsVisible: true });

		// _.map callback for generating sort/group by attr lists.
		const _formatAttr = function(attr) {
			return {
				Name: attr.Name,
				Value: attr.DisplayName || attr.Name,
				Selected: false
			};
		};

		// Both sort/group lists use the same format, but mapping twice
		// to ensure we have completely independent clones.
		this.get('GroupByAttributes').reset(_.map(publicAttrs, _formatAttr));
		this.get('SortByAttributes').reset(_.map(publicAttrs, _formatAttr));
	},

	setGroupSortAttributesFromDocSet: function(docs) {
		const attrs = docs.length > 0 ? _.keys(docs.at(0).attributes) : [];

		// _.map callback for generating sort/group by attr lists.
		const _createAttr = function(name) {
			return { Name: name, Value: name, Selected: false };
		};

		this.get('GroupByAttributes').reset(_.map(attrs, _createAttr));
		this.get('SortByAttributes').reset(_.map(attrs, _createAttr));
	},

	publish: function() {
		const that = this;
		let command;
		let items;
		const isSelectAll = this.get('SelectAll');

		if (this.get('SetType') === 'blackline') {
			command = 'app.services.publishing.blackline';
			items = this.options.blm.getMatchedPairs();
			this.set('BlacklinePairs', items);
		} else {
			command = 'app.services.publishing.publish';
			items = isSelectAll ? [] : this.selectedItems.pluck('Id');
			this.set('DocumentKeys', items);
		}

		if (isSelectAll || items.length > 0) {
			app.request(command, that).done(function() {
				that.trigger('published', 'success');
			}).fail(function() {
				that.trigger('published', 'failed', 'Publish failed. Please check Logs for more info.');
			});
		} else {
			that.trigger('published', 'failed', 'Must select at least one item to publish.');
		}
	}

});

export default PublishModel;
