import Marionette from 'marionette';
import template from './template.html';

const View = Marionette.LayoutView.extend({
	template: template,
	regions: {
		editEmail: '.editEmailView',
		editQnA: '.editQnAView',
		editPassword: '.editPasswordView'
	}
});

export default View;
