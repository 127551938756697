var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="app-nav" class="app-nav">\n    <div class="nav-primary">\n        ';
 _.each(modules, function(c) {
            var cls = c.isHomePage ? 'nav-btn-livedoc' : 'nav-btn-text';
            var href = c.region === 'main' ? '#' + c.id : 'javascript:void(0);';
        
__p+='\n        <a href="'+
((__t=( href ))==null?'':__t)+
'" class="nav-btn '+
((__t=( cls ))==null?'':__t)+
' js-link" data-link="'+
((__t=( c.id ))==null?'':__t)+
'" data-region="'+
((__t=( c.region ))==null?'':__t)+
'">\n            '+
((__t=( c.displayName ))==null?'':__t)+
'\n        </a>\n        ';
 }); 
__p+='\n    </div>\n    <div class="nav-secondary">\n        <div id="region-client-selector" class="nav-item client-selector"></div>\n        ';
 // <a href="javascript:void(0);" id="help-btn" class="nav-btn ldi ldi-help"></a>     
__p+='\n        <a href="javascript:void(0);" id="settings-btn" class="nav-btn ldi ldi-settings"\n           data-contextmenu="#app-settings-menu"></a>\n        <a href="javascript:void(0);" id="notifications-toggle" class="nav-btn ldi ldi-notifications">\n            <div class="notification-count"></div>\n        </a>\n    </div>\n</div>\n\n<div id="app-settings-menu" class="app-settings-menu context-menu arrow-left">\n    <div class="context-menu-section">\n        <div id="context-menu" class="context-menu-section-content">\n            <span class="context-menu-option has-hover js-link" data-link="profile" data-region="main">\n                <a href="#profile" class="context-menu-option-link"><span class="ldi ldi-user"></span> Profile</a>\n            </span>\n\n            ';
 if (isAdmin) { 
__p+='\n                <span class="context-menu-option has-hover js-link" data-link="admin" data-region="main">\n                <a href="#admin" class="context-menu-option-link">\n                    <span class="ldi ldi-settings"></span> Administration\n                </a>\n            </span>\n            ';
 } 
__p+='\n        </div>\n    </div>\n    <div class="context-menu-section">\n        <div class="context-menu-section-content">\n            <span class="context-menu-option has-hover">\n                <a tabIndex="0" class="context-menu-option-link js-logout">\n                    <span class="ldi ldi-exit"></span> Log out\n                </a>\n            </span>\n        </div>\n    </div>\n</div>\n\n<div id="app-notification-panel" class="app-notification-panel hidden"></div>\n<div id="app-notification-bubbles" class="app-notification-bubbles"></div>\n\n<div id="region-main" class="app-body"></div>\n<div id="region-sidebar" class="app-sidebar hidden"></div>\n';
}
return __p;
};
