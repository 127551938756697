import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import Cache from 'common/lib/dataCache';
import appSettings from 'config';

// pages
import groupsPage from './modules/groupsPage/module';
import usersPage from './modules/usersPage/module';
import securitySettings from './modules/securitySettings/module';

// mini modules for editing
import addGroup from './modules/addGroup/module';
import addUser from './modules/addUser/module';
import editGroup from './modules/editGroup/module';
import editUser from './modules/editUser/module'; // admin > edit user
import editAccount from './modules/editAccount/module'; // profile
import editProfile from './modules/editProfile/module';
import editEmail from './modules/editEmail/module';
import editPassword from './modules/editPassword/module';
import editQnA from './modules/editQnA/module';
import editIpRestrictions from './modules/editIpRestrictions/module';
import editUserGroups from './modules/editUserGroups/module';
import toggleMfa from './modules/toggleMfa/module';
import toggleUserLock from './modules/toggleUserLock/module';

// mini modules for display
import groupList from './modules/groupList/module';
import userGroupList from './modules/userGroupList/module';
import miniProfile from './modules/miniProfile/shortDisplay_module';

const subModuleApis = [
	groupsPage,
	usersPage,
	securitySettings,
	addGroup,
	addUser,
	editGroup,
	editUser,
	editAccount,
	editProfile,
	editEmail,
	editPassword,
	editQnA,
	editIpRestrictions,
	editUserGroups,
	toggleMfa,
	toggleUserLock,
	groupList,
	userGroupList,
	miniProfile
];

const uriRoot = appSettings.UserManagementUri;
const accountCompareFunc = function(a, b) {
	return a.Email > b.Email;
};

const _getAccounts = function(clientId) {
	const includeUsersQuery = { includeInternalUsers: true, includeExternalUsers: true, includeDeleted: false };
	const parsedQuery = `?${new URLSearchParams(includeUsersQuery).toString()}`;
	return $.ajax({
		url: `${uriRoot}clients/${clientId}/accounts${parsedQuery}`,
		type: 'GET',
		contentType: 'application/json',
		dataType: 'json'
	}).then(function(data) {
		return new Backbone.Collection(data.sort(accountCompareFunc));
	});
};

const _getAccount = function(clientId, userId) {
	return $.ajax({
		url: `${uriRoot}clients/${clientId}/accounts/${userId}`,
		type: 'GET',
		contentType: 'application/json',
		dataType: 'json'
	}).then(function(data) {
		return new Backbone.Model(data);
	});
};

const _getCurrentAccount = function() {
	return $.ajax({
		url: `${uriRoot}current`,
		type: 'GET',
		contentType: 'application/json',
		dataType: 'json'
	}).then(function(data) {
		return new Backbone.Model(data);
	});
};

const _getProfiles = function(clientId) {
	return $.ajax({
		url: `${uriRoot}clients/${clientId}/userprofiles/`,
		type: 'GET',
		contentType: 'application/json',
		dataType: 'json'
	}).then(function(data) {
		return new Backbone.Collection(data.sort(accountCompareFunc));
	});
};

const _getProfile = function(clientId, userId) {
	return $.ajax({
		url: `${uriRoot}clients/${clientId}/userprofiles/${userId}`,
		type: 'GET',
		contentType: 'application/json',
		dataType: 'json'
	}).then(function(data) {
		return new Backbone.Model(data);
	});
};

const _getGroups = function(clientId) {
	return $.ajax({
		url: `${uriRoot}clients/${clientId}/groups`,
		type: 'GET',
		contentType: 'application/json',
		dataType: 'json'
	}).then(function(list) {
		let i = 0;
		const n = list.length;
		for (; i < n; i++) {
			list[i].ClientId = clientId;
		}
		return new Backbone.Collection(list);
	});
};

const _getGroup = function(clientId, groupId) {
	return $.ajax({
		url: `${uriRoot}clients/${clientId}/groups/${groupId}`,
		type: 'GET',
		contentType: 'application/json',
		dataType: 'json'
	}).then(function(data) {
		data.ClientId = clientId;
		return new Backbone.Model(data);
	});
};

const _getSubjects = function(clientId) {
	return $.ajax({
		url: `${uriRoot}clients/${clientId}/subjects`,
		type: 'GET',
		contentType: 'application/json',
		dataType: 'json'
	}).then(function(data) {
		return new Backbone.Collection(data);
	});
};

// const _getSubject = function(clientId, subjectId) {
// 	return $.ajax({
// 		url: `${uriRoot}clients/${clientId}/subjects/${subjectId}`,
// 		type: 'GET',
// 		contentType: 'application/json',
// 		dataType: 'json'
// 	}).then(function(data) {
// 		return new Backbone.Model(data);
// 	});
// };

// Make caches.
const profilesCache = new Cache({
	name: 'prof',
	fetchItem: _getProfile,
	fetchCollection: _getProfiles
});
const accountsCache = new Cache({
	name: 'acct',
	fetchItem: _getAccount,
	fetchCollection: _getAccounts
});
const groupsCache = new Cache({
	name: 'grps',
	fetchItem: _getGroup,
	fetchCollection: _getGroups
});
const subjectsCache = new Cache({
	name: 'subj',
	fetchCollection: _getSubjects
	// fetchItem not provided b/c we always get item from the collection
});

function init (app) {
	const api = {
		// get/list accounts
		getProfiles: function(clientId) {
			return profilesCache.getCollection({
				key: clientId,
				fetchArgs: arguments
			});
		},

		getProfile: function(clientId, userId) {
			return profilesCache.getItem({
				key: userId,
				collectionKey: clientId,
				fetchArgs: arguments
			});
		},
		getAccounts: function(clientId) {
			return accountsCache.getCollection({
				key: clientId,
				fetchArgs: arguments
			});
		},
		getAccount: function(clientId, userId) {
			const r = accountsCache.getItem({
				key: userId,
				collectionKey: clientId,
				fetchArgs: arguments
			});

			return r;
		},
		getCurrentUser: function() {
			return accountsCache.getItem({
				key: 'current',
				fetchFunction: _getCurrentAccount,
				fetchArgs: arguments
			});
		},

		// editing accounts
		changeEmail: function(clientId, accountId, newEmail) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}/accounts/${accountId}/ChangeUsername`,
				type: 'POST',
				data: JSON.stringify({ AccountId: accountId, NewEmail: newEmail }),
				contentType: 'application/json',
				dataType: 'json'
			});
		},
		changeSecurityQuestionAndAnswer: function(clientId, accountId, question, answer) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}/accounts/${accountId}/ChangeSecurityQuestionAndAnswer`,
				type: 'POST',
				data: JSON.stringify({ AccountId: accountId, Question: question, Answer: answer }),
				contentType: 'application/json',
				dataType: 'json'
			});
		},
		changePassword: function(clientId, accountId, oldPassword, newPassword) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}/accounts/${accountId}/ChangePassword`,
				type: 'POST',
				data: JSON.stringify({ AccountId: accountId, NewPassword: newPassword, OldPassword: oldPassword }),
				contentType: 'application/json',
				dataType: 'json'
			});
		},
		updateProfile: function(userProfile) {
			if (!userProfile) throw new Error('empty userProfile object');

			const clientId = userProfile.get('ClientId');
			const userId = userProfile.get('Id');
			userProfile.url = `${uriRoot}clients/${clientId}/accounts/${userId}`;

			return $.ajax({
				url: userProfile.url,
				type: 'POST',
				contentType: 'application/json',
				data: JSON.stringify(userProfile.toJSON()),
				dataType: 'json'
			}).then(function() {
				accountsCache.reset();
				profilesCache.reset();
				return userProfile;
			});
		},

		addAccount: function(clientId, emailAddress) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}`,
				type: 'PUT',
				data: JSON.stringify({ ClientId: clientId, EmailAddress: emailAddress }),
				contentType: 'application/json',
				dataType: 'json'
			}).then(function() {
				// force cache reset; will refetch
				profilesCache.reset();
				accountsCache.reset();
				subjectsCache.reset();
				app.vent.trigger('services:users:account:added', clientId);
			});
		},

		removeAccount: function(clientId, accountId) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}/accounts/${accountId}`,
				type: 'DELETE',
				contentType: 'application/json',
				dataType: 'json'
			}).then(function() {
				profilesCache.removeItem(accountId);
				accountsCache.removeItem(accountId);
				subjectsCache.removeItem(accountId);
				app.vent.trigger('services:users:account:removed', clientId, accountId);
			});
		},

		lockAccount: function(clientId, accountId) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}/LockAccount`,
				type: 'POST',
				data: JSON.stringify({ ClientId: clientId, AccountId: accountId }),
				contentType: 'application/json',
				dataType: 'json'
			}).then(function() {
				// force cache reset; will refetch
				profilesCache.reset();
				accountsCache.reset();
				subjectsCache.reset();
			});
		},
		unlockAccount: function(clientId, accountId) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}/UnlockAccount`,
				type: 'POST',
				data: JSON.stringify({ ClientId: clientId, AccountId: accountId }),
				contentType: 'application/json',
				dataType: 'json'
			}).then(function() {
				// force cache reset; will refetch
				profilesCache.reset();
				accountsCache.reset();
				subjectsCache.reset();
			});
		},

		generateMFASecret: function() {
			return $.ajax({
				url: `${uriRoot}GenerateMFASecret`,
				type: 'GET',
				contentType: 'application/json',
				dataType: 'text'
			}).then(function(secret) {
				return {
					secretKey: secret,
					uri: `otpauth://totp/Transmission%20Media%20LiveDoc?secret=${secret}`
				};
			});
		},
		enableMFA: function(clientId, accountId, secretKey, firstToken) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}/EnableMFA`,
				type: 'POST',
				data: JSON.stringify({
					ClientId: clientId,
					AccountId: accountId,
					SecretKey: secretKey,
					Token: firstToken
				}),
				contentType: 'application/json',
				dataType: 'text'
			});
		},
		disableMFA: function(clientId, accountId) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}/DisableMFA`,
				type: 'POST',
				data: JSON.stringify({ ClientId: clientId, AccountId: accountId }),
				contentType: 'application/json',
				dataType: 'text'
			});
		},

		getClientLoginSettings: function(clientId) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}/LoginSettings`,
				type: 'GET',
				contentType: 'application/json',
				dataType: 'json'
			});
		},
		enableIpRestriction: function(clientId, allowedIpRanges) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}/EnableIpRestriction`,
				type: 'POST',
				contentType: 'application/json',
				data: JSON.stringify({ IpRanges: allowedIpRanges }),
				dataType: 'json'
			});
		},
		disableIpRestriction: function(clientId) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}/DisableIpRestriction`,
				type: 'POST',
				contentType: 'application/json',
				data: JSON.stringify({}),
				dataType: 'json'
			});
		},

		// get/list security items
		getSecurityGroups: function(clientId) {
			return groupsCache.getCollection({
				key: clientId,
				fetchArgs: arguments
			});
		},
		getSecurityGroup: function(clientId, groupId) {
			return groupsCache.getItem({
				key: groupId,
				collectionKey: clientId,
				fetchArgs: arguments
			});
		},
		getSecuritySubjects: function(clientId) {
			return subjectsCache.getCollection({
				key: clientId,
				fetchArgs: arguments
			});
		},
		getSecuritySubject: function(clientId, subjectId) {
			return subjectsCache.getItem({
				key: subjectId,
				collectionKey: clientId,
				fetchArgs: arguments,
				fetchViaCollection: true
			});
		},

		isSystemAdmin: function(clientId, userId) {
			const pGroups = api.getSecurityGroups(clientId);
			const pSubject = api.getSecuritySubject(clientId, userId);

			return $.when(pGroups, pSubject).then(function(groups, subject) {
				const memberships = subject.get('Groups');
				let isSysAdmin = false;
				let n = memberships.length;
				let group, perms;

				while (!isSysAdmin && n--) {
					group = groups.findWhere({ Id: memberships[n] });
					if (group.get('IsSystemDefined')) {
						perms = _.flatten(_.pluck(group.get('Policies'), 'Permissions'));
						isSysAdmin = _.indexOf(perms, 'allow //*:*:*') > -1;
					}
				}

				return isSysAdmin;
			});
		},

		isAdmin: function(clientId, userId) {
			const pGroups = api.getSecurityGroups(clientId);
			const pSubject = api.getSecuritySubject(clientId, userId);

			return $.when(pGroups, pSubject).then(function(groups, subject) {
				const memberships = subject.get('Groups');
				let isAdmin = false;
				let n = memberships.length;

				while (!isAdmin && n--) {
					isAdmin = groups.findWhere({ Id: memberships[n] }).get('IsSystemDefined');
				}

				return isAdmin;
			});
		},

		// editing security
		createSecurityGroup: function(clientId, groupName) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}/groups`,
				type: 'POST',
				data: JSON.stringify({ ClientId: clientId, Name: groupName }),
				contentType: 'application/json',
				dataType: 'json'
			}).then(function(obj) {
				groupsCache.reset();
				app.vent.trigger('services:users:group:added', clientId);
				return obj;
			});
		},
		removeSecurityGroup: function(clientId, groupId) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}/groups/${groupId}`,
				type: 'DELETE',
				contentType: 'application/json',
				dataType: 'json'
			}).then(function(obj) {
				groupsCache.removeItem(groupId);
				app.vent.trigger('services:users:group:removed', clientId, groupId);
				return obj;
			});
		},

		setSubjectSecurityGroups: function(clientId, subjectId, groupsIds) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}/subjects/${subjectId}/groups`,
				type: 'PUT',
				data: JSON.stringify({ GroupsIds: groupsIds }),
				contentType: 'application/json',
				dataType: 'json'
			}).then(function(obj) {
				subjectsCache.reset();
				return obj;
			});
		},
		getGroupJobTypePermissions: function(clientId, groupId) {
			let jobTypes;
			const getJobType = function(id) {
				return jobTypes.findWhere({ Id: id });
			};

			// First, get the job types
			return app
				.request('app.services.jobTypes.get', clientId)
				.then(function(list) {
					jobTypes = list;

					// Then, request permissions for the set of job types
					return $.ajax({
						url: `${uriRoot}clients/${clientId}/groups/${groupId}/jobtypepermissions`,
						type: 'POST',
						data: JSON.stringify({ JobTypeIds: list.pluck('Id') }),
						contentType: 'application/json',
						dataType: 'json'
					});
				})
				.then(function(permissions) {
					// For each permission, add job type name to it.
					_.each(permissions, function(p) {
						p.JobTypeName = getJobType(p.JobTypeId).get('DisplayName');
					});

					// All done!
					return new Backbone.Collection(permissions);
				});
		},
		setGroupJobTypePermissions: function(clientId, groupId, groupName, jobtypePermissions) {
			return $.ajax({
				url: `${uriRoot}clients/${clientId}/groups/${groupId}/jobtypepermissions`,
				type: 'PUT',
				data: JSON.stringify({
					// ClientId: clientId,
					// GroupId: groupId,
					Name: groupName,
					JobTypePermissions: jobtypePermissions
				}),
				contentType: 'application/json',
				dataType: 'json'
			}).then(function() {
				groupsCache.reset();
			});
		}
	};

	// Extend with submodule apis.
	_.each(subModuleApis, (subModuleApi) => {
		_.extend(api, typeof subModuleApi === 'function' ? subModuleApi(app) : subModuleApi);
	});

	return api;
}

export default init;
