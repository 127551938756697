import $ from 'jquery';
import Backbone from 'backbone';
import Cache from 'common/lib/dataCache';

import appSettings from 'config';
const uriRoot = `${appSettings.JobTypesUri}clients/`;

const JobTypeModel = Backbone.Model.extend({
	idAttribute: 'Id',
	url: function() {
		const jobtype = this;
		return `${uriRoot + jobtype.get('ClientId')}/jobtypes/${jobtype.id}`;
	}
});

const JobTypeCollection = Backbone.Collection.extend({ model: JobTypeModel });

const getJobTypesFromServer = function(clientId) {
	return $.ajax({
		url: `${uriRoot + clientId}/jobtypes`,
		type: 'GET',
		contentType: 'application/json',
		dataType: 'json'
	}).then(
		function(obj) {
			return new JobTypeCollection(obj);
		},
		function() {
			// if 403 when user belongs to no group or has no permissions
			return false;
		}
	);
};

const jobTypesCache = new Cache({
	name: 'jtyp',
	fetchCollection: getJobTypesFromServer
	// fetchItem not provided to enforce that item is always retrieved from collection
});

function init(app) {
	// Reset cache on client change.
	app.session.on('change:clientId', function() {
		jobTypesCache.reset();
	});

	return {
		get: function(clientId, jobTypeId) {
			if (!clientId) {
				return $.Deferred().reject(new Error('Client id required'));
			} else if (!jobTypeId) {
				return jobTypesCache.getCollection({ fetchArgs: arguments });
			} else {
				return jobTypesCache.getItem({
					key: jobTypeId,
					fetchArgs: arguments,
					fetchViaCollection: true
				});
			}
		}
	};
}

export default init;
