import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import tools from 'common/tools/main';

const PLUGINNAME = 'ColumnMenu';

/**

 Interface for thead view:
 - Each <th> has "tbf-data-attr" property, where the value is column
 attribute name.
 - Each <th> contains a `.tbf-thBtns`, where column toggles can go.

 Interface for column menu section views:
 - Has a boolean property `state`, where true means the menu dropdown
 icon's state should be active.
 - Triggers "tm:columnmenusection:change:state" when the view's `state`
 property changes. First argument is the new value for `state`.
 - Must call Controller#registerColumnMenuSection, with an object config
 that has the following keys:
 - {string} name
 Plugin name
 - {string} getDataMethod
 Name of controller method
 - {Backbone.View} [viewClass]
 View constructor
 - {function} [getViewClass]
 Function that accepts a column object config as an argument
 and returns a Backbone.View.

 */

function makeMenuId(attrName) {
	return `tbf-colmenu-${attrName}`;
}

function columnHasMenuSection(column, section) {
	const hasSection = column[`has${section.name}`];
	return tools.isNullish(hasSection) || hasSection === true;
}

const ColumnMenuView = Marionette.ItemView.extend({
	template: _.template(''),
	initialize: function(opts) {
		this.id = makeMenuId(opts.attrName);
	},
	serializeData: function() {
		return {};
	},
	onShow: function() {
		const that = this;
		const opts = that.options;
		const controller = opts.controller;
		const attrName = opts.attrName;
		const column = _.findWhere(controller.get('columns'), { id: attrName });
		const fragment = document.createDocumentFragment();
		const childViews = that.childViews = [];

		_.each(opts.sections, function(obj) {
			if (columnHasMenuSection(column, obj)) {
				const Cls = typeof obj.getViewClass === 'function'
					? obj.getViewClass(column) : obj.viewClass;

				const view = new Cls({
					controller: controller,
					attrName: attrName,
					column: column,
					data: obj.data
				});

				fragment.appendChild(view.render().el);
				childViews.push(view);

				that.listenTo(view, 'tm:columnmenusection:change:state', that.notifyColumnMenuState);
			}
		});

		// Attach all the child views.
		this.$el.append(fragment);
	},
	notifyColumnMenuState: function() {
		// Determine whether every section is off-ish.
		const off = _.every(this.childViews, function(v) {
			return !v.state;
		});
		this.options.controller.trigger('tm:columnmenu:change:state', this.options.attrName, !off);
	},
	onDestroy: function() {
		// Also destroy children views
		_.invoke(this.childViews, 'destroy');
	}
});

export default {

	name: PLUGINNAME,

	Controller: {
		initialize: function() {
			this.set('columnmenu:sections', []);
		},

		// menu sections need to register their keys for menu data
		// sample data: { stateKey: 'columnfilter:state', viewClass: <...> })
		registerColumnMenuSection: function(data) {
			this.get('columnmenu:sections').push(data);
		},

		toggleColumnMenu: function(attrName) {
			const that = this;
			// List of possible sections
			// [{ stateKey: 'columnsort:state', viewClass: ColumnSortView, getDataMethod: 'methodname' }, ...]
			const sectionDefs = this.get('columnmenu:sections');

			// Iterate through possible sections...
			const sections = [];
			_.each(sectionDefs, function(obj) {
				// If a menu section exists for this column, push it
				// to our list of sections.
				const data = that[obj.getDataMethod](attrName);
				if (data) sections.push(_.extend({ data: data }, obj));
			});

			this.trigger('tm:dropmenu:columnmenu', {
				controller: this,
				attrName: attrName,
				sections: sections
			});
		},
		columnHasMenu: function(col) {
			const sections = this.get('columnmenu:sections');
			let n = sections.length;
			let hasMenu = false;

			while (!hasMenu && n--) {
				hasMenu = columnHasMenuSection(col, sections[n]);
			}

			return hasMenu;
		}
	},

	SubstrateView: {
		initialize: function(opts) {
			this.listenTo(opts.controller, 'tm:dropmenu:columnmenu', this.toggleColumnMenu);
		},
		toggleColumnMenu: function(data) {
			const region = this.regionDropmenu;

			const viewId = makeMenuId(data.attrName);

			if (region.currentViewId === viewId) {
				// Already showing menu for this column. Treat event as
				// toggle and close the menu.
				region.empty();
			} else {
				// Show menu for this column.
				const view = new ColumnMenuView(data);

				const $thead = this.$('.region-thead');

				const $th = $thead.find(`th[data-tbf-attr="${data.attrName}"]`);
				region.show(view);

				// Position region container.
				const menuWidth = view.$el.width();

				const colWidth = $th.outerWidth();

				const colLeft = $th.offset().left;

				const top = $thead.outerHeight() - 1;
				let left = colLeft - this.$el.offset().left;

				// Default calculation aligns menu to left edge of column.
				// If column is wider than menu, or there is space to the
				// left of the column, align menu to right edge of column.
				if (menuWidth < colWidth || left >= menuWidth - colWidth) {
					left += colWidth - menuWidth;
				}

				region.position('down', { top: top, left: left });
			}
		}
	},

	TheadView: {
		initialize: function(opts) {
			const controller = opts.controller;
			this.listenTo(controller, 'tm:columnmenu:change:state', this.onChangeColumnMenuState);
		},
		events: {
			// @todo rename class
			'click .tbf-filter': 'onClickColumnMenuToggle'
		},
		onRender: function() {
			const that = this;
			const opts = that.options;
			const ctrl = opts.controller;
			const cols = opts.columns;

			_.each(cols, function(col) {
				if (ctrl.columnHasMenu(col)) {
					that.$(`th[data-tbf-attr="${col.id}"] .tbf-thBtns`).append('<button class="tbf-filter ldi ldi-filter"></button>');
				}
			});
		},
		onClickColumnMenuToggle: function(e) {
			const attrName = $(e.target).closest('th').attr('data-tbf-attr');
			this.options.controller.toggleColumnMenu(attrName);
		},
		onChangeColumnMenuState: function(attrName, state) {
			this.$(`th[data-tbf-attr="${attrName}"] .tbf-filter`).toggleClass('on', state);
		}
	}

};
