var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="region-header"></div>\n<div class="card-body">\n\n	';
 if (typeof InputConfig.Description === 'string' && InputConfig.Description) { 
__p+='\n	<div class="card-description">'+
((__t=( InputConfig.Description ))==null?'':__t)+
'</div>\n	';
 } 
__p+='\n\n	<div class="region-body"></div>\n\n</div>\n<div class="region-drawer card-drawer"></div>';
}
return __p;
};
