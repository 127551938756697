import app from 'app';
import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import Marionette from 'marionette';
import View from './monitor_view';

const debug = false;

const Monitor = Marionette.Object.extend({
	initialize: function() {
		const that = this;

		that.collection = new Backbone.Collection();
		that.view = new View({ collection: that.collection, controller: that });

		// Initial request for data.
		that.fetchCollectionData();

		// Update collection when we get relevant events.
		that.listenToServerEvents();
	},

	listenToServerEvents: function() {
		const cachedCreates = {};

		this.listenTo(app.vent, 'server:events', function(e) {
			// not my client
			if (e.ClientId !== app.session.get('clientId')) return;

			const collection = this.collection;
			const model = collection.findWhere({ Id: e.SourceId });
			if (!model && !cachedCreates[e.SourceId]) {
				// new document set
				if (debug) console.log('monitor: fetching new document set', e);
				cachedCreates[e.SourceId] = true;

				app.request('app.services.publishing.list', e.ClientId, e.JobTypeId, e.SourceId)
					.done(function(set) {
						if (debug) console.log('monitor: fetched new document set', set);
						collection.add(set, { at: 0 });
						delete cachedCreates[e.SourceId];
					})
					.fail(function(err) {
						if (debug) console.log('monitor: could not fetch new document set', err);
					});
			}
		});

		this.listenTo(app.session, 'change:clientId', function() {
			this.fetchCollectionData();
		});
	},

	fetchCollectionData: function() {
		const collection = this.collection;

		app.request('app.services.publishing.list', null, null, null, null, true).done(function(c) {
			if (debug) console.log('monitor: fetched document sets', c);
			collection.reset(c.models);
		});
	},

	markAsFinal: function(models, isFinal) {
		// If `isFinal` is not specified, default to `true`. Else, coerce
		// `isFinal` into a boolean and use that.
		isFinal = typeof isFinal === 'undefined' || isFinal === null || !!isFinal;

		const promises = _.map(models, function(m) {
			return app
				.request(
					'app.services.publishing.markAsFinal',
					m.get('ClientId'),
					m.get('JobTypeId'),
					m.get('Id'),
					isFinal
				)
				.then(function(c) {
					m.set('_isFinal', isFinal);
				});
		});

		return $.whenAll.apply($, promises);
	}
});

const create = function(opts) {
	return new Monitor(opts);
};

export { create };
