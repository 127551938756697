/**
 * Makes this collection a subset of a provided source collection. The subset
 * in question is defined by a filter function (if no filter function, subset is
 * the entire source collection). The subset collection mirrors
 * `add/remove/reset` events on the source collection.
 *
 * You can select a different subset of the original source collection afterwards,
 * by calling `setFilter` to change the filter function.
 *
 * ##### Added properties
 *
 * - `mixin_subsetCollection`: Mixin options.
 *
 * ##### Added methods
 *
 * NB: You can override these methods in your Collection definition.
 *
 * - `setFilter`
 * - `onSourceCollectionAdd`
 * - `onSourceCollectionRemove`
 * - `onSourceCollectionReset`
 *
 * @module common/mixins/subsetCollection
 *
 * @param {Backbone.Collection} collection  The subset collection. Alias: `that`.
 * @param {Backbone.Collection} source  Collection to get subset from.
 * @param {function} [filter]  Function used to filter source collection. Returns
 * 	`true` for models that belong in this subset, `false` otherwise.
 *
 * @example
 * 	var SampleCollection = Backbone.Collection.extend({
 *
 * 		initialize: function(unusedArg, options) {
 * 			// You may provide models for this collection in the first
 * 			// argument (unusedArg), but the mixin will already set a
 * 			// subset of the  models in the source collection on this
 * 			// collection for you. If you provide a collection in unusedArg,
 * 			// that will override whatever the mixin has set.
 *
 * 			// Subset whose models has Genre:Jazz.
 * 			mixin.subsetCollection({
 * 				'that': this,
 * 				'source': options.source,
 * 				'filter': function(model) {
 * 					return model.get('Genre') === 'Jazz';
 * 				},
 * 			});
 *
 * 		},
 *
 * 		setGenre: function(genreName) {
 * 			// I can have this collection use another Genre if I wanted.
 * 			this.setFilter(function(model) {
 * 				return model.get('Genre') === genreName;
 * 			});
 * 		},
 *
 * 	});
 */
import _ from 'underscore';
/**
	 * Returns an array of models from collection that pass the filter function.
	 * If filter is not a function, the returned array includes all models from
	 * collection.
	 *
	 * @param {Backbone.Collection} collection  Source collection.
	 * @param {function} [filter]  Filter function. If not a function, no
	 * 	filtering is done.
	 */
const _getFilteredModels = function(collection, filter) {
	if (typeof filter === 'function') {
		// Filter collection to only get relevant items. If no
		// matching items, empty array is returned.
		return collection.filter(filter);
	} else {
		// No filter, so just use the full collection.
		return collection.models;
	}
};

const subsetCollection = function(opts) {
	const collection = opts.that || opts.collection;

	const source = opts.source;

	const filter = opts.filter;

	// Save the mixin settings to the collection.
	collection.mixin_subsetCollection = _.extend({}, opts);

	// Extend collection with methods, letting existing ones take precedence.
	_.defaults(collection, {

		/**
			 * Sets this collection's filter to `newFilter`, and resets this
			 * collection with the subset of models as defined by `newFilter`.
			 *
			 * @param {function} [newFilter]  New filter function. If not a
			 * 	function, equivalent to an empty (= allow all) filter.
			 */
		setFilter: function(newFilter) {
			const mixin = this.mixin_subsetCollection;
			newFilter = typeof newFilter === 'function' ? newFilter : undefined;

			// Filter hasn't changed. Exit.
			if (newFilter === mixin.filter) return;

			mixin.filter = newFilter;
			this.reset(_getFilteredModels(mixin.source, newFilter));
		},

		/**
			 * Called when a model is added to the source collection.
			 *
			 * Adds the new model to this collection too, if it passes this
			 * collection's filter.
			 *
			 * @param {Backbone.Model} model  Model added to source collection.
			 */
		onSourceCollectionAdd: function(model) {
			const filter = this.mixin_subsetCollection.filter;

			const filterType = typeof filter;

			// Add item if no filter, or if item passes existing filter.
			if (filterType === 'undefined' || (filterType === 'function' && filter(model))) {
				this.add(model);
			}
		},

		/**
			 * Called when a model is removed from the source collection.
			 *
			 * Removes the model from this collection too if it exists.
			 *
			 * @param {Backbone.Model} model  Model removed from source collection.
			 */
		onSourceCollectionRemove: function(model) {
			// Remove the item from our collection. Don't need to do filter
			// check, b/c this collection is a subset of the source, and
			// thus shouldn't keep items that the source no longer has.
			const m = this._byId[model.cid];
			if (m) this.remove(m);
		},

		/**
			 * Called when the source collection is reset.
			 *
			 * Resets this collection with the relevant subset of the source
			 * collection.
			 *
			 * @param {Backbone.Collection} collection  New source collection.
			 */
		onSourceCollectionReset: function(collection) {
			const mixin = this.mixin_subsetCollection;
			this.reset(_getFilteredModels(mixin.source, mixin.filter));
		}

	});

	// Set up events.
	collection.listenTo(source, 'add', collection.onSourceCollectionAdd);
	collection.listenTo(source, 'remove', collection.onSourceCollectionRemove);
	collection.listenTo(source, 'reset', collection.onSourceCollectionReset);

	// Initial set up: filter the source's models and put 'em in this collection.
	collection.reset(_getFilteredModels(source, filter), { silent: true });
};

export default subsetCollection;
