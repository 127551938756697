import $ from 'jquery';
import _ from 'underscore';
import app from 'app';
import Backbone from 'backbone';

export const Model = Backbone.Model.extend({
	initialize: function() {
		const that = this;

		const clientId = that.get('ClientId');
		const jobTypeId = that.get('JobTypeId');

		const jobId = that.get('JobId');

		that.set('id', Model.createId(clientId, jobTypeId, jobId));
		that.set('IsNew', !jobTypeId || !jobId);
		that.set('__dataFetch', that.loadData());

		that.on('change:JobId', function() {
			that.set('IsNew', false);
			that.set('id', Model.createId(that.get('ClientId'), that.get('JobTypeId'), that.get('JobId')));
		});
	},

	loadData: function() {
		const d = $.Deferred();

		const that = this;

		const clientId = that.get('ClientId');

		const jobTypeId = that.get('JobTypeId');

		const jobId = that.get('JobId');

		if (jobTypeId && jobId) {
			const dJobType = app.request('app.services.jobTypes.get', clientId, jobTypeId);
			const dJob = app.request('app.services.jobs.get', clientId, jobTypeId, jobId);

			$.when(dJobType, dJob).done(function(jobType, job) {
				that.set('JobType', jobType);
				that.set('Job', job);
				d.resolve(that);
			});
		} else {
			// This is a new job.
			app.request('app.services.jobTypes.get', clientId).done(function(jobTypesCollection) {
				that.set('JobTypes', jobTypesCollection);
				d.resolve(that);
			});
		}

		return d.promise();
	},

	getCurrentRoute: function() {
		return this.makeRoute(this.get('CurrentToolName'), this.get('CurrentSubtoolName'));
	},

	makeRoute: function(toolName, subToolName) {
		const that = this;

		return app.router.makeJobRoute(
			that.get('ClientId'),
			that.get('JobTypeId'),
			that.get('JobId'),
			toolName,
			subToolName
		);
	}

});

Model.createId = function(clientId, jobTypeId, jobId) {
	if (!(_.isString(clientId) && clientId !== '')) {
		throw new Error('clientId is invalid');
	}

	if (_.isString(jobTypeId) && jobTypeId !== '' && _.isString(jobId) && jobId !== '') {
		return `${clientId}/${jobTypeId}/${jobId}`;
	} else {
		return `${clientId}/new`;
	}
};
