var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="region-header" class="workspace-header">\n	<div class="main">\n		<h1 class="title">Returns used to calculate SRRI for '+
((__t=( collection.fund.get("FundName") ))==null?'':__t)+
' at '+
((__t=( collection.offsetDate ))==null?'':__t)+
'</h1>\n		<div class="description" style="margin-top:0.9em;">\n			<button class="btn js-downloadRaws"><span class="ldi ldi-download"> Download (CSV)</button>\n		</div>\n	</div>\n</div>\n<div id="region-body" class="workspace-content single-column no-scroll"></div>';
}
return __p;
};
