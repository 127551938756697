import $ from 'jquery';
import Marionette from 'marionette';
import QRCode from 'qrcode';
import tools from 'common/tools/main';
import template from './template.html';
import 'ui/button';

const chunk = tools.string.chunk;

const ToggleMfaView = Marionette.LayoutView.extend({
	template: template,

	regions: {
		region_qrcode: '.region-qrcode'
	},

	modelEvents: {
		'mfaEnabled': 'onMfaEnabled',
		'mfaDisabled': 'onMfaDisabled'
	},

	events: {
		'click .js-enableMfa': 'showSetupForm',
		'click .js-resetMfa': 'showSetupForm',
		'click .js-disableMfa': 'disableMfa',
		'click .js-verifySetup': 'verifySetup',
		'click .js-cancelSetup': 'cancelSetup'
	},

	templateHelpers: function() {
		return { friendlySecretKey: chunk(this.model.get('Secret').secretKey) };
	},

	onRender: function() {
		const $el = this.$el;
		const model = this.model;

		$el.find('.js-verifySetup, .js-disableMfa, .js-enableMfa').tmbutton();

		new QRCode($el.find('.qrcode')[0], {
			text: model.get('Secret').uri,
			colorDark: '#333',
			colorLight: 'transparent'
		});

		this.toggleMfaState(model.get('IsMFAEnabled'));
	},

	showMessage: function(msg, lvl) {
		this.$el.find('.js-messages')
			.removeClass('hidden')
			.html(`<div class="msg ${lvl}">${msg}</div>`);
	},

	clearMessages: function() {
		this.$el.find('.js-messages').addClass('hidden').html('');
	},

	showSetupForm: function() {
		const that = this;
		const $el = this.$el;

		that.clearMessages();

		// Show setup form, hide Enable/Disable/Reset buttons, clear input.
		$el.find('.js-controls').addClass('hidden');
		$el.find('.js-setupForm').removeClass('hidden');
		$el.find('.js-securityCode').val('');
	},

	hideSetupForm: function() {
		const $el = this.$el;

		// Hide setup form, and show Enable/Disable/Reset buttons
		$el.find('.js-controls').removeClass('hidden');
		$el.find('.js-setupForm').addClass('hidden');
	},

	toggleMfaState: function(state) {
		// If state is TRUE, show enabledOnly items and hide disabledOnly
		// items, and v.v. for state == FALSE.
		const $el = this.$el;
		$el.find('[data-class="enabledOnly"]').toggleClass('hidden', !state);
		$el.find('[data-class="disabledOnly"]').toggleClass('hidden', !!state);
	},

	verifySetup: function(e) {
		const that = this;
		const $el = that.$el;
		const model = that.model;
		// const secret = model.get('Secret').secretKey;
		const token = $.trim($el.find('.js-securityCode').val());

		// Update Verify button to busy state and remove all messages.
		$el.find('.js-verifySetup').tmbutton('state', 'busy');
		that.clearMessages();
		model.enableMfa(token);
	},

	cancelSetup: function() {
		this.clearMessages();
		this.hideSetupForm();
	},

	disableMfa: function(e) {
		// Update Disable button to busy state and remove all messages.
		this.$el.find('.js-disableMfa').tmbutton('state', 'busy');
		this.clearMessages();
		this.model.disableMfa();
	},

	onMfaEnabled: function(status, err) {
		const that = this;
		const isReset = that.model.get('IsMFAReset');
		let message;

		if (status === 'success') {
			// Successfully enabled. Hide form, update state, update model.
			that.hideSetupForm();
			that.toggleMfaState(true);
			message = isReset ? 'Two-factor authentication successfully reset.' : 'Two-factor authentication enabled.';
			that.showMessage(message, 'success');
		} else {
			// Failed to enable/reset MFA. Show error message, and keep
			// the form on display.
			message = isReset ? 'Failed to reset MFA. Please try again.' : 'Failed to enable MFA. Please try again.';
			that.showMessage(message, 'error');
		}

		// Reset button state.
		that.$el.find('.js-verifySetup').tmbutton('state', 'ready');
	},

	onMfaDisabled: function(status, err) {
		const that = this;

		if (status === 'success') {
			// Successfully disabled. Update state and model.
			that.toggleMfaState(false);
			that.showMessage('Two-factor authentication disabled.', 'success');
		} else {
			// Failed to disable MFA. Show error message.
			that.showMessage('Failed to disable MFA. Please try again.', 'error');
		}

		// Reset button state.
		that.$el.find('.js-disableMfa').tmbutton('state', 'ready');
	},

	onDestroy: function() {
		this.$('.js-verifySetup, .js-enableMfa, .js-disableMfa').tmbutton('destroy');
	}
});

export default ToggleMfaView;
