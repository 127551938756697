var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 var allowAll = CreateJobs && ImportData && CreateDocuments && ViewDocuments && PublishDocuments && PublishBlackline; 
__p+='\n\n<td class="pe-col-chbx"><input type="checkbox" name="pe-all" value="job001" ';
 if (allowAll) { 
__p+=' checked ';
 } 
__p+=' /></td>\n<td class="pe-col-name">'+
((__t=( JobTypeName ))==null?'':__t)+
'</td>\n<td class="pe-col-activity"><input type="checkbox" name="CreateJobs" ';
 if (CreateJobs) { 
__p+=' checked ';
 } 
__p+=' /></td>\n<td class="pe-col-activity"><input type="checkbox" name="ImportData" ';
 if (ImportData) { 
__p+=' checked ';
 } 
__p+=' /></td>\n<td class="pe-col-activity"><input type="checkbox" name="CreateDocuments" ';
 if (CreateDocuments) { 
__p+=' checked ';
 } 
__p+=' /></td>\n<td class="pe-col-activity"><input type="checkbox" name="ViewDocuments" ';
 if (ViewDocuments) { 
__p+=' checked ';
 } 
__p+=' /></td>\n<td class="pe-col-activity"><input type="checkbox" name="PublishDocuments" ';
 if (PublishDocuments) { 
__p+=' checked ';
 } 
__p+=' /></td>\n<td class="pe-col-activity"><input type="checkbox" name="PublishBlackline" ';
 if (PublishBlackline) { 
__p+=' checked ';
 } 
__p+=' /></td>';
}
return __p;
};
