var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="flyout-header publish-header">\n	<button class="js-close ldi ldi-close hoverable-light btn-close"></button>\n	';
 publisherTitle = typeof publisherTitle === 'undefined' ? 'Publish' : publisherTitle; 
__p+='\n	<h1>'+
((__t=( publisherTitle ))==null?'':__t)+
'</h1>\n	<p>Total: <span class="js-itemsToPublish"></span></p>\n	<p>\n		<button class="btn js-publish">Publish</button>\n		<button class="btn js-close">Cancel</button>\n	</p>\n</div>\n<div class="flyout-content publish-form">\n	<form class="form">\n\n\n	';
 _.each(sections, function(section) { 
__p+='\n\n		<div class="flyout-content-section \n				js-section-'+
((__t=( section.name ))==null?'':__t)+
' \n				'+
((__t=( section.open ? 'open' : '' ))==null?'':__t)+
'\n				'+
((__t=( section.hidden ? 'hidden' : '' ))==null?'':__t)+
'\n		">\n			<div class="flyout-content-section-header flyout-content-section-toggle-area">\n				<span class="flyout-content-section-toggle fa fa-caret-right"></span>\n				<h2>'+
((__t=( section.heading ))==null?'':__t)+
'</h2>\n			</div>\n			<div class="flyout-content-section-body">\n				'+
((__t=( templates._looper({ section: section, templates: templates }) ))==null?'':__t)+
'\n			</div>\n		</div>\n\n\n	';
 }); 
__p+='\n	\n	</form>\n</div>\n';
}
return __p;
};
