import Collection from 'common/modules/logs/model';

const OperationLogs = Collection.extend({
	onInitialize: function() {
		this.listenTo(this.options.operation, 'change:Status', this.onOperationStatusChange);
	},
	onOperationStatusChange: function(model, attr) {
		if (attr === 'Successful' || attr === 'Failed') {
			// Finished running this operation, so fetch listing for it.
			// Wait 1s first, for logs to be saved on server.
			const logId = this.options.operation.get('CorrelationId');
			this.fetchListing(logId, 1000);
		}
	}
});

export default OperationLogs;
