import _ from 'underscore';
import Backbone from 'backbone';
import View from './view';

const ViewModel = Backbone.Model.extend({
	defaults: {
		ClientId: ''
	},

	validate: function(attrs, options) {
		const errors = {};

		if (!attrs.ClientId) {
			errors.ClientId = 'Missing client id. Please contact LiveDoc support.';
		}

		if (_.isEmpty(errors)) {
			// So that views know this model has been validated.
			this.trigger('valid');
		} else {
			// Must return value only if there are errors.
			return errors;
		}
	}
});

function init(app) {
	return {
		getSelectClientView: function(clientId) {
			const viewModel = new ViewModel({ app, ClientId: clientId });
			const view = new View({ app, model: viewModel });

			return app.request('app.services.clients.list').then(function(clients) {
				view.refreshClients(clients);
				return view;
			});
		}
	};
}

export default init;
