import $ from 'jquery';
import Marionette from 'marionette';
import template from './template.html';

const SelectClientView = Marionette.ItemView.extend({
	template: template,

	events: {
		'change select': 'onInputChange'
	},

	modelEvents: {
		change: 'render'
	},

	initialize (opts) {
		this.app = opts.app;
		this.selectClient(this.app.session.get('clientId'), true);
		this.listenTo(this.app.session, 'change:clientId', this.onAppClientChange);
	},

	refreshClients: function(clientsCollection) {
		this.model.set('Clients', clientsCollection);
	},

	selectClient: function(clientId, updateUi) {
		// Defaults to true.
		updateUi = typeof updateUi === 'undefined' || updateUi;

		if (updateUi) {
			this.$el.find(`input[value="${clientId}"]`).prop('selected', true);
		}

		this.model.set('ClientId', clientId);

		// Request client change.
		this.app.trigger('app:client:change', clientId);
	},

	onInputChange: function(e) {
		const id = $(e.target).val();
		this.selectClient(id, false);
	},

	onAppClientChange: function(session) {
		this.selectClient(session.get('clientId'), true);
	}
});

export default SelectClientView;
