// console.log('Hello, from main.js');

import $ from 'jquery';
import login from './login';
import app from './app';
import ui from './ui/main';
import data from './dev/data';

// import Mockjax from './common/mocks/ajax';
// Intercept $.ajax and provide local mocks
// new Mockjax({ jQuery: $, mocks: data });

window.ui = ui;
window.app = app;

login.whenLoggedIn(function() {
	app.start(data);
});

$(document).on('click', '.js-logout', function() {
	login.signOut();
});
