import app from 'app';
import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import mixin from 'common/mixins/main';

const HistoryModel = Backbone.Model.extend({
	defaults: {
		'DownloadUri': ''
	},
	initialize: function() {
		this.setCustomProperties();
	},
	setCustomProperties: function() {
		const that = this;

		// Add download URI to model for non-delete revisions.
		if (!this.get('isDeleteMarker')) {
			const key = this.get('key');
			const requestOpts = { versionNumber: this.get('versionNumber') };
			app.repository.getDownloadUrl(key, requestOpts).done(function(uri) {
				that.set('DownloadUri', uri);
			});
		}
	},
	restoreVersion: function() {
		// Restoring == Copy the desired version to the same location.
		const that = this;
		const key = this.get('key');
		const versionNum = this.get('versionNumber');

		app.repository.restore(key, versionNum).done(function() {
			const filespecName = that.collection.options.filespec.get('name');
			app.vents.filespecs.trigger(`${filespecName}:file:changed`, that.get('name'));
		});
	}
});

const HistoryCollection = Backbone.Collection.extend({
	model: HistoryModel,
	initialize: function(collection, opts) {
		this.options = opts;
		this.settings = new Backbone.Model({
			path: opts.path,
			filter: opts.filter || ''
		});

		// Make this a sortable collection.
		mixin.sortableCollection({ that: this, sortProperty: 'lastModified', sortDirection: -1 });

		// Make this a pageable collection.
		mixin.pagingCollection({ that: this, fetchMethod: 'fetchNext' });

		// Consider refreshing history if files changed.
		// 'change:key' for renames, 'change:etag' for overwrites.
		this.listenTo(opts.filespec.get('Files'), 'add remove reset change:key change:etag', this.onFilesChange);
	},

	/** Gets next page of version information from repository. **/
	fetchNext: function(override) {
		const that = this;
		const settings = that.settings;

		// If there's no more data to fetch and not forcing refresh, exit.
		if (!override && !that.hasServerPages()) return;

		// This is a error-prone path concat but the repo api will normalize it.
		const filter = settings.get('filter');
		const path = `${settings.get('path')}/${filter || ''}`;
		const method = filter ? 'listVersions' : 'listChildrenVersions';

		const opts = { take: that.pageLength, skip: override ? 0 : that.length };

		return app.repository[method](path, opts).then(function(data, jqXhr) {
			const nCollection = that.length;
			// const nPage = data.length;

			// @todo sparklycats
			const links = app.repository.parseLinkHeader(jqXhr.getResponseHeader('Link'));
			that.setHasServerPages(!!links.next);

			const clientId = app.session.get('clientId');

			const promises = _.map(data, function(item) {
				return app.request('app.services.users.getProfile', clientId, item.author);
			});

			return $.whenAll.apply($, promises).always(function() {
				const results = Array.prototype.slice.apply(arguments);

				// test: promise failed (e.g. 500)
				let i = 0;
				const n = results.length;
				for (; i < n; i++) {
					if (typeof results[i].state !== 'function') {
						data[i].authorName = results[i].get('Email');
					}
				}

				// If collection is empty or overriding, use "reset" so that
				// only one event is triggered. Else, use "add".
				nCollection && !override ? that.add(data) : that.reset(data);

				return data;
			});
		});
	},

	refresh: function() {
		this.fetchNext(true);
	},

	onFilesChange: function(file) {
		// Noticed something change. Refresh history only if change is
		// for file whose history is currently being displayed.
		if (file.get('name') === this.settings.get('filter')) {
			this.fetchNext(true);
		}
	}
});

export default HistoryCollection;
