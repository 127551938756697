import Marionette from 'marionette';
import Collection from 'common/modules/logs/model';
import View from './logs_view';

const Controller = Marionette.Object.extend({
	initialize: function(workspaceController) {
		const workspaceModel = workspaceController.model;
		const job = workspaceModel.get('Job');
		const prefix = `clients/${job.get('ClientId')}/jobtypes/${job.get('JobTypeId')}/jobs/${job.get('Id')}/logs/`;

		const collection = new Collection(null, {
			pageOptions: { pageLength: 50 },
			prefix: prefix
		});
		this.view = new View({ collection: collection, workspaceModel: workspaceModel });
	}
});

const create = function(workspaceController) {
	return new Controller(workspaceController);
};

export { create };
