var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--base pager-->\n<!--<div>-->\n	<div class="tbf2-control">'+
((__t=( first ))==null?'':__t)+
' to '+
((__t=( last ))==null?'':__t)+
' of '+
((__t=( total ))==null?'':__t)+
'</div>\n	<div class="tbf2-control">\n	<span class="btn-group">\n		<button class="js-prev btn '+
((__t=( prevDisabled ? 'disabled' : '' ))==null?'':__t)+
'"><span class="fa fa-caret-left"></span></button>\n		<button class="js-next btn '+
((__t=( nextDisabled ? 'disabled' : '' ))==null?'':__t)+
'"><span class="fa fa-caret-right"></span></button>\n	</span>\n	</div>\n<!--</div>-->\n';
}
return __p;
};
