// define([], function() {

export default [
	// job type collection
	{
		urlRegex: /^https?:\/\/.*transmissionmedia\.ca\/api\/jobmanagement\/clients\/([^\/]+)\/jobtypes$/,
		data: [
			{
				'ClientId': 'fc7c66b2-38f2-414c-939b-24d5830e9657',
				'DisplayName': 'Example',
				'Description': 'exaaaammmplleeeee',
				'IsActive': true,
				'Id': 'cd8e17f8-2934-4551-85dc-1c59afcbf465',
				'TimeStamp': 635914835330818216
			},
			{
				'ClientId': 'fc7c66b2-38f2-414c-939b-24d5830e9657',
				'DisplayName': 'KIID',
				'Description': 'KIID',
				'IsActive': false,
				'Id': '7b56c0eb-8682-4abd-a835-f7540a83d3d6',
				'TimeStamp': 635985722449608222
			},
			{
				'ClientId': 'fc7c66b2-38f2-414c-939b-24d5830e9657',
				'DisplayName': 'MRFP Trial',
				'Description': 'MRFP Trial',
				'IsActive': false,
				'Id': '21174327-5bf5-4ba7-95c2-9d0904a148c6',
				'TimeStamp': 636011709448688379
			}
		]
	},

	// job summaries
	{
		urlRegex: /^https?:\/\/.*transmissionmedia\.ca\/api\/jobmanagement\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/jobs$/i,
		data: [
			{
				'ClientId': 'fc7c66b2-38f2-414c-939b-24d5830e9657',
				'JobTypeId': 'cd8e17f8-2934-4551-85dc-1c59afcbf465',
				'DisplayName': 'Demo',
				'Description': 'whatever la',
				'DateCreated': '2016-06-27T17:21:45.433Z',
				'DateModified': '2016-06-27T17:21:45.433Z',
				'IsActive': true,
				'UserId': '78f7b1f5-cf6e-48e1-87cb-9c6ed69b5eac',
				'Id': '82fb8a47-e201-42eb-9725-89dcc54052f4',
				'TimeStamp': 636035033649828846
			}
		]
	},

	// job collection
	{
		urlRegex: /^https?:\/\/.*transmissionmedia\.ca\/api\/jobmanagement\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/jobs\/$/,
		data: []
	},

	// job
	{
		urlRegex: /^https?:\/\/.*transmissionmedia\.ca\/api\/jobmanagement\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/jobs\/([^\/]+)$/i,
		data: {}
	},

	// job type settings.json
	{
		urlRegex: /^https?:\/\/((.*transmissionmedia\.ca)|(localhost\:\d+))\/api\/repository\/resources\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/settings.json$/i,
		data: {
			DisplayName: 'MRFP Trial',
			Description: 'A test job to do MRFP documents from Word templates',
			Attributes: [
				{
					Name: 'DocumentDate',
					DisplayName: 'Document Date',
					Required: true,
					Description: 'Reporting period As At Date',
					Value: '20151231'
				}
			],
			PublishSettings: {
				PredefinedDocumentFileName: {
					Name: 'FundCode - FundName',
					Example: 'H6A9 - Balanced Bond Fund.pdf',
					Value: 'FundCode - FundName'
				}
			}
		}
	},
	// job settings.json
	{
		urlRegex: /^https?:\/\/((.*transmissionmedia\.ca)|(localhost\:\d+))\/api\/repository\/resources\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/jobs\/([^\/]+)\/settings.json$/i,
		data: {
			// ...other properties here, e.g. Attributes, DisplayName
			DisplayName: 'Demo job',
			Description: 'AYYYYY',

			PublishSettings: {
				PredefinedDocumentFileName: {
					Name: 'FundCode - FundName',
					Example: 'H6A9 - Balanced Bond Fund.pdf',
					Value: 'FundCode - FundName'
				}
			}
		}
	},

	// documents manifest .meta
	{
		urlRegex: /^https?:\/\/(?:(?:.*transmissionmedia\.ca)|(?:localhost\:\d+))\/api\/repository\/meta\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/jobs\/([^\/]+)\/documents\/manifest.json$/i,
		data: {
			headers: {
				'Content-Disposition': 'blah'
			},
			id: 'foobar',
			lastModified: 636035033649828846,
			filename: 'foobar'
		},
		getResponseHeader: () => 200
	},

	// documents manifest
	{
		urlRegex: /^https?:\/\/(?:(?:.*transmissionmedia\.ca)|(?:localhost\:\d+))\/api\/repository\/resources\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/jobs\/([^\/]+)\/documents\/manifest.json$/i,
		data: {
			'Manifest': [
				{
					id: 1,
					secret: '45876',
					secret2: 'drfgdfg',
					displayName: 'Fancy unicorn hedge fund',
					locale: 'en-US'
				},
				{
					id: 2,
					secret: '45877',
					secret2: 'uigfbdf',
					displayName: 'Le fancy unicorn hedge fond',
					locale: 'fr-CA'
				}
			],
			'Attributes': {
				id: {
					Name: 'id',
					DisplayName: 'Id',
					IsVisible: true,
					Type: 'number',
					hasColumnFilter: true,
					filterType: 'range',
					DisplayOrder: 3
				},
				displayName: {
					displayName: 'Fund name',
					hasColumnFilter: false,
					displayOrder: 1
				},
				secret: {
					isVisible: false // lower
				},
				secret2: {
					IsVisible: false // upper
				},
				locale: {
					displayName: 'Locale',
					displayOrder: 2
				}
			}
		}
	},

	// inputs
	// {
	//     urlRegex: /^https?:\/\/(?:(?:.*transmissionmedia\.ca)|(?:localhost\:\d+))\/api\/repository\/resources\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/jobs\/([^\/]+)\/inputs\/$/i,
	//     /*data: [
	//       {
	//         "id": "/clients/fc7c66b2-38f2-414c-939b-24d5830e9657/jobtypes/21174327-5bf5-4ba7-95c2-9d0904a148c6/jobs/975bd998-9de0-49cc-80fb-0550afe96901/inputs/settings.json",
	//         "author": "705ee825-0245-4309-b319-7b16af8538e0",
	//         "container": "/clients/fc7c66b2-38f2-414c-939b-24d5830e9657/jobtypes/21174327-5bf5-4ba7-95c2-9d0904a148c6/jobs/975bd998-9de0-49cc-80fb-0550afe96901/inputs/",
	//         "name": "settings.json",
	//         "isDeleted": false,
	//         "versionNumber": 1,
	//         "etag": "\"OrbQ4KO13PxwFm7qtVsSSg==\"",
	//         "lastModified": "2017-01-27 19:03:02Z",
	//         "headers": {
	//           "Content-Length": [
	//             "1593"
	//           ]
	//         },
	//         "type": null,
	//         "numberOfChildren": 0
	//       }
	//     ],*/
	//     data: function(reUrlMatches) {
	//         var clientId = reUrlMatches[1];
	//         var jobTypeId = reUrlMatches[2];
	//         var jobId = reUrlMatches[3];
	//         var data = [];
	//         var inputNames = ['master', 'dictionary', 'returns', 'annotations'];

	//         for (var i = 0, n = inputNames.length; i < n; i++) {
	//             data.push({
	//                 "id": `/clients/${clientId}/jobtypes/${jobTypeId}/jobs/${jobId}/inputs/${inputNames[i]}`,
	//                 "author": "705ee825-0245-4309-b319-7b16af8538e0",
	//                 "container": `/clients/${clientId}/jobtypes/${jobTypeId}/jobs/${jobId}/inputs/`,
	//                 "name": inputNames[i],
	//                 "isDeleted": false,
	//                 "versionNumber": 1,
	//                 "etag": `"${i}PJ1Bt6OBKuA+IC4STRXoQ=="`,
	//                 "lastModified": "2017-01-27 19:02:56Z",
	//                 "headers": {
	//                   "ldr-type": [
	//                     "collection"
	//                   ]
	//                 },
	//                 "type": "collection",
	//                 "numberOfChildren": 1
	//             });
	//         }

	//         return data;
	//     }
	// },

	// inputs - upload
	{
		urlRegex: /^https?:\/\/(?:(?:.*transmissionmedia\.ca)|(?:localhost\:\d+))\/api\/repository\/resources\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/jobs\/([^\/]+)\/inputs\/([^\.][^\/]+)\/([^\.][^\/]+)$/i,
		data: function(reUrlMatches) {
			const clientId = reUrlMatches[1];
			const jobTypeId = reUrlMatches[2];
			const jobId = reUrlMatches[3];
			const inputId = reUrlMatches[4];
			const filename = reUrlMatches[5];

			return {
				'id': `/clients/${clientId}/jobtypes/${jobTypeId}/jobs/${jobId}/inputs/${inputId}/${filename}`,
				'author': '705ee825-0245-4309-b319-7b16af8538e0',
				'container': `/clients/${clientId}/jobtypes/${jobTypeId}/jobs/${jobId}/inputs/`,
				'name': filename,
				'isDeleted': false,
				'versionNumber': 1,
				'etag': `"foo-PJ1Bt6OBKuA+IC4STRXoQ=="`,
				'lastModified': '2017-01-27 19:02:56Z'
				// "headers": {
				//  "ldr-type": [
				//	"collection"
				//  ]
				// },
				// "type": "collection",
				// "numberOfChildren": 1
			};
		},
		jqXhr: {
			getResponseHeader: () => 201
		}
	},

	// inputs - version history
	// {
	//     urlRegex: /^https?:\/\/(?:(?:.*transmissionmedia\.ca)|(?:localhost\:\d+))\/api\/repository\/collection-versions\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/jobs\/([^\/]+)\/inputs\/([^\/]+)$/i,
	//     data: function(reUrlMatches) {
	//         var clientId = reUrlMatches[1];
	//         var jobTypeId = reUrlMatches[2];
	//         var jobId = reUrlMatches[3];
	//         var inputId = reUrlMatches[4];
	//         var data = [];
	//         var filenames = ['foo', 'bar', 'xxx', 'yyy', 'zzz'];

	//         for (var i = 0, n = filenames.length; i < n; i++) {
	//             data.push({
	//                 "key": `/clients/${clientId}/jobtypes/${jobTypeId}/jobs/${jobId}/inputs/${inputId}/${filenames[i]}`,
	//                 "resourceId": `/clients/${clientId}/jobtypes/${jobTypeId}/jobs/${jobId}/inputs/${inputId}/${filenames[i]}`,
	//                 "author": "50307502-a450-4d0d-b8f6-28824bc11424",
	//                 "container": `/clients/${clientId}/jobtypes/${jobTypeId}/jobs/${jobId}/inputs/${inputId}`,
	//                 "name": filenames[i],
	//                 "isDeleteMarker": false,
	//                 "versionNumber": 1,
	//                 "etag": `"${i}PJ1Bt6OBKuA+IC4STRXoQ=="`,
	//                 "lastModified": "2017-01-27 19:02:56Z"
	//             });
	//         }

	//         return data;
	//     },
	//     jqXhr: {
	//         getResponseHeader: (key) => {
	//             console.log('getting response header');
	//             if (key == 'Count') {
	//                 return 59;
	//             } else if (key == 'Link') {
	//                 return '<../blurbs>; rel="parent", <.?skip=5&take=5>; rel="next"; title="next page"';
	//             }
	//         }
	//     }
	// },

	// operations
	{
		urlRegex: /^https?:\/\/(?:(?:.*transmissionmedia\.ca)|(?:localhost\:\d+))\/api\/repository\/resources\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/jobs\/([^\/]+)\/configuration\/operations\/$/i,
		data: function(reUrlMatches) {
			const clientId = reUrlMatches[1];
			const jobTypeId = reUrlMatches[2];
			const jobId = reUrlMatches[3];
			return [
				{
					'id': `/clients/${clientId}/jobtypes/${jobTypeId}/jobs/${jobId}/configuration/operations/01-createDocuments`,
					'author': '705ee825-0245-4309-b319-7b16af8538e0',
					'container': `/clients/${clientId}/jobtypes/${jobTypeId}/jobs/${jobId}/configuration/operations/`,
					'name': '01-createDocuments',
					'isDeleted': false,
					'versionNumber': 1,
					'etag': '"eWY4FQaxc02KlnalObOaDw=="',
					'lastModified': '2017-01-27 19:02:48Z',
					'headers': {
						'Content-Length': ['0'],
						'ldr-type': ['collection']
					},
					'type': 'collection',
					'numberOfChildren': 11
				}
			];
		}
	},

	// operations settings.json
	{
		urlRegex: /^https?:\/\/(?:(?:.*transmissionmedia\.ca)|(?:localhost\:\d+))\/api\/repository\/resources\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/jobs\/([^\/]+)\/configuration\/operations\/([^\/]+)\/settings\.json$/i,
		data: function(reUrlMatches) {
			const clientId = reUrlMatches[1];
			const jobTypeId = reUrlMatches[2];
			const jobId = reUrlMatches[3];
			const operationId = reUrlMatches[4];
			return {
				'DisplayName': 'Create Documents',
				'Description': 'Create the MRFP single-fund document pages',
				'ScriptLocation': `configuration/operations/${operationId}/createDocuments.js`,
				'RunAsCommand': true
			};
		}
	},

	// current user
	{
		urlRegex: /^https?:\/\/.*transmissionmedia\.ca\/api\/usermanagement\/current$/i,
		/* data: {
                ClientId: '8c6a83e1-a63a-4708-a1d1-b62ff02635e3',
                Id: 'lsm13svt-0218-45d8-954a-6be0248c3e0d'
            } */
		data: {
			ClientId: 'fc7c66b2-38f2-414c-939b-24d5830e9657',
			Id: '705ee825-0245-4309-b319-7b16af8538e0'
		}
	},

	// add user
	{
		urlRegex: /^https?:\/\/(?:(?:.*transmissionmedia\.ca)|(?:localhost\:\d+))\/api\/usermanagement\/clients\/([^\/]+)$/i,
		data: function(reUrlMatches, requestOpts) {
			const clientId = reUrlMatches[1];

			if (/^put$/i.test(requestOpts.method) || /^put$/i.test(requestOpts.type)) {
				// A successful PUT returns new user info
				return {
					ClientId: clientId,
					AccountId: '1202a046-cfcb-4e09-aed0-8853335d5c2c',
					EmailAddress: 'test2018060701@transmissionmedia.ca',
					LoginUrl: null
				};
			}
		}
	},

	// set subject's security groups
	{
		urlRegex: /^https?:\/\/(?:(?:.*transmissionmedia\.ca)|(?:localhost\:\d+))\/api\/usermanagement\/clients\/([^\/]+)\/subjects\/([^\/]+)\/groups$/i,
		data: function(reUrlMatches, requestOpts) {
			const clientId = reUrlMatches[1];
			const subjectId = reUrlMatches[2];

			if (/^put$/i.test(requestOpts.method) || /^put$/i.test(requestOpts.type)) {
				return {};
			}
		}
	},

	// user groups collection
	{
		urlRegex: /^https?:\/\/.*transmissionmedia\.ca\/api\/usermanagement\/clients\/([^\/]+)\/groups$/i,
		data: function(matches) {
			const clientId = matches[1];

			if (clientId === '8c6a83e1-a63a-4708-a1d1-b62ff02635e3') { // marlborough
				return [
					{
						'Id': 'admin',
						'Name': 'Administrators',
						'Policies': [
							{
								'Id': 'admin',
								'Name': 'Admin',
								'Permissions': [
									`allow /Client:${clientId}//*:*:*`,
									`allow /Client:${clientId}:View`
								]
							}
						],
						'IsSystemDefined': true,
						'Timestamp': 0
					},
					{
						'Id': 'v',
						'Name': 'Vocals',
						'Policies': {
							'Id': 'v',
							'Name': 'High notes',
							'Permissions': [
								`allow /Client:${clientId}:View`
							]
						},
						'IsSystemDefined': false,
						'Timestamp': 0
					},
					{
						'Id': 'p',
						'Name': 'Performance',
						'Policies': {
							'Id': 'p',
							'Name': 'Sharp moves',
							'Permissions': [
								`allow /Client:${clientId}:View`
							]
						},
						'IsSystemDefined': false,
						'Timestamp': 0
					}
				];
			} else {
				return [
					{
						'Id': '812e5235-eae9-40fa-aea0-1f3fe2229ee0',
						'Name': 'Administrators',
						'Policies': [
							{
								'Id': 'fb196f46-e162-4b68-a9e6-ad2136c0a6ec',
								'Name': 'Grants administration privileges',
								'Permissions': [
									`allow /Client:${clientId}//*:*:*`,
									`allow /Client:${clientId}:View`
								]
							}
						],
						'IsSystemDefined': true,
						'Timestamp': 0
					},
					{
						'Id': '4c1051b5-8f19-449b-9d85-7ca10e404531',
						'Name': 'Grants global system administration privileges',
						'Policies': [
							{
								'Id': 'dfda21de-16f7-43a7-853d-adcea703e976',
								'Name': 'grant all',
								'Permissions': [
									'allow //*:*:*'
								]
							}
						],
						'IsSystemDefined': true,
						'Timestamp': 0
					},
					{
						'Id': 'f9c36419-369c-44c6-91c0-ae7c1ec8a699',
						'Name': 'Cake Tester',
						'Policies': {
							'Id': 'fb196f46-e162-4b68-a9e6-ad2136c0a6ec',
							'Name': 'View items',
							'Permissions': [
								`allow /Client:${clientId}:View`
							]
						},
						'IsSystemDefined': false,
						'Timestamp': 0
					}
				];
			}
		}
	},

	// user subjects collection
	{
		urlRegex: /^https?:\/\/.*transmissionmedia\.ca\/api\/usermanagement\/clients\/([^\/]+)\/subjects$/i,
		data: function(matches) {
			const clientId = matches[1];

			if (clientId === '8c6a83e1-a63a-4708-a1d1-b62ff02635e3') { // marlborough
				return [
					{
						'Id': 'lsm13svt-0218-45d8-954a-6be0248c3e0d',
						'Groups': ['v', 'admin']
					}
				];
			} else {
				return [
					{
						'Id': 'efa7adff-d15a-45ee-8321-5e6b7027e25c',
						'Groups': [
							'812e5235-eae9-40fa-aea0-1f3fe2229ee0'
						]
					},
					{
						'Id': '705ee825-0245-4309-b319-7b16af8538e0',
						'Groups': [
							'4c1051b5-8f19-449b-9d85-7ca10e404531'
						]
					},
					{
						'Id': '50307502-a450-4d0d-b8f6-28824bc11424',
						'Groups': [
							'f9c36419-369c-44c6-91c0-ae7c1ec8a699'
						]
					}
				];
			}
		}
	},

	// user profiles collection
	{
		urlRegex: /^https?:\/\/.*transmissionmedia\.ca\/api\/usermanagement\/clients\/([^\/]+)\/userprofiles\/?$/i,
		data: function(matches) {
			const clientId = matches[1];

			if (clientId === '8c6a83e1-a63a-4708-a1d1-b62ff02635e3') { // marlborough
				return [
					{
						'Id': 'lsm13svt-0218-45d8-954a-6be0248c3e0d',
						'ClientId': clientId,
						'Email': 'dk@svt.com',
						'FirstName': '',
						'LastName': '',
						'Timestamp': 0
					}
				];
			} else {
				return [
					{
						'Id': 'efa7adff-d15a-45ee-8321-5e6b7027e25c',
						'ClientId': clientId,
						'Email': 'hamid@transmissionmedia.ca',
						'FirstName': '',
						'LastName': '',
						'Timestamp': 0
					},
					{
						'Id': '50307502-a450-4d0d-b8f6-28824bc11424',
						'ClientId': clientId,
						'Email': 'michael@transmissionmedia.ca',
						'FirstName': '',
						'LastName': '',
						'Timestamp': 0
					},
					{
						'Id': '705ee825-0245-4309-b319-7b16af8538e0',
						'ClientId': clientId,
						'Email': 'caroline@transmissionmedia.ca',
						'FirstName': '',
						'LastName': '',
						'Timestamp': 0
					}
				];
			}
		}
	},

	// user profile - single
	{
		urlRegex: /^https?:\/\/.*transmissionmedia\.ca\/api\/usermanagement\/clients\/([^\/]+)\/userprofiles\/([^\/]+)?$/i,
		data: function(matches) {
			const clientId = matches[1];
			const userId = matches[2];
			return {
				'Id': userId,
				'ClientId': clientId,
				'Email': 'foobar@transmissionmedia.ca',
				'FirstName': '',
				'LastName': '',
				'Timestamp': 0
			};
		}
	},

	// monitor collection
	{
		urlRegex: /^https?:\/\/(?:(?:.*transmissionmedia\.ca)|(?:localhost\:\d+))\/api\/meta\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/outputs\/(\?.*)?$/i,
		data: function(reUrlMatches) {
			const clientId = reUrlMatches[1];
			const jobTypeId = reUrlMatches[2];
			const data = [];

			for (let i = 0, n = 20; i < n; i++) {
				data.push({
					'_id': `/clients/${clientId}/jobtypes/${jobTypeId}/outputs/${i}`,
					'ClientId': clientId,
					'JobTypeId': jobTypeId,
					'JobId': '82fb8a47-e201-42eb-9725-89dcc54052f4',
					'SourceId': i,
					'Id': i,
					'Version': 2,
					'Date': '2017-01-23T22:12:28.366Z',
					'UserId': '6f1d999d-4626-49fd-9c7c-c4946f62c227',
					'CorrelationId': '52d268be-5fe1-4c72-8de4-7860b14b0c96',
					'Status': 'Successful',
					'DisplayName': `Document set ${i}`,
					'OutputType': 'DocumentSet'
				});
			}

			return data;
		}
	},

	// document set tags
	{
		urlRegex: /^https?:\/\/(?:(?:.*transmissionmedia\.ca)|(?:localhost\:\d+))\/api\/repository\/tags\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/outputs\/([^\/]+)$/i,
		data: function(reUrlMatches, requestOpts) {
			const clientId = reUrlMatches[1];
			const jobTypeId = reUrlMatches[2];
			const outputId = reUrlMatches[3];

			if (/^patch$/i.test(requestOpts.method)) {
				return ''; // A successful patch just returns an empty string
			} else {
				const data = Math.random() > 0.5 ? ['foo', 'bar', 'final'] : ['foo'];
				return JSON.stringify(data);
			}
		}
	},

	// srri
	{
		urlRegex: /^https?:\/\/.*transmissionmedia\.ca\/api\/srri\/([^\/]+)\/getall$/i,
		data: {
			'Documents': [
				{
					'Id': 'dfc3d872-9ab6-4dc8-84ec-b488227c53b0',
					'MasterKey': 'GB00B9L64Y17',
					'CalculatedData': {
						'ClientId': '8c6a83e1-a63a-4708-a1d1-b62ff02635e3',
						'JobTypeId': 'c10770b2-96f3-44a3-b0d0-a2984405fa79',
						'JobId': '6f1f4cec-e5a5-4ecb-837b-d2a849f72b8c',
						'Id': 5,
						'EffectiveDate': '2016-10-31',
						'Isin': 'GB00B9L64Y17',
						'FundName': 'Marlborough Defensive Fund, Class P Shares',
						'ShareClassName': 'Accumulation',
						'CalculatedSrri': 3,
						'CalculatedSrriDate': '2016-10-31',
						'NumWeeksOutside': 0,
						'SrriValues': [
							{
								'Date': '2016-10-16',
								'Srri': 3
							},
							{
								'Date': '2016-10-16',
								'Srri': 3
							},
							{
								'Date': '2016-10-16',
								'Srri': 3
							},
							{
								'Date': '2016-10-09',
								'Srri': 3
							},
							{
								'Date': '2016-10-02',
								'Srri': 3
							},
							{
								'Date': '2016-09-25',
								'Srri': 3
							},
							{
								'Date': '2016-09-18',
								'Srri': 3
							},
							{
								'Date': '2016-09-11',
								'Srri': 3
							},
							{
								'Date': '2016-09-04',
								'Srri': 3
							},
							{
								'Date': '2016-08-28',
								'Srri': 3
							},
							{
								'Date': '2016-08-21',
								'Srri': 3
							},
							{
								'Date': '2016-08-14',
								'Srri': 3
							},
							{
								'Date': '2016-08-07',
								'Srri': 3
							},
							{
								'Date': '2016-07-31',
								'Srri': 3
							},
							{
								'Date': '2016-07-24',
								'Srri': 3
							},
							{
								'Date': '2016-07-17',
								'Srri': 3
							},
							{
								'Date': '2016-07-10',
								'Srri': 3
							}
						],
						'PublishedSrri': 3,
						'PublishedSrriDate': '2016-10-31'
					},
					'PublishedData': {
						'ClientId': '8c6a83e1-a63a-4708-a1d1-b62ff02635e3',
						'JobTypeId': '49c41cfb-a0e6-46bf-8a7e-c53190864d2b',
						'JobId': '2bac6fa3-1c8f-4cc8-9ca6-c5f58eed3d79',
						'Id': 43,
						'ISIN': 'GB00B9L64Y17',
						'FundName': 'Marlborough Defensive Fund, Class P Shares',
						'ShareClassName': 'Test',
						'EffectiveDate': '2016-10-31',
						'PublishedSRRI': 3,
						'PublishedSRRIDate': '2016-10-31'
					}
				},
				{
					'Id': '5a4534f6-4916-468d-828d-2cd4f534b2f0',
					'MasterKey': 'GB00B9L64W92',
					'CalculatedData': {
						'ClientId': '8c6a83e1-a63a-4708-a1d1-b62ff02635e3',
						'JobTypeId': 'c10770b2-96f3-44a3-b0d0-a2984405fa79',
						'JobId': '6f1f4cec-e5a5-4ecb-837b-d2a849f72b8c',
						'Id': 69,
						'EffectiveDate': '2016-10-31',
						'Isin': 'GB00B9L64W92',
						'FundName': 'Marlborough Defensive Fund B Inc',
						'ShareClassName': 'Income',
						'CalculatedSrri': 3,
						'CalculatedSrriDate': '2016-10-31',
						'NumWeeksOutside': 44,
						'SrriValues': [
							{
								'Date': '2016-10-16',
								'Srri': 3
							},
							{
								'Date': '2016-10-16',
								'Srri': 3
							},
							{
								'Date': '2016-10-16',
								'Srri': 3
							},
							{
								'Date': '2016-10-09',
								'Srri': 3
							},
							{
								'Date': '2016-10-02',
								'Srri': 3
							},
							{
								'Date': '2016-09-25',
								'Srri': 3
							},
							{
								'Date': '2016-09-18',
								'Srri': 3
							},
							{
								'Date': '2016-09-11',
								'Srri': 3
							},
							{
								'Date': '2016-09-04',
								'Srri': 3
							},
							{
								'Date': '2016-08-28',
								'Srri': 3
							},
							{
								'Date': '2016-08-21',
								'Srri': 3
							},
							{
								'Date': '2016-08-14',
								'Srri': 3
							},
							{
								'Date': '2016-08-07',
								'Srri': 3
							},
							{
								'Date': '2016-07-31',
								'Srri': 3
							},
							{
								'Date': '2016-07-24',
								'Srri': 3
							},
							{
								'Date': '2016-07-17',
								'Srri': 3
							},
							{
								'Date': '2016-07-10',
								'Srri': 3
							}
						],
						'PublishedSrri': 0,
						'PublishedSrriDate': '2016-01-01'
					},
					'PublishedData': {
						'ClientId': '8c6a83e1-a63a-4708-a1d1-b62ff02635e3',
						'JobTypeId': '49c41cfb-a0e6-46bf-8a7e-c53190864d2b',
						'JobId': '2bac6fa3-1c8f-4cc8-9ca6-c5f58eed3d79',
						'Id': 230,
						'EffectiveDate': '2016-01-01',
						'ISIN': 'GB00B9L64W92',
						'FundName': 'Marlborough Defensive Fund B Inc',
						'ShareClassName': '',
						'PublishedSRRI': 0,
						'PublishedSRRIDate': '2016-01-01'
					}
				},
				{
					'Id': 'b35d64ce-e233-4c79-9e01-0cfb7255d5fe',
					'MasterKey': 'GB00B90VHJ34',
					'CalculatedData': {
						'ClientId': '8c6a83e1-a63a-4708-a1d1-b62ff02635e3',
						'JobTypeId': 'c10770b2-96f3-44a3-b0d0-a2984405fa79',
						'JobId': '6f1f4cec-e5a5-4ecb-837b-d2a849f72b8c',
						'Id': 68,
						'EffectiveDate': '2016-10-31',
						'Isin': 'GB00B90VHJ34',
						'FundName': 'Marlborough European Multi-Cap P Inc',
						'ShareClassName': 'Income',
						'CalculatedSrri': 5,
						'CalculatedSrriDate': '2016-10-31',
						'NumWeeksOutside': 44,
						'SrriValues': [
							{
								'Date': '2016-10-16',
								'Srri': 5
							},
							{
								'Date': '2016-10-16',
								'Srri': 5
							},
							{
								'Date': '2016-10-16',
								'Srri': 5
							},
							{
								'Date': '2016-10-09',
								'Srri': 5
							},
							{
								'Date': '2016-10-02',
								'Srri': 5
							},
							{
								'Date': '2016-09-25',
								'Srri': 5
							},
							{
								'Date': '2016-09-18',
								'Srri': 5
							},
							{
								'Date': '2016-09-11',
								'Srri': 5
							},
							{
								'Date': '2016-09-04',
								'Srri': 5
							},
							{
								'Date': '2016-08-28',
								'Srri': 5
							},
							{
								'Date': '2016-08-21',
								'Srri': 5
							},
							{
								'Date': '2016-08-14',
								'Srri': 5
							},
							{
								'Date': '2016-08-07',
								'Srri': 5
							},
							{
								'Date': '2016-07-31',
								'Srri': 5
							},
							{
								'Date': '2016-07-24',
								'Srri': 5
							},
							{
								'Date': '2016-07-17',
								'Srri': 6
							},
							{
								'Date': '2016-07-10',
								'Srri': 6
							}
						],
						'PublishedSrri': 0,
						'PublishedSrriDate': '2016-01-01'
					},
					'PublishedData': {
						'ClientId': '8c6a83e1-a63a-4708-a1d1-b62ff02635e3',
						'JobTypeId': '49c41cfb-a0e6-46bf-8a7e-c53190864d2b',
						'JobId': '2bac6fa3-1c8f-4cc8-9ca6-c5f58eed3d79',
						'Id': 246,
						'EffectiveDate': '2016-01-01',
						'ISIN': 'GB00B90VHJ34',
						'FundName': 'Marlborough European Multi-Cap P Inc',
						'ShareClassName': '',
						'PublishedSRRI': 0,
						'PublishedSRRIDate': '2016-01-01'
					}
				}
			]
		}
	},

	// job logs
	{

		urlRegex: /^https?:\/\/(?:(?:.*transmissionmedia\.ca)|(?:localhost\:\d+))\/api\/repository\/resources\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/jobs\/([^\/]+)\/logs\/(\?.*)?$/i,
		data: function(reUrlMatches, requestOpts) {
			const clientId = reUrlMatches[1];
			const jobTypeId = reUrlMatches[2];
			const jobId = reUrlMatches[3];
			const query = requestOpts.data || {};
			const skip = typeof query.skip !== 'undefined' ? query.skip : 0;
			const take = typeof query.take !== 'undefined' ? query.take : 0;
			const data = [];

			for (let i = skip, n = skip + take; i < n; i++) {
				data.push({
					'id': `/clients/${clientId}/jobtypes/${jobTypeId}/jobs/${jobId}/logs/api.preview.js.${i}.log`,
					'author': '705ee825-0245-4309-b319-7b16af8538e0',
					'name': `api.preview.js.${i}.log`,
					'isDeleted': false,
					'versionNumber': 1,
					'etag': '"hnS5VCAaySpD34EN+10Dqw=="',
					'lastModified': '2017-02-09 17:53:50Z',
					'headers': {
						'Content-Length': [
							'1960'
						],
						'Content-Type': [
							'text/plain; charset=utf-8'
						]
					},
					'type': null,
					'numberOfChildren': 0
				});
			}

			return data;
		},
		jqXhr: {
			getResponseHeader: () => 260
		}
	},

	// job log
	{
		urlRegex: /^https?:\/\/(?:(?:.*transmissionmedia\.ca)|(?:localhost\:\d+))\/api\/repository\/resources\/clients\/([^\/]+)\/jobtypes\/([^\/]+)\/jobs\/([^\/]+)\/logs\/([^\/]+)$/i,
		data: () => {}
	}
];

// });
