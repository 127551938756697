import app from 'app';
import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import Model from './blackline_model';
import View from './blackline_view';
import BlacklineTable from './blacklineTable/blacklineTable_ctrl';
import CatalogueView from './docSetExplorer/view_catalogue';
import SelectedView from './docSetExplorer/view_selected';

const debug = false;

// @todo relocate meeeeee~
const CatalogueLoadingView = Marionette.ItemView.extend({
	template: _.template(
		'<div class="column-inner"><div class="bl-setList-available-newer">' +
		'<h2 class="empty-state fill-container">Loading data... ' +
		'<span class="ldi ldi-spinner"></span>' +
		'</h2>' +
		'</div>'
	)
});

const Controller = Marionette.Object.extend({
	initialize: function(workspaceController) {
		const that = this;
		const workspaceModel = workspaceController.model;

		// Fetch list of document sets.
		const job = that.options.model.get('Job');
		this.docsetsPromise = app.request(
			'app.services.publishing.getDocumentSets',
			job.get('ClientId'),
			job.get('JobTypeId')
		);

		// Prepare blackline model.
		this.blacklineModel = new Model({ workspaceModel: workspaceModel });
		this.docsetsPromise.done(function(collection) {
			that.blacklineModel.set('availableDocSets', collection);
		});

		// Contains child views that used in this layout.
		this.childViews = {};
		this.catalogueViewsLoaded = $.Deferred();
		const view = (this.view = new View());

		this.listenTo(view, 'show', function() {
			const job = workspaceModel.get('Job');
			const jobType = workspaceModel.get('JobType');

			app.request('app.services.jobs.getMetadataView', job, { jobType: jobType }).done(function(metadataView) {
				view.regionHeader.show(metadataView);
			});

			this.setupBlacklineTable(); // blackline table
			this.setupSelectedView(); // shows the document sets that are currently in blackline model
			this.setupCatalogueViews(); // lists documents sets that can be used as either older/newer sets for blacklining
		});
	},

	// --- Set up the various components for blackline tool --- //

	setupBlacklineTable: function() {
		this.blacklineTable = BlacklineTable.create(this.blacklineModel);
		this.view.regionTable.show(this.blacklineTable.view);
	},

	setupSelectedView: function() {
		const selectedView = (this.childViews.selected = new SelectedView({
			collection: this.blacklineModel.get('oldDocSetColl'),
			blm: this.blacklineModel
		}));
		this.view.regionSelectedDocsets.show(selectedView);

		this.listenTo(selectedView, 'showNewCatalogueView', this.showNewCatalogue);
		this.listenTo(selectedView, 'showOldCatalogueView', this.showOldCatalogue);
		this.listenTo(selectedView, 'removeOldDocSet', this.removeOldDocSet);
	},

	setupCatalogueViews: function() {
		const that = this;

		const childViews = that.childViews;

		// at least show a loading view or something if the user clicks on the change button in the mean time
		that.docsetsPromise.done(function(collection) {
			const newView = (childViews.catalogueNew = new CatalogueView({
				collection: collection,
				blm: that.blacklineModel,
				viewType: 'newer'
			}));

			const oldView = (childViews.catalogueOld = new CatalogueView({
				collection: collection,
				blm: that.blacklineModel,
				viewType: 'older'
			}));

			// Listen to events to show/hide appropriate catalogue regions.
			that.listenTo(newView, 'hideCatalogueView', that.hideCatalogue);
			that.listenTo(oldView, 'hideCatalogueView', that.hideCatalogue);

			// Detect changes in catalogue views and notify model.
			that.listenTo(newView, 'changeNewDocSet', that.changeNewDocSet);
			that.listenTo(oldView, 'addOldDocSet', that.addOldDocSet);
			that.listenTo(oldView, 'removeOldDocSet', that.removeOldDocSet);

			that.catalogueViewsLoaded.resolve();
		});
	},

	// --- Layout show/hide catalogue regions --- //

	showNewCatalogue: function() {
		const that = this;
		const promise = this.catalogueViewsLoaded;

		if (promise.state('pending')) {
			that.view.regionCatalogue.show(new CatalogueLoadingView());
		}

		promise.done(function() {
			that.view.showCatalogueView(that.childViews.catalogueNew);
		});
	},
	showOldCatalogue: function() {
		this.view.showCatalogueView(this.childViews.catalogueOld);
	},
	hideCatalogue: function() {
		this.view.hideCatalogueView();
	},

	// --- Notify model of catalogue events --- //

	changeNewDocSet: function(id) {
		if (debug) console.log('blackline_ctrl:changeNewDocSet', id);
		this.blacklineModel.changeNewDocSet(id);
	},

	addOldDocSet: function(id) {
		if (debug) console.log('blackline_ctrl:addOldDocSet', id);
		this.blacklineModel.addOldDocSet(id);
	},

	removeOldDocSet: function(id) {
		if (debug) console.log('blackline_ctrl:removeOldDocSet', id);
		this.blacklineModel.removeOldDocSet(id);
	},

	onDestroy: function() {
		// Trash components.
		this.blacklineModel.destroy();
		this.blacklineTable.destroy();

		// Destroy and remove refs to child views.
		_.each(this.childViews, function(view) {
			if (view) view.destroy();
		});
		this.childViews = undefined;
	}
});

const create = function(workspaceController) {
	return new Controller(workspaceController);
};

export { create };
