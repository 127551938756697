/**
 # delayRequest

 This model and collection definition handles requests that need to be delayed,
 executing those requests even after the views that called them have been closed
 and cleaned up. Additionally, this collection alerts the user if they navigate
 away while there are remaining requests, giving the user the option to stay
 and wait for the requests to complete first.

 */

import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import 'jquery-whenAll';

/**
 Has the following properties:
 * func {function} Function to execute after delay.
 * delay {number} Delay length in milliseconds.
 * context {object} Optional context to run func in.
 */
const DelayedRequest = Backbone.Model.extend({
	initialize: function(data) {
		const id = setTimeout(_.bind(this._execute, this), data.delay);
		this.set('id', id);
	},
	_execute: function() {
		const context = this.get('context') || this;
		const result = this.get('func').call(context);
		if (this.collection) this.collection.remove(this);
		return result;
	},
	executeNow: function() {
		clearTimeout(this.get('id'));
		return this._execute();
	},
	cancel: function() {
		clearTimeout(this.get('id'));
		if (this.collection) this.collection.remove(this);
	}
});

const DelayedRequests = Backbone.Collection.extend({
	model: DelayedRequest,
	initialize: function() {
		const that = this;
		window.onbeforeunload = function() {
			let msg;

			if (that.length) {
				msg = 'Some tasks are still running! Please wait a few moments while we complete them.';
				that.once('queueflushed', function() {
					window.alert('All complete! Thanks for waiting. Have a great day!');
				});
				that.executeAll();
			}

			return msg;
		};
	},
	executeAll: function() {
		const that = this;

		// Make a copy of the models array. Can't directly iterate through
		// original array b/c it's live -- models may get deleted!
		const reqs = that.models.slice(0);

		// Keep track of return vals from requests. If they are promises,
		// will wait on them until they complete.
		const results = _.map(reqs, function(model) {
			return model.executeNow();
		});

		// When all requests have completed, inform user.
		$.whenAll.apply($, results).done(function() {
			that.trigger('queueflushed');
		});
	}
});

export default DelayedRequests;
