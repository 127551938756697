var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<td>\n	';
 if (DownloadUri) { 
__p+='\n		<a href="'+
((__t=( DownloadUri ))==null?'':__t)+
'" target="_blank">'+
((__t=( logId ))==null?'':__t)+
'</a>\n	';
 } else { 
__p+='\n		'+
((__t=( logId ))==null?'':__t)+
'\n	';
 } 
__p+='\n</td>\n<td>'+
((__t=( scriptName ))==null?'':__t)+
'</td>\n<td>'+
((__t=( lastModified.format('UiSortable') ))==null?'':__t)+
'</td>\n';
}
return __p;
};
