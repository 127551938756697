import app from 'app';
import _ from 'underscore';
import Backbone from 'backbone';
import Marionette from 'marionette';
import AddJobView from './addJob_view';

const ViewModel = Backbone.Model.extend({
	initialize: function(data, opts) {
		const dataModel = opts.model;
		this.set(_.clone(dataModel.attributes));
		this.listenTo(dataModel, 'change', this.onDataModelChange);
		this.on('change:Job', this.onChangeJob);
		this.on('change:JobType', this.onChangeJobType);
		this.on('change:JobTypeId', this.onChangeJobTypeId);

		if (this.has('Job')) this.onChangeJob();
	},
	onDataModelChange: function(dataModel) {
		this.set(dataModel.changedAttributes());
	},
	onChangeJob: function() {
		const job = this.get('Job');

		this.set({
			DisplayName: job.get('DisplayName'),
			Description: job.get('Description'),
			Attributes: _.clone(job.get('Attributes'))
		});
	},
	onChangeJobType: function() {
		const jobType = this.get('JobType');
		this.set({
			DisplayName: null,
			Description: null,
			Attributes: _.clone(jobType.get('Attributes'))
		});
	},
	onChangeJobTypeId: function(that, jobTypeId) {
		if (jobTypeId === '') {
			that.unset('JobType');
		} else {
			const clientId = that.get('ClientId');
			const path = `clients/${clientId}/jobtypes/${jobTypeId}/settings.json`;

			app.request('app.services.jobTypes.get', clientId, jobTypeId).done(function(jobType) {
				app.repository.getJson(path).done(function(config) {
					jobType.set('Attributes', config.Attributes);
					jobType.set('Inputs', config.Inputs);
					jobType.set('Operations', config.Operations);
					jobType.set('Version', config.Version);
					that.set('JobType', jobType);
				});
			});
		}
	}
});

// Backing model is workspace model.
const AddJobController = Marionette.Object.extend({
	initialize: function(workspaceModel) {
		const ctrl = this;
		ctrl.model = workspaceModel;

		// Set up view model.
		const viewModel = new ViewModel(false, { model: workspaceModel });

		// Set up this module's view.
		ctrl.view = new AddJobView({ model: viewModel });
		ctrl.listenTo(ctrl.view, 'save', ctrl.saveJob);
	},

	/** Creates or updates the existing job and sends it to the server. */
	saveJob: function(viewModel) {
		if (viewModel.has('JobId')) {
			this.updateJob(viewModel);
		} else {
			this.createJob(viewModel);
		}
	},

	updateJob: function(viewModel) {
		const that = this;

		// Update the Job model with the form data.
		const job = that.model.get('Job');
		job.set({
			Attributes: _.clone(viewModel.get('Attributes')),
			DisplayName: viewModel.get('DisplayName'),
			Description: viewModel.get('Description')
		});

		app.request('app.services.jobs.update', job)
			.done(function() {
				viewModel.trigger('updated');
			})
			.fail(function() {
				viewModel.trigger('error');
			});
	},

	createJob: function(viewModel) {
		const that = this;
		const jobType = viewModel.get('JobType');

		// Get the data from view model to construct a valid job to send to the server.
		const job = new Backbone.Model({
			Attributes: _.clone(viewModel.get('Attributes')),
			DisplayName: viewModel.get('DisplayName'),
			Description: viewModel.get('Description'),
			ClientId: viewModel.get('ClientId'),
			JobTypeId: viewModel.get('JobType').id,
			Inputs: jobType.get('Inputs'),
			Operations: jobType.get('Operations'),
			Version: jobType.get('Version')
		});

		app.request('app.services.jobs.create', job)
			.done(function(completeJob) {
				// Update workspace with created job info.
				that.model.set({
					JobTypeId: completeJob.get('JobTypeId'),
					JobId: completeJob.id,
					Job: completeJob
				});
				viewModel.trigger('created');
			})
			.fail(function() {
				viewModel.trigger('error');
			});
	},

	onDestroy: function() {
		this.view.destroy();
	}
});

const create = function(workspaceController) {
	return new AddJobController(workspaceController.model);
};

export { create };
