var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (typeof marker !== 'undefined' && marker) { 
__p+='\n<div class="ldi ldi-'+
((__t=( marker.shape ))==null?'':__t)+
' bl-set-marker bl-set-color-'+
((__t=( marker.color ))==null?'':__t)+
'"></div>\n';
 } else { 
__p+='\n&nbsp;\n';
 } 
__p+='\n';
}
return __p;
};
