import _ from 'underscore';
import app from 'app';
import mixin from 'common/mixins/main';
import Backbone from 'backbone';

/** Wrapper for s3 listObjects item */
const FileModel = Backbone.Model.extend({
	defaults: {
		DownloadUri: ''
	},
	initialize: function() {
		// const filespec = this.collection.options.filespec;
		this.setCustomProperties();

		// If this has "IsUpdated" flag, schedule for it to be removed.
		if (this.get('IsUpdated')) this.unsetUpdatedFlag();

		// Schedule removal of "IsUpdated" flag for future.
		this.on('change:IsUpdated', this.unsetUpdatedFlag);
	},
	unsetUpdatedFlag: function() {
		const that = this;

		setTimeout(function() {
			that.unset('IsUpdated', { silent: true });
		}, 3000);
	},
	setCustomProperties: function() {
		const that = this;

		app.repository.getDownloadUrl(this.get('key')).done(function(uri) {
			that.set('DownloadUri', uri);
		});
	},
	refreshMetadata: function() {
		const that = this;

		app.repository.getMetadata(that.get('key')).done(function(data) {
			that.set(data);
			that.setCustomProperties();
		});
	},
	renameFile: function(newName) {
		const that = this;
		const oldName = that.get('filename');

		// No actual change.
		if (oldName === newName) return;

		app.repository.renameFile(that.get('key'), newName).done(function(data) {
			that.refreshMetadata();
			that.set('IsUpdated', true);
		}).fail(function(err) {
			const msg = `Could not rename to "${newName}". ${err.message}`;
			that.trigger('err', msg);
		});
	},
	replaceFile: function(newFile) {
		if (!newFile) return;

		const that = this;
		const opts = { filename: that.get('filename') };

		app.repository.put(that.get('key'), newFile, false, opts).done(function(data) {
			that.refreshMetadata();
			that.set('IsUpdated', true);
		}).fail(function(err) {
			const msg = `Could not replace "${that.get('name')}" with new file. ${err}`;
			that.trigger('err', msg);
		});
	},
	deleteFile: function(opts) {
		opts = opts || {};
		const that = this;
		const delay = typeof opts.delay === 'number' ? opts.delay : 3000;

		const req = app.delayRequest(function() {
			// If no longer in trash, do nothing.
			if (that && !that.get('InTrash')) return;

			app.repository.remove(that.get('key')).done(function(data) {
				that.collection.remove(that);
			}).fail(function(err) {
				const msg = `Could not delete "${that.get('name')}". ${err}`;
				that.trigger('err', msg);
				that.cancelDelete();
			});
		}, delay, that);

		that.set({ InTrash: true, DeleteRequest: req });
	},
	cancelDelete: function() {
		this.get('DeleteRequest').cancel();
		this.set({
			InTrash: false,
			DeleteRequest: undefined
		});
	},
	onChanged: function() {
		const that = this;
		that.refreshMetadata();
		that.set({ IsUpdated: true, InTrash: false });
	}
});

/** Wrapper for s3 listObjects array */
const FileCollection = Backbone.Collection.extend({
	model: FileModel,
	initialize: function(collection, opts) {
		window.c ? window.c.push(this) : window.c = [this];

		this.options = opts;

		mixin.sortableCollection({
			that: this,
			sortProperty: 'name',
			sortDirection: 1
		});

		mixin.pagingCollection({
			that: this,
			fetchMethod: 'fetchNext',
			pageLength: 10
		});

		// Listen for file changes in this input.
		this.listenTo(app.vents.filespecs, `${opts.filespec.get('name')}:file:changed`, function(name) {
			const model = this.findWhere({ name: name });
			if (model) {
				// We already have this file. Notify it of the change.
				model.onChanged();
			} else {
				this.fetchOne(name);
			}
		});
	},
	addFile: function(obj) {
		// Adds item to top of current page.
		const index = this.getVisibleItemRange().first;
		this.add(obj, { at: index, merge: true });
		// @todo but what if this item already exists? then we need to move it to this page
	},
	fetchOne: function(filename) {
		const that = this;
		const path = `${this.options.filespec.get('key')}/${filename}`;

		app.repository.getMetadata(path).done(function(data) {
			data.IsUpdated = true;
			that.addFile(data);
		});
	},
	fetchNext: function(override) {
		const that = this;

		// If there's no more data to fetch and not forcing refresh, exit.
		if (!override && !that.hasServerPages()) return;

		const path = this.options.filespec.get('key');
		const opts = { take: that.pageLength, skip: override ? 0 : that.length };

		return app.repository.listFiles(path, opts).then(function(list, jqXhr) {
			const nCollection = that.length;
			// const nPage = list.length;

			// @todo sparklycats
			const links = app.repository.parseLinkHeader(jqXhr.getResponseHeader('Link'));
			that.setHasServerPages(!!links.next);

			// If no items yet, do a reset; it's faster than `add`.
			nCollection && !override ? that.add(list) : that.reset(list);

			return list;
		});
	},
	refresh: function() {
		this.fetchNext(true);
	}
});

/** A filespec */
const FilespecModel = Backbone.Model.extend({
	initialize: function(opts) {
		// Set defaults for config.
		this.initConfig();

		this.on('change:Error', this.refreshStatus);

		// Start up a list of files.
		const files = new FileCollection(null, { filespec: this });
		this.set('Files', files);
		this.listenTo(files, 'add remove reset', this.refreshStatus);
		// this.listenTo(files, 'err', this.handleFileError);
		this.listenToServerEvents();
	},
	initConfig: function() {
		if (!this.has('InputConfig')) { this.set('InputConfig', {}); }

		const config = this.get('InputConfig');

		if (typeof config.MinimumFiles !== 'number') { config.MinimumFiles = 1; }

		if (typeof config.MaximumFiles !== 'number') { config.MaximumFiles = Infinity; }
	},
	listenToServerEvents: function() {
		const that = this;
		that.listenTo(app.vent, 'server:events', function(e) {
			if (e.Type === 'InputGenerated' && that.get('Name') === e.InputName) {
				that.refresh();
			}
		});
	},
	addFiles: function(newFiles) {
		const that = this;
		const files = this.get('Files');
		const path = this.get('key');
		const numNewFiles = newFiles.length;

		// Callback for each file.
		const fileDoneCallback = function(data) {
			that.set('Error', undefined);

			data.IsUpdated = true;
			data.InTrash = false;
			files.addFile(data);
		};

		// Callback for _.reduce, used to count num uploaded.
		const progressCallback = function(memo, item) {
			return item ? ++memo : memo;
		};

		// Starting progress bar...
		that.set('ProgressPct', 0.01);

		app.repository.uploadFiles(path, newFiles, fileDoneCallback).progress(function() {
			const numUploaded = _.reduce(arguments, progressCallback, 0);
			that.set('ProgressPct', numUploaded / numNewFiles);
		}).fail(function(err) {
			that.set('Error', err);
		}).always(function() {
			// Turn off progress bar.
			that.set('ProgressPct', undefined);
		});
	},
	handleFileError: function(msg) {
		this.set('Error', msg);
	},
	refresh: function() {
		this.get('Files').refresh();
	},
	refreshStatus: function() {
		// Valid statuses: OK | Error | TooFewFiles | TooManyFiles
		const config = this.get('InputConfig');
		const numFiles = this.get('Files').length;
		const minFiles = config.MinimumFiles;
		const maxFiles = config.MaximumFiles;

		const status = this.get('Error') ? 'Error'
			: numFiles < minFiles ? 'TooFewFiles'
				: numFiles > maxFiles ? 'TooManyFiles'
					: 'OK';

		this.set('Status', status);
	}
});

/** A collection of filespecs */
const FilespecCollection = Backbone.Collection.extend({
	model: FilespecModel
});

export default FilespecCollection;
