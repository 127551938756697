const module = {};

/**
 Returns "number" clamped to the specified range.

 @param {number} number  The number to clamp.
 @param {number} lower  The lower bound.
 @param {number} upper  The upper bound.
 @returns {number}  Returns the clamped number.
 */
module.clamp = function(number, lower, upper) {
	if (typeof upper === 'number' && number > upper) {
		number = upper;
	}
	if (typeof lower === 'number' && number < lower) {
		number = lower;
	}
	return number;
};

export default module;
