var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 var order = data.order; 
__p+='\n<div class="context-menu-section-title tbf-colSort">Sort by</div>\n<div class="context-menu-section-content  tbf-colSort  tbf-colSort-0">\n	<span class="context-menu-option radio">\n		<label>\n			<input type="radio" name="tbf-colSortOrder" value="asc" '+
((__t=( order == 'asc' ? 'checked' : '' ))==null?'':__t)+
'>\n			Ascending (A-Z)\n		</label>\n	</span>\n	<span class="context-menu-option radio">\n		<label>\n			<input type="radio" name="tbf-colSortOrder" value="dsc" '+
((__t=( order == 'dsc' ? 'checked' : '' ))==null?'':__t)+
'>\n			Descending (Z-A)\n		</label>\n	</span>\n</div>\n';
}
return __p;
};
