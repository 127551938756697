var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!-- @todo: directly copy/pasted from documents table. still using tbf classes! -->\n<div class="tbf-header">\n	<div class="tbf-selectedRowCount">\n		<span class="tbf-selectedRows blackline-numMatches">'+
((__t=( numMatches ))==null?'':__t)+
'</span>\n		<span class="tbf-selectedHiddenRows"></span>\n	</div>\n	<div class="tbf-search"><input id="bl-tb-search" class="bl-tb-search" type="text" name="blackline-search" placeholder="Search"></div>\n</div>\n\n<div class="bl-tb">\n	<div class="bl-tb-head-viewport">\n		<div class="bl-tb-head">\n			<div class="bl-tb-colgroup bl-tb-newerSet">\n				<div class="bl-tb-colgroup-cap bl-tb-colgroup-cap-newerSet">Newer set\'s documents</div>\n				<div class="bl-tb-col bl-tb-col-fundname">\n					<div class="bl-tb-th">\n						<span class="iconf-tb-th bl-tb-sort-toggle blackline-sort-newer-name fa fa-sort"></span>\n						Name\n					</div>\n				</div>\n			</div>\n			<div class="bl-tb-colgroup bl-tb-olderSets">\n				<div class="bl-tb-colgroup-cap bl-tb-colgroup-cap-olderSets">\n					<span class="bl-tb-olderSets-scroll-l ldi ldi-left hoverable-light" style="display:none;"></span>\n					Older sets\n					<span class="bl-tb-olderSets-scroll-r ldi ldi-right hoverable-light" style="display:none;"></span>\n				</div>\n				<div id="region-olderSets-header" class="bl-tb-olderSets-viewport">\n					<div class="bl-tb-olderSets-viewport-inner">\n						<!-- older set header collection -->\n					</div>\n				</div>\n			</div>\n			<div class="bl-tb-colgroup bl-tb-summary">\n				<div class="bl-tb-colgroup-cap bl-tb-colgroup-cap-summary">\n					Matched\n					<button class="btn btn-sm btn-clear">Clear</button>\n				</div>\n			</div>\n		</div>\n	</div>\n	<div class="bl-tb-body-viewport">\n		<div class="bl-tb-body">\n			<div id="region-newestSets-body" class="bl-tb-colgroup bl-tb-newerSet">\n				<!-- new set column -->\n			</div>\n			<div class="bl-tb-colgroup bl-tb-olderSets"> <!-- sizer class goes here -->\n				<div id="region-olderSets-body" class="bl-tb-olderSets-viewport">\n					<!-- older set body collection -->\n				</div>\n			</div>\n			<div id="region-summary-body" class="bl-tb-colgroup bl-tb-summary">\n				<!-- summary column -->\n			</div>\n		</div>\n		';
 if (!rows.length) { 
__p+='\n			<div class="empty-state fill-container">\n				<div class="ldi ldi-question empty-state-img"></div>\n				<div class="empty-state-blurb">\n					<ol class="empty-state-list">\n						<li>Choose a "newer" document set. Documents in this set will be added to this area.</li>\n						<li>Choose at least one "older" document set to compare your newer document set against.</li>\n						<li>For the documents you want to blackline, indicate which of the older document sets you want to compare it to.</li>\n						<li>Once you\'ve finished your selection, hit "Publish" on the toolbar to the left!</li>\n					</ol>\n				</div>\n			</div>\n		';
 } 
__p+='\n	</div>\n</div>\n';
}
return __p;
};
