import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import tools from 'common/tools/main';

import appSettings from 'config';

const isPrivateKey = tools.regex.isPrivateKey;
const uriRoot = `${appSettings.JobTypesUri}clients/`;

const Document = Backbone.Model.extend({ idAttribute: 'Id' });

const DocumentCollection = Backbone.Collection.extend({ model: Document });

const JobModel = Backbone.Model.extend({
	idAttribute: 'Id',
	url: function() {
		const job = this;
		return `${uriRoot + job.get('ClientId')}/jobtypes/${job.get('JobTypeId')}/jobs/${job.id}`;
	},

	initialize: function(attributes, opts) {
		this.app = opts.app;
		const job = this;
		job.set('Documents', new DocumentCollection());
		job.set('DocumentAttributesMeta', {});
	},

	fetchSettingsJson: function() {
		// Get settings.json
		const job = this;
		const path =
			`clients/${
				job.get('ClientId')
			}/jobtypes/${
				job.get('JobTypeId')
			}/jobs/${
				job.get('Id')
			}/settings.json`;

		return this.app.repository.getJson(path).then(function(settings) {
			job.set(settings);
			return settings;
		});
	},

	fetchDocuments: function() {
		const d = $.Deferred();
		const job = this;

		if (job.has('ClientId') && job.has('JobTypeId')) {
			this.app.request('app.services.jobs.getDocuments', job.get('ClientId'), job.get('JobTypeId'), job.id)
				.done(function(data) {
					// Need to process docs first b/c that may add new columns.
					// If attributes are prepped first, may miss out on those
					// new columns.
					job.get('Documents').reset(job._prepareRawDocuments(data));
					job.set('DocumentAttributesMeta', job._prepareRawDocumentAttributes(data));
					d.resolve(job.get('Documents'));
				})
				.fail(function() {
					job.get('Documents').reset();
					job.set('DocumentAttributesMeta', {});
					d.resolve(job.get('Documents'));
				});
		} else {
			d.reject(new Error('Missing client id and/or job type id'));
		}

		return d.promise();
	},

	getDocuments: function() {
		const d = $.Deferred();
		const that = this;
		const docs = that.get('Documents');
		const key = `clients/${that.get('ClientId')}/jobtypes/${that.get('JobTypeId')}/jobs/${that.get('Id')}/documents/manifest.json`;

		// Fetch metadata to figure out if docs we have are up-to-date.
		this.app.repository
			.getMetadata(key)
			.done(function(meta) {
				const newDate = meta.lastModified;

				if (docs.meta && docs.meta.lastModified >= newDate) {
					// Existing documents are up-to-date.
					d.resolve(docs);
				} else {
					// There are new(er) documents available. Get them.
					docs.meta = meta;
					docs.meta.lastModified = newDate;
					that.fetchDocuments()
						.done(d.resolve)
						.fail(d.reject);
				}
			})
			.fail(function(err) {
				// If err.statusCode == 404, means no docs exist at the moment.
				d.resolve(docs);
			});

		return d.promise();
	},

	_prepareRawDocuments: function(data) {
		const docs = _.isArray(data.Manifest) ? data.Manifest : [];
		let doc;

		let n = docs.length;
		const uriRoot = this.app.request(
			'app.services.publishing.getPdfPreviewUrl',
			this.get('ClientId'),
			this.get('JobTypeId'),
			this.get('Id')
		);

		while (n--) {
			doc = docs[n];
			doc._PreviewUri = uriRoot + doc.Id;
		}

		return docs;
	},
	_prepareRawDocumentAttributes: function(data) {
		// Init attrs if missing.
		const attrs = data.Attributes || {};
		const docs = data.Manifest;

		// If there are docs, use the 1st doc's properties to fill in
		// missing attributes info.
		// Of course, this assumes every doc has the same properties.
		if (_.isArray(docs) && docs.length) {
			_.each(_.keys(docs[0]), function(k) {
				attrs[k] = _.extend(
					{
						Name: k,
						DisplayName: k,
						IsVisible: !isPrivateKey(k)
					},
					attrs[k]
				);
			});
		}

		return attrs;
	}
});

const JobCollection = Backbone.Collection.extend({ model: JobModel });

export default {
	Document,
	DocumentCollection,
	JobModel,
	JobCollection
};
