
import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import 'jquery-sizes';
import tpl from '../templates/base-substrate.html';
import tplPager from '../templates/base-pager.html';

const PagerView = Marionette.ItemView.extend({
	tm_classId: 'PagerView',
	template: tplPager,
	events: {
		'click .js-prev': 'pagePrev',
		'click .js-next': 'pageNext'
	},
	initialize: function(opts) {
		this.listenTo(opts.controller, 'page', this.render);
	},
	serializeData: function() {
		const opts = this.options;
		const ctrl = opts.controller;
		const total = ctrl.get('filteredTotal');

		return {
			options: opts,
			first: total ? ctrl.get('pageFirstItem') + 1 : 0,
			last: ctrl.get('pageLastItem'),
			total: total,
			prevDisabled: !ctrl.get('hasPrevPage'),
			nextDisabled: !ctrl.get('hasNextPage')
		};
	},
	pagePrev: function() {
		this.options.controller.pagePrev();
	},
	pageNext: function() {
		this.options.controller.pageNext();
	}
});

const DropMenuRegion = Marionette.Region.extend({
	onShow: function(view) {
		this.currentViewId = view.id;
		this.listenTo(view, 'destroy close', function() {
			this.stopListening(view);
			this.empty();
			this.$el.addClass('hidden');
		});
	},
	onEmpty: function() {
		this.currentViewId = undefined;
	},
	attachHtml: function(view) {
		this.$el
			.empty()
			.append(view.el)
			.removeClass('hidden');
	},
	position: function(direction, css) {
		const $el = this.$el;

		// Determine whether menu opens upwards or downwards.
		if (direction === 'down' && !$el.hasClass('dropdown')) {
			$el.removeClass('dropup').addClass('dropdown');
		} else if (direction === 'up' && !$el.hasClass('dropup')) {
			$el.removeClass('dropdown').addClass('dropup');
		}

		// Position menu. Unset any specified position properties.
		$el.css(
			_.extend(
				{
					top: 'auto',
					bottom: 'auto',
					left: 'auto',
					right: 'auto'
				},
				css
			)
		);
	}
});

const SubstrateView = Marionette.LayoutView.extend({
	tm_classId: 'SubstrateView',
	template: tpl,
	className: 'tbf-wrapper',
	attributes: function() {
		return {
			'data-tbf2-id': this.options.controller.cid
		};
	},
	regions: {
		regionThead: '.region-thead',
		regionTbody: '.region-tbody',
		regionHeaderLeft: '.region-header-left',
		regionHeaderRight: '.region-header-right',
		regionFooterLeft1: '.region-footer-left1',
		regionFooterLeft2: '.region-footer-left2',
		regionFooterRight: '.region-footer-right',
		regionDropmenu: {
			selector: '.region-dropmenu',
			regionClass: DropMenuRegion
		}
	},
	initialize: function(opts) {
		this.listenTo(opts.controller, 'page', this.onPage);
	},
	serializeData: function() {
		return { options: this.options };
	},
	onRender: function() {
		const that = this;
		const opts = that.options;
		const classes = opts.classes;

		this.regionThead.show(new classes.TheadView(opts));
		this.regionTbody.show(new classes.TbodyView(opts));
		this.regionFooterLeft1.show(new PagerView(opts));

		// Set height. If fill height, wait until DOM is appended first,
		// b/c need everything attached to calculate height.
		const height = opts.controller.get('tableHeight');

		if (height === 'fill') {
			const resize = _.debounce(_.bind(that.fillHeight, that), 200);
			$(window).on('resize', resize);
			setTimeout(resize, 1);
		} else {
			that.setHeight(height);
		}

		// When tbody scrolls horizontally, scroll header as well
		this.$('.region-tbody').on('scroll.tbf', function() {
			const scrollAmt = $(this).scrollLeft();
			that.$('.tbf-thead-viewport').scrollLeft(scrollAmt);
		});
	},
	onPage: function() {
		// On page change, return to top of page.
		this.$('.region-tbody').scrollTop(0);
	},
	setHeight: function(h) {
		this.$('.region-tbody').height(h);
	},
	fillHeight: function() {
		const $container = this.$el.parent();

		const containerH = $container.outerHeight();

		const containerP = $container.getPadding('top') + $container.getPadding('bottom');

		const theadH = this.$('.region-thead').outerHeight();

		const headerH = this.$('.js-header').outerHeight();

		const footerH = this.$('.js-footer').outerHeight();

		const h = containerH - containerP - theadH - headerH - footerH - 1;

		this.setHeight(h);
	}
});

export default SubstrateView;
