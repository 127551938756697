import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';

import itemTemplate from './itemTemplate.ejs';
import template from './template.ejs';

const GridRow = Marionette.ItemView.extend({
	template: itemTemplate,
	tagName: 'tr',
	events: {
		'change input[type="checkbox"]': 'checkboxChanged'
	},

	checkboxChanged: function(e) {
		const view = this;
		const $t = $(e.target);
		const checked = $t.is(':checked');
		const name = $t.attr('name');
		if (name === 'pe-all') {
			view.$el.find('input[type="checkbox"][name!="pe-all"]').each(function(i, chk) {
				$(chk).prop('checked', checked).change();
			});
		} else {
			view.model.set(name, checked);
		}
	}

});

const EmptyRow = Marionette.ItemView.extend({
	template: _.template('<td colspan="8">No job types</td>'),
	tagName: 'tr',
	className: 'row-empty'
});

const GridView = Marionette.CompositeView.extend({
	childView: GridRow,
	emptyView: EmptyRow,
	childViewContainer: 'tbody',
	template: template,

	modelEvents: {
		'valid': 'onModelValid',
		'invalid': 'onModelInvalid',
		'saved': 'onSaveSuccess',
		'failed': 'onSaveFail'
	},

	events: {
		'change input[name="GroupName"]': 'onGroupNameChanged',
		'click .js-save': 'onClickSave'
	},

	onRender: function() {
		this.$('.js-save').tmbutton();
	},

	onGroupNameChanged: function(e) {
		const view = this;
		view.model.set('Name', $(e.target).val());
	},

	onModelValid: function() {
		this.$el.find('.error').remove();
	},

	onModelInvalid: function(model, errors) {
		const $el = this.$el;
		$el.find('.error').remove();

		if (errors.Name) {
			$el.find('input[name="GroupName"]')
				.on('focus')
				.after(`<span class="msg error">${errors.Name}</span>`);
		}

		if (errors.Permissions) {
			$el.find('.policy-editor table').after(`<span class="msg error">${errors.Permissions}</span>`);
		}
	},

	onClickSave: function(e) {
		e.preventDefault();
		this.$el.find('.js-save').tmbutton('state', 'busy');
		this.model.saveChanges();
	},

	onSaveSuccess: function() {
		this.$('.js-save').tmbutton('state', 'success');
	},

	onSaveFail: function() {
		this.$('.js-save').tmbutton('state', 'error');
		this.$('.js-messages').html('<div class="msg error">Could not save changes. Please try again.</div>');
	},

	onDestroy: function() {
		this.$('.js-save').tmbutton('destroy');
	}

});

export default GridView;
