// Module to return.
const module = {};

/**
 Returns the str padded on the left, the right, or both sides to the
 specified padding length. If the "pad" is not supplied, the input is
 padded with spaces. Otherwise it is padded with characters from "pad" up
 to the "len" length.

 @param str {str} String to pad.
 @param len {int} Length to pad to.
 @param pad {str} Characters to pad str with.
 @param dir {int} Side(s) to pad.
 1 = Left only
 2 = Right only
 3 = Both sides
 @returns {str} Padded version of str
 @source http://www.webtoolkit.info/javascript-pad.html
 */
module.pad = function(str, len, pad, dir) {
	const STR_PAD_LEFT = 1;
	const STR_PAD_RIGHT = 2;
	const STR_PAD_BOTH = 3;

	if (typeof (len) === 'undefined') {
		len = 0;
	}
	if (typeof (pad) === 'undefined') {
		pad = ' ';
	}
	if (typeof (dir) === 'undefined') {
		dir = STR_PAD_RIGHT;
	}

	if (len + 1 >= str.length) {
		switch (dir) {
			case STR_PAD_LEFT:
				str = new Array(len + 1 - str.length).join(pad) + str;
				break;

			case STR_PAD_BOTH:
				const padlen = len - str.length;
				const right = Math.ceil(padlen / 2);
				const left = padlen - right;
				str = new Array(left + 1).join(pad) + str + new Array(right + 1).join(pad);
				break;

			default:
				str = str + new Array(len + 1 - str.length).join(pad);
				break;
		}
	}

	return str;
};

/**
 Returns the given string with the byte order marker (BOM) stripped.

 @param str {str} String to remove BOM from.
 @returns {str} "str", without BOM.
 */
module.stripBOM = function(str) {
	// This catches EF BB BF (the UTF-8 BOM) because the buffer-to-string
	// conversion in `fs.readFileSync()` translates it to FEFF, the UTF-16 BOM.
	if (str.charCodeAt(0) === 0xFEFF) {
		str = str.slice(1);
	}
	return str;
};

/**
 Returns the given string with final trailing slash stripped.

 @param str {str} String to remove trailing slash from.
 @returns {str} "str", without trailing slash.
 */
module.stripTrailingSlash = function(str) {
	return str.substr(-1) === '/' ? str.substr(0, str.length - 1) : str;
};

/**
 Inserts a specified string `sep` after every `chunkSize` number of
 characters in `str`.

 E.g. `chunk('abcdefghijk', 4, ' ')` results in 'abcd efgh ijk'.

 @param {string} str  String to chunk.
 @param {int} [chunkSize=4]  Number of characters per chunk.
 @param {string} [sep=' ']  Chunk separator.
 @returns {string} Chunked version of `str`.
 */
module.chunk = function(str, chunkSize, sep) {
	chunkSize = typeof chunkSize === 'number' ? chunkSize : 4;
	sep = typeof sep === 'string' ? sep : ' ';

	// If chunk size <= 0 or at least as big as str, or if separator is "",
	// return str.
	if (chunkSize <= 0 || chunkSize >= str.length || sep === '') {
		return str;
	}

	const chunks = [];
	let offset = 0;
	const n = str.length;

	while (offset < n) {
		chunks.push(str.slice(offset, (offset += chunkSize)));
	}

	return chunks.join(sep);
};

module.lowerFirst = function(s) {
	return s.length ? s.charAt(0).toLowerCase() + s.substring(1) : s;
};

export default module;
