import $ from 'jquery';
import _ from 'underscore';

const debug = false;

// Module to return.
const module = {};

// @todo: may belong elsewhere...
const debounceResize = function(app, view, methodName, wait) {
	wait = wait || 100;

	const eventId = `resize.${view.cid}`;
	const debouncedFn = _.debounce(function() {
		view[methodName]();
	}, wait);

	// Resize view when it is shown or when the container size changes.
	view.on('render attach', debouncedFn);
	view.listenTo(app, 'ui:skeleton:resized', debouncedFn);

	// Resize view when window is resized.
	$(window).on(eventId, debouncedFn);

	// Unbind window event handler when view is trashed.
	view.on('destroy', function() {
		$(window).off(eventId);
	});
};

/**
 Calls specified function after a delay.
 Private function used by transientErrorHandler.

 @param func {function} Function to call. Returns a $.Deferred.
 @param delay {int} Delay in milliseconds.
 @return {$.Deferred}
 */
const callFunctionWithDelay = function(func, delay) {
	const d = $.Deferred();

	// - set timer before the call
	setTimeout(function() {
		try {
			func().done(function(obj) {
				d.resolve(obj);
			}).fail(function(obj) {
				d.reject(obj);
			});
		} catch (ex) {
			d.reject(ex);
		}
	}, delay);

	return d.promise();
};

/**
 Calls func until the result passes doAgain or maxTrials is reached.

 @param func {function} Function to call repeatedly.
 @param isDesiredResult {function} Function that returns true iff "func"
 returned desired result and "func" should no longer be called.
 - "this" is the promise returned by "func".
 - Called always, regardless of whether the promise is resolved or
 rejected. Can check promise state in doAgain with `this.state()`.
 @param maxTrials {int} [default=10] Max times to call "func" before failing.
 @param delay {int} [default=200] Delay in milliseconds b/w each try.
 @param trialCounter {int} Current trial count. Needed for recursive calls.
 @return {$.Deferred}
 */
const callUntil = function(func, isDesiredResult, maxTrials, delay, trialsCounter) {
	// Initialize parameters if not set.
	if (typeof maxTrials === 'undefined') {
		maxTrials = 10;
	}
	if (typeof delay === 'undefined') {
		delay = 200;
	}
	if (typeof trialsCounter === 'undefined') {
		trialsCounter = 0;
	}

	const d = $.Deferred();
	const delayForThisTry = trialsCounter === 0 ? 1 : delay; // fast first call, delay on subsequent calls
	trialsCounter++;

	callFunctionWithDelay(func, delayForThisTry).always(function(data) {
		if (isDesiredResult.call(this, data)) {
			// Got what we wanted.
			if (debug) {
				console.log(`callUntil: tried ${trialsCounter} time(s) before getting desired result`);
			}
			d.resolve(data);
		} else {
			if (trialsCounter < maxTrials) {
				// Try again.
				callUntil(func, isDesiredResult, maxTrials, delay, trialsCounter)
					.done(function(res) {
						d.resolve(res);
					})
					.fail(function(err) {
						d.reject(err);
					});
			} else {
				// Already tried max times; failing.
				d.reject(data);
			}
		}
	});

	return d.promise();
};

/**
 Handles transient errors by calling the specified function after a delay,
 retrying until successful or timeout.

 @param functionToCall {function} function to retry calling
 @param strategy {function} function to trigger retries
 - called when functionToCall fails but haven't reached maxTrials yet
 - if return true, try again; otherwise, fail immediately
 @param maxTrials {int} max number of trials before sending back an error
 @param delay {int} delay in milliseconds between each try
 @param trialCounter {int} current trial count, needed for recursive calls
 @return {$.Deferred}
 */
const transientErrorHandler = function(functionToCall, strategy, maxTrials, delay, trialsCounter) {
	// initialize parameters if not set
	if (typeof maxTrials === 'undefined') {
		maxTrials = 5;
	}
	if (typeof delay === 'undefined') {
		delay = 500;
	}
	if (typeof trialsCounter === 'undefined') {
		trialsCounter = 0;
	}

	const d = $.Deferred();
	trialsCounter++;

	const delayForThisTry = trialsCounter < 2 ? 1 : delay;	// fast first call, delay on subsequent calls
	callFunctionWithDelay(functionToCall, delayForThisTry)
		.done(function(obj) {
			d.resolve(obj);
		})
		.fail(function(err) {
			if (trialsCounter >= maxTrials || !strategy(err)) {
				d.reject(err);
			} else {
				transientErrorHandler(functionToCall, strategy, maxTrials, delay, trialsCounter)
					.done(function(obj) {
						d.resolve(obj);
					})
					.fail(function(obj) {
						d.reject(obj);
					});
			}
		});

	return d.promise();
};

module.debounceResize = debounceResize;
module.callUntil = callUntil;
module.transientErrorHandler = transientErrorHandler;

export default module;
