import _ from 'underscore';
import clients from 'services/clients/module';
import jobs from 'services/jobs/module';
import jobTypes from 'services/jobTypes/module';
import meta from 'services/meta/module';
import publishing from 'services/publishing/module';
import srri from 'services/srri/module';
import users from 'services/users/module';

const moduleApiSetupFns = [clients, jobs, jobTypes, meta, publishing, srri, users];

// Order of module names must match order of module Apis.
const moduleNames = ['clients', 'jobs', 'jobTypes', 'meta', 'publishing', 'srri', 'users'];

export default function initServices (app) {
	// defined here so that following helper function has access to it.
	let handlerPrefix;

	// helper; defined here to avoid multiple definitions w/i loop
	const registerApiHandler = function(handler, key) {
		app.reqres.setHandler(handlerPrefix + key, handler);
	};

	// main
	for (let i = 0, n = moduleApiSetupFns.length; i < n; i++) {
		handlerPrefix = `app.services.${moduleNames[i]}.`;
		const moduleApi = moduleApiSetupFns[i](app);
		_.each(moduleApi, registerApiHandler);
	}
}

// not returning the full api here, to enforce usage of services through app.request().
