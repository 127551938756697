import Backbone from 'backbone';
import View from './view';

function init(app) {
	const ViewModel = Backbone.Model.extend({
		toggleLock: function() {
			const that = this;
			const isLocked = that.get('IsLocked');
			const endpoint = isLocked ? 'app.services.users.unlockAccount' : 'app.services.users.lockAccount';

			app.request(endpoint, that.get('ClientId'), that.get('Id'))
				.done(function() {
					that.set('IsLocked', !isLocked);
					that.trigger('success');
				})
				.fail(function(err) {
					that.trigger('error', err);
				});
		}
	});

	return {
		getToggleUserLockView: function(clientId, accountId) {
			return app.request('app.services.users.getAccount', clientId, accountId).then(function(user) {
				const model = new ViewModel(user.attributes);
				return new View({ model: model });
			});
		}
	};
}

export default init;
