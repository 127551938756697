import app from 'app';
import _ from 'underscore';
import Backbone from 'backbone';
import Marionette from 'marionette';
import View from './jobExplorer_view';
import Model from './jobExplorer_model';

// Keep references to tools to save some lookups.
const createSearchRegex = app.tools.regex.createSearchRegex;
const isJobRoute = app.tools.regex.isJobRoute;

// Returns true iff the job represented by this tree node is inactive.
function isJobInactive(node) {
	const isModel = typeof node.cid !== 'undefined';
	const model = isModel ? node.get('model') : node.model;
	return !model.get('IsActive');
}

const JobExplorer = Marionette.Object.extend({
	initialize: function() {
		// For keeping tracking of explorer state.
		this.state = new Model.ExplorerState();

		this.setupTree();
		this.setupView();
		this.setupClientManagement();
		this.detectCurrentJob();

		this.listenTo(app, {
			'app:job:created': this.onJobCreated,
			'app:job:updated': this.refreshJobData,
			'app:job:launch': this.onJobOpen,
			'app:show:main': this.onJobClose
		});
	},

	setupTree: function() {
		// Collection of job type tree nodes.
		const collection = new Model.TreeNodeCollection([]);

		this.tree = {
			collection: collection,
			view: new View.TreeRoot({ collection: collection })
		};
	},

	setupView: function() {
		const view = (this.view = new View.Layout({ state: this.state }));

		this.listenTo(view, 'show', function() {
			view.regionTree.show(this.tree.view, { preventDestroy: true });
		});

		this.listenTo(view, 'addJob', function() {
			app.trigger('app:job:new', this.state.get('clientId'));
		});

		this.listenTo(view, 'refreshJobs', this.refreshJobs);
		this.listenTo(view, 'search', this.search);
	},

	setupClientManagement: function() {
		// Set current client.
		this.setClient(app.session.get('clientId'));

		// Update our own state when client changes.
		this.listenTo(app.session, 'change:clientId', this.onClientChange);
	},

	setClient: function(clientId) {
		this.state.set('clientId', clientId);
		this.refreshJobTypes(clientId);
	},

	detectCurrentJob: function() {
		// If a job is open, then the returned routeReturns route as an array of names: [client, jobType, job]
		const route = isJobRoute(app.getCurrentRoute());
		// If a job is already opened, mark it as selected.
		// Using apply so that we don't have to deserialize the
		if (route) this.onJobOpen.apply(this, route);
	},

	onClientChange: function(session) {
		this.setClient(session.get('clientId'));
	},

	onJobCreated: function(obj) {
		const isModel = typeof obj.attributes !== 'undefined';
		const clientId = isModel ? obj.get('ClientId') : obj.ClientId;

		if (clientId === this.state.get('clientId')) {
			const jobTypeId = isModel ? obj.get('JobTypeId') : obj.JobTypeId;
			const branch = this.getJobTypeBranch(jobTypeId);
			if (branch) {
				branch.nodes.add(this.makeJobNodeObj(obj), { at: 0 });
			}
		}
	},

	onJobOpen: function(clientId, jobTypeId, jobId) {
		this.state.set('currentJob', {
			clientId: clientId,
			jobTypeId: jobTypeId,
			jobId: jobId
		});

		const node = this.getJobNode(jobTypeId, jobId);
		if (node) node.set('selected', true);
	},

	onJobClose: function() {
		const data = this.state.get('currentJob');

		if (data) {
			const node = this.getJobNode(data.jobTypeId, data.jobId);

			if (node) {
				node.set('selected', false);
				this.state.set('currentJob', undefined);
			}
		}
	},

	search: function(searchString) {
		const that = this;
		let regex;

		let searchObj = false;

		// If we have a non-empty search string, create a regex with it.
		if (searchString.length) {
			regex = createSearchRegex(searchString, false, true, true);
			searchObj = { string: searchString, regex: regex };
		}

		// Apply the search on each job type's job nodes.
		this.tree.collection.each(function(jobTypeNode) {
			that.filterJobNodes(jobTypeNode, regex);
		});

		// Save search settings to job explorer state model.
		this.state.set('search', searchObj);
	},

	refreshJobTypes: function(clientId) {
		const tree = this.tree;

		// If no client provided, clear the tree and exit.
		if (!clientId) {
			tree.collection.reset([]);
			return;
		}

		app.request('app.services.jobTypes.get', clientId).done(function(collection) {
			const nodes = _.map(collection.models, function(model) {
				const treeNode = new Model.TreeNode({
					nodeName: model.get('DisplayName'),
					nodeId: model.get('Id'),
					nodeType: 'jobType',
					model: model
				});

				treeNode.nodes = new Model.TreeNodeCollection(); // where job nodes will be stored

				return treeNode;
			});

			tree.collection.reset(nodes);
		});
	},

	refreshJobs: function(jobTypeId) {
		const that = this;

		app.request('app.services.jobs.getSummaries', this.state.get('clientId'), jobTypeId).done(function(collection) {
			const jobList = [];
			let jobNode;

			let n = collection.length;

			const branch = that.getJobTypeBranch(jobTypeId);

			let hasMatches = false;

			// If the job type branch doesn't exist, don't do anything more.
			if (!branch) return;

			if (n) {
				// jobList's nodes are in reversed order (relative to collection's models).
				while (n--) {
					// Make the job node, checking it against any existing search
					// filter along the way, and add it to jobList.
					jobNode = that.makeJobNodeObj(collection[n]);
					hasMatches = that.filterJobNode(jobNode, that.state.get('search').regex) || hasMatches;
					jobList.push(jobNode);
				}

				// Set empty state for this job type branch if all job nodes were
				// filtered out. Do it silently, because the following reset will
				// apply the update.
				branch.set('_hiddenAllChildren', !hasMatches, { silent: true });
			}

			// Use the new jobs data in the job type tree.
			branch.nodes.reset(jobList);
		});
	},

	refreshJobData: function(job) {
		// Not the client we currently have data for, so nothing to refresh.
		if (this.state.get('clientId') !== job.get('ClientId')) return;

		// Update metadata stored in job node with the job's data.
		// The views will update themselves, in response to the model change.
		const jobNode = this.getJobNode(job.get('JobTypeId'), job.get('Id'));
		if (jobNode) {
			jobNode.set({
				nodeName: job.get('DisplayName'),
				model: job
			});
		}
	},

	/**
	 Returns the tree branch for the job type called jobTypeName, or
	 undefined if no such job type branch exists.

	 @param jobTypeName {string}
	 @returns {Model.TreeNode|undefined} Job type tree branch.
	 */
	getJobTypeBranch: function(jobTypeId) {
		return this.tree.collection._byId[jobTypeId];
	},

	/**
	 Returns the tree node for the job with jobName, under the job type with
	 jobTypeName. If no such job exists, returns undefined.

	 @param jobTypeName {string}
	 @param jobName {string}
	 @returns {Model.TreeNode|undefined} Job tree node.
	 */
	getJobNode: function(jobTypeId, jobId) {
		const branch = this.getJobTypeBranch(jobTypeId);
		if (branch) {
			return branch.nodes._byId[jobId];
		}
	},

	/**
	 Returns an object with attributes to back a TreeNode model for a job, using
	 information from "data" and "opts".

	 @param data {object|Backbone.Model} Only these properties/attributes are needed:
	 {string} JobId (if obj) or Id (if Backbone.Model)
	 {string} DisplayName

	 @returns {object} Has the following properties:
	 nodeType:   {string}         'job'
	 nodeId:     {string}          Id/JobId from @param data)
	 nodeName:   {string}          DisplayName from @param data)
	 model:      {Backbone.Model}  data, or data cast to Backbone.Model if it was an object
	 */
	makeJobNodeObj: function(data) {
		const state = this.state;
		const currJob = state.get('currentJob');
		const isModel = typeof data.attributes !== 'undefined'; // sniffing
		const model = isModel ? data : new Backbone.Model(data);
		const jobId = isModel ? model.get('Id') : data.JobId;
		const isSelected =
			currJob &&
			currJob.clientId === state.get('clientId') &&
			currJob.jobId === jobId &&
			currJob.jobTypeId === model.get('JobTypeId');

		return {
			nodeType: 'job',
			nodeId: jobId,
			nodeName: model.get('DisplayName'),
			selected: isSelected,
			model: model
		};
	},

	filterJobNode: function(node, regex) {
		const isModel = typeof node.cid !== 'undefined';

		let strParts;

		let isMatch = true;
		const model = isModel ? node.get('model') : node.model;

		if (regex) {
			// See if the search terms can be found in the job's display
			// name, description
			strParts = [];
			if (typeof model.get('DisplayName') !== 'undefined') strParts.push(model.get('DisplayName'));
			if (typeof model.get('Description') !== 'undefined') strParts.push(model.get('Description'));

			isMatch = regex.test(strParts.join(' '));
		}

		isMatch = isMatch && !isJobInactive(node);

		// Update the job node model to indicate whether we should hide
		// its tree node or not. The view will update in response.
		if (isModel) {
			node.set('_hiddenNode', !isMatch);
		} else {
			node._hiddenNode = !isMatch;
		}

		// Return true iff this node passed the filter.
		return isMatch;
	},

	/**
	 Filters the job nodes in jobTypeNode, hiding only the job nodes for which
	 at least one of the functions returns true or the regex matches.

	 @param jobTypeNode {Model.TreeNode}
	 @param functions {function[]}
	 @param regex {RegEx} [optional]
	 */
	_filterJobNodes: function(jobTypeNode, functions, regex) {
		const that = this;
		const nodes = jobTypeNode.nodes;

		let hasMatches = false;

		// Look through the collection of job nodes...
		nodes.each(function(node) {
			let isMatch = false;

			functions.forEach(function(f) {
				isMatch = isMatch || f(node);
			});

			isMatch = isMatch || that.filterJobNode(node, regex);

			// Note whether we have at least one matching job node so far.
			hasMatches = hasMatches || isMatch;
		});

		// Set empty state for this job type branch if all job nodes were
		// filtered out.
		jobTypeNode.set('_hiddenAllChildren', !hasMatches);
	},

	/**
	 Filters the job nodes in jobTypeNode, showing only the job nodes that
	 match regex. If regex isn't provided, all job nodes are shown.

	 @param jobTypeNode {Model.TreeNode}
	 @param regex {RegExp} [optional]
	 */
	filterJobNodes: function(jobTypeNode, regex) {
		this._filterJobNodes(jobTypeNode, [isJobInactive], regex);
	}
});

// Single instance.
let instance;

// Start.
const create = function() {
	// Return existing instance of the JobExplorer, or return a new
	// one, saving it for future use.
	return instance || (instance = new JobExplorer());
};

export { create };
