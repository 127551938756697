import _ from 'underscore';
import Marionette from 'marionette';

const Router = Marionette.AppRouter.extend({
	appRoutes: {
		'job/:client/:jobtype/:job(/:tool)(/:subtool)': 'openJob',
		'job/:client/new(/*)': 'newJob',
		'jobType/:client/:jobtype(/:tool)(/:subtool)': 'openJobType',
		'*path': 'openPage'
	},
	makeRoute: function() {
		let args = Array.prototype.slice.call(arguments);

		// Find first undefined value
		let i = _.findIndex(args, function(item) {
			return item === '' || !_.isString(item);
		});
		i = i < 0 ? args.length : i;

		args = args.slice(0, i);
		return args.join('/');
	},
	makeJobTypeRoute: function(clientId, jobTypeId, toolName, subtoolName) {
		if (!jobTypeId) {
			return this.makeRoute('jobType', clientId, 'new');
		} else {
			return this.makeRoute('jobType', clientId, jobTypeId, toolName, subtoolName);
		}
	},
	makeJobRoute: function(clientId, jobTypeId, jobId, toolName, subtoolName) {
		if (!jobTypeId || !jobId) {
			return this.makeRoute('job', clientId, 'new');
		} else {
			return this.makeRoute('job', clientId, jobTypeId, jobId, toolName, subtoolName);
		}
	}
});

function init (app) {
	const routeController = {
		openPage: function(route) {
			app.trigger('app:show:main', route);
		},

		openJobType: function(clientId, jobTypeId, toolName, subtoolName) {
			app.trigger('app:jobtype:launch', clientId, jobTypeId, toolName, subtoolName);
		},

		openJob: function(clientId, jobTypeId, jobId, toolName, subtoolName) {
			app.trigger('app:job:launch', clientId, jobTypeId, jobId, toolName, subtoolName);
		},

		newJob: function(clientId) {
			app.trigger('app:job:new', clientId);
		}
	};

	const router = (app.router = new Router({ controller: routeController }));

	// Show modules in main/sidebar areas.
	app.on('app:show:main', app.openPage);
	app.on('app:show:sidebar', app.openSidebar);

	app.on('app:jobtype:launch', function(clientId, jobTypeId, toolName, subtoolName) {
		app.navigate(router.makeJobTypeRoute(clientId, jobTypeId, toolName, subtoolName));
		app.openJobType(clientId, jobTypeId, toolName, subtoolName);
	});

	/** Opens a new job for client with the specified id */
	app.on('app:job:new', function(clientId) {
		app.navigate(router.makeJobRoute(clientId));
		app.openJob(clientId);
	});

	/** Opens a job, or switches to an already-open job. */
	app.on('app:job:launch', function(clientId, jobTypeId, jobId, toolName, subtoolName) {
		app.navigate(router.makeJobRoute(clientId, jobTypeId, jobId, toolName, subtoolName));
		app.openJob(clientId, jobTypeId, jobId, toolName, subtoolName);
	});

	/** Updates route for newly created job to use its name. */
	app.on('app:job:created', function(job) {
		const clientId = job.get('ClientId');
		const currentRoute = app.getCurrentRoute();
		if (currentRoute === router.makeJobRoute(clientId)) {
			// We are currently on the tab for the new job that was just created.
			app.navigate(router.makeJobRoute(clientId, job.get('JobTypeId'), job.get('Id')));
		}
	});

	/** Client change requested. */
	app.on('app:client:change', function(clientId) {
		app.session.set('clientId', clientId);
	});
}

export default init;
