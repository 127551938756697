import $ from 'jquery';

/**
 Shows/hides an optional field linked to the checkbox or radio button that
 was changed.

 @eventHandler
 @param e {$.Event} Checkbox or radio button change event.
 */
const onClickToggleCheckboxOrRadioOptField = function(e) {
	const $input = $(e.target);
	let $siblings;

	if ($input.attr('type') === 'radio') {
		// Remove .on from other form inputs containing radios in this
		// series.
		$siblings = $input
			.closest('.form-item')
			.siblings()
			.find(`[name=${$input.attr('name')}]`);
		$siblings.filter('.opt-field-toggle').each(function() {
			$(this)
				.closest('.form-item-w-opt-field')
				.removeClass('on');
		});
	}

	// Toggle .on on the form item containing the changed input.
	$input.closest('.form-item-w-opt-field').toggleClass('on', $input.prop('checked'));
};

$('.app').on(
	'change',
	'.form-items-w-opt-field input[type=checkbox], .form-items-w-opt-field input[type=radio]',
	onClickToggleCheckboxOrRadioOptField
);
