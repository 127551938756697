import $ from 'jquery';
import 'jquery-whenAll';

import _ from 'underscore';
import Backbone from 'backbone';
import View from './view';

function init (app) {
	return {
		getUserGroupListView: function(clientId, subjectId) {
			const d1 = app.request('app.services.users.getSecuritySubject', clientId, subjectId);
			const d2 = app.request('app.services.users.getSecurityGroups', clientId);

			return $.whenAll(d1, d2).then(function(subject, allGroups) {
				if (typeof subject === 'undefined') return;

				const subjectGroups = allGroups.filter(function(g) {
					return _.contains(subject.get('Groups'), g.get('Id'));
				});

				const viewModel = new Backbone.Model({
					SubjectId: subject.get('Id'),
					Groups: subjectGroups.map(function(g) {
						return {
							GroupId: g.get('Id'),
							GroupName: g.get('Name')
						};
					})
				});

				return new View({ model: viewModel });
			});
		}
	};
}

export default init;
