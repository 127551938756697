import _ from 'underscore';
import Marionette from 'marionette';
import mixin from 'common/mixins/main';
import PagerControlsView from 'common/modules/pagerControls/view';

const ItemView = Marionette.ItemView.extend({
	tagName: 'tr',
	modelEvents: {
		'change:DownloadUri': 'render'
	}
});

const EmptyView = Marionette.ItemView.extend({
	tagName: 'tr',
	className: 'row-empty'
});

const CollectionView = Marionette.CompositeView.extend({
	tagName: 'table',
	className: 'table table-minimal',
	childViewContainer: 'tbody',
	childView: module.ItemView,
	emptyView: module.EmptyView,

	// Default set of mixins
	mixins: ['sortableCollectionView', 'pagingCollectionView', 'resizableTable'],

	initialize: function(opts) {
		const that = this;
		const mixinOpts = { view: that };

		// Apply selected mixins.
		_.each(that.mixins, function(name) {
			mixin[name](mixinOpts);
		});
	}
});

const LayoutView = Marionette.LayoutView.extend({
	className: 'paged-table',
	collectionView: module.CollectionView,
	regions: {
		regionTable: '.region-table',
		regionPager: '.region-pager'
	},
	onRender: function() {
		const collection = this.collection;
		const CollectionView = this.collectionView;
		this.regionTable.show(new CollectionView({ collection: collection }));
		this.regionPager.show(new PagerControlsView({ collection: collection }));
	}
});

export default {
	ItemView, EmptyView, CollectionView, LayoutView
};
