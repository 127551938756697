import _ from 'underscore';
import Marionette from 'marionette';
import app from 'app';
import tools from 'common/tools/main';
import tplSelected from './templates/tpl_selected.html';
import tplOldDoc from './templates/tpl_oldDocSet.html';

const OldDocSetView = Marionette.ItemView.extend({
	template: tplOldDoc,
	className: 'bl-set-panel older',
	triggers: {
		'click .btn-close': 'removeOldDocSet'
	}
});

const NoOldDocSetView = Marionette.ItemView.extend({
	template: _.template('No older document sets selected'),
	className: 'bl-set-panel empty'
});

const SelectedView = Marionette.CompositeView.extend({
	template: tplSelected,
	emptyView: NoOldDocSetView,
	childView: OldDocSetView,
	childViewContainer: '.region-oldDocsets',

	events: {
		'click .bl-setList-chooseBtn': 'onBtnClickedChangeNewSet',
		'click .bl-setList-addBtn': 'onBtnClickedAddOldSet'
	},

	initialize: function(options) {
		this.blm = options.blm;
		this.model = null;
		this.listenTo(this.blm, 'change:newDocSet', this.onNewDocSetChange);

		// Set up view resizing when window resizes.
		tools.func.debounceResize(app, this, 'setViewHeight');

		// Patch ui remove docset event from childview to controller.
		this.on('childview:removeOldDocSet', function(view) {
			this.trigger('removeOldDocSet', view.model.get('Id'));
		});
	},

	setViewHeight: function() {
		const $el = this.$el;

		const $viewContainer = $el.closest('.blackline-right');
		let height;

		// Can only calculate height if we could find a $viewContainer,
		// i.e. if this view has been attached to the DOM.
		if ($viewContainer.length) {
			height =
				$viewContainer.height() -
				$el.find('.bl-setList-newer-header').outerHeight(true) -
				$el.find('.bl-setList-newer').outerHeight(true) -
				$el.find('.bl-setList-older-header').outerHeight(true);

			$el.find('.bl-setList-older').outerHeight(height);
		}
	},

	onNewDocSetChange: function(blm, newDocSet) {
		this.model = newDocSet;
		this.render();
	},

	onBtnClickedChangeNewSet: function() {
		this.trigger('showNewCatalogueView');
	},

	onBtnClickedAddOldSet: function() {
		this.trigger('showOldCatalogueView');
	}
});

export default SelectedView;
