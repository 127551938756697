import Marionette from 'marionette';
import tplView from './templates/view.html';

const Layout = Marionette.LayoutView.extend({
	template: tplView,
	regions: {
		regionHeader: '#region-header',
		regionBody: '#region-body'
	}
});

export default Layout;
