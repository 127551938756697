import FormModel from 'common/modules/formView/model';
import FormView from 'common/modules/formView/view';
import tools from 'common/tools/main';

const isValidEmailAddress = tools.regex.isValidEmailAddress;

function init (app) {
	const ViewModel = FormModel.extend({
		defaults: {
			Email: ''
		},

		saveChanges: function() {
			const that = this;

			if (!that.isValid()) return;

			app.request('app.services.users.changeEmail',
				that.get('ClientId'),
				that.get('Id'),
				that.get('Email')
			).done(function() {
				that.trigger('saved');
			}).fail(function(err) {
				let msg;

				if (err.responseJSON && err.responseJSON.Message) {
					msg = err.responseJSON.Message;
				}

				that.trigger('failed', msg);
			});
		},

		validateData: function(attrs, options) {
			const errors = {};

			if (!attrs.Email || !isValidEmailAddress(attrs.Email)) {
				errors.Email = 'Invalid email address.';
			}

			return errors;
		}
	});

	const formFields = [
		{
			name: 'Email',
			label: 'Email (username)',
			isRequired: true
		}
	];

	return {
		getEditEmailView: function() {
			return app.request('app.services.users.getCurrentUser').then(function(model) {
				const viewModel = new ViewModel(model.attributes);

				return new FormView({
					model: viewModel,
					formTitle: 'Email',
					formFields: formFields
				});
			});
		}
	};
}

export default init;
