import Marionette from 'marionette';
import app from 'app';
import Collection from './model';
import View from './view';

const Controller = Marionette.Object.extend({
	initialize: function() {
		const that = this;
		const collection = that.collection = new Collection();
		that.view = new View({ collection: collection });
		collection.fetchData();

		this.listenTo(app.session, 'change:clientId', function() {
			collection.fetchData();
		});
	}
});

const create = function() {
	return new Controller();
};

export { create };
