var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div id="region-header" class="workspace-header">\n	<div class="main">\n		<h1 class="title">SRRI Monitor</h1>\n		<div class="description">\n			<span class="js-dataLoadingIndicator ldi ldi-spinner hidden"></span>\n			<label for="srri-asofdate">Data as of</label>\n			<input type="text" id="srri-asofdate" class="pika-input" value="today" />\n			\n			<p style="margin-top:0.9em;">\n				<button class="btn js-download"><span class="ldi ldi-download"> Download data (CSV)</button>\n				<button class="btn js-breakdown"><span class="ldi ldi-view"> <span class="js-breakdown-label">'+
((__t=(
				resources.showPastSrriValues ))==null?'':__t)+
'</span></button>\n				<button class="btn js-delete" disabled><span class="ldi ldi-trash"> Delete</button>\n			</p>\n		</div>\n	</div>\n	<div class="metadata job-metadata"></div>\n</div>\n<div id="region-body" class="workspace-content single-column no-scroll"></div>\n<div class="js-overlay workspace-content-overlay hidden">\n	<div id="region-modal" class="modal"></div>\n	<div class="js-closeModal modal-close ldi ldi-close hoverable"></div>\n</div>\n';
}
return __p;
};
