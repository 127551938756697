import _ from 'underscore';
import featureDetection from './featureDetection';
import file from './file';
import regex from './regex';
import date from './date';
import number from './number';
import object from './object';
import string from './string';
import func from './func';

const tools = {};

tools.features = featureDetection;
tools.file = file;
tools.regex = regex;
tools.string = string;
tools.func = func;

_.extend(tools, date, number, object);

export default tools;
