import HistoryViews from 'common/modules/logs/view';
import tplLayout from './templates/tpl_layout.html';
import tplCompositeView from './templates/tpl_compositeView.html';
import tplItemView from './templates/tpl_itemView.html';
import tplEmptyItemView from './templates/tpl_emptyItemView.html';

const ItemView = HistoryViews.ItemView.extend({
	template: tplItemView
});

const EmptyView = HistoryViews.EmptyView.extend({
	template: tplEmptyItemView
});

const CollectionView = HistoryViews.CollectionView.extend({
	template: tplCompositeView,
	childView: ItemView,
	emptyView: EmptyView
});

const LayoutView = HistoryViews.LayoutView.extend({
	template: tplLayout,
	collectionView: CollectionView
});

export default LayoutView;
