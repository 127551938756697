import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import View from './view';

function hasPermissions(permissions) {
	const operations = [
		'CreateDocuments',
		'CreateJobs',
		'ImportData',
		'PublishBlackline',
		'PublishDocuments',
		'ViewDocuments'
	];

	return permissions.some(jobType => {
		return operations.some(operation => {
			return jobType.get(operation) === true;
		});
	});
}

function init(app) {
	const ViewModel = Backbone.Model.extend({
		validate: function(attrs, options) {
			const errors = {};

			if (attrs.Name === '') {
				errors.Name = 'Group must have a name.';
			}

			if (!hasPermissions(attrs.Permissions)) {
				errors.Permissions = 'This group must provide at least one permission to at least one job type (i.e. at least one box should be checked).';
			}

			if (_.isEmpty(errors)) {
				// So that views know this model has been validated.
				this.trigger('valid');
			} else {
				// Must return value only if there are errors.
				return errors;
			}
		},

		saveChanges: function() {
			const that = this;

			if (!that.isValid()) {
				that.trigger('failed');
				return;
			}

			app.request(
				'app.services.users.setGroupJobTypePermissions',
				that.get('ClientId'),
				that.get('Id'),
				that.get('Name'),
				that.get('Permissions').toJSON()
			)
				.done(function() {
					that.trigger('saved');
				})
				.fail(function() {
					that.trigger('failed');
				});
		}
	});

	return {
		getEditGroupView: function(clientId, groupId) {
			const d1 = app.request('app.services.users.getSecurityGroup', clientId, groupId);
			const d2 = app.request('app.services.users.getGroupJobTypePermissions', clientId, groupId);

			return $.whenAll(d1, d2).then(function(group, groupJobTypePermissions) {
				const model = new ViewModel(group.attributes);
				model.set('Permissions', groupJobTypePermissions);

				return new View({ model: model, collection: groupJobTypePermissions });
			});
		}
	};
}

export default init;
