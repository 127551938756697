// prototype modifications
import 'common/lib/dateFormat';

// libs
import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import Marionette from 'marionette';

// jquery plugins
import 'jquery-whenAll';
import 'jquery-migrate';
import 'jquery-sizes';

// custom libraries/tools
import appSettings from 'config';
import initServices from 'services/main';
import initSignalR from 'signalr/signalr';
import DelayedRequests from 'common/lib/delayRequest';
import Repository from './common/lib/livedoc.repository';
import tools from './common/tools/main';
import initRouting from './appControl';

// app components/settings
import moduleCatalogue from './appModules/app/moduleCatalogue';
import AppView from './appModules/app/app_view';
import NotificationManager from './appModules/notifications/controller';

const SessionModel = Backbone.Model.extend({
	initialize: function(attrs, opts) {
		this.app = opts.app;
		this.set('clientId', sessionStorage.clientId);
		this.on('change', this.onChange);
		this.on('change:currentPage', this.onChangePage);
	},
	onChange: function(model) {
		const app = this.app;
		_.each(model.changed, function(value, key) {
			// TODO: Put all of SessionModel in sessionStorage or not? For now just doing the clientId
			if (key === 'clientId') sessionStorage.setItem(key, value);

			app.trigger(`app:${key}:changed`, value);
		});
	},
	onChangePage: function(model, newPage) {
		const oldPage = this.previousAttributes().currentPage;
		if (oldPage) {
			oldPage.destroy();
			model._previousAttributes.currentPage = undefined; // stop backbone from holding onto previous page
		}
	}
});

const LiveDocApp = Marionette.Application.extend({
	events: {
		start: 'onStart'
	},

	initialize: function() {
		// Increase timeout to 2 minutes, for long-running processes.
		$.ajaxSetup({ timeout: 1000 * 60 * 2 });

		// General settings.
		this.settings = appSettings;

		// For storing session info, e.g. current client.
		this.session = new SessionModel({}, { app: this });

		// Generic utilities.
		this.tools = tools;

		// Radio channels.
		this.vents = {};

		// Where delayed requests are queued.
		this.session.set('DelayedRequests', new DelayedRequests());
	},

	navigate: function(route, opts) {
		Backbone.history.navigate(route, opts || {});
	},

	getCurrentRoute: function() {
		return Backbone.history.fragment;
	},

	onStart: function(loginInfo) {
		const that = this;

		this.repository = new Repository({
			lowercaseFileNames: true,
			host: that.settings.RepositoryUri
			// scope: 'ld3-002'
		});

		// Load services.
		initServices(that);

		// Set session info.
		this.getSessionData()
			.then(function() {
				// View will consult resulting list to generate nav items.
				return that.registerModules(moduleCatalogue);
			})
			.then(function() {
				// Start router. Do after fetching user so that if the 1st
				// page we navigate to is under a different client, we can
				// update the session client.
				initRouting(that);

				if (!Backbone.History.started) {
					Backbone.history.start();
				}

				// Setup app layout.
				that.layout = new AppView({ app: that });
				that.layout.render();
				that.pageCache = {};

				// Start notifications
				that.notifications = new NotificationManager({ app: that });
				that.notifications.subscribe(that.vent, 'server:events');
				that.notifications.render();
			});

		// Start SignalR
		initSignalR(that);

		// Check periodically to see if user is still logged in, and alert
		// user if no logged in.
		this.scheduleSessionExpiryCheck();
	},

	openJobType: function(clientId, jobTypeId, toolName, subtoolName) {
		const that = this;
		const session = that.session;
		const currPage = session.get('currentPage');

		// Update the app client so it matches the job we're working on.
		that.trigger('app:client:change', clientId);

		import(/* webpackMode: "eager" */ 'appModules/workspace/controller').then(Controller => {
			import(/* webpackMode: "eager" */ 'appModules/jobType/workspaceModel').then(({ Model }) => {
				if (Controller.isSpecifiedPage(currPage, Model, clientId, jobTypeId)) {
					// Same job, but navigating to different tool.
					currPage.switchToTool(toolName, subtoolName);
				} else {
					// Create new page.
					const controller = Controller.create({
						model: new Model({
							ClientId: clientId,
							JobTypeId: jobTypeId
						}),
						toolName: toolName,
						subtoolName: subtoolName,
						toolConfigPath: 'appModules/jobType/workspaceToolsConfig'
					});
					that.layout.showPage('jobTypes', controller.view);
					session.set('currentPage', controller);
					session.set('currentPageName', 'jobType');
				}
			});
		});
	},

	openJob: function(clientId, jobTypeId, jobId, toolName, subtoolName) {
		const that = this;
		const session = that.session;
		const currPage = session.get('currentPage');

		// Update the app client so it matches the job we're working on.
		that.trigger('app:client:change', clientId);

		import(/* webpackMode: "eager" */ 'appModules/workspace/controller').then(Controller => {
			import(/* webpackMode: "eager" */ 'appModules/job/workspaceModel').then(({ Model }) => {
				if (Controller.isSpecifiedPage(currPage, Model, clientId, jobTypeId, jobId)) {
					// Same job, but navigating to different tool.
					currPage.switchToTool(toolName, subtoolName);
				} else {
					// Create new page.
					const controller = Controller.create({
						model: new Model({
							ClientId: clientId,
							JobTypeId: jobTypeId,
							JobId: jobId
						}),
						toolName: toolName,
						subtoolName: subtoolName,
						toolConfigPath: 'appModules/job/workspaceToolsConfig'
					});
					that.layout.showPage('jobs', controller.view);
					session.set('currentPage', controller);
					session.set('currentPageName', 'job');
				}
			});
		});
	},

	openPage: function(route) {
		const defaultPage = 'dashboard';
		const that = this;
		const session = that.session;
		const routeParts = route ? route.split('/') : [defaultPage];
		const name = routeParts[0];
		const internalRouteParts = routeParts.slice(1);

		that.getPage(name, { routeParts: internalRouteParts })
			.done(function(controller) {
				// Requested valid page. Show it and update route.
				session.set('currentPage', controller);
				session.set('currentPageName', name);
				that.navigate(route);
				that.layout.showPage(name, controller.view);
			})
			.fail(function(err) {
				// 404. Go to default page. :(
				that.navigate(defaultPage);
				console.warn(err);
			});
	},

	openSidebar: function(name) {
		const that = this;

		that.getCacheablePage(name).done(function(controller) {
			that.layout.toggleSidebar(name, controller.view);
		});
	},

	getPage: function(name, opts) {
		const defer = $.Deferred();
		const session = this.session;
		const currPageName = session.get('currentPageName');

		if (name === currPageName) {
			// Already have this page open. Get existing page, have it
			// navigate to the relevant subpage.
			const controller = session.get('currentPage');
			try {
				controller.nav(opts.routeParts);
			} catch (err) {} // @todo event instead??
			defer.resolve(controller);
		} else {
			// console.log('dyanmic import:', 'appModules/' + name + '/' + name + '_ctrl');
			import(/* webpackMode: "eager" */ `appModules/${name}/${name}_ctrl`).then(
				Controller => {
					defer.resolve(Controller.create(opts));
				},
				function(err) {
					defer.reject(err); // module not found
				}
			);
		}

		return defer.promise();
	},

	getCacheablePage: function(name) {
		const that = this;
		const defer = $.Deferred();
		const controller = that.pageCache[name];

		if (controller) {
			defer.resolve(controller);
		} else {
			this.getPage(name)
				.done(function(c) {
					that.pageCache[name] = c;
					defer.resolve(c);
				})
				.fail(defer.reject);
		}

		return defer.promise();
	},

	getSessionData: function(override) {
		const d = $.Deferred();
		const session = this.session;

		if (!override && session.get('userAccount')) {
			// Already have user account, and not overriding cache.
			// Resolve promise.
			d.resolve(session);
		} else {
			const that = this;

			// Attempt to fetch user.
			that.request('app.services.users.getCurrentUser')
				.done(function(account) {
					const clientId = account.get('ClientId');
					const isAdmin = account.get('IsAdmin');
					const isSysAdmin = account.get('IsSuperAdmin');

					session.set({
						userAccount: account,
						isAdmin,
						isSysAdmin
					});

					if (!session.has('clientId')) {
						session.set({ clientId: clientId });
					}

					d.resolve(session);
				})
				.fail(d.reject);
		}
		return d.promise();
	},

	/**
	 Executes func after the specified delay, within context.
	 If user attempts to navigate away before delay elapses, executes func
	 immediately.

	 @param func {function} Function to run.
	 @param delay {number} Time in milliseconds.
	 @param context {object} Optional context to run func in.
	 */

	delayRequest: function(func, delay, context) {
		return this.session.get('DelayedRequests').add({ context: context, func: func, delay: delay });
	},

	registerModules: function(configs) {
		const that = this;
		that.modules = {};

		function handleResult(cfg, pass) {
			if (pass) {
				that.modules[cfg.id] = cfg;
			}
		}

		const promises = [];

		_.each(configs, function(cfg) {
			if (typeof cfg.checkRequirements === 'function') {
				const d = $.Deferred();
				cfg.checkRequirements(that).always(function(res) {
					handleResult(cfg, res);
					d.resolve();
				});
				promises.push(d.promise());
			} else {
				handleResult(cfg, true);
			}
		});

		return $.whenAll.apply($, promises);
	},

	scheduleSessionExpiryCheck: function () {
		// Check every 1 minute to see if still logged in.
		const interval = 1000 * 60;
		const re = /.*_lduiauth=.*/;

		const intervalId = setInterval(() => {
			const cookies = document.cookie;
			const isStillLoggedIn = re.test(cookies);

			if (!isStillLoggedIn) {
				alert('Your session has expired; please log in again');
				clearInterval(intervalId);
				location.reload();
			}
		}, interval);
	}
});

export default new LiveDocApp();
