var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<td>\n	<a href="'+
((__t=( DownloadUri ))==null?'':__t)+
'" target="_blank">'+
((__t=( logId ))==null?'':__t)+
'</a>\n	<br><span class="cell-caption">'+
((__t=( lastModified.format('UiSortable') ))==null?'':__t)+
'</span>\n</td>';
}
return __p;
};
