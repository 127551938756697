var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="context-menu-section tbf-pageLengthConfig">\n	<div class="context-menu-section-title  tbf-pageLengthConfig">Rows per page</div>\n	<div class="context-menu-section-content  tbf-pageLengthConfig  tbf-pageLengthConfig-0">\n		';
 _.each(options, function(o) { 
__p+='\n			<span class="context-menu-option radio">\n				<label>\n					<input type="radio" name="tbf-pageLength" value="'+
((__t=( o.value ))==null?'':__t)+
'" '+
((__t=( o.value === current ? 'checked' : '' ))==null?'':__t)+
' />\n					'+
((__t=( o.display ))==null?'':__t)+
'\n				</label>\n			</span>\n		';
 }); 
__p+='\n	</div>\n</div>\n';
}
return __p;
};
