import Marionette from 'marionette';
import tpl from './template.html';

const Layout = Marionette.LayoutView.extend({
	template: tpl,

	regions: {
		region_profile: '.region-profile',
		region_memberships: '.region-memberships',
		region_lock: '.region-lock',
		region_mfa: '.region-mfa'
	}
});

export default Layout;
