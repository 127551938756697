var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<p data-class="viewOnly">\n	<button class="js-edit btn btn-link">Add new user</button>\n</p>\n\n<p data-class="afterCreate" class="hidden">\n	<span class="msg success"><span class="ldi ldi-success"></span> User added! Remember to assign this user to a group so they have permission to log in.</span>\n</p>\n\n<form class="form-mini boxed" data-class="editOnly">\n	<h1 class="title">Add new user</h1>\n	<div class="form-group">\n		<input type="text" name="Email" class="form-control" placeholder="Email address" value="'+
((__t=( Email ))==null?'':__t)+
'" />\n	</div>\n	<div class="form-group">\n		<button class="js-save btn">Create</button>\n		<button class="js-cancel btn btn-link btn-sm">Cancel</button>\n	</div>\n</form>\n';
}
return __p;
};
