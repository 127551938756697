import Backbone from 'backbone';

// Used to keep track of job explorer state.
const ExplorerState = Backbone.Model.extend({
	defaults: {
		clientName: '',
		clientId: '',
		search: {
			string: '',
			regex: false
		}
	}
});

const TreeNode = Backbone.Model.extend({
	idAttribute: 'nodeId',

	defaults: {
		nodeId: '',
		nodeName: '',
		nodeType: ''
	}
});

const TreeNodeCollection = Backbone.Collection.extend({
	model: TreeNode,
	comparator: function(a, b) {
		// Attempts to compare by DateModified in node model.
		a = a.get('model');
		b = b.get('model');

		// If no backing model, exit.
		if (!a || !b) return;

		a = new Date(a.get('DateModified'));
		b = new Date(b.get('DateModified'));

		// If no valid dates to compare, exit.
		if (a.toString() === 'Invalid Date' || b.toString() === 'Invalid Date') return;

		return a - b;
	}
});

export default {
	ExplorerState,
	TreeNode,
	TreeNodeCollection
};
