var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="tree-node-contents">\n	<span class="tree-node-label node-text">'+
((__t=( message ))==null?'':__t)+
'</span>\n</div>';
}
return __p;
};
