import $ from 'jquery';
import View from './view';

function init (app) {
	return {
		getEditAccountView: function() {
			return $.whenAll(
				app.request('app.services.users.getEditEmailView'),
				app.request('app.services.users.getEditPasswordView'),
				app.request('app.services.users.getEditQnAView')
			).then(function(emailView, passwordView, qnaView) {
				const composite = new View();

				composite.on('render', function() {
					composite.editEmail.show(emailView);
					composite.editQnA.show(qnaView);
					composite.editPassword.show(passwordView);
				});

				return composite;
			});
		}
	};
}

export default init;
