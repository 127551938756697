import Marionette from 'marionette';
import tpl from './templates/layout.html';

const Layout = Marionette.LayoutView.extend({
	template: tpl,

	regions: {
		region_ipRestrictions: '.region-ipRestrictions'
	}
});

export default Layout;
