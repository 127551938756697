import _ from 'underscore';
import Marionette from 'marionette';
import app from 'app';
import NoPermissionView from 'common/modules/forbiddenPage/view';
import View from './admin_view';

const toolsConfig = {
	groups: {
		displayName: 'Manage groups',
		command: 'app.services.users.getGroupsPageView'
	},
	'groups/item': {
		displayName: 'Edit group',
		command: 'app.services.users.getEditGroupView',
		isModal: true
	},
	users: {
		displayName: 'Manage users',
		command: 'app.services.users.getUsersPageView'
	},
	'users/item': {
		displayName: 'Edit user',
		command: 'app.services.users.getEditUserView',
		isModal: true
	},
	security: {
		displayName: 'Security settings',
		command: 'app.services.users.getSecuritySettingsView'
	}
};

const Controller = Marionette.Object.extend({
	initialize: function(opts) {
		const view = (this.view = new View());

		// Default tool is users.
		this.currentRoute = opts && _.isArray(opts.routeParts) && opts.routeParts.length ? opts.routeParts : ['users'];

		// Reload current page with new client.
		this.listenTo(app.session, 'change:clientId', this.refresh);

		// On show, display default page.
		this.listenTo(view, 'show', this.refresh);

		// Ensure this module destroys itself when its view is closed.
		this.listenTo(view, 'destroy', this.destroy);
	},

	nav: function(route, refresh) {
		const currRoute = this.currentRoute;
		route = route || currRoute;
		const name = route[0];
		const item = route[1];

		if (refresh || name !== currRoute[0] || item !== currRoute[1]) {
			const key = item ? `${name}/item` : name;
			const config = toolsConfig[key];
			if (config) this.showTool(config, name, item);
		}
	},

	showTool: function(config, name, item) {
		const that = this;
		const pSession = app.getSessionData();

		pSession.done(function(session) {
			if (session.get('isAdmin')) {
				app.request(config.command, app.session.get('clientId'), item).done(function(view) {
					that.currentRoute = [name, item];
					that.view.showTool(_.defaults({ view: view }, config));
					app.navigate(`admin/${name}${item ? `/${item}` : ''}`);
				});
			} else {
				that.view.showTool({ view: new NoPermissionView(), displayName: 'Sorry!' });
			}
		});
	},

	refresh: function() {
		// Possible that client has changed; if so, most likely can't
		// navigate to item in route b/c it belongs to prev client. So go
		// directly to main subpage instead.
		this.nav(this.currentRoute.slice(0, 1), true);
	},

	onDestroy: function() {
		this.view.destroy();
		this.view.remove();
	}
});

const create = function(opts) {
	return new Controller(opts);
};

export { create };
