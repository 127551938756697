var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div>\n    <a href="javascript:void(0);" class="ldi ldi-close hoverable large notification-close"></a>\n    <span class="ldi ldi-'+
((__t=( model.get('icon') ))==null?'':__t)+
' large notification-icon"></span>\n    <div class="notification-body">\n        ';

        var title = model.get('title');
        var body = model.get('body');
        var progress = model.get('progress');
        var timestamp = model.get('timestamp');

        // If no title, show body text as title.
        if (!title) {
        title = body;
        body = '';
        }
        
__p+='\n\n        <span class="notification-title">'+
((__t=( title ))==null?'':_.escape(__t))+
'</span>\n\n        ';
 if (body) { 
__p+='\n        <span class="notification-message">'+
((__t=( body ))==null?'':_.escape(__t))+
'</span>\n        ';
 } 
__p+='\n\n        ';
 if (typeof progress == 'number') { 
__p+='\n        <div class="progress-bar blue thin"><span class="amt" style="width: '+
((__t=( progress * 100 ))==null?'':_.escape(__t))+
'%"></span></div>\n        ';
 } 
__p+='\n\n        ';
 if (typeof timestamp == 'number') { 
__p+='\n        <span class="notification-date" data-timestamp="'+
((__t=( timestamp ))==null?'':__t)+
'"> '+
((__t=( $.timeago(timestamp) ))==null?'':__t)+
' </span>\n        ';
 } 
__p+='\n    </div>\n</div>';
}
return __p;
};
