var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="sidebar-header">\n	<button id="btn-addJob" class="btn sidebar-addJob" '+
((__t=( state.get('clientId') ? '' : 'disabled' ))==null?'':__t)+
'>Add Job</button>\n	<button class="js-close btn-close ldi ldi-close hoverable-light" title="Close sidebar"></button>\n	<div class="sidebar-search-wrapper">\n		<label for="sidebar-jobSearch" class="hidden">Search (press <kbd>Enter</kbd> to submit</label>\n		<input id="sidebar-jobSearch" type="text" class="search-input sidebar-search" placeholder="Search (press &quot;Enter&quot; to submit)" />\n	</div>\n</div>\n<div id="region-job-tree" class="sidebar-explorer"></div>';
}
return __p;
};
