import Marionette from 'marionette';
import tools from 'common/tools/main';
import Grid from 'common/modules/table/main';
import tplView from './templates/view.html';

const View = Marionette.LayoutView.extend({
	template: tplView,
	className: 'workspace-main',
	regions: {
		regionBody: '#region-body'
	},
	events: {
		'click .js-downloadRaws': 'downloadCsv'
	},
	serializeData: function() {
		return this.options;
	},
	createGrid: function() {
		// Trash the previous grid.
		if (this.grid) this.grid.destroy();

		const grid = (this.grid = new Grid({
			collection: this.options.collection,
			modelIdAttribute: 'cid',
			columns: [
				{ id: 'PeriodStart', displayName: 'Start Date', width: 110, hasColumnFilter: false },
				{ id: 'PeriodEnd', displayName: 'End Date', width: 110, hasColumnFilter: false },
				{ id: 'PeriodStartDay', displayName: 'Start Day', width: 110 },
				{ id: 'PeriodEndDay', displayName: 'End Day', width: 110 },
				{ id: 'Return', displayName: 'Return', width: 180, type: 'number', filterType: 'range' }
			],
			hasRowSelect: false
		}));

		return grid;
	},
	onRender: function() {
		this.regionBody.show(this.createGrid().view);
	},
	downloadCsv: function() {
		const collection = this.options.collection;
		const fundName = collection.fund.get('FundName');
		const offsetDate = collection.offsetDate;
		const name = `SRRI returns data for ${fundName} as at ${offsetDate}`;
		tools.file.download(name, 'csv', collection.toCsv());
	},
	onDestroy: function() {
		if (this.grid) this.grid.destroy();
	}
});

export default View;
