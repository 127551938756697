import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import Marionette from 'marionette';
import MessageView from 'common/modules/message';
import template from './template.html';
import 'ui/button';

const _getGroup = function(groupArray, id) {
	return _.find(groupArray, function(g) {
		return g.GroupId === id;
	});
};

const View = Marionette.LayoutView.extend({
	template: template,
	className: 'form-horizontal',

	regions: {
		region_error: '.region-error'
	},

	events: {
		'click  .js-submit': 'onSave',
		'change .js-groups': 'groupSelectionChanged'
	},

	initialize: function() {
		this.on('saved', this.onSaved);
	},

	onRender: function() {
		if (this.model.get('error')) {
			const model = new Backbone.Model({ icon: 'warning', color: 'error', message: this.model.get('error') });
			this.region_error.show(new MessageView({ model: model }));
		} else {
			this.$el.find('.js-submit').tmbutton();
		}
	},

	groupSelectionChanged: function(e) {
		const $checkbox = $(e.target);
		const group = _getGroup(this.model.get('Groups'), $checkbox.val());

		if (group) {
			group.Selected = $checkbox.prop('checked');
		}
	},

	onSave: function(e) {
		e.preventDefault();
		this.$el.find('.js-submit').tmbutton('state', 'busy');
		this.trigger('save', this.model);
	},

	onSaved: function() {
		this.$el.find('.js-submit').tmbutton('state', 'ready');
	},

	onDestroy: function() {
		this.$('.js-submit').tmbutton('destroy');
	}

});

export default View;
