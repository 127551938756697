import Marionette from 'marionette';
import * as Views from './blacklineTable_view';

const Controller = Marionette.Object.extend({
	initialize: function(blacklineModel) {
		this.blacklineModel = blacklineModel;
		const blacklineRows = blacklineModel.get('blacklineRows');

		this.newSetView = new Views.NewSetView({
			collection: blacklineRows,
			model: blacklineModel.get('newDocSet')
		});

		this.oldSetCollectionView = new Views.OldDocCollectionView({
			collection: blacklineModel.get('oldDocSetColl'),
			blm_rows: blacklineRows
		});

		this.oldSetHeaderView = new Views.OldDocHeaderCollectionView({
			collection: blacklineModel.get('oldDocSetColl'),
			blm_rows: blacklineRows
		});

		this.summaryView = new Views.SummaryColumnView({
			collection: blacklineRows
		});

		this.view = new Views.Layout({ blm: blacklineModel });
		this.listenTo(this.view, 'show', function() {
			this.view.regionNewerSetBody.show(this.newSetView);
			this.view.regionOlderSetsHeader.show(this.oldSetHeaderView);
			this.view.regionOlderSetsBody.show(this.oldSetCollectionView);
			this.view.regionSummaryBody.show(this.summaryView);
		});

		this.listenTo(this.view, 'clearMatches', function(searchString) {
			this.blacklineModel.clearMatches(searchString);
		});
		this.listenTo(this.view, 'search', function(searchString) {
			this.blacklineModel.search(searchString);
		});
		this.listenTo(this.view, 'sort', function(attribute, direction) {
			this.blacklineModel.sortRows(attribute, direction);
		});
		this.listenTo(this.oldSetHeaderView, 'matchAllToDocSet', function(docSetId, selection) {
			this.blacklineModel.selectDocSet(docSetId, selection);
		});
		this.listenTo(this.oldSetCollectionView, 'matchToDocSet', function(matchKey, docSetId, selection) {
			this.blacklineModel.selectDoc(matchKey, docSetId, selection);
		});
	},

	onDestroy: function() {
		this.view.remove();
	}
});

const create = function(blacklineModel) {
	return new Controller(blacklineModel);
};

export default { create };
