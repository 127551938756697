const uiDate = {};

// Date unit conversions.
const UNITS = {
	ms: 1,
	s: 1000,
	min: 60 * 1000,
	h: 60 * 60 * 1000,
	d: 24 * 60 * 60 * 1000,
	w: 7 * 24 * 60 * 60 * 1000
};

// Matches strings like "past 15 y" (past 15 years), "PAST 200 MS"
// (past 200 milliseconds), and "next3m" (next 3 months).
const regexRelativeDate = /^\s*(past|next)\s*(\d+)\s*(ms|s|min|h|d|w|m|y)\s*$/i;

/**
 A collection of functions that returns a date that is "n unit" away from
 "now". If "now" is undefined, uses current datetime.

 @param now {Date} Date to calculate relative from.
 @param n {int} Positive for future dates, negative for past dates.
 @param unit {string} One of the following: ms s min h d w m y
 */
const _dateFrom = {
	generic: function(now, n, unit) {
		if (!(unit = UNITS[unit])) return;

		const d = now ? new Date(now) : new Date();
		const ms = d.getMilliseconds();

		d.setMilliseconds(ms + n * unit);

		return d;
	},

	m: function(now, n) {
		// Get current date.
		const d = now ? new Date(now) : new Date();
		const month = d.getMonth();
		const day = d.getDate();
		const monthN = month + n;

		// Set the month.
		d.setMonth(monthN);

		if (d.getDate() !== day) {
			// Days changed b/c desired month has fewer days than current
			// month, causing us to roll forward. Fix by manually setting
			// the day, then resetting the month.
			d.setDate(day - d.getDate());
			d.setMonth(monthN);
		}

		// Blank out time so we can get the full day.
		// d.setHours(0, 0, 0, 0);

		return d;
	},

	y: function(now, n) {
		// Get current date.
		const d = now ? new Date(now) : new Date();
		const year = d.getFullYear();
		const yearN = year + n;

		// If it's Feb 29 on a leap year but n years ago wasn't a leap year,
		// then we need to set the date for one year ago to Feb 28.
		if (uiDate.isLeapYear(year) && d.getMonth() === 1 && d.getDate() === 29 && !uiDate.isLeapYear(yearN)) {
			d.setDate(28);
		}

		// Set year to n years ago.
		d.setYear(yearN);

		// Blank out time so we can get the full day.
		// d.setHours(0, 0, 0, 0);

		return d;
	}
};

/**
	Returns true iff "year" is a leap year.

	@param year {int} Year to check.
	@returns {bool} True iff "year" is a leap year.
	*/
uiDate.isLeapYear = function(year) {
	return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

/**
	Returns a date that is some "period" of time from the specified "startDate".

	E.g.
	var now = new Date();
	relativeTo(now, 'past 1d');     // returns Date for yesterday, at same time
	relativeTo(now, 'next 30min');  // returns Date for 30 minutes later

	@param startDate {Date}
	@param period {string} Some amount of time, in the future or past.
	@returns {Date} Date that is "period" from "startDate".
	*/
uiDate.relative = function(startDate, period) {
	const matches = regexRelativeDate.exec(period);

	if (matches) {
		const amount = parseInt(matches[1] === 'past' ? `-${matches[2]}` : matches[2]);
		const unit = matches[3];

		if (unit === 'm' || unit === 'y') {
			return _dateFrom[unit](startDate, amount);
		} else {
			return _dateFrom.generic(startDate, amount, unit);
		}
	}
};

export default uiDate;
