import Marionette from 'marionette';
import Model from './model';
import View from './publisher_view';

const Controller = Marionette.Controller.extend({
	initialize: function(opts) {
		this.model = new Model(null, opts);
		this.view = new View({ model: this.model });
	}
});

const create = function(opts) {
	return new Controller(opts);
};

export { create };
