import Marionette from 'marionette';
import app from 'app';
import tpl from './templates/tpl_layout.html';

const Layout = Marionette.LayoutView.extend({
	template: tpl,
	className: 'workspace-main',
	regions: {
		region_profile: '#region-profile',
		region_account: '#region-account'
	},

	onRender: function() {
		const that = this;

		// Request required views and show them when they arrive.
		app.request('app.services.users.getCurrentUser').done(function(u) {
			app.request('app.services.users.getEditProfileView', u.get('ClientId'), u.get('Id')).done(function(v) {
				that.region_profile.show(v);
			});
		});

		app.request('app.services.users.getEditAccountView').done(function(v) {
			that.region_account.show(v);
		});
	}
});

export default Layout;
