import _ from 'underscore';
import Marionette from 'marionette';
import tools from 'common/tools/main';
import helpers from '../helpers';
import tpl from '../templates/base-thead.html';

const TheadView = Marionette.ItemView.extend({
	tm_classId: 'TheadView',
	template: tpl,
	className: 'tbf-thead-wrapper',
	initialize: function(opts) {
		Marionette.bindEntityEvents(this, opts.collection, Marionette.getOption(this, 'collectionEvents'));
		this.listenTo(opts.controller, 'tm:redraw', this.render);
	},
	serializeData: function() {
		return { options: this.options };
	},
	render: function() {
		// Trigger onBeforeRender callbacks
		this.triggerMethod('before:render', this);

		const opts = this.options;

		const cols = opts.columns;

		const fragment = document.createDocumentFragment();
		let i = 0;
		const n = cols.length;
		let col, childView;

		for (; i < n; i++) {
			col = cols[i];

			// Do not render this cell if it's in a hidden column.
			if (!col.isHidden) {
				childView = this._getChildView(col, opts);
				fragment.appendChild(childView.render().el);
			}
		}

		this.$el.html(this.template({ options: opts }));
		this.$('.tbf-row-sizer').append(fragment);

		const w = helpers.calculateTableWidth(opts.columns);
		this.$('table').width(w);
		opts.controller.set('tableWidth', w);

		// Trigger onRender callbacks
		this.triggerMethod('render', this);

		return this;
	},
	_getChildViewClass: function(col) {
		let Cls = col.thViewClass;

		if (!Cls) {
			// Use base class instead of customized one.
			Cls = col.thViewClass = typeof col.thViewMixin === 'object'
				? tools.extendClass(this.options.classes.ThView, col.thViewMixin)
				: this.options.classes.ThView;
		}

		return Cls;
	},
	_getChildView: function(column, options) {
		const ChildView = this._getChildViewClass(column);
		return new ChildView(_.extend({ column: column }, options));
	}
});

export default TheadView;
