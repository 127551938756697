/**
 # dataCache.js

 Make your API data requests through this cache, so that if the data has
 already been fetched, no additional server requests will be sent.

 ## Requirements

 - jQuery (for Deferred objects)
 - Underscore
 - Backbone (for working with Backbone.Collections)

 ## Basic usage

 Make a new Cache, giving it functions to call to fetch uncached data:

 var usersCache = new Cache({
		fetchItem: function getUser(userId, includeGroups) {
			return $.ajax(...);  // A $.Deferred promise
		},
		fetchCollection: function getUsersFromServer() {
			// A $.Deferred promise that resolves with the following:
			// [ { Id: 'x001', ...}, { Id: 'x002', ... }, ... ]
			return $.ajax(...);
		}
	});

 This will call the `fetchCollection` function defined above; following calls
 will return cached data:

 usersCache.getCollection();

 The collection is either a Backbone.Collection or array with objects.

 Since we've fetched a collection already, this will attempt to retrieve the
 item in the collection first, by comparing the `Id` field of each item to `key`.

 usersCache.getItem({
		key: 'x001',
		fetchArgs: ['some-user-id', true]
	});

 If that fails, it will call the `fetchItem` function with `fetchArgs`.

 To remove an item from the cache:

 usersCache.remove('some-user-id');

 To clear the entire cache:

 usersCache.reset();

 ## Fetch single items through the collection

 When you know you need the entire collection eventually anyway, you may want
 to fetch the entire collection and lookup your item there. In that case, set
 `fetchViaCollection` to true. Note that with this setting, `fetchArgs` is
 for `fetchCollection`.

 usersCache.getItem({
		key: 'x001',
		fetchArgs: [],
		fetchViaCollection: true
	});

 ## Enabling logging

 To turn on logging by default, do this before initializing any Caches:

 Cache.prototype.defaults.debug = true;

 To turn on for a single Cache, you can do it upon initialization:

 var usersCache = new Cache({
		debug: true,
		...
	});

 Or after initialization:

 usersCache.settings.debug = true;

 @version 1.0.0
 @module common/lib/dataCache
 */
import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';

const BACKBONECOLLECTION = Backbone.Collection; // for type checking
let idCounter = 0;
const defaults = { debug: false, singleCollectionKey: 'default' };

/**
 Creates a new cache.

 @constructor
 @param {object} opts  Config.
 - {bool} [debug=false] If true, enables logging.
 - {function} fetchCollection  Returns $.Deferred promise that resolves
 with the requested collection.
 - {function} [fetchItem]  Returns $.Deferred promise that resolves with
 the requested item.
 - {string} [name]  Used in logs to identify this Cache.
 */
const Cache = function(opts) {
	// Set a fixed id.
	Object.defineProperty(this, 'id', { value: ++idCounter });

	// Prep settings.
	this.settings = _.extend({}, defaults, opts);
	this._setLogPrefix();

	// The actual data is cached in the "cache" properties; the fetch
	// promises are stored in the "promises" properties.
	this.itemCache = {}; // Backbone.Model || {}
	this.itemPromises = {};
	this.collCache = {}; // Backbone.Collection || [ {}, {}, ... ]
	this.collPromises = {};
};

Cache.prototype = {
	/**
	 Default settings.

	 Can be overridden, e.g. `Cache.prototype.defaults.debug = true` to
	 enable logging by default.
	 */
	defaults: defaults,

	/**
	 Resolves with requested item.
	 If a collection is cached, will also look there for item.

	 @param {object} opts  Valid properties:
	 - {string} key  Used to look up item in collection and cache.
	 - {string} [collectionKey]  Used to look up containing collection.
	 Necessary iff fetching through collection and there are more than
	 one cached collections.
	 - {array} fetchArgs  If item not found in cache, the arguments with
	 which to call the fetch function.
	 - {function} [fetchFunction]  Custom fetch function. Returns a
	 a $.Deferred promise.
	 - {bool} [fetchViaCollection=false] If true, then the when item is
	 not cached, fetches the collection and looks for the item there
	 instead. (The collection will be cached.)
	 @returns {$.Deferred} Promise.
	 */
	getItem: function(opts) {
		const d = $.Deferred();
		const that = this;
		const settings = that.settings;
		const key = opts.key;
		const collKey = opts.collectionKey || settings.singleCollectionKey;
		const cache = that.itemCache;
		const promises = that.itemPromises;
		let item, request, fetchFunc;

		if (opts.fetchViaCollection) {
			item = that._findItemInCachedCollection(key, collKey);

			if (item) {
				that.log(`getItem "${key}" -- cache hit`);
				d.resolve(item);
			} else {
				that.log(`getItem "${key}" -- cache miss; fetching`);
				that.getCollection({
					key: collKey,
					fetchArgs: opts.fetchArgs
				}).done(function() {
					item = that._findItemInCachedCollection(key, collKey);
					item ? d.resolve(item) : d.reject(item);
				});
			}
		} else {
			// Attempt retrieval from singles cache, then collection cache.
			item = cache[key] || that._findItemInCachedCollection(key, collKey);

			if (item) {
				that.log(`getItem "${key}" -- cache hit`);
				d.resolve(item);
			} else {
				// Already fetching it?
				request = promises[key];

				if (request) {
					that.log(`getItem "${key}" -- promise hit`);
				} else {
					// Not fetching yet. Do it now. Use custom func if provided.
					that.log(`getItem "${key}" -- cache miss; fetching`);
					fetchFunc = opts.fetchFunction || settings.fetchItem;

					if (!fetchFunc) {
						d.reject(new Error(`Item "${key}" not found and no method provided to fetch single item`));
					} else {
						request = promises[key] = fetchFunc.apply(this, opts.fetchArgs);
					}
				}

				if (request) {
					request.done(function(obj) {
						d.resolve(cache[key] = obj);
					}).always(function() {
						promises[key] = undefined;
					}).fail(d.reject);
				}
			}
		}

		return d.promise();
	},

	/**
	 Resolves with requested collection.

	 @param {object} opts  Valid properties:
	 - {string} [key]  Value under which to store this collection.
	 Necessary iff caching more than one collection.
	 - {array} fetchArgs  If item not found in cache, the arguments with
	 which to call the fetch function.
	 - {function} [fetchFunction]  Custom fetch function. Returns a
	 $.Deferred promise.
	 @returns {$.Deferred} Promise.
	 */
	getCollection: function(opts) {
		const that = this;
		const d = $.Deferred();
		const key = opts.key || that.settings.singleCollectionKey;
		const cache = that.collCache;
		const promises = that.collPromises;
		const coll = cache[key];

		if (!coll) {
			// Don't have collection yet. See if we're already fetching it.
			let request = promises[key];
			that.log('getCollection -- promise hit');

			if (!request) {
				// Haven't sent fetch request yet. Do it now. Use custom
				// function if provided.
				that.log('getCollection -- cache miss; fetching');
				const fetchFunc = opts.fetchFunction || that.settings.fetchCollection;
				request = promises[key] = fetchFunc.apply(this, opts.fetchArgs);
			}

			request.done(function(obj) {
				d.resolve(cache[key] = obj);
			}).always(function() {
				promises[key] = undefined;
			}).fail(d.reject);
		} else {
			// Resolve with cached collection.
			that.log('getCollection -- cache hit');
			d.resolve(coll);
		}

		return d.promise();
	},

	/**
	 Removes item with specified key from cache. If there are cached
	 collections, attempts to remove the matching item from those too.

	 @param {string} key  Identifier for cached item.
	 */
	removeItem: function(key) {
		const that = this;

		// Remove from indiv cache.
		const itemCache = that.itemCache;
		if (itemCache[key]) {
			itemCache[key] = undefined;
			that.log(`remove "${key}"`);
		}

		// Remove from collection(s).
		const collCache = that.collCache;

		const colls = _.keys(collCache);

		let coll; let item;

		let i = colls.length;

		while (!item && i--) {
			coll = collCache[colls[i]];
			item = coll.findWhere({ Id: key });
			if (item) {
				coll.remove(item);
				that.log(`remove "${key}" (collection)`);
			}
		}
	},

	/**
	 Removes collection with specified key from cache.

	 @param {string} key  Identifier for cached item.
	 */
	removeCollection: function(key) {
		const cache = this.collCache;
		if (cache[key]) {
			cache[key] = undefined;
			this.log(`remove collection "${key}"`);
		}
	},

	/**
	 Resets entire cache.
	 */
	reset: function() {
		this.log('reset');
		this.itemCache = {};
		this.collCache = {};
	},

	/**
	 Logs message to console if debug is enabled.

	 @param {string} msg  Message to log.
	 */
	log: function(msg) {
		const settings = this.settings;
		if (settings.debug) console.log(settings.logPrefix + msg);
	},

	/**
	 Sets log prefix using this Cache's id and name (if provided).
	 */
	_setLogPrefix: function() {
		const that = this;
		const name = that.settings.name ? `/${that.settings.name}` : '';
		that.settings.logPrefix = `CACHE#${that.id}${name}: `;
	},

	/**
	 Returns the item for the given key if it's in the cached collection,
	 or undefined otherwise.

	 @param {string} itemKey  Item identifier.
	 @param {string} collKey  Collection identifier.
	 @returns {object|Backbone.Model|undefined}
	 */
	_findItemInCachedCollection: function(itemKey, collKey) {
		collKey = typeof collKey === 'string' ? collKey : this.settings.singleCollectionKey;
		let item;
		const collection = this.collCache[collKey];

		if (collection) {
			item = collection instanceof BACKBONECOLLECTION
				? collection.findWhere({ Id: itemKey })
				: _.findWhere(collection, { Id: itemKey });
		}

		return item;
	}
};

export default Cache;
