/**
 * Mix into a Collection to add paging capabilities.
 *
 * NB: You can override all the added methods in your Collection definition.
 *
 * @module common/mixins/pagingCollection
 *
 * @param {Backbone.Collection} collection  The collection to add this mixin to.
 * 	Alias: `that`
 * @param {string} [fetchMethod]  Name of the method to call to fetch more
 * 	data from server.
 * @param {boolean} [hasMoreServerData] Initial setting for whether more data
 * is available on server. @todo better description for this option
 * @param {int} [pageLength=5]  Number of items per page.
 * @param {int} [currPage=0]  Page to start at.
 */

import _ from 'underscore';

const pagingCollection = function(opts) {
	const collection = opts.that || opts.collection;
	const fetchMethod = typeof collection[opts.fetchMethod] === 'function' ? opts.fetchMethod : false;
	let hasMoreServerData = typeof opts.hasMoreServerData === 'boolean' ? opts.hasMoreServerData : true; // Whether more data is available on the server.

	// Extend collection with methods, letting existing ones take precedence.
	_.defaults(collection, {

		/**
             * Pages this collection based on the provided direction. Triggers a
             * "page" event on this collection.
             *
             * If on last page and server fetch method provided, attempts to fetch
             * more data.
             *
             * @param {string} direction  "prev", "next", "first", or "last".
             */
		page: function(direction) {
			const that = this;

			// If viewing last of client data but more data available on
			// server, go fetch it before actually paging.
			const last = this.getVisibleItemRange().last;
			if ((last < 0 || last + 1 >= this.length) && fetchMethod && this.hasServerPages()) {
				collection[fetchMethod]().then(function() {
					that._page(direction);
				}).fail(function(err) {
					console.warn('Error fetching page from server', err);
				});
			} else {
				this._page(direction);
			}
		},

		_page: function(direction) {
			const viewingFirst = this.isViewingFirstPage();
			const viewingLast = this.isViewingLastPage();

			if (direction === 'prev' && !viewingFirst) {
				this.currPage--;
				this.trigger('page');
			} else if (direction === 'next' && !viewingLast) {
				this.currPage++;
				this.trigger('page');
			} else if (direction === 'first' && !viewingFirst) {
				this.currPage = 0;
				this.trigger('page');
			} else if (direction === 'last' && !viewingLast) {
				this.currPage = this.lastPage;
				this.trigger('page');
			}
		},

		/**
             * Returns an object containing the indices of the first and last
             * items displayed on the current page. Indices start at 0.
             *
             * @returns {object.<string, int>} Contains two properties,
             * 	"first" and "last". E.g. `{ first: 0, last: 4 }` when
             * 	each page has 5 items nd we're on the first page.
             */
		getVisibleItemRange: function() {
			let first; let last;

			const numItems = this.getTotalLength();

			const currPage = this.currPage;

			const pageLength = this.pageLength;

			if (numItems === 0) {
				first = -1;
				last = -1;
			} else if (pageLength === Infinity) {
				first = 0;
				last = numItems - 1;
			} else {
				first = currPage * pageLength;
				last = Math.min(numItems, (currPage + 1) * pageLength) - 1;
			}

			return { first: first, last: last };
		},

		/**
             * Returns true iff on first page.
             *
             * @returns {bool} True iff on the first page.
             */
		isViewingFirstPage: function() {
			return this.currPage <= 0;
		},

		/**
             * Returns true iff on last page.
             *
             * @returns {bool} True iff on the last page.
             */
		isViewingLastPage: function() {
			return this.currPage === this.lastPage;
		},

		/**
             * Returns true if item with provided index is on current page.
             *
             * @param {int} index  Index of an item in this collection.
             * @returns {bool}  True iff item is on the current page.
             */
		isOnCurrentPage: function(index) {
			const range = this.getVisibleItemRange();
			return index >= range.first && index <= range.last;
		},

		/**
             * Returns total number of available pages with current page length
             * settings.
             *
             * @returns {int} Total number of pages using current page length.
             */
		getNumPages: function() {
			return this._calculateLastPageIndex(this.getTotalLength(), this.pageLength) + 1;
		},

		/**
             * Returns the total number of available pages with the default page
             * length settings.
             *
             * @returns {int} Total number of pages using default page length.
             */
		getDefaultNumPages: function() {
			return this._calculateLastPageIndex(this.getTotalLength(), this.defaultPageLength) + 1;
		},

		getTotalLength: function() {
			// we don't know the total items on server anymore
			return this.length;
		},
		setTotalLength: function() {
		},
		setHasServerPages: function(bool) {
			hasMoreServerData = bool;
		},

		/**
             * Returns true if server-side paging is enabled.
             *
             * @returns {bool}  True iff server-size paging is enabled.
             */
		hasServerPages: function() {
			return hasMoreServerData;
		},

		/**
             * Changes page length (number of items per page) to "n".
             *
             * @param {int} n  New page length.
             */
		setPageLength: function(n) {
			this.pageLength = n;
			this.trigger('pageLengthChange', n);
			this._recalculatePages();
		},

		/**
             * Returns the index of the last page.
             *
             * @param {int} numItems  Total number of items in collection.
             * @param {int} pageLength  Number of items per page.
             * @returns {int}  Index of last page.
             */
		_calculateLastPageIndex: function(numItems, pageLength) {
			return pageLength !== Infinity ? Math.max(0, Math.ceil(numItems / pageLength) - 1) : 0;
		},

		/**
             * Triggered when items are added/removed from this collection, and
             * when the collection is reset. Updates paging and triggers a
             * "page" event on the collection, if necessary.
             */
		_recalculatePages: function() {
			const oldCurrPage = this.currPage;

			const oldLastPage = this.lastPage;

			const newLastPage = this._calculateLastPageIndex(this.getTotalLength(), this.pageLength);

			if (newLastPage !== oldLastPage) {
				// Gained or lost some pages, so update our page indices.
				this.lastPage = newLastPage;

				if (oldCurrPage > newLastPage) {
					// We were on the last page and it got removed, so
					// update index for currently viewed page.
					this.currPage = Math.max(0, oldCurrPage - (oldLastPage - newLastPage));
				}
			}

			this.trigger('page');
			this.trigger('sizeChange');
		}
	});

	const collectionSize = opts.collectionSize || collection.length;

	// Default pager settings
	collection.defaultPageLength = opts.pageLength || 5;
	collection.pageLength = collection.defaultPageLength;
	collection.currPage = opts.currPage || 0;
	collection.lastPage = collection._calculateLastPageIndex(collectionSize, collection.pageLength);

	collection.on('add remove reset', collection._recalculatePages);

	// Forces a fetch as needed.
	collection.page();
};

export default pagingCollection;
