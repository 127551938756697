var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="column-inner">\n    <div class="column-header bl-setList-header bl-setList-newer-header">\n        <h2 class="column-title bl-setList-title">Newer document set</h2>\n        <button class="btn btn-sm bl-setList-chooseBtn">Change</button>\n    </div>\n	\n    <div class="bl-setList bl-setList-newer">\n		';
 if (typeof Id === 'undefined') { 
__p+='\n        <div class="bl-set-panel empty">No newer document set selected</div>\n		\n		';
 } else { 
__p+='\n        <div class="bl-set-panel newer">\n            <div class="bl-set-data">\n                <div class="bl-set-name">'+
((__t=( DisplayName || 'Unnamed' ))==null?'':__t)+
'</div>\n                <div class="bl-set-details">\n					<span class="bl-set-detail">'+
((__t=( Id ))==null?'':__t)+
'</span>\n					\n					';
 if (typeof Date !== 'undefined') { 
__p+='\n					<span class="bl-set-detail">'+
((__t=( Date.format('UiSortable') ))==null?'':__t)+
'</span>\n					';
 } 
__p+='	\n					\n					';
 if (typeof UserName !== 'undefined') { 
__p+='\n					<span class="bl-set-detail">'+
((__t=( UserName ))==null?'':__t)+
'</span>\n					';
 } 
__p+='	\n					\n					<span class="bl-set-detail">\n						';
 if (DownloadUri) { 
__p+='\n							<a href="'+
((__t=( DownloadUri ))==null?'':__t)+
'" target="_blank">'+
((__t=( NumberOfDocuments ))==null?'':__t)+
' documents <span class="ldi ldi-download"></span></a>\n						';
 } else { 
__p+='	\n							'+
((__t=( NumberOfDocuments ))==null?'':__t)+
' documents\n						';
 } 
__p+='	\n					</span>\n                </div>\n            </div>\n        </div>\n		';
 } 
__p+='\n    </div>\n\n    <div class="column-header bl-setList-header bl-setList-older-header">\n        <h2 class="column-title bl-setList-title">Older document sets</h2>\n		';
 var disableAddBtn = typeof Id === 'undefined' ? 'disabled' : ''; 
__p+='\n        <button class="btn btn-sm bl-setList-addBtn" '+
((__t=( disableAddBtn ))==null?'':__t)+
'>Add</button>\n    </div>\n    <div class="region-oldDocsets bl-setList bl-setList-older"></div>\n</div>\n';
}
return __p;
};
