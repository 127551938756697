import Marionette from 'marionette';
import Collection from './model';
import View from './view';

const Controller = Marionette.Object.extend({
	initialize: function(opts) {
		const that = this;
		const collection = new Collection(null, opts);
		collection.fetchData();
		that.view = new View({ collection: collection });
	}
});

export default Controller;
