import Marionette from 'marionette';
import tpl from './templates/tpl_view.html';

const View = Marionette.ItemView.extend({
	template: tpl,
	events: {
		'click .js-run': 'runOperation'
	},
	runOperation: function() {
		const parameters = this.$('.js-parameters').val();
		this.trigger('run', {
			parameters: parameters
		});
	}
});

export default View;
