var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<form data-document-id="'+
((__t=( docId ))==null?'':__t)+
'">\n    <input\n        type="checkbox"\n        data-match-key="'+
((__t=( matchKey ))==null?'':__t)+
'"\n        data-docset-id="'+
((__t=( docSetId ))==null?'':__t)+
'"\n        name="documentId" value="'+
((__t=( docId ))==null?'':__t)+
'"\n        data-key="selected"\n        '+
((__t=( checked ))==null?'':__t)+
'\n    />\n	&nbsp;\n	<span class="ldi-container">\n		';
 if (pdfUri) { 
__p+='\n			<a href="'+
((__t=( pdfUri ))==null?'':__t)+
'" class="ldi ldi-pdf x-large" target="_blank"></a>\n		';
 } 
__p+='\n	</span>\n</form>\n';
}
return __p;
};
