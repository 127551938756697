import FormModel from 'common/modules/formView/model';
import FormView from 'common/modules/formView/view';

function init(app) {
	const ViewModel = FormModel.extend({
		saveChanges: function() {
			const that = this;

			if (!that.isValid()) return;

			app.request(
				'app.services.users.changeSecurityQuestionAndAnswer',
				that.get('ClientId'),
				that.get('AccountId'),
				that.get('SecurityQuestion'),
				that.get('SecurityAnswer')
			)
				.done(function() {
					that.trigger('saved');
				})
				.fail(function(err) {
					let msg;

					if (err.responseJSON && err.responseJSON.Message) {
						msg = err.responseJSON.Message;
					}

					that.trigger('failed', msg);
				});
		},

		validateData: function(attrs, options) {
			const errors = {};

			if (!attrs.SecurityQuestion) {
				errors.SecurityQuestion = 'Question cannot be blank';
			}

			if (!attrs.SecurityAnswer) {
				errors.SecurityAnswer = 'Answer cannot be blank';
			}

			return errors;
		}
	});

	const formFields = [
		{
			name: 'SecurityQuestion',
			label: 'Security question',
			isRequired: true
		},
		{
			name: 'SecurityAnswer',
			label: 'Security answer',
			isRequired: true,
			isSensitive: true
		}
	];

	return {
		getEditQnAView: function() {
			return app.request('app.services.users.getCurrentUser').then(function(model) {
				const viewModel = new ViewModel({
					AccountId: model.get('Id'),
					ClientId: model.get('ClientId'),
					SecurityQuestion: model.get('SecurityQuestion'),
					SecurityAnswer: model.get('SecurityAnswer')
				});

				return new FormView({
					model: viewModel,
					formTitle: 'Security question',
					formFields: formFields
				});
			});
		}
	};
}

export default init;
