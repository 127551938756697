import _ from 'underscore';
import Backbone from 'backbone';

const module = {};
const ARRAY_PROTOTYPE = Array.prototype;
const FUNCTION_PROTOTYPE = Function.prototype;
const OBJECT_PROTOTYPE = Object.prototype;
const OBJECT_STRING = '[object Object]';
const OBJECT_CONSTRUCTOR_STRING = Function.prototype.toString.call(Object);
const COPY_PROTOTYPE = Backbone.Model.extend;

/**
 Returns true if val is null, undefined, or "".

 @param {*} val
 @returns {boolean}
 */
module.isNullish = function isNullish(val) {
	return val === null || val === '' || typeof val === 'undefined';
};

/**
 Returns true if "obj" is an object literal, i.e. a list of key/values
 enclosed in curly braces {}.

 @param obj {*} Item to test.
 @returns {bool} True if "obj" is object literal, false otherwise
 @source https://github.com/lodash/lodash/blob/4.11.1/lodash.js
 */

module.isPlainObject = function isPlainObject(obj) {
	// Bail if not even remotely object-like.
	// The stringified prototype detects things like [], Math, Error, and
	// arguments as not plain objects.
	if (!obj || typeof obj !== 'object' || OBJECT_PROTOTYPE.toString.call(obj) !== OBJECT_STRING) {
		return false;
	}

	// Objects with `null` prototypes are plain objects.
	const proto = Object.getPrototypeOf(obj);
	if (proto === null) return true;

	const Ctor = OBJECT_PROTOTYPE.hasOwnProperty.call(proto, 'constructor') && proto.constructor;
	return typeof Ctor === 'function' && Ctor instanceof Ctor &&
		FUNCTION_PROTOTYPE.toString.call(Ctor) === OBJECT_CONSTRUCTOR_STRING;
};

/**
 Recursively extends target object with one or more source objects.

 Note: All values that are not object literals (Array, RegExp, Date,
 Foo, etc.) are copied by reference. Be careful of mutation!

 @param target {obj} Object to extend.
 @param *sources {obj} Objects with data to copy over to target.
 */
module.deepExtend = function deepExtend(target) {
	target = _.isObject(target) ? target : {};

	_.each(ARRAY_PROTOTYPE.slice.call(arguments, 1), function(source) {
		// Skip non-object sources.
		if (!module.isPlainObject(source)) return;

		_.each(source, function(val, key) {
			if (module.isPlainObject(val)) {
				// Recursively merge object literals.
				target[key] = module.deepExtend(target[key], val);
			} else {
				// Items that aren't object literals or primitives are
				// copied by reference. This includes arrays!
				target[key] = val;
			}
		});
	});

	return target;
};

/**
 Returns a map of the given array, using the specified property and value
 keys. Example:

 var a = [{name: 'a', value: 1},{name: 'b', value: 2},{name: 'c', value: 3}];

 console.log(mapToObject(a, 'name', 'value'));
 // { a: 1, b: 2, c: 3 }

 var o = { foo: 'bar' };
 mapToObject(a, 'name', 'value', o);
 console.log(o);
 // { foo: 'bar', a: 1, b: 2, c: 3 }

 @param {array} arr  Array of objects to map.
 @param {string} propKey  Property to key by.
 @param {string} valueKey  Property where value is.
 @param {object} [obj]  Object to map "arr" to. If not provided, uses new
 empty object.
 @returns {object}
 */
module.mapToObject = function mapToObject(arr, propKey, valueKey, obj) {
	obj = module.isPlainObject(obj) ? obj : {};

	let item; let i = 0;
	const n = arr.length;
	for (; i < n; i++) {
		item = arr[i];
		obj[item[propKey]] = item[valueKey];
	}

	return obj;
};

/**
 Returns a new Class based on `Cls`, whose prototype is extended with
 the provided objects.

 The extend attempts to be deep where possible:
 - Plain objects are recursively extended
 - Functions are composed into a composite function. The return value
 of this composite function is the last non-undefined return value
 from the source functions.
 - Exception: Functions located at "template" key are not composed. Only
 the most recent "template" function is kept! This is because we
 usually use this to extend Views, and want to be able to replace
 templates.
 - All other complex items are copied by reference. This includes
 arrays! They are not cloned.

 @param {Class} Cls
 @param {array|object*} objs  Objects to extend `Cls` with. Provide in
 single array or as multiple arguments.
 @returns {Class} A new Class.
 @source Inspired by Backbone.Cocktail https://github.com/onsi/cocktail
 */
module.extendClass = function extendClass(Cls, objs) {
	// Backbone `extend` is shallow copy, so can't use it to add
	// plugins (e.g. "events" hashes won't be merged). But we can use it
	// to make a copy of Cls.
	const proto = _.extend({}, Cls.prototype);
	Cls = COPY_PROTOTYPE.apply(Cls);
	Cls.prototype = proto;

	// To handle function collisions.
	const collisions = {};

	if (!_.isArray(objs)) {
		// Extensions are probably provided across multiple arguments.
		objs = ARRAY_PROTOTYPE.slice.call(arguments, 1);
	}

	// Iteratee for the next block.
	const mergePropertyIntoTarget = function(val, key) {
		if (module.isPlainObject(val)) {
			// Recursively merge object literals.
			proto[key] = module.deepExtend({}, proto[key], val);
		} else if (_.isFunction(val)) {
			// If function already exists in prototype, save to collisions
			// and handle later. Exception: If "template" key, assume
			// underscore template and replace function entirely.
			if (proto.hasOwnProperty(key) && key !== 'template') {
				collisions[key] = collisions.hasOwnProperty(key) ? collisions[key] : [proto[key]];
				collisions[key].push(val);
			} else {
				proto[key] = val;
			}
		} else {
			// All other items are copied by reference. Includes arrays!
			proto[key] = val;
		}
	};

	// For each source, go through each of its properties and merge
	// it into the target obj.
	_.each(objs, function(source) {
		_.each(source, mergePropertyIntoTarget);
	});

	// For each key with function collisions, create a composite
	// function that will call each of the functions. The final return
	// value of the composite function is the last non-undefined return
	// value from the set of functions.
	_.each(collisions, function(functions, key) {
		proto[key] = function() {
			const that = this; const args = arguments;
			let i = 0;
			const n = functions.length;
			let tempReturn, finalReturn;

			for (; i < n; i++) {
				tempReturn = functions[i].apply(that, args);
				finalReturn = typeof tempReturn === 'undefined' ? finalReturn : tempReturn;
			}

			return finalReturn;
		};
	});

	return Cls;
};

export default module;
