var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (typeof data.guid !== 'undefined') { 
__p+='\n<span class="metadata-pair">\n	<span class="metadata-key">GUID: </span>\n	<span class="metadata-value">'+
((__t=( data.guid ))==null?'':__t)+
'</span>\n</span>\n';
 } 
__p+='\n\n';
 if (typeof data.dateCreated !== 'undefined') { 
__p+='\n<span class="metadata-pair">\n	<span class="metadata-key">Created: </span>\n	<span class="metadata-value">'+
((__t=( data.dateCreated.format('UiSortable') ))==null?'':__t)+
'</span>\n</span>\n';
 } 
__p+='\n\n';
 if (typeof data.dateModified !== 'undefined') { 
__p+='\n<span class="metadata-pair">\n	<span class="metadata-key">Modified: </span>\n	<span class="metadata-value">'+
((__t=( data.dateModified.format('UiSortable') ))==null?'':__t)+
'</span>\n</span>\n';
 } 
__p+='\n\n';
 if (typeof data.description !== 'undefined') { 
__p+='\n<span class="metadata-pair">\n	<span class="metadata-key">Description: </span>\n	<span class="metadata-value">'+
((__t=( data.description ))==null?'':__t)+
'</span>\n</span>\n';
 } 
__p+='\n\n';
 if (typeof data.username !== 'undefined') { 
__p+='\n<span class="metadata-pair">\n	<span class="metadata-key">Username: </span>\n	<span class="metadata-value">'+
((__t=( data.username ))==null?'':__t)+
'</span>\n</span>\n';
 } 
__p+='\n';
}
return __p;
};
