import app from 'app';
import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import Papa from 'papaparse';
import tools from 'common/tools/main';

const SrriRawModel = Backbone.Model.extend({
	toCsvReadyJson: function() {
		// Exclude tm:* attributes.
		return _.omit(this.attributes, tools.regex.isTmAttrIteratee);
	}
});

const SrriRaw = Backbone.Collection.extend({
	model: SrriRawModel,
	initialize: function(models, opts) {
		this.options = opts;
		this.fund = opts.fund;
		this.offsetDate = opts.offsetDate;
	},
	fetchData: function(date) {
		const that = this;
		const d = $.Deferred();
		const opts = that.options;
		that.trigger('fetching');

		app.request('app.services.srri.getRawValues', app.session.get('clientId'), opts.fund.get('Guid'), opts.offsetDate).done(function(data) {
			that.reset(data);
			d.resolve(that);
		}).fail(d.reject);

		return d.promise();
	},
	toCsv: function() {
		const data = this.map(function(model) {
			return model.toCsvReadyJson();
		});
		return Papa.unparse(data);
	}
});

export default SrriRaw;
