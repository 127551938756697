var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="bl-tb-th">\n	<input type="checkbox" name="oldDocSetId" value="'+
((__t=( oldDocSetId ))==null?'':__t)+
'" data-key="docSet-selected" />\n	<div class="ldi ldi-'+
((__t=( marker.shape ))==null?'':__t)+
' bl-set-marker bl-set-color-'+
((__t=( marker.color ))==null?'':__t)+
'"></div>\n</div>\n';
}
return __p;
};
