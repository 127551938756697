import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import 'jquery-sizes';
import tpl from '../templates/base-tbody.html';

const TbodyView = Marionette.ItemView.extend({
	tm_classId: 'TbodyView',
	template: tpl,
	tagName: 'table',
	className: 'table table-striped table-bordered-v',
	initialize: function(opts) {
		this.options = opts;

		// Reusables
		// this.emptyView;
		this.children = [];
		this.rowPool = [];

		this.listenTo(opts.controller, 'page change:tableWidth', this.render);
		Marionette.bindEntityEvents(this, opts.collection, Marionette.getOption(this, 'collectionEvents'));
	},
	serializeData: function() {
		return { options: this.options };
	},
	render: function() {
		this._cleanupChildren();

		// Trigger onBeforeRender callbacks
		this.triggerMethod('before:render', this);

		const opts = this.options;

		const ctrl = opts.controller;

		const models = ctrl.get('pageModels');

		const fragment = this._getDocFragment();

		const renderOpts = { redraw: true };
		let i = 0;
		const n = models.length;
		let childView;

		if (n) {
			// Render children, passing `renderOpts` to inform them that
			// they are part of a bulk render, and therefore should detach
			// themselves (if they are reused) for efficiency.
			for (; i < n; i++) {
				childView = this._getRowView(models[i], opts);
				fragment.appendChild(childView.render(renderOpts).el);
			}
		} else {
			fragment.appendChild(this._getEmptyRowView().render().el);
		}

		// Set width before appending children. Reflow less expensive when
		// there are fewer children.
		this.$el.empty().width(ctrl.get('tableWidth'));

		// Put together <tbody> first, then append entire thing. Bypassing
		// jQuery as much as possible for efficiency.
		const tbody = $(this.template({ options: opts }))[0];
		tbody.appendChild(fragment);
		this.el.appendChild(tbody);

		// Trigger onRender callbacks
		this.triggerMethod('render', this);

		// This event tells everyone to redelegate view events in async
		// manner. Do it like this so that layout can happen asap.
		setTimeout(function() {
			ctrl.trigger('tm:redrawn');
		}, 0);

		return this;
	},
	_getDocFragment: function() {
		return this.fragment || (this.fragment = document.createDocumentFragment());
	},
	_getEmptyRowView: function() {
		return this.emptyView || (this.emptyView = new this.options.classes.EmptyRowView(this.options));
	},
	_getRowView: function(model, options) {
		const rowPool = this.rowPool;
		const opts = _.extend({ model: model }, options);
		const childView = rowPool.length
			? rowPool.pop().reinitialize(opts)
			: new options.classes.RowView(opts);

		this.children.push(childView);

		return childView;
	},
	_cleanupChildren: function() {
		this.rowPool = this.rowPool.concat(this.children);
		this.children.length = 0;
	},
	_destroyViews: function(list) {
		_.invoke(list, 'destroy');
		list.length = 0;
	},
	onDestroy: function() {
		this._destroyViews(this.children);
		this._destroyViews(this.rowPool);
	}
});

export default TbodyView;
