import Marionette from 'marionette';
import app from 'app';
import View from './dataUploader_view';
import fileSpecs from './fileSpecs/controller';
import operations from './operations/operations_ctrl';

const Controller = Marionette.Object.extend({
	initialize: function(workspaceModel) {
		const view = (this.view = new View());

		this.fileSpecs = fileSpecs.create(workspaceModel);
		this.operations = operations.create(workspaceModel);

		this.listenTo(view, 'render', function() {
			const job = workspaceModel.get('Job');
			const jobType = workspaceModel.get('JobType');

			app.request('app.services.jobs.getMetadataView', job, { jobType: jobType }).done(function(metadataView) {
				view.regionHeader.show(metadataView);
			});
			this.view.regionFileSpecs.show(this.fileSpecs.view);
			this.view.regionOperations.show(this.operations.view);
		});
	},

	onDestroy: function() {
		// Run each controller's close method.
		this.fileSpecs.destroy();
		this.operations.destroy();

		// Close the layout. This also ensures that any views in the layout
		// that weren't closed by their controllers are closed.
		this.view.destroy();
		this.view.remove();
	}
});

const create = function(workspaceController) {
	return new Controller(workspaceController.model);
};

export { create };
