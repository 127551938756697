var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<!--<div>-->\n';

var dataLoaded = model.get('__dataFetch').state() !== 'pending';
var isNew = model.get('IsNew');
var currTool = model.get('CurrentToolName');
var currSubtool = model.get('CurrentSubtoolName');
var toolsConfig = model.get('ToolsConfig');
var tools = toolsConfig && toolsConfig.tools;
var makeHref = function(toolEnabled, toolName, subtoolName) {
	return toolEnabled ? 'href="#' + model.makeRoute(toolName, subtoolName) + '"': '';
}

__p+='\n\n<ul class="workspace-tools">\n';
 _.each(tools, function(tool) {
	var toolName = tool.name;
	var toolEnabled = isNew ? !!tool.alwaysEnabled : dataLoaded;
	var toolActive = toolEnabled && toolName === currTool;
	var subtools = tool.subtools;

__p+='\n\n<li class="workspace-tool '+
((__t=( toolEnabled ? '' : 'disabled' ))==null?'':__t)+
' '+
((__t=( toolActive ? 'active' : '' ))==null?'':__t)+
'">\n	<a '+
((__t=( makeHref(toolEnabled, toolName) ))==null?'':__t)+
'>\n		<span class="workspace-tool-icon ldi ldi-'+
((__t=( tool.icon ))==null?'':__t)+
'"></span>\n		<span class="label">'+
((__t=( tool.displayName ))==null?'':__t)+
'</span>\n	</a>\n	\n	';
 if (typeof subtools !== 'undefined' && subtools.length) { 
__p+=' \n	\n	<ul class="workspace-tools">\n		<div class="title">Subtools</div>\n		\n		';
 _.each(subtools, function(subtool) {
			var subtoolName = subtool.name;
			var subtoolActive = toolActive && subtoolName === currSubtool;
		
__p+='\n		<li class="workspace-tool '+
((__t=( subtoolActive ? 'active' : '' ))==null?'':__t)+
'">\n			<a '+
((__t=( makeHref(toolEnabled, toolName, subtoolName) ))==null?'':__t)+
'>\n				<span class="workspace-tool-icon ldi ldi-'+
((__t=( subtool.icon ))==null?'':__t)+
'"></span>\n				<span class="label">'+
((__t=( subtool.displayName ))==null?'':__t)+
'</span>\n			</a>\n		</li>\n		\n		';
 }); 
__p+='\n		\n	</ul>\n	\n	';
 } 
__p+='\n</li>\n\n';
 }); 
__p+='\n</ul>\n<span class="workspace-toolbar-toggle ldi ldi-grip hoverable js-toolbarToggle"></span>\n<!--</div>-->';
}
return __p;
};
