var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>Lock / Unlock User Account</h2>\n\n<div class="grid-row">\n	<form class="form-horizontal grid-md-12 grid-lg-8">\n		<div class="form-group hidden">\n			<div class="grid-md-0 js-messages"></div>\n		</div>\n		<div class="form-group">\n			<div class="grid-md-0">\n				<button name="toggleLock" class="btn js-toggleLock">'+
((__t=( IsLocked ? 'Unlock' : 'Lock' ))==null?'':__t)+
'</button>\n			</div>\n		</div>\n	</form>\n</div>\n';
}
return __p;
};
