import filesaver from '../../lib/fileSaverPolyfill';

// Module to return.
const module = {};

const MIMETYPES = {
	csv: 'text/csv;charset=utf-8,',
	xls: 'application/vnd.ms-excel;charset=UTF-8,',
	xlsx: 'application/vnd.ms-excel;charset=UTF-8,'
};

// String to array buffer
function s2ab(s) {
	const buf = new ArrayBuffer(s.length);
	const view = new Uint8Array(buf);
	for (let i = 0; i !== s.length; ++i) {
		view[i] = s.charCodeAt(i) & 0xFF;
	}
	return buf;
}

// Download using FileSaver
module.download = function(name, ext, data) {
	const filename = `${name}.${ext}`;
	const blob = new Blob([s2ab(data)], { type: MIMETYPES[ext] });
	filesaver.saveAs(blob, filename);
};

export default module;
