import FormModel from 'common/modules/formView/model';
import View from './view';

function isGoodPassword(pw) {
	return (
		pw.length > 7 &&
		/[A-Z]/.test(pw) && // not uppercase only
		/[a-z]/.test(pw) && // not lowercase only
		/\d+/.test(pw) && // not numbers only
		/[^a-z\d]/i.test(pw)
	); // not symbols only
}

function init(app) {
	const ViewModel = FormModel.extend({
		defaults: {
			OldPassword: '',
			NewPassword: '',
			ConfirmPassword: ''
		},

		saveChanges: function() {
			const that = this;

			if (!that.isValid()) return;

			app.request(
				'app.services.users.changePassword',
				that.get('ClientId'),
				that.get('Id'),
				that.get('OldPassword'),
				that.get('NewPassword')
			)
				.done(function() {
					that.trigger('saved');
				})
				.fail(function(err) {
					let msg;

					if (err.responseJSON && err.responseJSON.Message) {
						msg = err.responseJSON.Message;
					}

					that.trigger('failed', msg);
				});
		},

		validateData: function(attrs, options) {
			const errors = {};

			if (!attrs.OldPassword) {
				errors.OldPassword = 'Please enter your current password';
			}

			if (!attrs.NewPassword) {
				errors.NewPassword = 'Please enter your new password';
			} else if (!isGoodPassword(attrs.NewPassword)) {
				errors.NewPassword =
					'New password is not strong enough; must have at least one uppercase letter, one lowercase letter, one number, and one symbol.';
			}

			if (!attrs.ConfirmPassword) {
				errors.ConfirmPassword = 'Please confirm your new password';
			} else if (attrs.NewPassword !== attrs.ConfirmPassword) {
				errors.ConfirmPassword = 'New password and Confirm password do not match.';
			}

			return errors;
		}
	});

	return {
		getEditPasswordView: function() {
			return app.request('app.services.users.getCurrentUser').then(function(model) {
				const viewModel = new ViewModel(model.attributes);
				return new View({ model: viewModel });
			});
		}
	};
}

export default init;
