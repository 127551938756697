import Marionette from 'marionette';
import View from './profile_view';

const Controller = Marionette.Controller.extend({
	initialize: function(workspaceController) {
		this.view = new View();
	},

	onDestroy: function() {
		this.view.destroy();
		this.view.remove();
	}
});

const create = function() {
	return new Controller();
};

export { create };
