import Marionette from 'marionette';
import template from './templates/tpl_layout.html';

const Layout = Marionette.LayoutView.extend({
	template: template,

	regions: {
		regionHeader: '#region-header',
		regionTable: '#region-table',
		regionSelectedDocsets: '#region-selected',
		regionCatalogue: '#region-catalogue'
	},

	showCatalogueView: function(view) {
		this.regionCatalogue.show(view, { preventDestroy: true });
	},

	hideCatalogueView: function() {
		this.regionCatalogue.empty({ preventDestroy: true });
	}
});

export default Layout;
