var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (numSelected === 1) { 
__p+='\n	1 row selected\n';
 } else { 
__p+='\n	'+
((__t=( numSelected ))==null?'':__t)+
' rows selected\n';
 } 
__p+='\n';
 if (numSelectedHidden) { 
__p+='\n	('+
((__t=( numSelectedHidden ))==null?'':__t)+
' hidden by your filters -- <a href="#" class="tbf-deselectHiddenRows">deselect hidden?</a>)\n';
 } 
__p+='\n';
}
return __p;
};
