import app from 'app';
import Backbone from 'backbone';
import EventLogCollection from './widgets/eventLog/model';

const OperationModel = Backbone.Model.extend({
	idAttribute: 'key',
	defaults: {
		ProgressPct: false,
		Status: 'Ready' // Ready | Queued | Started | Successful | Failed | <undefined>
	},
	initialize: function() {
		this.set('Events', new EventLogCollection());
		this.checkStatus();
		this.listenForEvents();

		// Additional helper properties
		this.set('scriptName', /configuration\/(.+)/.exec(this.get('ScriptLocation'))[1].split('/').join('.'));
	},
	checkStatus: function() {
		const that = this;
		const clientId = this.get('clientId');
		const jobTypeId = this.get('jobTypeId');
		const jobId = this.get('jobId');
		const operationName = this.get('name');
		const metaKey = `clients/${clientId}/jobtypes/${jobTypeId}/jobs/${jobId}/operations/${operationName}`;

		app.repository.getJson(metaKey).done(function(data) {
			that.set('Status', data.Status);
			that.set('CorrelationId', data.CorrelationId);
		});
	},
	runOperation: function(opts) {
		opts = typeof opts === 'object' ? opts : {};

		const that = this;
		const job = that.collection.options.job;
		that.set('Status', 'Queued');

		app.request('app.services.jobs.runOperation', this, job, opts.parameters).done(function(data) {
			that.set('Status', 'Started');
			that.set('CorrelationId', data.CorrelationId);
		}).fail(function(err) {
			that.set('Status', 'Failed');
			that.get('Events').add({
				Timestamp: new Date(),
				Message: {
					Text: 'Request to run operation failed',
					Status: 'Failed'
				}
			});
		});
	},
	listenForEvents: function() {
		const that = this;
		const events = that.get('Events');

		that.listenTo(app.vent, 'server:events', function(e) {
			const myCorrelId = that.get('CorrelationId');
			if (!myCorrelId || myCorrelId !== e.CorrelationId) return;

			events.add(e);

			if (typeof e.Message.Percentage === 'number') {
				that.set('ProgressPct', e.Message.Percentage);
			}
			if (e.Message.Status) {
				that.set('Status', e.Message.Status);
			}
		});
	}
});

const OperationCollection = Backbone.Collection.extend({
	model: OperationModel,
	initialize: function(collection, opts) {
		this.options = opts;
	}
});

export default OperationCollection;
