import _ from 'underscore';
import tools from 'common/tools/main';

const REGEX_TMATTR = /^tm:.+$/i;

const helpers = {

	/**
	 Returns `val` casted to the specified type. If specified type is
	 unrecognized, returns `val` as is. If `val` is nullish (null, undefined,
	 or empty string), returns `undefined`.

	 @param {*} val
	 @param {string} type  Valid types are 'boolean', 'number', 'date'.
	 @returns {*}
	 */
	toType: function(val, type) {
		// Nullish values represent lack of values, so return `undefined`.
		if (tools.isNullish(val)) return undefined;

		switch (type) {
			case 'boolean':
				return val === 'false' ? false : !!val;
			case 'number':
				return +val;
			case 'date':
				const d = new Date(val);
				return tools.isValidDate(d) ? d : undefined;
			default:
				return val;
		}
	},

	/**
	 Returns an object where the properties are the values of each object
	 in `arr` at the specified key. Each property in the resulting object
	 has a value of `true`.

	 This is similar to doing `_.object(_.pluck(arr, key), true)` with
	 Underscore, except this Underscore solution would have properties
	 pointing to `undefined` instead of `true`.

	 @param {array} arr
	 @param {string} key
	 @returns {object}
	 */
	pluckToObject: function(arr, key) {
		const result = {};
		let n = arr.length;
		while (n--) {
			result[arr[n][key]] = true;
		}
		return result;
	},

	/**
	 Returns true iff `val` is in `list`. Treats null, undefined, and ""
	 as the same.

	 @param {array} list
	 @param {*} val
	 @returns {boolean}
	 */
	contains: function(list, val) {
		return tools.isNullish(val)
			? _.indexOf(list, null) > -1 || _.indexOf(list, undefined) > -1 || _.indexOf(list, '') > -1
			: _.indexOf(list, val) > -1;
	},

	// == Backbone Models & Collections

	getColumnValue: function(model, column) {
		return typeof column.getValue === 'function'
			? column.getValue(model, column) : model.get(column.id);
	},

	getColumnValues: function(collection, column) {
		if (typeof column.getValue === 'function') {
			return collection.map(function(model) {
				return column.getValue(model, column);
			});
		} else {
			return collection.pluck(column.id);
		}
	},

	/**
	 Returns true iff at least one actual model attribute (i.e. not prefixed
	 with "tm:") changed.

	 @param {Backbone.Model}
	 @returns {boolean}
	 */
	hasModelAttrsChanged: function(model) {
		return _.some(model.changed, function(val, key) {
			return !REGEX_TMATTR.test(key);
		});
	},

	// == UI stuff

	/**
	 Returns the minimum required width for the table, given the specified
	 columns and starting sum.

	 @param {array} cols
	 @param {number} [sum]
	 @returns {number}
	 */
	calculateTableWidth: function(cols, sum) {
		sum = sum || 0;
		_.each(cols, function(col) {
			if (!col.isHidden) { // @todo this is part of a plugin
				sum += col.width;
			}
		});
		return sum;
	},

	// == Generic comparators

	comparators: {
		// Case-sensitive sorting.
		asc: function(a, b) {
			// Drop falsy values to bottom of list
			if (tools.isNullish(a) || a === false) return 1;
			if (tools.isNullish(b) || b === false) return -1;
			if (a > b) return 1;
			if (a < b) return -1;
			return 0;
		},
		dsc: function(a, b) {
			if (a < b) return 1;
			if (a > b) return -1;
			return 0;
		},

		// Case-insensitive sorting.
		caseinsen_asc: function(a, b) {
			a = a.toLowerCase();
			b = b.toLowerCase();
			if (a > b) return 1;
			if (a < b) return -1;
			return 0;
		},
		caseinsen_dsc: function(a, b) {
			a = a.toLowerCase();
			b = b.toLowerCase();
			if (a < b) return 1;
			if (a > b) return -1;
			return 0;
		}

	}

};

export default helpers;
