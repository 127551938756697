import Marionette from 'marionette';
import tpl from './shortDisplay_tpl.html';

const ShortDisplayView = Marionette.ItemView.extend({
	template: tpl,

	modelEvents: {
		change: 'render'
	}
});

export default ShortDisplayView;
