import FormModel from 'common/modules/formView/model';
import FormView from 'common/modules/formView/view';

function init(app) {
	const ViewModel = FormModel.extend({
		saveChanges: function() {
			const that = this;

			if (!that.isValid()) return;

			app.request('app.services.users.updateProfile', that)
				.done(function() {
					that.trigger('saved');
				})
				.fail(function(err) {
					let msg;

					if (err.responseJSON && err.responseJSON.Message) {
						msg = err.responseJSON.Message;
					}

					that.trigger('failed', msg);
				});
		},

		validateData: function(attrs, options) {
			const errors = {};

			if (!attrs.FirstName) {
				errors.FirstName = 'Cannot be blank';
			}

			if (!attrs.LastName) {
				errors.LastName = 'Cannot be blank';
			}

			return errors;
		}
	});

	return {
		getEditProfileView: function(clientId, userId, opts) {
			opts = opts || {};

			return app.request('app.services.users.getProfile', clientId, userId).then(function(profile) {
				return new FormView({
					model: new ViewModel(profile.attributes),
					formTitle: 'Profile information',
					formFields: [
						{
							name: 'Email',
							label: 'Email address',
							isReadOnly: true,
							show: !!opts.showEmail
						},
						{
							name: 'FirstName',
							label: 'First name',
							isRequired: true
						},
						{
							name: 'LastName',
							label: 'Last name',
							isRequired: true
						}
					]
				});
			});
		}
	};
}

export default init;
