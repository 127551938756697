var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<thead>\n	<tr>\n		<th>\n			Run Id / Date\n			'+
((__t=( _generateSortHtml('lastModified') ))==null?'':__t)+
'\n		</th>\n	</tr>\n</thead>\n<tbody></tbody>';
}
return __p;
};
