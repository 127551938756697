import $ from 'jquery';
import Backbone from 'backbone';
import Marionette from 'marionette';
import ui from 'ui/main';
import tpl from './templates/tpl_layout.html';

const View = Marionette.LayoutView.extend({
	template: tpl,
	className: 'workspace-instance',

	regions: {
		regionBody: '.region-body'
	},

	events: {
		'click .js-toolbarToggle': 'toggleToolbar',
		'click .js-back': 'onClickBack'
	},

	setActiveToolButton: function(toolName) {
		const $el = this.$el;
		$el.find('.workspace-tool').removeClass('active');
		$el.find(`.tool-${toolName}`).addClass('active');
	},

	showTool: function(data) {
		const that = this;
		const d = $.Deferred();

		if (!that.isRendered) {
			that.once('render', d.resolve);
			that.render();
		} else {
			d.resolve();
		}

		d.promise().done(function() {
			if (data.displayName) {
				that.$('.region-title').html(data.displayName);
			}

			that.$('.workspace-header')
				.find('.controls')
				.toggleClass('hidden', !data.isModal);

			that.regionBody.show(data.view);
			that.setActiveToolButton(data.name);

			ui.skeleton.refresh();
		});
	},

	toggleToolbar: function() {
		// Show/hide toolbar; used in mobile where toolbar is not always shown
		this.$('.workspace-toolbar').toggleClass('show');
	},

	onClickBack: function() {
		Backbone.history.history.back();
	}
});

export default View;
