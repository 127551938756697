import _ from 'underscore';

const PLUGINNAME = 'ColumnVis';

export default {

	name: PLUGINNAME,

	Controller: {
		// columns would be better as a model
		// each would have a 'visible' property
		// change would cause thead & tbody to re-render
		/**

		 @param {array<string>|string} ids
		 @param {boolean} show
		 */
		toggleColumnVis: function(ids, show) {
			const columns = this.get('columns');
			const isBool = typeof show === 'boolean';

			function toggleColVis(id) {
				const col = _.findWhere(columns, { id: id });
				if (col) {
					col.isHidden = isBool ? !show
						: typeof col.isHidden === 'undefined' ? true : !col.isHidden;
				}
			}

			if (_.isArray(ids)) {
				_.each(ids, toggleColVis);
			} else if (typeof ids === 'string') {
				toggleColVis(ids);
			}

			this.trigger('tm:redraw');
		}
	}

};
