import _ from 'underscore';
import Marionette from 'marionette';
import tools from 'common/tools/main';
import Grid from 'common/modules/table/main';
import moment from 'moment';
import Pikaday from 'pikaday';
import tplView from './templates/view.html';
import RawValueController from './raws/controller';

const WEEKS_OUT_THRESHOLD = 16;

const tplCalcDateCell = _.template(
	'<% if (typeof renderedValue !== \'undefined\') { %>' +
	'<% if (model.get(\'QaPdfUrl\') != null) { %>' +
	'<a href="<%= model.get(\'QaPdfUrl\') %>" class="ldi ldi-pdf x-large" target="_blank"></a> ' +
	'<% } %>' +
	'<%= renderedValue %>' +
	'<% } %>'
);

const tplPubDateCell = _.template(
	'<% if (typeof renderedValue !== \'undefined\') { %>' +
	'<a href="<%= model.get(\'PublishedPdfUrl\') %>" class="ldi ldi-pdf x-large" target="_blank"></a> ' +
	'<%= renderedValue %>' +
	'<% } %>'
);

const srriCellMixin = {
	template: _.template(
		'<%= value %> ' +
		'<span class="ldi ldi-view clickable hoverable js-viewRaw"></span>'
	),
	events: {
		'click .js-viewRaw': 'onClickViewRaw'
	},
	onClickViewRaw: function(e) {
		this.model.collection.trigger('showRawValues', this.model, this.options.column.id);
	}
};

// Render fn for SRRI column table header.
function srriColumnRenderDisplayName(column) {
	let dateLabel = '&nbsp;';
	const model = column['custom:collection'].first();

	if (model) {
		const data = model.get(column.id);

		if (typeof data === 'object' && data.Date) {
			dateLabel = data.Date;
		}
	}

	return `W${column['custom:weekNum']}<br/><small>${dateLabel}</small>`;
}

// For fetching value in SRRI column.
function srriColumnGetValue(model, column) {
	const v = model.get(column.id);
	if (typeof v === 'object') return v.Srri;
}

/**
 Generates column configs for observed SRRI values.

 @param {Backbone.Collection} collection  Original data collection for
 each column to reference.
 @param {int} numCols  Number of columns to generate.
 @param {int} [offset]  Id offset. First column generated will have
 an id of `offset + 1`.
 @param {boolean} [isHidden]  Do thise columns start off as hidden? Defaults
 to true.
 @returns {array<object>}
 */
function generateSrriColumnConfigs(collection, numCols, offset, isHidden) {
	offset = offset || 0;
	isHidden = typeof isHidden === 'undefined' ? true : isHidden;
	const cols = [];
	let i = 1;
	const n = numCols;
	let id;

	for (; i <= n; i++) {
		id = i + offset;
		cols.push({
			id: `Week${id}`,
			displayName: srriColumnRenderDisplayName,
			width: 80,
			type: 'number',
			getValue: srriColumnGetValue,
			isHidden: isHidden,
			filterType: 'range',
			thClasses: 'suppl',
			tdClasses: 'suppl',
			tdViewMixin: srriCellMixin,
			'custom:weekNum': id,
			'custom:collection': collection
		});
	}

	return cols;
}

const View = Marionette.LayoutView.extend({
	template: tplView,
	className: 'workspace-main',
	regions: {
		regionBody: '#region-body',
		regionModal: '#region-modal'
	},
	collectionEvents: {
		'fetching': 'onCollectionFetch',
		'reset': 'onCollectionReset',
		'tm:selectionchange': 'onSelectionChange',
		'showRawValues': 'showRawValues'
	},
	events: {
		'click .js-download': 'downloadCsv',
		'click .js-breakdown': 'toggleCalculatedSrriColumns',
		'click .js-delete': 'deleteItems',
		'click .js-closeModal': 'closeModal'
	},
	resources: {
		editHint: '(change)',
		fetchingMessage: 'Fetching data...',
		showPastSrriValues: 'Show past observed SRRI values',
		hidePastSrriValues: 'Hide past observed SRRI values'
	},
	initialize: function() {
		this.observCols = { show: false, ids: [], startAt: 0 };
	},
	templateHelpers: function() {
		return { resources: this.resources };
	},
	createGrid: function() {
		const collection = this.collection;
		const observCols = this.observCols;

		// First block of columns.
		const cols = [
			{ id: 'FundName', displayName: 'Fund name', width: 200, hasColumnFilter: false },
			{ id: 'ShareClassName', displayName: 'Share Class', width: 120 },
			{ id: 'Isin', displayName: 'ISIN', width: 80, hasColumnFilter: false },
			{ id: 'EffectiveDate', displayName: 'Effective date', width: 110, hasColumnFilter: false },
			{ id: 'CalculatedSrri', displayName: 'Calc. <br/>SRRI', width: 80, type: 'number', filterType: 'range' }
		];
		observCols.startAt = cols.length;

		// Push SRRI observation columns.
		// Save these column ids, so we can toggle them on/off later.
		const ids = observCols.ids;
		const newCols = generateSrriColumnConfigs(collection, collection.state.numObservations);
		_.each(newCols, function(col) {
			cols.push(col);
			ids.push(col.id);
		});

		// Push remaining columns.
		cols.push(
			{ id: 'PublishedSrri', displayName: 'Pub. <br/>SRRI', width: 80, type: 'number', filterType: 'range' },
			{
				id: 'CalculatedSrriDate',
				displayName: 'Calculated',
				width: 130,
				hasColumnFilter: false,
				tdViewMixin: {
					template: tplCalcDateCell
				}
			},
			{
				id: 'PublishedSrriDate',
				displayName: 'Published',
				width: 130,
				hasColumnFilter: false,
				tdViewMixin: {
					template: tplPubDateCell
				}
			},
			{
				id: 'NumWeeksOutside',
				displayName: '# Wks <br/>Outside',
				width: 90,
				type: 'number',
				filterType: 'range',
				tdViewMixin: {
					onRender: function() {
						if (this.model.get('NumWeeksOutside') > WEEKS_OUT_THRESHOLD) {
							this.$el.addClass('error');
						}
					}
				}
			}
		);

		return new Grid({
			collection: collection,
			columns: cols,
			modelIdAttribute: 'cid'
		});
	},
	onRender: function() {
		const that = this;
		const collection = that.options.collection;

		that.picker = new Pikaday({
			field: this.$('#srri-asofdate')[0],
			defaultDate: new Date(),
			setDefaultDate: true,
			// format: function(date) {
			// 	let s = tools.isToday(date) ? 'today' : date.format('UiSortableDate');
			// 	s += ' ' + that.resources.editHint;
			// 	return s;
			// },
			toString(date, format = 'YYYY/MM/DD') {
				const dateString = tools.isToday(date) ? 'today' : moment(date).format(format);
				return `${dateString} ${that.resources.editHint}`;
			},
			onSelect: function(date) {
				const dateStr = date > new Date() || tools.isToday(date) ? undefined : date.format('yyyy-MM-dd');
				collection.fetchData(dateStr);
			}
		});

		// Make a grid and show it. Trash the previous grid if it exists.
		if (that.grid) that.grid.destroy();
		that.grid = that.createGrid(collection);
		that.regionBody.show(that.grid.view);
	},
	onCollectionFetch: function() {
		this.$('.js-dataLoadingIndicator').removeClass('hidden');
	},
	onCollectionReset: function() {
		this.$('.js-dataLoadingIndicator').addClass('hidden');
		this.reconfigObservationsColumns();
	},
	onSelectionChange: function() {
		// Toggle disabled on delete btn
		const selected = this.collection.where({ 'tm:selected': true });
		this.$('.js-delete').prop('disabled', !selected.length);
	},

	reconfigObservationsColumns: function() {
		const collection = this.collection;
		const observCols = this.observCols;
		const cols = observCols.ids;
		const numPrev = cols.length;
		const numCurr = collection.state.numObservations;

		if (numCurr > numPrev) {
			// Add new cols.
			const numNew = numCurr - numPrev;
			const newCols = generateSrriColumnConfigs(collection, numNew, numPrev, !observCols.show);
			_.each(newCols, function(col) {
				cols.push(col.id);
			});
			if (this.grid) this.grid.get('controller').addColumns(newCols, { at: numPrev + observCols.startAt });
		} else if (numCurr < numPrev) {
			// Remove extra cols.
			const extraCols = cols.splice(numCurr);
			if (this.grid) this.grid.get('controller').removeColumns(extraCols);
		}
	},
	showModal: function(childView) {
		this.$('.js-overlay').removeClass('hidden');
		this.regionModal.show(childView);
	},
	closeModal: function() {
		this.$('.js-overlay').addClass('hidden');
		this.regionModal.empty();
	},
	downloadCsv: function() {
		const collection = this.options.collection;
		const asOfDate = collection.state.asOfDate || new Date().format('yyyy-MM-dd');
		const name = `SRRI data as at ${asOfDate}`;
		tools.file.download(name, 'csv', collection.toCsv());
	},
	deleteItems: function() {
		const selected = this.collection.where({ 'tm:selected': true });
		this.collection.remove(selected);
		this.$('.js-delete').prop('disabled', true);
	},
	toggleCalculatedSrriColumns: function() {
		const observCols = this.observCols;
		const show = observCols.show = !observCols.show;
		this.grid.get('controller').toggleColumnVis(observCols.ids, show);

		this.$('.js-breakdown-label').html(show
			? this.resources.hidePastSrriValues
			: this.resources.showPastSrriValues);
	},
	showRawValues: function(model, attr) {
		const that = this;
		const c = that.options.collection;
		const offsetDate = model.get(attr).Date;

		that.showModal(new RawValueController({
			clientId: c.clientId,
			fund: model,
			offsetDate: offsetDate
		}).view);
	},
	onDestroy: function() {
		if (this.grid) this.grid.destroy();
		if (this.picker) this.picker.destroy();
	}
});

export default View;
