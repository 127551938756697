import $ from 'jquery';
import Marionette from 'marionette';
import tplMenu from '../templates/tableSettings-menu.html';
import tplToggle from '../templates/tableSettings-toggle.html';

const PLUGINNAME = 'TableSettings';

const ToggleView = Marionette.ItemView.extend({
	template: tplToggle,
	events: {
		'click .js-tablesettings': 'toggleTableSettingsMenu'
	},
	toggleTableSettingsMenu: function(e) {
		this.options.controller.trigger('tm:dropmenu:tablesettings');
	},
	serializeData: function() {
		return {};
	}
});

const MenuView = Marionette.ItemView.extend({
	id: 'tbf-tablesettings',
	template: tplMenu,
	events: {
		'change input': 'onChangePageLength'
	},
	serializeData: function() {
		const controller = this.options.controller;

		return {
			current: controller.get('pageLength'),
			options: [
				{ display: 25, value: 25 },
				{ display: 50, value: 50 },
				{ display: 100, value: 100 }
			]
		};
	},
	onChangePageLength: function(e) {
		const length = +$(e.target).val();
		if (typeof length === 'number') {
			this.options.controller.set('pageLength', length);
		}
	}
});

export default {

	name: PLUGINNAME,

	SubstrateView: {
		initialize: function(opts) {
			this.listenTo(opts.controller, 'tm:dropmenu:tablesettings', this.toggleTableSettings);
		},
		onRender: function() {
			this.regionFooterLeft2.show(new ToggleView(this.options));
		},
		toggleTableSettings: function() {
			this.regionDropmenu.show(new MenuView(this.options));

			// Position menu.
			const btnOffset = this.$('.js-tablesettings').offset();
			const bottom = this.$('.js-footer').offset().top - btnOffset.top;
			const left = btnOffset.left - this.$('.tbf-compositeTable').offset().left;
			this.regionDropmenu.position('up', { bottom: bottom, left: left });
		}
	}
};
