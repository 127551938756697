import app from 'app';
import _ from 'underscore';
import Backbone from 'backbone';
import mixin from 'common/mixins/main';

const reLogFilePath = /.+\/(.+)\.(.+)\.log/i; // (path)/(name).(guid).log

const HistoryModel = Backbone.Model.extend({
	defaults: {
		DownloadUri: ''
	},
	initialize: function() {
		this.setCustomProperties();
	},
	setCustomProperties: function() {
		const that = this;

		app.repository.getDownloadUrl(this.get('key')).done(function(uri) {
			that.set('DownloadUri', uri);
		});
	}
});

/**

 Options:
 - prefix {str} S3 prefix
 - pageOptions {obj} To be given to mixin.pagingCollection
 - sortOptions {obj} To be given to mixin.sortableCollection
 - [takeAll] {bool} True to fetch as many items as repository will return on
 a GET request. Otherwise, fetches only <pageLength> number of items.

 */
const HistoryCollection = Backbone.Collection.extend({
	model: HistoryModel,
	initialize: function(collection, opts) {
		this.options = opts;

		// Make this a sortable collection.
		mixin.sortableCollection(_.extend({
			that: this,
			sortProperty: 'lastModified',
			sortDirection: -1
		}, opts.sortOptions));

		// Make this a pageable collection.
		mixin.pagingCollection(_.extend({
			that: this,
			fetchMethod: 'fetchNext'
		}, opts.pageOptions));

		if (typeof this.onInitialize === 'function') this.onInitialize();
	},

	/**
	 Fetches metadata for the specified log, adding it to this collection
	 or updating its existing entry.

	 @param id {str}
	 */
	fetchListing: function(id, delay) {
		if (!id) return;

		if (delay) {
			_.delay(_.bind(this._fetchListing, this), delay, id);
		} else {
			this._fetchListing(id);
		}
	},
	_fetchListing: function(id) {
		const that = this;
		const path = `${that.options.prefix}.${id}.log`;

		app.repository.listFiles(path).done(function(data) {
			const objs = that.formatRawListing(data);
			that.add(objs);
		});
	},

	/**
	 Fetches next page of data from server, if available, and adds it
	 to this collection.
	 Required by mixin.pagingCollection.
	 */
	fetchNext: function(override) {
		const that = this;

		// If there's no more data to fetch and not forcing refresh, exit.
		if (!override && !that.hasServerPages()) return;

		const path = that.options.prefix;
		const opts = {
			skip: override ? 0 : that.length,
			sortBy: 'LastModifiedDate',
			sortDirection: 'desc'
		};

		if (this.options.takeAll !== true) {
			opts.take = that.pageLength;
		}

		that._fetching = true;
		that.trigger('fetching');

		return app.repository.listFiles(path, opts).then(function(list, jqXhr) {
			const nCollection = that.length;
			// const nPage = list.length;

			// @todo sparklycats
			const links = app.repository.parseLinkHeader(jqXhr.getResponseHeader('Link'));
			that.setHasServerPages(!!links.next);

			// Massage data into format we want.
			const objs = that.formatRawListing(list);

			// If no items yet, do a reset; it's faster than `add`.
			nCollection && !override ? that.add(objs) : that.reset(objs);

			return objs;
		}).always(function() {
			that._fetching = false;
			that.trigger('fetched');
		});
	},

	/**
	 Formats incoming repository data, by adding additional properties on
	 each item. Note: Changes `list`!

	 @param {array<object>} list  Each object contains info about a log file.
	 @returns {array} `list`
	 */
	formatRawListing: function(list) {
		_.each(list, function(item) {
			const nameParts = reLogFilePath.exec(item.key);
			item.scriptName = nameParts[1];
			item.logId = nameParts[2];
		});

		const filter = this.options.filter;

		if (typeof filter === 'function') {
			list = _.filter(list, filter);
		}

		return list;
	}
});

export default HistoryCollection;
