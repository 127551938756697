var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<td>\n	<b>'+
((__t=( Email ))==null?'':__t)+
'</b>\n	<div class="js-userinfo"></div>\n</td>\n<td class="js-groups"></td>\n<td class="js-actions">\n	<a href="#admin/users/'+
((__t=( Id ))==null?'':__t)+
'" class="btn">Edit</a>\n	<button class="js-deleteUser btn" data-userid="'+
((__t=( Id ))==null?'':__t)+
'">Delete</button>\n</td>\n';
}
return __p;
};
