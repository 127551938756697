import $ from 'jquery';
import ui from 'ui/main';

let appInstance;

/** --- DOM cache management --- **/
const domCache = {};

// Mapping of cache keys to actual dom selectors.
const domLabels = {
	app: '.app',
	app_nav: '.app-nav',
	sidebar: '.app-sidebar',
	sidebar_toggle: '#sidebar-toggle'
};

/**
 Fetches DOM item under the key "name" from cache. If not in cache, fetches
 it from DOM (or $parent, if provided) and caches it for next time.

 @param name {str} Name of item to fetch. Names are in domLabel dict.
 @param $parent {$} [optional] DOM element to fetch $el from. If not given,
 $el fetched from DOM.
 @param refresh {bool} [optional] Force re-fetch from DOM/$parent, even
 if element is already in cache. Caches the newly fetched version.
 @returns {$} Fetched $el, or undefined if not found.
 */
function $c(name, $parent, refresh) {
	if (typeof domCache[name] === 'undefined' || refresh) {
		const selector = domLabels[name];

		// If $parent provided, return $parent if it matches the selector
		// or attempt to find an element inside $parent that does.
		// If no $parent, attempt to find element in DOM.
		const $el = $parent ? ($parent.is(selector) ? $parent : $parent.find(selector)) : $(selector);

		return setCache(name, $el);
	} else {
		return domCache[name];
	}
}

/**
 Caches DOM item "$el" under the key "name", and returns $el.
 If $el is an empty selection, it is not cached and return value is undefined.
 */
function setCache(name, $el) {
	if ($el.length) {
		domCache[name] = $el;
		return $el;
	}
}

/** --- Layout public methods --- **/

/**
 Sets the main layout's size and attaches relevant event bindings.
 Run this when the layout DOM is ready.
 */
function init(app) {
	if (app) appInstance = app;

	// Set sizes on the layout.
	setLayoutSizes();

	// Set up event bindings.
	$(window).on('resize', onWindowResize);
}

/**
 Sets the dimensions for the app.
 */
function setLayoutSizes() {
	setSidebarSize();

	if (appInstance) {
		appInstance.trigger('ui:skeleton:resized');
	}
}

/** --- Sidebar public methods --- **/

/**
 Sets the sidebar's layout component sizes and attaches relevant event
 bindings. Run this after the sidebar DOM is ready.

 @param $el {$} [optional] DOM element for .sidebar-inner. If not provided,
 fetches from DOM.
 */
function initSidebar($inner) {
	// Set up event bindings.
	$c('sidebar_toggle').on('click', toggleSidebar); // sidebar toggle on nav
}

function showSidebar() {
	$c('sidebar').removeClass('hidden');
	setSidebarSize();
}

function hideSidebar() {
	$c('sidebar').addClass('hidden');
	setSidebarSize();
}

function toggleSidebar(e) {
	if (e) e.stopPropagation();
	$c('sidebar').toggleClass('hidden');
	setSidebarSize();
}

function setSidebarSize() {
	const $sidebar = $c('sidebar');

	if ($sidebar.length) {
		const windowSize = ui.getWindowSize();
		const $nav = $c('app_nav');
		const navH = $nav.length ? $nav.outerHeight() : 0;
		const $header = $sidebar.find('.sidebar-header');
		const headerH = $header.length ? $header.outerHeight() : 0;
		const h = windowSize.h - navH - headerH;

		// the side bar for job explorer
		$sidebar.find('.sidebar-explorer').outerHeight(h);
	}
}

/** --- Event handler definitions --- **/

/**
 Triggered on window resize. Resizes the layout.
 */
function onWindowResize() {
	setLayoutSizes();
}

export default function(styleguide) {
	const api = {};
	api.init = init;
	api.refresh = setLayoutSizes;

	if (styleguide) {
		api.initSidebar = initSidebar;
		api.showSidebar = showSidebar;
		api.hideSidebar = hideSidebar;
		api.toggleSidebar = toggleSidebar;
	}

	return api;
};
