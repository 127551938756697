import $ from 'jquery';
import 'jquery-whenAll';
import tools from 'common/tools/main';
import appSettings from 'config';
import pascalcaseKeys from 'pascalcase-keys';


import Models from './model';

const apiRootUri = appSettings.ApiRootUri;
const apiUri = appSettings.ApiUri;
const requestUriRoot = appSettings.ScriptSvcUri;

function init(app) {
	function _formatFullModel(ds) {
		const d = $.Deferred();
		const model = new Models.SetModel(ds, { app });
		model.fetchDocuments().always(function() {
			d.resolve(model);
		});
		return d.promise();
	}

	function _listSingle(opts) {
		const key = `publisher/${opts.clientId}/publishsets/${opts.outputId}`;
		const d = $.Deferred();

		app.request('app.services.meta.get', key)
			.done(function(set) {
				d.resolve(new Models.SetModel(set, { app, lazyLoad: opts.lazyLoad }));
			})
			.fail(d.reject);

		return d.promise();
	}

	function _list(opts) {
		const d = $.Deferred();
		const type = opts.type;
		const jobTypeId = opts.jobTypeId;
		const key = `publisher/${opts.clientId}/publishsets?skip=&take=`;
		const queryObj = {};

		if (type) {
			queryObj.publishType = type;
		}

		if (jobTypeId) {
			queryObj.jobTypeId = jobTypeId;
		}

		app.request('app.services.meta.get', key, queryObj).done(function(data) {
			const combinedOpts = { app, lazyLoad: opts.lazyLoad };
			const items = data.items.map(obj => pascalcaseKeys(obj));
			d.resolve(new Models.SetCollection(items, combinedOpts));
		});

		return d.promise();
	}

	function _publish(model, type) {
		const clientId = model.get('ClientId');
		if (tools.isNullish(clientId)) throw new Error('clientId is required');

		const jobTypeId = model.get('JobTypeId');
		if (tools.isNullish(jobTypeId)) throw new Error('jobtypeId is required');

		const jobId = model.get('JobId');
		if (tools.isNullish(jobId)) throw new Error('jobId is required');

		const command = type === 'blackline' ? 'blackline' : 'publish';
		model.url = `${apiUri}publisher/${clientId}/${command}`;

		return $.ajax({
			url: model.url,
			type: 'POST',
			contentType: 'application/json',
			data: JSON.stringify(model.toJSON()),
			dataType: 'text'
		});
	}

	const api = {
		getDocumentSets: function(clientId, jobTypeId, outputId) {
			let key;
			const d = $.Deferred();

			if (!clientId || !jobTypeId) {
				d.reject('Missing required parameter(s)');
			} else if (!outputId) {
				// multiple
				const queryparams = {
					jobTypeId,
					publishType: 'DocumentSet',
					publishStatus: 'Completed',
					take: 100,
					skip: 0
				};

				// the API pages the results, with default page size of 100,
				// so we will read everything until there are no more items to read.
				const items = [];
				const getAllPagesAfter = function(skip = 0) {
					queryparams.skip = skip;
					key = `publisher/${clientId}/publishsets?${new URLSearchParams(queryparams).toString()}`;
					const d = $.Deferred();
					app.request('app.services.meta.get', key).done(function(sets) {
						sets.items.map(obj => {
							if (obj.status === 'Completed') {
								items.push(pascalcaseKeys(obj));
							}
						});

						if (sets.items.length < queryparams.take) {
							// if we did not receive a full page, then resolve the promise
							d.resolve(new Models.SetCollection(items, { app }));
						} else {
							// if we received a full page, then there may be more items to fetch.
							getAllPagesAfter(skip + queryparams.take).done(d.resolve).fail(d.reject);
						}
					});

					return d;
				};

				getAllPagesAfter().done(d.resolve).fail(d.reject);
			} else {
				// single
				key = `publisher/${clientId}/publishsets/${outputId}`;
				app.request('app.services.meta.get', key).done(function(set) {
					_formatFullModel(set)
						.done(d.resolve)
						.fail(d.reject);
				});
			}

			return d.promise();
		},

		list: function(clientId, jobTypeId, outputId, type, lazyLoad) {
			clientId = clientId || app.session.get('clientId');

			const request = !outputId ? _list : _listSingle;

			return request({
				clientId: clientId,
				jobTypeId: jobTypeId,
				outputId: outputId,
				type: type || null,
				lazyLoad: !!lazyLoad
			});
		},

		listDocumentSets: function(clientId, jobTypeId, outputId) {
			return api.list(clientId, jobTypeId, outputId, 'DocumentSet');
		},

		publish: function(model) {
			return _publish(model);
		},

		blackline: function(model) {
			return _publish(model, 'blackline');
		},

		cancel: function(clientId, publishSetId) {
			const url = `${apiRootUri}/${clientId}/publishsets/${publishSetId}/cancel`;
			return $.ajax({ url: url, type: 'POST', data: {} });
		},

		markAsFinal: function(clientId, jobTypeId, outputId, isFinal) {
			const path = `clients/${clientId}/jobtypes/${jobTypeId}/outputs/${outputId}`;
			const tags = ['final'];
			const add = isFinal ? tags : undefined;
			const remove = isFinal ? undefined : tags;
			return app.repository.updateTags(path, add, remove);
		},

		getPdfPreviewUrl: function(clientId, jobTypeId, jobId, docId) {
			docId = tools.isNullish(docId) ? '' : docId;
			return (
				`${requestUriRoot
				}clients/${
					clientId
				}/jobtypes/${
					jobTypeId
				}/jobs/${
					jobId
				}/configuration/api/preview?docId=${
					docId}`
			);
		}
	};

	return api;
}

export default init;
