var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<tbody>\n	<tr class="tbf-row-sizer">\n		';
 _.each(options.columns, function(col, i) { 
__p+='\n\n			';

			// Do not render this cell if it's in a hidden column.
			if (col.isHidden) { return; }
			
__p+='\n\n			<td class="tbf-colWidthCell" data-tbf-attr="'+
((__t=( col.id ))==null?'':__t)+
'" style="width: '+
((__t=( col.width ))==null?'':__t)+
'px;"></td>\n		';
 }); 
__p+='\n	</tr>\n</tbody>\n';
}
return __p;
};
