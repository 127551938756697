import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import tools from 'common/tools/main';
import tplCount from '../templates/rowSelect-count.html';

const PLUGINNAME = 'RowSelect';

function didModelChange(model, changedSelection, controller) {
	let isChanged;

	if (_.isArray(changedSelection)) {
		const idAttr = controller.get('modelIdAttribute');
		const id = model.get(idAttr);
		isChanged = _.find(changedSelection, function(m) {
			return m.get(idAttr) === id;
		});
	} else {
		isChanged = model === changedSelection;
	}

	return isChanged;
}

const ThViewMixin = {
	template: _.template('<input type="checkbox" name="tbf-rows" value="all" />'),
	collectionEvents: {
		'tm:filtered': 'onCollectionSelectionChange',
		'tm:selectionchange': 'onCollectionSelectionChange'
	},
	events: {
		'change input': 'onChangeSelectAll'
	},
	onChangeSelectAll: function(e) {
		const checked = $(e.target).prop('checked');
		this.options.controller.select('', checked);
	},
	onCollectionSelectionChange: function() {
		const ctrl = this.options.controller;
		const coll = ctrl.get('collection');
		const filtered = coll.where({ 'tm:match': true });
		const selected = coll.where({ 'tm:match': true, 'tm:selected': true });

		// Refresh select all checkbox state
		const someChecked = selected.length;
		const allChecked = filtered.length === selected.length;
		this.$('.tbf-rowSelectCol input').prop({
			checked: someChecked && allChecked,
			indeterminate: someChecked && !allChecked
		});
	}
};

const TdViewMixin = {
	template: _.template('<input type="checkbox" name="tbf-rows" value="<%= model[column.modelIdAttribute] %>" <%= value ? \'checked\' : \'\' %>/>'),
	collectionEvents: {
		'tm:selectionchange': 'onCollSelectionChange'
	},
	modelEvents: {
		'change:tm:selected': 'render'
	},
	events: {
		'change input[name="tbf-rows"]': 'onChangeSelect'
	},
	onCollSelectionChange: function(selection, controller) {
		// Am I part of this selection change?
		if (didModelChange(this.model, selection, controller)) {
			this.render();
		}
	},
	onChangeSelect: function(e) {
		const controller = this.options.controller;
		const idAttr = controller.get('modelIdAttribute');
		const id = this.model[idAttr];
		const checked = $(e.target).prop('checked');
		controller.select(id, checked);
	}
};

const SelectedRowCountView = Marionette.ItemView.extend({
	template: tplCount,
	collectionEvents: {
		'change:tm:selected tm:filtered tm:selectionchange': 'render'
	},
	events: {
		'click .tbf-deselectHiddenRows': 'onClickDeselectHidden'
	},
	serializeData: function() {
		const collection = this.options.collection;
		const selected = collection.where({ 'tm:selected': true });
		const numSelectedHidden = _.reduce(selected, function(memo, model) {
			return !model.get('tm:match') ? ++memo : memo;
		}, 0);

		return {
			numSelected: selected.length,
			numSelectedHidden: numSelectedHidden
		};
	},
	onClickDeselectHidden: function(e) {
		e.preventDefault();
		this.options.controller.deselectHidden();
	}
});

const COLUMN_CONFIG = {
	id: 'tm:selected',
	thClasses: 'tbf-rowSelectCol tb-col-checkbox',
	tdClasses: 'tbf-rowSelectCol tb-col-checkbox',
	thViewMixin: ThViewMixin,
	tdViewMixin: TdViewMixin,
	width: 32
};

export default {

	name: PLUGINNAME,

	// defaults: rowSelectType: checkbox|radio|multiple|single

	Controller: {
		initialize: function() {
			this.addColumns(COLUMN_CONFIG, { at: 0 });
		},
		select: function(id, select) {
			// If `select` not a boolean, can't be sure what user means, so
			// throw an error.
			if (typeof select !== 'boolean') {
				throw new Error('`select` must be a boolean');
			}

			// 'tm:select' is always updated silently, because we trigger
			// a custom overarching 'tm:selectionchange' event.
			const coll = this.get('collection');
			const silent = { silent: true };
			let selection;

			// If `id` is a null-ish thing, assume selection applies to all
			// models that match the current filter.
			if (tools.isNullish(id)) {
				selection = coll.where({ 'tm:match': true });

				_.each(selection, function(model) {
					model.set('tm:selected', select, silent);
				});
			} else {
				const idAttr = this.get('modelIdAttribute');
				selection = coll.find(function(m) {
					return m[idAttr] === id;
				});

				if (selection) {
					selection.set('tm:selected', select, silent);
				} else {
					throw new Error(`No such model (id: ${id})`);
				}
			}

			coll.trigger('tm:selectionchange', selection, this);
		},
		deselectHidden: function() {
			const collection = this.get('collection');
			const models = collection.where({ 'tm:selected': true, 'tm:match': false });
			const silent = { silent: true };

			_.each(models, function(model) {
				model.set('tm:selected', false, silent);
			});

			collection.trigger('tm:selectionchange', models, this);
		}
	},

	SubstrateView: {
		onRender: function() {
			this.regionHeaderLeft.show(new SelectedRowCountView(this.options));
		}
	},

	RowView: {
		collectionEvents: {
			'tm:selectionchange': 'onCollSelectionChange'
		},
		modelEvents: {
			'change:tm:selected': 'render'
		},
		onCollSelectionChange: function(selection, controller) {
			// Am I part of this selection change?
			if (didModelChange(this.model, selection, controller)) {
				this.render();
			}
		},
		onRender: function() {
			const selected = this.model.get('tm:selected');
			this.$el.toggleClass('selected', !!selected);
		}
	}

};
