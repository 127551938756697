var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (nodeType === 'job') { 
__p+='\n';
 var cssClass = selected ? 'b' : ''; 
__p+='\n<a href="#'+
((__t=( route ))==null?'':__t)+
'" class="tree-node-contents '+
((__t=( cssClass ))==null?'':__t)+
'" data-type="'+
((__t=( nodeType || 'no-type' ))==null?'':__t)+
'" data-id="'+
((__t=( nodeId || 'no-id' ))==null?'':__t)+
'">\n	<span class="tree-node-controls">\n		<span class="ldi ldi-info hoverable large js-info" title="View details"></span>\n	</span>\n    <span class="tree-toggle fa fa-caret-right"></span>\n    <span class="tree-node-label node-text">'+
((__t=( nodeName ))==null?'':__t)+
'</span>\n</a>\n<div class="region-details tree-node-details hidden"></div>\n\n';
 } else { 
__p+='\n\n<div class="tree-node-contents" data-type="'+
((__t=( nodeType || 'no-type' ))==null?'':__t)+
'" data-id="'+
((__t=( nodeId || 'no-id' ))==null?'':__t)+
'" tabIndex="0">\n    <span class="tree-toggle fa fa-caret-right"></span>\n    <span class="tree-node-label node-text">'+
((__t=( nodeName ))==null?'':__t)+
'</span>\n</div>\n\n';
 } 
__p+='\n\n<ul class="tree-nodes"></ul>\n';
}
return __p;
};
