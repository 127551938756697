import app from 'app';
import $ from 'jquery';
import Marionette from 'marionette';
import tools from 'common/tools/main';
import treeTpl from './templates/tpl_catalogue.html';

const CatalogueView = Marionette.ItemView.extend({
	tagName: 'div',
	className: 'column-inner',
	template: treeTpl,

	initialize: function(opts) {
		this.collection = opts.collection;
		this.blm = opts.blm;
		this.viewType = opts.viewType; // 'newer' or 'older'
		tools.func.debounceResize(app, this, 'setViewHeight');
	},

	events: {
		'click .bl-setList-done': 'onClickDone',
		'click .bl-setList-jobNodeContents': 'onClickToggleJobTreeNode',
		'change input[name="bl-sets-newer"]': 'onInputChangeNewerSet',
		'change input[name="bl-sets-older"]': 'onInputChangeOlderSet'
	},

	serializeData: function() {
		const blm = this.blm;
		const newDocSet = blm.get('newDocSet');
		const newDocSetId = newDocSet ? newDocSet.get('Id') : '';
		const oldDocSets = blm.get('oldDocSetColl');

		const grouped = this.collection
			.chain()
			// Mark models whether they've been selected as new/old docset.
			// @todo Maybe this should on the model side.
			.map(function(model) {
				const id = model.id;
				const isNewDocSet = id === newDocSetId;
				const isOldDocSet = !!oldDocSets._byId[id];
				model.set({ isNewDocSet: isNewDocSet, isOldDocSet: isOldDocSet });
				return model;
			})
			// Sort models by creation date, most recent first.
			.sortBy(function(model) {
				return -(model.get('Date'));
			})
			// Group models by job id.
			.groupBy(function(model) {
				return model.get('JobId');
			})
			// Return the massaged collection.
			.value();

		return {
			viewType: this.viewType,
			collection: grouped
		};
	},

	onInputChangeNewerSet: function(e) {
		const id = $(e.target).val();
		this.trigger('changeNewDocSet', id);
		this.trigger('hideCatalogueView');
	},

	onInputChangeOlderSet: function(e) {
		const $input = $(e.target);

		const id = $input.val();

		const eventType = $input.prop('checked') ? 'addOldDocSet' : 'removeOldDocSet';

		this.trigger(eventType, id);
	},

	setViewHeight: function() {
		const $el = this.$el;

		const $viewContainer = $('.blackline-right');
		let height;

		// Can only calculate height if we could find a $viewContainer,
		// i.e. if this view has been attached to the DOM.
		if ($viewContainer.length) {
			height = $viewContainer.height();
			$el.find('.bl-setList-treeContainer').outerHeight(height);
		}
	},

	onClickToggleJobTreeNode: function(e) {
		$(e.target)
			.closest('.tree-node')
			.toggleClass('open');
	},

	onClickDone: function(e) {
		this.trigger('hideCatalogueView');
	}
});

export default CatalogueView;
