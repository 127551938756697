/**
 * @file Adds `$.whenAll` static method to jQuery, which waits until all
 * provided Deferreds have been resolved or rejected.
 *
 * **NOTE:** DO NOT replace with the similarly-named NPM package. It won't work,
 * because functions differently from this one.
 */

import $ from 'jquery';

/**
 Returns a promise that resolves once all provided Deferreds are no longer
 pending.

 @param {...*} arguments  Deferreds to wait on. If non-Deferreds are
 provided, they will be treated as Deferreds that immediately resolve to
 themselves.
 @returns {$.Deferred}
 */
$.whenAll = function() {
	const items = Array.prototype.slice.apply(arguments);
	let numPending = items.length;

	// No args! Resolve immediately.
	if (!numPending) return $.Deferred().resolve();

	const d = $.Deferred();
	const results = new Array(numPending);
	let item;

	let i = 0;
	const n = numPending;

	/**
	 Called when one of the Deferred being monitored completes.
	 Composite Deferred reports update by calling `notify`. If all monitored
	 Deferreds have completed, composite Deferred also resolves.

	 The composite Deferred calls `notify` and `resolve` with the resolved/
	 rejected values of all monitored Deferreds.

	 @param {number} i  Position of monitored Deferred in `arguments`.
	 @param {*} result  What the monitored Deferred resolved/rejected with.
	 */
	function update(i, result) {
		if (typeof i === 'number') {
			results[i] = result;
			d.notify.apply(d, results);
		}
		if (--numPending < 1) {
			d.resolve.apply(d, results);
		}
	}

	// Process all the things we're going to monitor.
	for (; i < n; i++) {
		item = items[i];

		if (typeof item === 'object' && typeof item.promise === 'function') {
			// Looks like a Deferred. When it resolves/rejects, update the
			// composite Deferred. Use a IIFE to pass in the index of this
			// Deferred to `update`.
			/* jshint -W083 */
			(function(index) {
				item.always(function(result) {
					update(index, result);
				});
			})(i);
		} else {
			// Non-Deferred. 's already resolved! Update composite Deferred.
			update(i, item);
		}
	}

	return d.promise();
};
