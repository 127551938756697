import _ from 'underscore';
import Marionette from 'marionette';
import tools from 'common/tools/main';

const RowView = Marionette.ItemView.extend({
	tm_classId: 'RowView',
	tagName: 'tr',
	_initialize: function(opts) {
		this.options = opts;
		const model = (this.model = opts.model);
		Marionette.bindEntityEvents(this, model, this.modelEvents);
		if (typeof this.onInitialize === 'function') this.onInitialize(opts);
		return this;
	},
	initialize: function(opts) {
		this.children = [];
		this.listenTo(opts.controller, 'tm:redraw', this._invalidateChildViews);
		this.listenTo(opts.controller, 'tm:redrawn', this.delegateEvents);
		Marionette.bindEntityEvents(this, opts.collection, this.collectionEvents);
		return this._initialize(opts);
	},
	reinitialize: function(opts) {
		Marionette.unbindEntityEvents(this, this.model, this.modelEvents);
		return this._initialize(opts);
	},
	serializeData: function() {
		return { options: this.options };
	},
	render: function(renderOpts) {
		// Trigger onBeforeRender callbacks
		this.triggerMethod('before:render', this);

		const opts = this.options;

		const cols = opts.columns;

		const $el = this.$el;

		const fragment = this._getDocFragment();
		let i = 0;
		const n = cols.length;
		let col, childView;

		for (; i < n; i++) {
			col = cols[i];

			// Do not render this cell if it's in a hidden column.
			if (!col.isHidden) {
				childView = this._getChildView(i, col, opts);
				fragment.appendChild(childView.render(renderOpts).el);
			}
		}

		// If this is a bulk redraw, detach $el before mutating it. Someone
		// else will be responsible for re-attaching.
		if (typeof renderOpts === 'object' && renderOpts.redraw) $el.detach();

		this.$el.empty();
		this.el.appendChild(fragment);

		// Trigger onRender callbacks
		this.triggerMethod('render', this);

		return this;
	},
	_getDocFragment: function() {
		return this.fragment || (this.fragment = document.createDocumentFragment());
	},
	_getChildViewClass: function(col) {
		let Cls = col.tdViewClass;

		if (!Cls) {
			// Use base class instead of customized one.
			Cls = col.tdViewClass =
				typeof col.tdViewMixin === 'object'
					? tools.extendClass(this.options.classes.TdView, col.tdViewMixin)
					: this.options.classes.TdView;
		}

		return Cls;
	},
	_getChildView: function(i, column, options) {
		const children = this.children;
		const viewOpts = _.extend({ column: column }, options);
		let childView = children[i];

		if (childView) {
			childView.reinitialize(viewOpts);
		} else {
			const ChildView = this._getChildViewClass(column);
			childView = new ChildView(viewOpts);
			children.splice(i, 0, childView);
		}

		return childView;
	},
	_invalidateChildViews: function() {
		this._destroyChildren();
		this.render();
	},
	_destroyChildren: function() {
		_.each(this.children, function(childView) {
			if (childView) childView.destroy();
		});
		this.children.length = 0;
	},
	onDestroy: function() {
		this._destroyChildren();
	}
});

export default RowView;
