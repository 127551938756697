import $ from 'jquery';
import appSettings from 'config';
const uriRoot = appSettings.ApiUri;

function init(app) {
	return {
		get: function(key, query) {
			const url = uriRoot + key;

			// If the url has a query string and a query object is also
			// provided, jQuery merges them.
			query = query || {};

			// Server requires $query object to be stringified.
			if (typeof query.$query === 'object') {
				query.$query = JSON.stringify(query.$query);
			}

			return $.ajax({ method: 'get', url: url, data: query });
		}
	};
}

export default init;
