import Marionette from 'marionette';
import View from './view';

const Controller = Marionette.Object.extend({
	initialize: function(opts) {
		this.model = opts.operation;
		this.view = new View();
		this.listenTo(this.view, 'run', this.runOperation);
	},
	runOperation: function(parameters) {
		this.model.runOperation(parameters);
	}
});

export default Controller;
