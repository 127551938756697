import Marionette from 'marionette';
import Collection from './model';
import View from './view';

const Controller = Marionette.Object.extend({
	initialize: function(opts) {
		const collection = new Collection(null, opts);
		this.view = new View({ collection: collection });
	}
});

// So other people can check their view instances against the View.
Controller.View = View;

export default Controller;
