var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<h2>Two-factor authentication</h2>\n\n<div class="form-horizontal">\n\n	<div class="form-group">\n		<div class="grid-md-0 js-messages"></div>\n	</div>\n\n	<div class="form-group js-controls">\n		<div class="grid-md-0">\n			<button class="btn js-enableMfa" data-class="disabledOnly">Enable</button>\n			<button class="btn js-resetMfa" data-class="enabledOnly">Reset</button>\n			<button class="btn js-disableMfa" data-class="enabledOnly">Disable</button>\n		</div>\n	</div>\n\n	<div class="form-group js-setupForm hidden">\n		<div class="grid-md-0">\n			<div id="qrcode" class="qrcode mfa-qrcode"></div>\n		</div>\n		<div class="grid-md-8 grid-lg-4">\n			<ol class="mfa-instructions">\n				<li>Scan the following QR code with your phone, or enter the following code: <code class="qrcode-str">'+
((__t=( friendlySecretKey ))==null?'':__t)+
'</code></li>\n				<li>\n					<p>Enter the security code generated by your authenticator app:</p>\n					<p><input type="text" class="form-control js-securityCode" placeholder="Security code" /></p>\n					<p>\n						<button class="js-verifySetup btn">Verify</button>\n						<button class="js-cancelSetup btn btn-link btn-sm">Cancel setup</button>\n					</p>\n				</li>\n			</ol>\n		</div>\n	</div>\n\n</div>\n';
}
return __p;
};
