/**
 * Mix into a Collection to add sorting capabilities.
 *
 * ##### Added properties
 *
 * - `sortProperty`: Current property to sort by. @see @param sortProperty.
 * - `sortDirection`: Current direction to sort in. @see @param sortDirection.
 *
 * ##### Added methods
 *
 * NB: You can override these methods in your Collection definition.
 *
 * - `comparator`: For sorting. Handles strings, numbers, Dates.
 * - `sortData`: Sorts your collection according to some property and a
 * 	sort direction.
 *
 * @module common/mixins/sortableCollection
 *
 * @param {Backbone.Collection} collection  The collection to add this mixin to.
 * 	Alias: `that`
 * @param {string} sortProperty  Initial property to sort by. This is an attribute
 * 	name in this Collection's models.
 * @param {number} [sortDirection=1] Initial sort direction. 1 for ascending,
 * 	-1 for descending.
 *
 * @todo Option to autosort on add/reset/change.
 */
import _ from 'underscore';

const sortableCollection = function(opts) {
	const collection = opts.that || opts.collection;

	// Initial sort properties.
	collection.sortProperty = opts.sortProperty;
	collection.sortDirection = opts.sortDirection || 1;

	// Extend collection with methods, letting existing ones take precedence.
	_.defaults(collection, {

		/**
			 * Compares two models by this Collection's @property `sortProperty`
			 * and @property `sortDirection`.
			 *
			 * @param {Backbone.Model} a  Item to compare.
			 * @param {Backbone.Model} b  Another item to compare.
			 * @returns {int}  1, 0, or -1.
			*/
		comparator: function(a, b) {
			const isAsc = this.sortDirection >= 0;

			const prop = this.sortProperty;

			a = a.get(prop);
			b = b.get(prop);

			if (a instanceof Date && b instanceof Date) {
				return isAsc ? a - b : b - a;
			} else {
				if (isAsc) {
					if (a > b) return 1;
					else if (a < b) return -1;
				} else {
					if (a > b) return -1;
					else if (a < b) return 1;
				}

				return 0;
			}
		},

		/**
			 * Sorts data in this collection using property, with sort order
			 * determined by direction.
             *
			 * @param {string} property  Name of attribute to sort by, e.g. UserName.
			 * @param {int} direction  1 for ascending order, -1 for descending.
			 */
		sortData: function(property, direction) {
			this.sortProperty = property;
			this.sortDirection = direction;
			if (typeof property !== 'undefined' && typeof direction !== 'undefined') {
				this.sort();
			}
		},

		/**
			 * Called when a model is added to the collection.
			 *
			 * Checks if new model was inserted at a specific position. If it was,
			 * then this collection is no longer sorted. Clear the `sortProperty`
			 * and `sortDirection` properties.
			 *
			 * @param {Backbone.Model} model  Newly added model.
			 * @param {Backbone.Collection} coll  This collection.
			 * @param {object} opts  Add options.
			 */
		_onAddCheckSort: function(model, coll, opts) {
			if (typeof opts.at === 'number') {
				// Model was added at a custom position. Probably out of order.
				this.sortProperty = null;
				this.sortDirection = null;
			}
		}

	});

	collection.on('add', collection._onAddCheckSort);
};

export default sortableCollection;
