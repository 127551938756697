import $ from 'jquery';
import Marionette from 'marionette';
import app from 'app';
import ui from 'ui/main';
import tools from 'common/tools/main';
import HistoryViews from 'common/modules/logs/view';
import PagerControlsView from 'common/modules/pagerControls/view';
import tplLayout from './templates/tpl_layout.html';
import tplCompositeView from './templates/tpl_compositeView.html';
import tplItemView from './templates/tpl_itemView.html';
import tplEmptyItemView from './templates/tpl_emptyItemView.html';

const ItemView = HistoryViews.ItemView.extend({
	template: tplItemView
});

const EmptyView = HistoryViews.EmptyView.extend({
	template: tplEmptyItemView,
	templateHelpers: function() {
		// Confusingly, `this.collection` is the collection view.
		// The collection view itself has a `collection` property that
		// points to the actual set of models.
		return { collection: this.options.pagingCollection };
	},
	initialize: function() {
		this.listenTo(this.options.pagingCollection, 'fetching fetched', this.render);
	}
});

const CollectionView = HistoryViews.CollectionView.extend({
	template: tplCompositeView,
	childView: ItemView,
	emptyView: EmptyView,
	childViewOptions: function() {
		return { pagingCollection: this.collection };
	}
});

const LayoutView = Marionette.LayoutView.extend({
	template: tplLayout,
	className: 'paged-table',
	regions: {
		regionHeader: '.region-header',
		regionTable: '.region-table',
		regionPager: '.region-pager'
	},

	initialize: function() {
		tools.func.debounceResize(app, this, 'resizeTable');
	},

	onRender: function() {
		const that = this;
		const wm = that.options.workspaceModel;
		const collection = this.collection;
		app.request('app.services.jobs.getMetadataView', wm.get('Job'), { jobType: wm.get('JobType') }).done(function(metadataView) {
			that.regionHeader.show(metadataView);
		});
		this.regionTable.show(new CollectionView({ collection: collection }));
		this.regionPager.show(new PagerControlsView({ collection: collection }));

		ui.skeleton.refresh();
	},
	resizeTable: function() {
		const h = $('.workspace-content').height() - this.$('.region-pager').height();
		this.$('.table-scroll').height(h);
	}
});

export default LayoutView;
