var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<thead>\n	<tr>\n		<th style="width: 2.5em;">&nbsp;</th>\n		<th>\n			File\n			'+
((__t=( _generateSortHtml('name') ))==null?'':__t)+
'\n		</th>\n		<th style="width: 13em;">\n			Date \n			'+
((__t=( _generateSortHtml('lastModified') ))==null?'':__t)+
'\n		</th>\n		<th style="width: 6em;">\n			User\n			'+
((__t=( _generateSortHtml('author') ))==null?'':__t)+
'\n		</th>\n		<th style="width: 3em; text-align: center;">&nbsp;</th>\n	</tr>\n</thead>\n<tbody></tbody>\n';
}
return __p;
};
