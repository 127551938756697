/**
 * Includes all mixins.
 *
 * @module common/mixins
 */

import _ from 'underscore';
import cacheData from './cacheData';
import cardView from './cardView';
import customShow from './customShow';
import sortableCollection from './sortableCollection';
import sortableCollectionView from './sortableCollectionView';
import subsetCollection from './subsetCollection';
import pagingCollection from './pagingCollection';
import pagingCollectionView from './pagingCollectionView';
import resizableTable from './resizableTable';

const mixins = [
	cacheData,
	cardView,
	customShow,
	sortableCollection,
	sortableCollectionView,
	subsetCollection,
	pagingCollection,
	pagingCollectionView,
	resizableTable
];
const names = [
	'cacheData',
	'cardView',
	'customShow',
	'sortableCollection',
	'sortableCollectionView',
	'subsetCollection',
	'pagingCollection',
	'pagingCollectionView',
	'resizableTable'
];

// Returns an object where <mixin-name> => <mixin>.
export default _.object(names, mixins);
