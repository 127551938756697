import $ from 'jquery';
import Marionette from 'marionette';

import template from './template.ejs';

const NO_INPUT = 'NO_INPUT';
const INCORRECT_FORMAT = 422;

const IpRestrictionsView = Marionette.ItemView.extend({
	template: template,

	events: {
		'click .js-enable': 'showForm',
		'click .js-edit': 'showForm',
		'click .js-disable': 'disable',
		'click .js-submit': 'saveChanges',
		'click .js-cancel': 'onClickCancel',
		'keyup .js-ipRanges-input': 'formDirty'
	},

	initialize: function() {
		this.on('enabled', this.enabled);
		this.on('disabled', this.disabled);
	},

	onRender: function() {
		this.$('.js-disable, .js-submit').tmbutton();
		this.toggleState(this.model.get('IsEnabled'));
	},

	setMode: function(mode) {
		const $el = this.$el;
		const isEdit = mode === 'edit';
		$el.find('[data-mode="readOnly"]').toggleClass('hidden', !!isEdit);
		$el.find('[data-mode="editOnly"]').toggleClass('hidden', !isEdit);
	},

	toggleState: function(state) {
		// If state is true, show enabledOnly items and hide disabledOnly
		// items, and v.v. for state == false.
		const $el = this.$el;
		$el.find('[data-class="enabledOnly"]').toggleClass('hidden', !state);
		$el.find('[data-class="disabledOnly"]').toggleClass('hidden', !!state);
	},

	showMessage: function(msg, lvl, parentSelector) {
		let selector = '.js-messages';

		if (parentSelector) {
			selector = `${parentSelector} ${selector}`;
		}

		this.$el.find(selector).html(`<div class="msg ${lvl}">${msg}</div>`);
	},

	clearMessages: function() {
		this.$el.find('.js-messages').html('');
	},

	showForm: function() {
		this.clearMessages();
		this.setMode('edit');
	},

	hideForm: function() {
		this.setMode('read');
	},

	formDirty: function() {
		this.clearMessages();
	},

	parseIpRanges: function(str) {
		const ipRanges = str.split('\n');
		let n = ipRanges.length;
		let range;

		while (n--) {
			range = $.trim(ipRanges[n]);
			if (range === '') {
				ipRanges.splice(n, 1);
			} else {
				ipRanges[n] = range;
			}
		}

		return ipRanges;
	},

	saveChanges: function(e) {
		e.preventDefault();
		const $textarea = this.$el.find('.js-ipRanges-input');
		const ipRanges = this.parseIpRanges($textarea.val());
		if (ipRanges.length === 0) {
			this.trigger('enabled', 'fail', { status: NO_INPUT });
		} else {
			this.model.set('NewIpRanges', ipRanges);
			this.trigger('enableIpRestriction', ipRanges);
		}
	},

	enabled: function(status, err) {
		this.$('.js-submit').tmbutton('state', 'ready');

		let message;
		const model = this.model;

		if (status === 'success') {
			message = model.get('IsEnabled') ? 'Settings updated.' : 'IP restriction enabled.';

			// Update the model.
			model.set({ IsEnabled: true, IpRanges: model.get('NewIpRanges') });
			model.unset('NewIpRanges');

			// Re-render to easily hide the form, and show a success message.
			this.render();
			this.showMessage(message, 'success');
		} else {
			switch (err.status) {
				case NO_INPUT:
					message = 'Please enter at least one IP range.';
					break;
				case INCORRECT_FORMAT:
					message = 'Incorrect format. Please check IP addresses and try again.';
					break;
				default:
					message = 'Something went wrong. Please try again.';
			}
			this.showMessage(message, 'error', '.js-form');
		}
	},

	disable: function(e) {
		e.preventDefault();
		this.$('.js-disable').tmbutton('state', 'busy');
		this.trigger('disableIpRestriction');
	},

	disabled: function(status, err) {
		this.$('.js-disable').tmbutton('state', 'ready');

		if (status === 'success') {
			const model = this.model;
			model.set('IsEnabled', false);
			model.unset('IpRanges');
			this.render();
			this.showMessage('IP restriction disabled.', 'success');
		} else {
			this.showMessage('Something went wrong. Please try again.', 'error');
		}
	},

	onClickCancel: function(e) {
		e.preventDefault();
		this.render();
	},

	onDestroy: function() {
		this.$('.js-disable, .js-submit').tmbutton('destroy');
	}

});

export default IpRestrictionsView;
