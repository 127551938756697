import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'marionette';
import app from 'app';
import tools from 'common/tools/main';
import tplView from './templates/tpl_layout.html';
import tplNewerDoc from './templates/tpl_newerDoc.html';
import tplOlderDoc from './templates/tpl_olderDoc.html';
// import tplOlderDocOrphan from './templates/tpl_olderDoc_orphan.html';
import tplOlderDocNoMatch from './templates/tpl_olderDoc_noMatch.html';
import tplSummaryItem from './templates/tpl_summaryItem.html';
import tplOlderSetHeaderItem from './templates/tpl_olderSetHeaderItem.html';

const formatNumMatches = function(numMatches) {
	if (numMatches === 1) {
		return '1 pair of documents matched';
	} else {
		return `${numMatches} pairs of documents matched`;
	}
};

export const Layout = Marionette.LayoutView.extend({
	template: tplView,

	regions: {
		regionOlderSetsHeader: '#region-olderSets-header',
		regionOlderSetsBody: '#region-olderSets-body',
		regionNewerSetBody: '#region-newestSets-body',
		regionSummaryBody: '#region-summary-body'
	},

	initialize: function(opts) {
		// Set up local aliases
		this.blm = opts.blm;

		const blacklineModel = this.blm;
		const blacklineRows = blacklineModel.get('blacklineRows');
		const oldDocSetCollection = blacklineModel.get('oldDocSetColl');

		this.listenTo(oldDocSetCollection, 'add', this.onModelAddOldDocSet);
		this.listenTo(oldDocSetCollection, 'remove', this.onModelRemoveOldDocSet);
		this.listenTo(oldDocSetCollection, 'reset', this.onCollectionResetOldDocSets);
		this.listenTo(blacklineRows, 'reset', this.onBlacklineRowReset);
		this.listenTo(blacklineModel, 'change:newDocSet', this.onChangeNewDocSet);
		this.listenTo(blacklineModel, 'change:numMatches', this.onChangeNumMatches);

		tools.func.debounceResize(app, this, 'setViewHeight');
	},

	events: {
		'click .bl-tb-olderSets-scroll-l:not(.disabled)': 'scrollOlderSetsLeft',
		'click .bl-tb-olderSets-scroll-r:not(.disabled)': 'scrollOlderSetsRight',
		'click .bl-tb-sort-toggle': 'onClickToggleSort',
		'click .bl-tb-filterIcon': 'onClickToggleSummaryFilterMenu',
		'click .bl-tb-summary .btn-clear': 'onClickClearAllMatch',
		'keyup .bl-tb-search': 'onKeyUpSearch'
	},

	templateHelpers: function() {
		return {
			rows: this.blm.get('blacklineRows'),
			numMatches: formatNumMatches(this.blm.get('numMatches'))
		};
	},

	onChangeNumMatches: function() {
		this.$el.find('.blackline-numMatches').html(formatNumMatches(this.blm.get('numMatches')));
	},

	onKeyUpSearch: function(e) {
		e.preventDefault();
		const searchString = this.$el.find('.bl-tb-search').val();
		this.trigger('search', searchString);
	},

	onBlacklineRowReset: function() {
		this.$el.find('.bl-tb-search').val('');
	},

	onClickClearAllMatch: function() {
		this.trigger('clearMatches');
	},

	setViewHeight: function() {
		const $el = this.$el;

		const $content = $('.workspace-content');

		if ($content.length) {
			const $header = $el.find('.tbf-header');

			const $headViewport = $el.find('.bl-tb-head-viewport');

			const $bodyViewport = $el.find('.bl-tb-body-viewport');

			const padding = $content.find('.blackline-left').getPadding('top') * 2;

			const height = $content.height() - $header.outerHeight() - $headViewport.outerHeight() - padding;

			$bodyViewport.outerHeight(height);
		}
	},

	/**
	 Click handler for display name column's sort toggle. Sorts table in
	 ascending/descending order.

	 @eventHandler
	 */
	onClickToggleSort: function(e) {
		const $toggle = $(e.target);
		let direction;

		if ($toggle.hasClass('asc')) {
			// clicking on it again makes it 'dsc'
			direction = -1;
			$toggle.removeClass('asc fa-sort-up').addClass('dsc fa-sort-down');
		} else {
			direction = 1;
			$toggle.removeClass('dsc fa-sort-down').addClass('asc fa-sort-up');
		}

		this.trigger('sort', 'displayName', direction);
	},

	/**
	 Shows/hides summary column's filter menu.

	 @todo the menu html is currently static, read in from layout_blacklineTable.html
	 @todo will want to save a reference to context menu ($el or view, whatever it may be) and toggle that instead

	 @param e {$.Event} Click event on filter menu toggle.
	 */
	onClickToggleSummaryFilterMenu: function(e) {
		e.stopPropagation();

		const $el = $(this.el);

		const $menu = $el.find('.context-menu');

		if ($menu.is(':hidden')) {
			// We're going to show it, so we need to figure out where to
			// place this menu. Want it to align right.
			const $th = $(e.target).closest('.bl-tb-colgroup-cap');

			const $table = $el.find('.bl-tb');

			const top = $th.outerHeight() - 2;
			// -2 for borders

			const left = $table.width() - $menu.width() - 2;

			$menu.css({ top: top, left: left });
		}

		$menu.toggle();
	},

	disableOlderSetScrollButtons: function(left, right) {
		const $table = $('.bl-tb');

		const $scrollL = $table.find('.bl-tb-olderSets-scroll-l');

		const $scrollR = $table.find('.bl-tb-olderSets-scroll-r');

		$scrollL.toggleClass('disabled', left);
		$scrollR.toggleClass('disabled', right);
	},

	isLastOlderSetVisible: function() {
		const $viewport = this.$el.find('.bl-tb-head-viewport').find('.bl-tb-olderSets');

		const $viewportInner = $viewport.find('.bl-tb-olderSets-viewport-inner');

		const $olderSetCols = $viewport.find('.bl-tb-col-olderSet');

		const viewportW = $viewport.width();

		const left = $viewportInner.position().left;

		const width = $olderSetCols.length * $olderSetCols.first().outerWidth(); // all older set columns have the same width

		return viewportW >= width || width + left <= viewportW;
	},

	getDistanceToOlderSetContainerEdge: function(edge) {
		const $viewport = this.$el.find('.bl-tb-head-viewport').find('.bl-tb-olderSets');

		const $viewportInner = $viewport.find('.bl-tb-olderSets-viewport-inner');

		const viewportW = $viewport.width();

		const left = $viewportInner.position().left;

		if (edge === 'left') {
			return Math.abs(Math.min(0, left));
		} else if (edge === 'right') {
			const numOlderSets = this.blm.get('oldDocSetColl').length;
			const olderSetW = $viewport
				.find('.bl-tb-col-olderSet')
				.first()
				.outerWidth();
			return olderSetW > 3 ? numOlderSets * olderSetW + left - viewportW : 0;
		}
	},

	scrollOlderSets: function(direction) {
		let delta;
		const change = direction === 'left' ? '+' : '-';

		const $viewportInner = this.$el.find('.bl-tb-olderSets-viewport-inner');

		const maxScrollDist = 50;
		let distToEdge;

		// Finish all queued scrolls first, before we decide whether
		// further scrolling is necessary.
		$viewportInner.finish();

		if (
			(direction === 'left' && $viewportInner.position().left <= 0) ||
			(direction === 'right' && !this.isLastOlderSetVisible())
		) {
			distToEdge = this.getDistanceToOlderSetContainerEdge(direction);
			delta = Math.min(maxScrollDist, distToEdge);
			$viewportInner.animate({ left: `${change}=${delta}` }, 200);
		}

		const reachedEdge = maxScrollDist >= distToEdge;
		this.disableOlderSetScrollButtons(direction === 'left' && reachedEdge, direction === 'right' && reachedEdge);
	},

	scrollOlderSetsLeft: function() {
		this.scrollOlderSets('left');
	},

	scrollOlderSetsRight: function() {
		this.scrollOlderSets('right');
	},

	scrollOlderSetsFront: function() {
		const $viewportInner = this.$el.find('.bl-tb-olderSets-viewport-inner');
		$viewportInner.animate({ left: '0' }, 200);
		this.disableOlderSetScrollButtons(true, false);
	},

	scrollOlderSetsEnd: function() {
		const $viewportInner = this.$el.find('.bl-tb-olderSets-viewport-inner');
		const pos = this.getDistanceToOlderSetContainerEdge('right') || '1';
		$viewportInner.animate({ left: `-=${pos}` }, 200);
		this.disableOlderSetScrollButtons(false, true);
	},

	setOldSetColgroupSize: function(numOldSets) {
		const $el = this.$el;

		const $older = $el.find('.bl-tb-olderSets');

		const $newer = $el.find('.bl-tb-col-fundname');

		const $scrollBtns = $el.find('.bl-tb-olderSets-scroll-l, .bl-tb-olderSets-scroll-r');

		// 4+ doc sets treated as one case.
		if (numOldSets > 4) numOldSets = 4;

		// Remove any existing sizing classes.
		$older.removeClass('bl-tb-olderSets-2 bl-tb-olderSets-3 bl-tb-olderSets-4');
		$newer.removeClass('bl-tb-col-fundname-2 bl-tb-col-fundname-3 bl-tb-col-fundname-4');

		// Add new sizing classes if needed.
		if (numOldSets > 1) {
			$older.addClass(`bl-tb-olderSets-${numOldSets}`);
			$newer.addClass(`bl-tb-col-fundname-${numOldSets}`);
		}

		// Show/hide scroll buttons.
		$scrollBtns.toggle(numOldSets > 3);
	},

	onModelAddOldDocSet: function(model) {
		const numOldSets = this.blm.get('oldDocSetColl').length;
		this.setOldSetColgroupSize(numOldSets);
		if (numOldSets > 3) this.scrollOlderSetsEnd();
	},

	onModelRemoveOldDocSet: function(model) {
		const numOldSets = this.blm.get('oldDocSetColl').length;
		this.setOldSetColgroupSize(numOldSets);
		if (numOldSets >= 3) this.scrollOlderSetsEnd();
	},

	onChangeNewDocSet: function() {
		this.$('.empty-state').addClass('hidden');
	},

	onCollectionResetOldDocSets: function(oldDocSetCollection) {
		this.setOldSetColgroupSize(oldDocSetCollection.length);
		this.scrollOlderSetsFront();
	}
});

export const OldDocView = Marionette.ItemView.extend({
	getTemplate: function() {
		return this.oldDocModel ? tplOlderDoc : tplOlderDocNoMatch;
	},

	className: 'bl-tb-td',

	events: {
		'change form input': 'onCheckboxChange'
		// 'change form select': 'onSelectChange'
	},

	modelEvents: {
		'change:isVisible': 'render'
	},

	initialize: function(opts) {
		// Model is a BlacklineRow. The old docset document that this
		// cell visually represents is located at this.oldDocModel.

		const blacklineRow = this.model;
		this.matchKey = this.model.id;

		this.docSetId = opts.docSetId;
		const documents = blacklineRow.get('oldDocs');
		const document = documents.findWhere({ DocSetId: this.docSetId });
		this.oldDocModel = document;

		if (document) {
			this.listenTo(document, 'change:PdfUri', this.render);
			this.listenTo(document, 'change:Selected', this.onDocChangeSelected);
		}

		if (documents) {
			this.listenTo(documents, 'reset', this.render);
		}
	},

	serializeData: function() {
		const doc = this.oldDocModel;
		const data = {
			matchKey: this.matchKey,
			docSetId: this.docSetId,
			docId: doc ? doc.id : '',
			pdfUri: doc ? doc.get('PdfUri') : '',
			checked: doc ? (doc.get('Selected') ? 'checked' : '') : ''
		};
		return data;
	},

	onRender: function() {
		const isVisible = this.model.get('isVisible');
		this.$el.toggleClass('hidden', !isVisible);
	},

	onCheckboxChange: function(e) {
		e.preventDefault();
		const checked = $(e.target).prop('checked');
		this.trigger('matchToDocSet', this.matchKey, this.docSetId, checked);
	},

	onDocChangeSelected: function() {
		// Make sure checkbox mirrors the state in model
		const isSelected = this.oldDocModel.get('Selected');
		this.$el.find('input:checkbox').prop('checked', isSelected);
	}
});

export const OldSetView = Marionette.CollectionView.extend({
	className: 'bl-tb-col bl-tb-col-olderSet',
	childView: OldDocView,
	childViewOptions: function() {
		return { docSetId: this.model.id };
	},
	initialize: function(options) {
		// Has an old docset at this.model.
		// Set its collection to the blackline rows.
		this.collection = options.blm_rows;

		this.on('childview:matchToDocSet', function(view, matchKey, docSetId, selection) {
			this.trigger('matchToDocSet', matchKey, docSetId, selection);
		});
	}
});

export const OldDocCollectionView = Marionette.CollectionView.extend({
	className: 'bl-tb-olderSets-viewport-inner',
	childView: OldSetView,
	childViewOptions: function() {
		return { blm_rows: this.options.blm_rows };
	},
	initialize: function(options) {
		this.on('childview:matchToDocSet', function(view, matchKey, docSetId, selection) {
			this.trigger('matchToDocSet', matchKey, docSetId, selection);
		});
	}
});

export const OldDocHeaderItemView = Marionette.ItemView.extend({
	template: tplOlderSetHeaderItem,
	className: 'bl-tb-col bl-tb-col-olderSet',

	events: {
		'change input': 'onCheckboxChange'
	},

	initialize: function() {
		// The model here is the old docset.
		this.listenTo(this.model, 'docSelectionChanged', this.onModelSelectionChange);
	},

	serializeData: function() {
		const model = this.model;
		return {
			oldDocSetId: model.id,
			marker: model.get('marker')
		};
	},

	onModelSelectionChange: function(selectedDoc, selection) {
		// This should check if all newer docs are all matched to docs in this old docset. @todo
		// (Currently, it checks if all docs in this docset is matched.)
		const documents = this.model.get('Documents');
		// An array of booleans, to indicate whether items are selected or not.
		const selected = documents.pluck('Selected');
		const numSelected = _.filter(selected, item => item === true).length;
		const isAllSelected = numSelected === documents.length;
		const isNoneSelected = numSelected === 0;
		const state = !isAllSelected && !isNoneSelected ? 'indeterminate' : isAllSelected ? 'all' : 'none';
		this.updateCheckboxState(state);
	},

	updateCheckboxState: function(state) {
		const $checkbox = this.$el.find('input[type="checkbox"]');

		switch (state) {
			case 'indeterminate':
				$checkbox.prop('indeterminate', true);
				break;
			case 'all':
				$checkbox.prop('indeterminate', false);
				$checkbox.prop('checked', true);
				break;
			case 'none':
				$checkbox.prop('indeterminate', false);
				$checkbox.prop('checked', false);
				break;
		}
	},

	onCheckboxChange: function(e) {
		// Select/deselect entire docset
		const checked = $(e.target).prop('checked');
		this.trigger('matchAllToDocSet', this.model.id, checked);
	}
});

export const OldDocHeaderCollectionView = Marionette.CollectionView.extend({
	className: 'bl-tb-olderSets-viewport-inner',
	childView: OldDocHeaderItemView,
	initialize: function() {
		this.on('childview:matchAllToDocSet', function(view, docSetId, selection) {
			this.trigger('matchAllToDocSet', docSetId, selection);
		});
	}
});

export const NewDocView = Marionette.ItemView.extend({
	template: tplNewerDoc,
	className: 'bl-tb-td',

	initialize: function() {
		// Model is a blackline row.
		const model = this.model;
		this.listenTo(model, 'change:isVisible', this.render);
		this.listenTo(model.get('newDoc'), 'change:PdfUri', this.render);
	},

	serializeData: function() {
		const model = this.model;
		return {
			displayName: model.get('displayName'),
			pdfUri: model.get('newDoc').get('PdfUri')
		};
	},

	onRender: function() {
		const isVisible = this.model.get('isVisible');
		this.$el.toggleClass('hidden', !isVisible);
	}
});

export const NewSetView = Marionette.CollectionView.extend({
	className: 'bl-tb-col bl-tb-col-fundname',
	childView: NewDocView
});

export const SummaryItemView = Marionette.ItemView.extend({
	template: tplSummaryItem,
	className: 'bl-tb-td',

	modelEvents: {
		'change:marker change:isVisible': 'render'
	},

	serializeData: function() {
		const model = this.model;
		return { marker: model.get('marker') };
	},

	onRender: function() {
		const isVisible = this.model.get('isVisible');
		this.$el.toggleClass('hidden', !isVisible);
	}
});

export const SummaryColumnView = Marionette.CollectionView.extend({
	childView: SummaryItemView,
	className: 'bl-tb-col bl-tb-col-summary'
});
