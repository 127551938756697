// todo harcoded here
// var moduleConfig = module.config();

// var ui = {
// 	config: {
// 		dev: !!moduleConfig.dev,
// 		styleguide: !!moduleConfig.styleguide,
// 	}
// };

const config = {
	dev: true,
	styleguide: false
};

export default config;
