import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import appSettings from 'config';

const repositoryPrefix = appSettings.RepositoryUri;

const DocumentModel = Backbone.Model.extend({
	// when blacklining, documents with the same master entity key are added to a collection
	// if two documents
	idAttribute: 'CompositeId',
	defaults: {
		PdfUri: '',
		Selected: false
	},
	initialize: function(attrs, opts) {
		this.app = opts.app;

		const that = this;
		const collectionOpts = this.collection.options;
		const id = that.get('Id');
		const docSetId = collectionOpts.docSetId;

		this.set({
			CompositeId: `${docSetId}/${id}`,
			DocSetId: docSetId
		});

		const pdfUri =
			`${repositoryPrefix}resources/clients/${
				collectionOpts.clientId
			}/jobtypes/${
				collectionOpts.jobTypeId
			}/outputs/${docSetId}/${id}.pdf`;

		that.set('PdfUri', pdfUri);
	}
});

const DocumentCollection = Backbone.Collection.extend({
	model: DocumentModel,
	initialize: function(models, options) {
		this.options = options || {};
	}
});

const SetModel = Backbone.Model.extend({
	idAttribute: 'Id',
	defaults: {
		DownloadUri: '',
		StatsUri: ''
	},
	initialize: function(attrs, opts) {
		this.app = opts.app;

		// alias Id to SourceId
		this.set('Id', this.get('Id'));

		// cast date into Date obj
		if (this.has('Date')) this.set('Date', new Date(Date.parse(this.get('Date'))));

		this.set('NumberOfDocuments', this.get('DocsCount'));

		if (!opts || !opts.lazyLoad) this.load();
	},
	load: function() {
		if (!this.loaded && !this.loading) {
			const that = this;
			that.loading = true;

			$.whenAll(this.fetchAdditionalData(), this.fetchTags(), this.fetchDownloadUris()).done(function() {
				that.loading = false;
				that.loaded = true;
			});
		}
	},
	fetchAdditionalData: function() {
		const that = this;
		const app = this.app;
		const clientId = this.get('ClientId');
		const jobTypeId = this.get('JobTypeId');

		// fetch data that wasn't provided to us
		const dJobType = app.request('app.services.jobTypes.get', clientId, jobTypeId);
		const dJob = app.request('app.services.jobs.getSummary', clientId, jobTypeId, this.get('JobId'));
		const dUser = app.request('app.services.users.getProfile', clientId, this.get('UserId'));

		return $.whenAll(dJobType, dJob, dUser).then(function(jobType, job, user, tags) {
			if (jobType instanceof Backbone.Model) {
				that.set('JobTypeName', jobType.get('DisplayName'));
			}
			if (_.has(job, 'DisplayName')) {
				that.set('JobName', job.DisplayName);
			}
			if (user instanceof Backbone.Model) {
				that.set('UserName', user.get('Email'));
			}
			return that;
		});
	},
	fetchTags: function() {
		const that = this;
		const path =
			`clients/${this.get('ClientId')}/jobtypes/${this.get('JobTypeId')}/outputs/${this.get('Id')}`;

		return this.app.repository.getTags(path).then(function(tags) {
			const i = _.findIndex(tags, function(item) {
				return /^final$/i.test(item);
			});

			that.set('_isFinal', i > -1);

			return tags;
		});
	},
	fetchDownloadUris: function() {
		const that = this;
		const app = this.app;
		const prefix =
			`clients/${this.get('ClientId')}/jobtypes/${this.get('JobTypeId')}/outputs/${this.get('Id')}`;
		const promises = [];
		const isDocumentSet = this.get('OutputType') === 'DocumentSet';

		// If blackline set, override default filename with custom filename.
		const opts = isDocumentSet ? {} : {
			responseFilename: 'blacklines.zip'
		};

		promises.push(
			app.repository.getSignedUrl(`${prefix}/documents.zip`, opts).then(url => {
				that.set('DownloadUri', url);
			})
		);

		if (isDocumentSet) {
			// Only document sets have stats.txt.
			promises.push(
				app.repository.getSignedUrl(`${prefix}/stats.txt`).done(url => {
					that.set('StatsUri', url);
				})
			);
		}

		return $.whenAll.apply($, promises);
	},
	fetchDocuments: function() {
		const that = this;
		const d = $.Deferred();

		this.docsPromise = d.promise();

		const clientId = this.get('ClientId');
		const outputId = this.get('Id');

		const key = `publisher/${clientId}/publishsets/${outputId}/manifest`;
		$.when(this.app.request('app.services.meta.get', key)).then(function(manifest) {
			that.set('_Documents', manifest.documents);
			d.resolve(manifest.documents);
		});

		return d.promise();
	},
	formatDocuments: function() {
		const that = this;
		const d = $.Deferred();
		const clientId = this.get('ClientId');
		const jobTypeId = this.get('JobTypeId');

		// Exit if docs are already formatted.
		// const docs = this.get('Documents');
		// const rawDocs = this.get('_Documents');
		// if (docs instanceof Backbone.Collection && docs.length === rawDocs.length) return;

		// If docs haven't been fetched yet, go get them.
		const docsPromise = this.docsPromise || this.fetchDocuments();

		docsPromise.done(function(rawDocs) {
			const docs = new DocumentCollection(rawDocs, { clientId, jobTypeId, docSetId: that.get('Id'), app: that.app });
			that.set('Documents', docs);
			d.resolve(docs);
		});

		return d.promise();
	}
});

const SetCollection = Backbone.Collection.extend({ model: SetModel });

export default {
	DocumentModel,
	DocumentCollection,
	SetModel,
	SetCollection
};
